import { Component } from "../components/Component";
import { VideoPlatform } from "./VideoPlatform";
import { RectangleComponent } from "../components/RectangleComponent";
import { Input } from "../inputs/Input";
import { JsonObject, JsonProperty } from "json2typescript";
import { absoluteComponent, fromJson, jsonConvert } from "../../Common/JsonUtil";
import { StringInput } from "../inputs/StringInput";
import { ImageInput } from "../inputs/ImageInput";
import { MultiInput } from "../inputs/MultiInput";
import { convertInput } from "../inputs/InputFactory";
import { allComponents, convertComponent } from "../components/ComponentFactory";

@JsonObject("Template")
export class Template {

    @JsonProperty("video", Component)
    video: Component = new RectangleComponent();

    @JsonProperty("snapVideo", Component)
    snapVideo: Component = new RectangleComponent();


    @JsonProperty("instaVideo", Component)
    instaVideo: Component = new RectangleComponent();


    @JsonProperty("instaStoryVideo", Component)
    instaStoryVideo: Component = new RectangleComponent();


    @JsonProperty("youTubeVideoLandscape", Component)
    youTubeVideoLandscape: Component = new RectangleComponent();


    @JsonProperty("youTubeVideoPortrait", Component)
    youTubeVideoPortrait: Component = new RectangleComponent();


    @JsonProperty("twitterVideoLandscape", Component)
    twitterVideoLandscape: Component = new RectangleComponent();


    @JsonProperty("twitterVideoSquare", Component)
    twitterVideoSquare: Component = new RectangleComponent();

    @JsonProperty("tikTok", Component)
    tikTok: Component = new RectangleComponent();

    @JsonProperty("variable", [Input])
    variable: Input[] = [];

    initialVariable: Input[] = [];

    processedInputs: Input[] = [];
    previewMultiplier:number = 1.0;

    absoluteComponentWithSubstitutions(videoPlatform:VideoPlatform):Component {
        return absoluteComponent(this.videoComponent(videoPlatform), this.processedInputs, videoPlatform);
    }

    videoComponent(videoPlatform:VideoPlatform):Component {
        if (videoPlatform == VideoPlatform.TIKTOK) {
            if (this.tikTok.subComponents.length === 0) {
                return this.snapVideo;
            }
            return this.tikTok;
        }
        switch (videoPlatform) {
            case VideoPlatform.FACEBOOK:
                return this.video;
            case VideoPlatform.SNAPCHAT:
                return this.snapVideo;
            case VideoPlatform.INSTAGRAM:
                return this.instaVideo;
            case VideoPlatform.INSTA_STORY:
                return this.instaStoryVideo;
            case VideoPlatform.YOUTUBE_LANDSCAPE:
                return this.youTubeVideoLandscape;
            case VideoPlatform.YOUTUBE_PORTRAIT:
                return this.youTubeVideoPortrait;
            case VideoPlatform.TWITTER_LANDSCAPE:
                return this.twitterVideoLandscape;
            case VideoPlatform.TWITTER_SQUARE:
                return this.twitterVideoSquare;
        }
        return this.video;
    }

    async render(frameNumber:number, videoPlatform:VideoPlatform):Promise<HTMLCanvasElement> {
        let abComponent = this.absoluteComponentWithSubstitutions(videoPlatform);

        let img = await abComponent.render(frameNumber, this.previewMultiplier, null, videoPlatform);
        return img;
    }


    async renderInCanvas(frameNumber:number, videoPlatform:VideoPlatform, canv:HTMLCanvasElement):Promise<void> {
        let abComponent = this.absoluteComponentWithSubstitutions(videoPlatform);

        let img = await abComponent.render(frameNumber, this.previewMultiplier, null, videoPlatform);
        let context = canv.getContext('2d');
        if (context !== null) {
            context.clearRect(0, 0, canv.width, canv.height);
            context.drawImage(img, 0, 0, img.width, img.height, 0, 0, canv.width, canv.height);
        }
        return;
    }

    

    async process(videoPlatform: VideoPlatform): Promise<void> {
        try {
            let newInputList: Input[] = [];
            this.variable.forEach((element, index) => {
                if (element.isVisible) {
                    newInputList.push(element);
                }
            });
            const oldInputList: Input[] = [];
            this.processedInputs.forEach((element, index) => {
                oldInputList.push(element);
            });
            this.processedInputs = [];

            let newMap = new Map<string, Input>();
            newInputList.forEach((input, index) => {
                newMap[input.name] = input;
            });

            let oldMap = new Map<string, Input>();
            oldInputList.forEach((input, index) => {
                oldMap[input.name] = input;
            });


            for (var key in oldMap) {
                let oldInput = oldMap[key];
                let newInput = newMap[key];
                if (oldInput === undefined) {
                    continue;
                }
                if (newInput === undefined) {
                    oldInput.dispose();
                    continue;
                }
                else if (newInput.type !== oldInput.type || newInput.getValue() !== (oldInput.getValue())) {
                    oldInput.dispose();
                    await newInput.process();
                    this.processedInputs.push(newInput);
                }
                else {
                    this.processedInputs.push(oldInput);
                }
                newMap.delete(key);
            }
            for (var key in newMap) {
                let newInput = newMap[key];
                if (newInput === undefined) {
                    continue;
                }
                await newInput.process();
                this.processedInputs.push(newInput);
            }

        } catch (e) {
            console.log((<Error>e));
        }
    }

    onObjectConversionFromJson(): void {
        let correctedVariable: Input[] = [];
        this.variable.forEach((element) => {
           correctedVariable.push(convertInput(element));
        });
        this.variable = correctedVariable;
        this.initialVariable = Object.assign([], correctedVariable);
        this.video = convertComponent(this.video);
        this.snapVideo = convertComponent(this.snapVideo);
        this.instaVideo = convertComponent(this.instaVideo);
        this.instaStoryVideo = convertComponent(this.instaStoryVideo);
        this.youTubeVideoLandscape = convertComponent(this.youTubeVideoLandscape);
        this.youTubeVideoPortrait = convertComponent(this.youTubeVideoPortrait);
        this.twitterVideoLandscape = convertComponent(this.twitterVideoLandscape);
        this.twitterVideoSquare = convertComponent(this.twitterVideoSquare);
        this.tikTok = convertComponent(this.tikTok);
    }

}