import { JsonObject, JsonProperty } from "json2typescript";
import { ExpressionParams, ExpressionParamsBuilder } from "./ExpressionParams";
import { evaluate } from "./MathExpressionStatement";


@JsonObject("StepExpression")
export class StepExpression {


    @JsonProperty("start")
    start:string = "0";

    @JsonProperty("end")
    end:string = "100000";

    @JsonProperty("expression")
    expression:string = "0";


    startVal(params:ExpressionParams):number {
        try {
            return evaluate(params.substitute(this.start));
        } catch (e) {
            return 0;
        }
    }

    endVal(params:ExpressionParams):number {
        try {
            return evaluate(params.substitute(this.end));
        } catch (e) {
            return -1;
        }
    }

    stepRelativeFrame(params:ExpressionParams):number {
        return params.superFrameNumberVal()  - this.startVal(params);
    }

    value(expressionParams:ExpressionParams):number {
        return evaluate(expressionParams.substitute(this.expression));
    }

}

@JsonObject("Expression")
export class Expression {

    @JsonProperty("baseValue", String)
    baseValue:string = "0";

    @JsonProperty("delta", Number)
    delta:number = 0;

    @JsonProperty("multiplier", Number)
    multiplier:number = 1.0;

    @JsonProperty("offsets", [StepExpression])
    offsets:StepExpression[] = [];


    constructor(base:string) {
        this.baseValue = base;
    }


    baseValueVal(params:ExpressionParams):number {
        return evaluate(params.substitute(this.baseValue)) + (this.delta);
    }

    baseValueWithoutDelta(params:ExpressionParams):number  {
        try {
            return evaluate(params.substitute(this.baseValue));
        }
        catch (e){
            return 0;
        }
    }

    evaluateExpression(params:ExpressionParams):number {
        return this.evaluateExpressionInternal(params,true);
    }

    evaluateExpressionInternal(params:ExpressionParams,shouldUseMultiplier:boolean):number {
        try {
            let baseValue = this.baseValueVal(params);
            let expression:StepExpression | null = null;
            if (this.offsets != null) {
                let arr = this.offsets.filter(stepExpression => stepExpression.startVal(params)<=params.superFrameNumberVal() && params.superFrameNumberVal()<=stepExpression.endVal(params));
                if (arr.length > 0) {
                    expression = arr[0];
                }
            }
            if (expression === null) {
                return shouldUseMultiplier? baseValue * ((this.multiplier)): baseValue;
            }
            let builder:ExpressionParamsBuilder = new ExpressionParamsBuilder(params).relativeStart(expression.startVal(params)).relativeEnd(expression.endVal(params)).relativeFrameNumber(expression.stepRelativeFrame(params));
            let addedOffset:number = baseValue + (expression.value(builder.expressionParams));
            return shouldUseMultiplier?addedOffset * ((this.multiplier)):addedOffset;
        }
        catch (e) {
            throw("Unable to process value");
        }
    }



}