import { SessionStorage, VideoPlayer } from 'frontend-core';
import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../appContext';
import Routes from '../../common/routes';
import VideoWithBg from '../../common/video-with-bg';
import constants from '../../constants';
import Authenticate from '../Authenticate';
import { LandingContext } from './LandingContext';
import LandingLayout from './Layout';

const metaPartnersLink = 'https://www.facebook.com/business/partner-directory/search?solution_type=campaign_management&id=3893517700693255&section=overview';

const Home: React.FC = () => {

  const { authModal } = useContext(AppContext);
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [showVideoPlayer, setShowVideoPlayer] = useState<boolean>(false);
  const { setShowContactModal, showContactModal } = useContext(LandingContext);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const openVideoPlayer = (url) => {
    setVideoUrl(url);
    setShowVideoPlayer(true);
  };

  const closeVideoPlayer = () => setShowVideoPlayer(false);


  const navigateLeft = () => {
    // setOpacityAnimation(leftScrollBtn);
    const t = document.getElementById('carousel-cards');
    const card = document.getElementsByClassName('card')[0];
    let scrollAmount = 0;
    if (window.innerWidth < 900 && t) {
       scrollAmount = t?.clientWidth + 10;
    }
    else {
      scrollAmount = card.clientWidth + 80;
    }
    // console.log("🚀 ~ file: Home.tsx:29 ~ navigateLeft ~ t.clientWidth", t?.clientWidth);
    t?.scrollTo({
      left: Math.max(t.scrollLeft - (scrollAmount), 0),
      behavior: "smooth"
    });
    // toggleNavigations();
  };
  
  const navigateRight = () => {
    // setOpacityAnimation(rightScrollBtn);
    const t = document.getElementById('carousel-cards');
    const card = document.getElementsByClassName('card')[0];

    let scrollAmount = 0;
    if (window.innerWidth < 900 && t) {
      scrollAmount = t?.clientWidth + 10;
   }
    else {
      scrollAmount = card.clientWidth + 80;
    }

    t?.scrollTo({
      left: t.scrollLeft + (scrollAmount),
      behavior: "smooth"
    });
    // toggleNavigations();
  };
  // toggleNavigations();

  function handleTouchStart(e) {
    setTouchStart(e.targetTouches[0].clientX);
  }

  function handleTouchMove(e) {
    setTouchEnd(e.targetTouches[0].clientX);
  }

  function handleTouchEnd() {
    if (touchStart - touchEnd > 50) {
      navigateRight();
    }

    if (touchStart - touchEnd < -50) {
      navigateLeft();
    }
  }


  return (
    <LandingLayout>
      <div className="home">
        <div className="bg-content">
          <div className="layout-container flex">
            <div className="left">
              <div className="h3">Supercharge your growth.</div>
              <div className="h1">
                Want
                <span className="highlight"> 1,000s of unique ads </span>
                per month?
              </div>
              <div className="caption">
                Our technology-powered platform generates ads at scale in fully-automated paid media campaigns on Facebook, TikTok, Google, and more. With 10 years of experience and $500m+ in managed spend, we're ready to take you to the top.
              </div>

              <div className='flex gap-15 mt-40 mb-10 flex-wrap'>
                <button
                  type="button"
                  className="btn v2 medium"
                  onClick={() => {
                    setShowContactModal(true);
                    window['contactOpenSource'] = 'HomePage::Enterprise Support CTA';
                  }}
                >
                  Enterprise Support
                </button>

                <button
                  type="button"
                  className="btn v2 medium"
                  onClick={() => {
                    window.open('https://www.smartblitz.ai?signup=true', '_blank');
                  }}
                >
                  Try SMART for Free
                </button>
              </div>
            </div>
            <div className="right w-p50">
              <VideoWithBg
                videoName="StudioHero"
                posterUrl={constants.imagesUrl + "blitz-web-reel-v2-poster.png"}
                videoUrl={constants.videosUrl + "blitz-web-reel-v2.mp4"}
                backgroundColor="transparent"
                alignment="left"
                // iconSize='large'
                fullWidth={true}
                iconTheme="white"
                classes={{
                  container: 'hero-video',
                }}
              />
            </div>
          </div>
          <div className="layout-container mt-40 social-icons-container">
            <a
              target="_blank"
              rel="noreferrer"
              href={metaPartnersLink}
              className="meta-partners flex jcc"
            >
              <img src={constants.imagesUrl + 'icons/meta-partners.png'} alt="" width="200" />
            </a>
            <img 
              className='social-icon' 
              src={'https://media.useblitz.com/assets/images/social-icons/facebook.png'} 
              alt="" 
            />
            <img 
              className='social-icon' 
              src={'https://media.useblitz.com/assets/images/social-icons/instagram.png'} 
              alt="" 
            />
            <img 
              className='social-icon' 
              src={'https://media.useblitz.com/assets/images/social-icons/twitter.png'} 
              alt="" 
            />
            <img 
              className='social-icon' 
              src={'https://media.useblitz.com/assets/images/social-icons/snapchat.png'} 
              alt="" 
            />
            <img 
              className='social-icon' 
              src={'https://media.useblitz.com/assets/images/social-icons/tiktok.png'} 
              alt="" 
            />
            <img 
              className='social-icon' 
              src={'https://media.useblitz.com/assets/images/social-icons/adwords.png'} 
              alt="" 
            />
          </div>
        </div>

        <div className="layout-container brands">
          <div className="h2 text-center">Our Customers</div>
          <ul className="brands-list">
            <li className="brand joyride"></li>
            <li className="brand gopuff"></li>
            <li className="brand buzzer"></li>
            <li className="brand pixelcut"></li>
            <li className="brand atoms"></li>
            <li className="brand real"></li>
            <li className="brand spadeco"></li>
            <li className="brand onward"></li>
            <li className="brand talkspace"></li>
            <li className="brand elsa"></li>
            <li className="brand mercari"></li>
            <li className="brand matchday"></li>
            <li className="brand sparkcamera"></li>
            <li className="brand dispo"></li>
            <li className="brand convo"></li>
          </ul>
        </div>

        <div className="testimonials-container">
          <div className="layout-container testimonials">
            <div className="thin-primary-text">TESTIMONIAL</div>
            <div className="h2">What Our Customers Say</div>
            <div className="carousel-container">
              <div 
                className="carousel-cards" 
                id="carousel-cards"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
              >
                <div className="controls previous" onClick={navigateLeft}></div>
                <div className="controls next" onClick={navigateRight}></div>
                <div className="card">
                  <div className="quote"></div>
                  <div className="content">
                    The Blitz team are attentive performance marketers who treat our partnership as though they work on our internal team. From the beginning of our partnership, their creative and analytic prowess has allowed us to scale considerably on paid networks without sacrificing performance.
                  </div>
                  <div className="brand buzzer"></div>
                </div>
                <div className="card">
                  <div className="quote"></div>
                  <div className="content">
                    With Blitz's technology-powered solution, we've been continuously able to unlock and expand our growth opportunity. They have become an invaluable and trusted partner with deep creative expertise, excellent execution, and the muscle to have our performance marketing accelerated.
                  </div>
                  <div className="brand mercari"></div>
                </div>
                <div className="card">
                  <div className="quote"></div>
                  <div className="content">
                    Blitz has been an incredible growth partner for us, helping us massively scale our business profitably. Their team is world-class at paid acquisition and continue to stay ahead of the curve in the ever-changing landscape of performance marketing.
                  </div>
                  <div className="brand pixelcut"></div>
                </div>
                <div className="card">
                  <div className="quote"></div>
                  <div className="content">
                    The Blitz team has been delivering above and beyond our expectations, providing us an end-to-end marketing solution across a diverse set of channels. Their team is heavily focused on data driven execution with a great eye for creatives and design. We would not be where we are without their A+ service.
                  </div>
                  <div className="brand joyride"></div>
                </div>
                <div className="card">
                  <div className="quote"></div>
                  <div className="content">
                    Over the past few years, Blitz has become an invaluable partner for our paid media team. They have deep knowledge of the paid media space and they always approach our projects with enthusiasm and care.
                  </div>
                  <div className="brand gopuff"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="capabilities-container">
          <div className="layout-container capabilities">
            <div className="h2 white">Our Capabilities</div>
            <div className="h3-white">Using technology including AI, machine learning, and our propriety software, we support growth at all costs</div>
            <ul className="white-cards mt-30">
              <li className="card">
                <div className="icon creative"></div>
                <div className="head">Creative Generation</div>
                <div className="details">
                  Companies from pre-seed to post-IPO struggle to power their marketing campaigns with enough creative to learn quickly enough to iterate meaningfully month over month. We use a unique blend of UGC content, generative AI, and proprietary technology to support your growth at any scale.
                </div>
              </li>
              <li className="card">
                <div className="icon manage-campaign"></div>
                <div className="head">Campaign Management</div>
                <div className="details">
                  All the creative in the world is useless unless it can be deployed effectively. We leverage our in-house tech to expedite campaign setup, allocate spend across platforms, consolidate performers and cycle out fatigued assets, test audiences and campaign targets, and try out new campaign structures.
                </div>
              </li>
              <li className="ornament-holder">
                <div className="card ornament-for">
                  <div className="icon analytics"></div>
                  <div className="head">Marketing Analytics</div>
                  <div className="details">
                    Our iterative approach relies on hardcore analysis. We quickly spin up new creative and campaign strategies based on what's working and why, ensuring that each new bet we make is informed by a detailed understanding of prior performance via machine learning.
                  </div>
                </div>
                <div className="ornament white bottom-right"></div>
              </li>
            </ul>
          </div>
        </div>

        <div className="layout-container">
          <div className="product-section-container flex">
            <div className="left">
              <div className="h3">Blitz SMART : AI-powered ads in minutes</div>
              <div className="h1">
                Set up Facebook campaigns in minutes with AI-powered ads
              </div>
              <div className="caption">Introducing our AI-powered advertising solution - the perfect tool for low spending clients looking to maximize their advertising budget on Facebook and Instagram. Set up campaigns in minutes with visually appealing ads generated by our AI technology. Our platform provides real-time analytics and ML-powered optimization to boost ad performance and increase revenue. Say goodbye to manual ad creation and optimization and let our AI do the work for you.</div>
              <div className="mt-30 mb-30">
                <a
                  href={'https://www.smartblitz.ai'}
                  target='_blank'
                  rel="noreferrer"
                  className="orange-link medium arrow-top-right"
                >
                  Explore More
                </a>

                <button
                  type="button"
                  className="btn v2 large mt-40"
                  onClick={() => window.open('https://www.smartblitz.ai?signup=true')}
                >
                  Try for Free
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="case-study-container">
          <div className="layout-container case-study">
            <div className="video-pane">
              <div className="ornament-holder">
                <img
                  className="pointer ornament-for"
                  src={constants.imagesUrl + 'new-post.svg'}
                  onClick={() => openVideoPlayer('https://www.youtube.com/watch?v=1S0C4cP4Fgs')}
                />
                <VideoPlayer
                  url={videoUrl}
                  showClose={true}
                  show={showVideoPlayer}
                  onCloseModal={closeVideoPlayer}
                />
                <div className="ornament bottom-left"></div>
              </div>
            </div>
            <div className="content-pane">
              <div className="thin-primary-text">Pixelcut</div>
              <div className="h1">
                Scaled profitably to
                <br/>
                <span className="highlight"> $1m in MRR </span>
                supported by Blitz Growth
              </div>
              <div className="caption-dark mt-20">
                Blitz served as the in-house marketing team, strategizing from pre-launch to the present case of seven figure revenue per month.
              </div>

              <div className="mt-60">
                <Link to={Routes.CASE_STUDIES} className="orange-link medium arrow-top-right">Read Full Case Study</Link>
              </div>
            </div>
          </div>
        </div>

        <Authenticate
          type={authModal.linkType}
          updateProp={authModal.setLinkType}
          setIsModalVisible={authModal.setIsModalVisible}
        />
      </div>

    </LandingLayout>
  );
};

export default Home;
