import React, {useContext, useEffect} from 'react';

import AfterHeader from '../AfterHeader';
import LandingLayout from '../Layout';
import { useWindowResize } from 'frontend-core';
import Posts, { IBlogPost } from './Posts';
import RenderPosts from './RenderPosts';
import Authenticate from '../../Authenticate';
import { AppContext } from '../../../appContext';

const Blog: React.FC = () => {
  const [width] = useWindowResize();
  const { authModal } = useContext(AppContext);

  useEffect(() => {
    let iFrame = document.getElementById('blog-iframe') as HTMLIFrameElement;
    let pageWidth = document.body.scrollWidth;
    if (iFrame) {
      if (pageWidth >= 992) {
        iFrame.height = '1750px';
      }
      else if (768 <= pageWidth && pageWidth < 992) {
        iFrame.height =  `${2350 - ((992 - pageWidth) * 1.56)}px`;
      }
      else {
        iFrame.height =  `${5750 - ((768 - pageWidth) * 4.89)}px`;
      }
    }
  }, [width]);

  // const openBlog = (post: IBlogPost) => {
  //   navigate(Routes.BLOG_POSTS.replace(':slug', post.slug));
  // };

  return (
    <LandingLayout>
      <>
        <AfterHeader
          title="Resources"
          caption="Blitz Blog"
        />
        <div className="blog">
          <RenderPosts posts={Posts} />
        </div>
        <Authenticate
          type={authModal.linkType}
          updateProp={authModal.setLinkType}
          setIsModalVisible={authModal.setIsModalVisible}
        />
      </>
    </LandingLayout>
  );
};

export default Blog;
