const creativeOptions = [
  {
    label: 'MEDIA',
    value: 'creativeMediaName',
  },
  {
    label: 'BODY',
    value: 'creativeBody',
  },
  {
    label: 'TITLE',
    value: 'creativeTitle',
  },
  {
    label: 'DESCRIPTION',
    value: 'creativeDescription',
  },
  {
    label: 'CTA',
    value: 'ctaText',
  },
];

export default creativeOptions;
