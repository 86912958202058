import React, {
  useState,
  createContext,
  Dispatch,
  SetStateAction,
} from 'react';

export interface ILandingContext {
  showContactModal: boolean;
  setShowContactModal: Dispatch<SetStateAction<boolean>>;
}

const LandingContext = createContext<ILandingContext>({
  showContactModal: false,
  setShowContactModal: () => {}
});

const useLandingContext = () => {
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  return {
    showContactModal,
    setShowContactModal
  }
};

export {
  LandingContext,
  useLandingContext,
};
