import React, { useState, useRef } from "react";

const InputCode = ({ length, onComplete, onSubmit }) => {
  const [code, setCode] = useState([...Array(length)].map(() => ""));
  const inputs = useRef<(HTMLInputElement | null)[]>([]);
  const processInput = (e, slot) => {  
    const num = e.target.value;
    if (/[^0-9]/.test(num)) return;
    const newCode = [...code];
    newCode[slot] = num;
    setCode(newCode);
    if (slot !== length - 1 && (num || num === 0)) {
      inputs?.current?.[slot + 1]?.focus();
    }
    onComplete(newCode.join(""));
  };

  const onKeyDown = (e, slot) => {
    const newCode = [...code];
    if (e.keyCode === 8 && !code[slot] && slot !== 0) {
      newCode[slot - 1] = "";
      setCode(newCode);
      inputs?.current?.[slot - 1]?.focus();
    }
    onComplete(newCode.join("")); 

    if (e.keyCode === 13 && code.length === 6) {
      let counter = 0;
      code.forEach(c => {
        if (c.length) counter++;
      });
      
      if (counter === length && onSubmit) {
        onSubmit()
      }
    }
  };

  const handlePaste =(e) => {
    let pastedCode = e.clipboardData.getData('text').trim();
    if(isNaN(+pastedCode)) return
    pastedCode = pastedCode.length > length ? pastedCode.substring(0,length).split("") : pastedCode.split("");
    const codeLength = pastedCode.length >= length ? length-1 : pastedCode.length;
    inputs?.current?.[codeLength]?.focus(); 
    code.forEach((elem,idx) => !pastedCode[idx] && pastedCode.push(""));
    setCode(pastedCode);
    onComplete(pastedCode.join(""));
    e.preventDefault();
   }

  return (
    <div className="code-input">
      <div className="code-inputs">
        {code.map((num, idx) => {
          return (
            <input
              key={idx}
              type="text"
              inputMode="numeric"
              maxLength={1}
              value={num}
              autoFocus={!code[0].length && idx === 0}
              onChange={e => processInput(e, idx)}
              onKeyDown={e => onKeyDown(e, idx)}
              ref={ref => inputs.current.push(ref)}
              onPaste={handlePaste}
            />
          );
        })}
      </div>
    </div>
  );
};

export default InputCode;
