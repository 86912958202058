import React, {
  useState,
  Dispatch,
  SetStateAction
} from "react";

export interface ICanvasAiContext {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

const CanvasAiContext = React.createContext<ICanvasAiContext>({
  isLoading: true,
  setIsLoading: () => {},
});

const useCanvasAiContext = () => {

  const [isLoading, setIsLoading] = useState<boolean>(false);

  return {
    isLoading,
    setIsLoading
  };
};

export {
  CanvasAiContext,
  useCanvasAiContext,
};
