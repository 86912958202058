import { VideoPlayer } from 'frontend-core';
import React, { useState } from 'react';
import constants from '../../constants';
import AfterHeader from './AfterHeader';
import LandingLayout from './Layout';

const CaseStudies: React.FC = () => {

  const [videoUrl, setVideoUrl] = useState<string>('');
  const [showVideoPlayer, setShowVideoPlayer] = useState<boolean>(false);

  const openVideoPlayer = (url) => {
    setVideoUrl(url);
    setShowVideoPlayer(true);
  };

  const closeVideoPlayer = () => setShowVideoPlayer(false);

  return (
    <LandingLayout>
      <div className="case-studies">
        <AfterHeader
          title="Resources"
          caption="Case Studies"
        />

        <div className="alt-section-container">
          <div className="layout-container alt-section reverse">
            <div className="ornament-holder left-pane">
              <div className="left-pane-bg ornament-for">
                <div className="thin-primary-text-alt">Real</div>
                <div className="h2">Creative delivered a 75% lower CAC</div>
                <div className="caption-dark mt-20">
                  Real tasked Blitz with upping their creative in paid social campaigns on Facebook, TikTok, and via influencer marketing. We generated thousands of variants of dozens of creative concepts, leading to 100% of conversions being attributed to Blitz creative within the first six months and a decline of 75% in the cost per acquired customer. Simultaneously, we iterated several long-term partnerships with key influencers in the space to document their authentic experience of the product, bolstering the brand presence on TikTok and Instagram.
                </div>
              </div>
              <div className="ornament bottom-right"></div>
            </div>

            <div className="right-pane ornament-holder">
              <div className="ornament-for">
                <img
                  className="pointer"
                  style={{ margin: 'auto -65px' }}
                  src={constants.imagesUrl + 'real-video-poster.png'}
                  onClick={() => openVideoPlayer('https://www.youtube.com/watch?v=s-OSiTf248c')}
                />
              </div>
              <div className="ornament top-left"></div>
            </div>
          </div>
        </div>

        <div className="alt-section-container">
          <div className="layout-container alt-section">
            <div className="ornament-holder left-pane">
              <div className="left-pane-bg ornament-for">
                <div className="thin-primary-text-alt">Gopuff</div>
                <div className="h2">Product scaled to a $1b+ valuation</div>
                <div className="caption-dark mt-30">
                  Gopuff brought on Blitz to unblock their internal team on creative generation, as they were undergoing design bottlenecks while their product rapidly expanded across the globe. We quickly developed and tested thousands of ads for key platforms (Facebook, Google, TikTok, Snapchat, and programmatic), leaning in on UGC-style and customer testimonials while developing pipelines for campaign and creative setup. Blitz generated 60% of top performing ads by volume as well as provided key support on creative testing, campaign execution, and learnings.
                </div>
              </div>
              <div className="ornament top-left"></div>
            </div>

            <div className="right-pane ornament-holder">
              <div className="ornament-for">
                <img
                  className="pointer"
                  style={{ margin: 'auto -85px' }}
                  src={constants.imagesUrl + 'gopuff-video-poster.png'}
                  onClick={() => openVideoPlayer('https://www.youtube.com/watch?v=2psVhl1uEMQ')}
                />
              </div>
              <div className="ornament bottom-right"></div>
            </div>
          </div>
        </div>

        <div className="alt-section-container">
          <div className="layout-container alt-section reverse">
            <div className="ornament-holder left-pane">
              <div className="left-pane-bg ornament-for">
                <div className="thin-primary-text-alt">Joyride Games</div>
                <div className="h2">Reduced CPA by ~30%</div>
                <div className="caption-dark mt-20">
                Ahead of a big product launch, Joyride needed help growing a community to raise excitement for their new web3 game. We tested paid campaigns on several channels before leaning in on influencer marketing, where we were able to launch 70+ partnerships, achieving a total reach of 30M+ followers, undercutting existing acquisition costs by more than 30%. Post-launch we supported the growth of their first game to 1m+ installs via global paid social campaigns on Facebook, Ironsource, and Unity with optimized creatives.
                </div>
              </div>
              <div className="ornament bottom-right"></div>
            </div>

            <div className="right-pane ornament-holder">
              <div className="ornament-for">
                <img
                  className="pointer"
                  style={{ margin: 'auto -65px' }}
                  src={constants.imagesUrl + 'joyride-video-poster.png'}
                  onClick={() => openVideoPlayer('https://www.youtube.com/watch?v=gF0ziSwm2GY')}
                />
              </div>
              <div className="ornament top-left"></div>
            </div>
          </div>
        </div>

        <div className="alt-section-container">
          <div className="layout-container alt-section">
            <div className="ornament-holder left-pane">
              <div className="left-pane-bg ornament-for">
                <div className="thin-primary-text-alt">Pixelcut</div>
                <div className="h2">Scaled profitably to $1m in MRR</div>
                <div className="caption-dark mt-30">
                  Blitz served as the in-house marketing team, strategizing from pre-launch to the present case of seven figure revenue per month. Campaigns were strategically and progressively deployed across Facebook, Google, TikTok, Snapchat, and Twitter, and maximizing ROAS and scale global subscription revenue via creative testing at volume.
                </div>
              </div>
              <div className="ornament top-left"></div>
            </div>

            <div className="right-pane ornament-holder">
              <div className="ornament-for">
                <img
                  className="pointer"                  
                  src={constants.imagesUrl + 'new-post.svg'}
                  onClick={() => openVideoPlayer('https://www.youtube.com/watch?v=1S0C4cP4Fgs')}
                />
              </div>
              <div className="ornament bottom-right"></div>
            </div>
          </div>
        </div>
        <VideoPlayer
          url={videoUrl}
          showClose={true}
          show={showVideoPlayer}
          onCloseModal={closeVideoPlayer}
        />
      </div>
    </LandingLayout>
  );
};

export default CaseStudies;
