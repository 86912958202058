import React, { Fragment, useState, ChangeEvent, FormEvent, Dispatch, SetStateAction } from "react";
import NavStatic from "../nav-static";
import Axios from "axios";
import { ICustomWindow } from "../../common/model";
import Grid from "../../common/Grid";

declare const window: ICustomWindow;

interface IResetPasswordForm {
  password: string;
  confirmPassword: string;
  passwordError?: string;
  key: string;
  email: string;
  [key: string]: string | undefined;
}

const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  form: IResetPasswordForm, setForm: Function, setFormIsValid: Function) => {
  form[e.target.name] = e.target.value;
  setForm(form);
  isFormValid(form, setForm, setFormIsValid);
}

const isFormValid = (form: IResetPasswordForm, setForm: Function,
  setFormIsValid: Function) => {
  const newForm: IResetPasswordForm = {
    password: form.password,
    confirmPassword: form.confirmPassword,
    passwordError: "",
    key: form.key,
    email: form.email,
  };
  if (!form.password || !form.confirmPassword) {
    newForm.passwordError = "Both fields are required";
  } else if (form.password.length < 6) {
    newForm.passwordError = "Password must be at least 6 characters in length";
  }
  if (form.password !== form.confirmPassword) {
    newForm.passwordError = "Passwords do not match";
  }
  setForm(newForm);
  if (newForm.passwordError) {
    setFormIsValid(false);
    return false;
  } else {
    setFormIsValid(true);
  }
  return true;
};

const submitForm = async (event: FormEvent, form: IResetPasswordForm, setForm: Function,
  setResponse: Function, setFormIsValid: Function, setError: Dispatch<SetStateAction<string>>) => {
  const errorMessage = 'Something went wrong. Please try again.';
  event.preventDefault();
  if (!isFormValid(form, setForm, setFormIsValid)) return;
  try {
    const searchParams = new URLSearchParams(window.location.search);
    const key = searchParams.get("key") || "";
    const email = searchParams.get("email") || "";
    const data = form;
    delete data.passwordError;
    data.key = key;
    data.email = email;
    const response = await Axios.post("/api/resetPassword", data);
    if (response.data === 'password reset success') {
      setResponse(response.data);
      setTimeout(() => {
        window.location.href = "/projects";
      }, 2000);
    } else {
      setError(errorMessage);
    }
    if (response.status === 400) {
      setError(errorMessage);
    }
  } catch (err : any) {
    setError(err?.response?.data || errorMessage);
  }
};

const defaultForm: IResetPasswordForm = {
  password: "",
  confirmPassword: "",
  passwordError: "",
  key: "",
  email: "",
};

function ResetPassword(props: any) {
  const [form, setForm] = useState(defaultForm);
  const [response, setResponse] = useState("");
  const [error, setError] = useState<string>('');
  const [formIsValid, setFormIsValid] = useState(false);
  return(
    <div className="reset-pw-container">
      <div className="card-container">
          <div className="logo" />
          <h1>Reset Password</h1>
          <h3>You can enter your new password here and login again.</h3>
          {/* <p>No worries! Enter your email and we will send you a reset link.</p> */}
          <form onSubmit={(e) => submitForm(e, form, setForm, setResponse, setFormIsValid, setError)} style={{width: '100%'}}>
            <div className="form-control full-width">
              <label>Password</label>
              <input name="password"
                type="password" required onChange={(e) => handleChange(e, form, setForm, setFormIsValid)} />
            </div>
            <div className="form-control full-width">
              <label>Confirm Password</label>
              <input name="confirmPassword"
                type="password" required onChange={(e) => handleChange(e, form, setForm, setFormIsValid)} />
            </div>
              {response &&
                <p className="h3">Your password is reset successully. Please login to continue.</p>}
              {form.passwordError &&
                <p style={{color: 'red'}}><small>{form.passwordError}</small></p>}
              {error &&
                <p style={{color: 'red'}}><small>{error}</small></p>
              }
            <button type="submit" className="btn" disabled={!formIsValid}>Reset Password</button>
          </form>
      </div>
    </div>
  );
}

export default ResetPassword;
