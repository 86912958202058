export type TextRenderOptions = {
  x: number;
  y: number;
  text: string;
  font: string;
  color: string;
  background: string;
}

export interface ITextElement {
  render: (options: TextRenderOptions) => void;
}

class TextElement implements ITextElement {

  private context: any;

  constructor (context: string) {
    this.context = context;
  }

  render (options: TextRenderOptions): void {
    const {
      x,
      y,
      font,
      text,
      background
    } = options;
    const ctx = this.context
    ctx.font = font;
    ctx.textBaseline = 'middle';

    ctx.fillStyle = background || '#000000';
    this.context.fillText(text, x, y);
  }

}

export default TextElement;
