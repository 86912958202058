import { JsonObject, JsonProperty } from "json2typescript";
import { InputType, InputTypeConverter } from "./InputType"
import { VideoPlatform } from "../templates/VideoPlatform";
import { Pair } from "./Pair";

@JsonObject("Input")
export class Input {

    @JsonProperty("name", String)
    name: string = "";


    @JsonProperty("type", InputTypeConverter)
    type: InputType = InputType.STRING;

    @JsonProperty("value", String)
    value: string = "";


    @JsonProperty("label", String)
    label: string = "";


    @JsonProperty("extraInfo", String)
    extraInfo: string = "-1";


    @JsonProperty("id", String)
    id: string = '';

    @JsonProperty("aiEnabled")
    aiEnabled: boolean = false;


    @JsonProperty("isVisible", Boolean)
    isVisible: boolean = true;

    maxLength: number = 0;

    defaultValue: string = "";

    variableName(): string {
        return "";
    }

    getName(): string {
        return this.name;
    }

    getVariables(videoPlatform: VideoPlatform): Pair<string, string>[] {
        
        return [];
    }

    dispose(): void {

    }

    async process(): Promise<void> {
        this.dispose();
    }

    async reprocess(): Promise<void> {

    }

    enableCaching():void {

    }

    getValue():string {
        return this.value;
    }


}