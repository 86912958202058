import { JsonObject, JsonProperty } from "json2typescript";
import { ExpressionParams, ExpressionParamsBuilder } from "../../Common/ExpressionParams";
import { evaluate } from "../../Common/MathExpressionStatement";
import { Expression } from "../../Common/Expression";
import { EffectType, EffectTypeConverter } from "./EffectType";

@JsonObject("Effect")
export class Effect {

    @JsonProperty("alpha", Expression)
    alpha:Expression = new Expression("1.0");

    @JsonProperty("startF", String)
    startF: string = "0";

    @JsonProperty("deltaStartF", Number)
    deltaStartF: number = 0;

    @JsonProperty("endF", String)
    endF:string = "0";

    @JsonProperty("deltaEndF", Number)
    deltaEndF:number = 0;

    @JsonProperty("effectType", EffectTypeConverter)
    effectType:EffectType = EffectType.COMPOSITE;

    @JsonProperty("effectName", String)
    effectName: string = "";

    @JsonProperty("shouldApplyPostRenderingSubComponents", String)
    shouldApplyPostRenderingSubComponents: string = "false";

    shouldApplyWithoutStitchingToParent():boolean {
        return false;
    }


    shouldApplyPostRenderingSubComponentsVal():boolean {
        try {
            if (this.shouldApplyPostRenderingSubComponents === undefined || this.shouldApplyPostRenderingSubComponents === null) {
                return true;
            }
            return Boolean(this.shouldApplyPostRenderingSubComponents);
        }
        catch (e) {
            return true;
        }
    }

    canvas(width: number, height: number): HTMLCanvasElement {
        let can = document.createElement("canvas");
        can.width = width;
        can.height = height;
        return can;
    }


    canvasFromImage(img: HTMLCanvasElement): HTMLCanvasElement {
        let canv = this.canvas(img.width, img.height);
        let ctx = canv.getContext("2d");
        if (ctx === null) {
            throw ("exception while getting ctx");
        }
        ctx.drawImage(img, 
            0, 0, 
            img.width, img.height, 
            0, 0, 
            img.width, img.height
        );

        return canv;
    }

    image(canvas: HTMLCanvasElement): HTMLCanvasElement {
        return canvas;
    }



    getValue(frameNumber:number):number {
        return frameNumber - this.startFVal();
    }



    params(frameNumber:number):ExpressionParams {
        return (new ExpressionParamsBuilder(null).start(this.startFVal()).end(this.endFVal()).superFrameNumber(this.getValue(frameNumber))).params();
    }


    startFVal():number {
        try {
            return evaluate(this.startF) + this.deltaStartF;
        } catch (e) {
            return this.deltaStartF;
        }
    }

    endFVal(): number {
        try {
            return evaluate(this.endF) + this.deltaEndF;
        } catch (e) {
            return this.deltaEndF;
        }
    }

}

