import React, { useRef, useState, useContext, useEffect, RefObject, ReactComponentElement, ReactElement } from 'react';
import { AppContext } from '../appContext';
import { ReactComponent as PlayIcon } from './icons/play.svg';
import { ReactComponent as PauseIcon } from './icons/pause.svg';
import { ReactComponent as PlayWhiteSolidIcon } from './icons/play-white-solid.svg';
import { ReactComponent as PauseWhiteSolidIcon } from './icons/pause-white-solid.svg';
// import { ReactComponent as RemoveIcon } from './icons/remove-ads-icon.svg';
import clsx from 'clsx';

const playVideo = (bgVideo: RefObject<HTMLVideoElement>, video: RefObject<HTMLVideoElement>) => {
  bgVideo.current?.play();
  video.current?.play();
};

const pauseVideo = (bgVideo: RefObject<HTMLVideoElement>, video: RefObject<HTMLVideoElement>) => {
  bgVideo.current?.pause();
  video.current?.pause();
};

type alignment = 'left' | 'right';
type position = "-moz-initial" | "inherit" | "initial" | "revert" | "unset" | "absolute" | "-webkit-sticky" | "fixed" | "relative" | "static" | "sticky" | undefined;
type iconSizes = 'small' | 'regular' | 'large';

interface IVideoProps {
  videoName: string;
  posterUrl: string;
  videoUrl: string;
  backgroundColor?: string;
  alignment?: alignment;
  fullWidth?: boolean;
  position?: position;
  customStyles?: any;
  classes?: {
    container: any;
  };
  iconSize?: iconSizes;
  deletable?: boolean;
  onDeleteClick?: Function;
  iconTheme?: 'default' | 'white' | 'white-alt' | 'orange';
  playIcon?: SVGElement;
}

const VideoWithBg = (props: IVideoProps) => {
  const bgVideo = useRef(null);
  const video = useRef<HTMLVideoElement>(null);
  const [showPlayBtn, setShowPlayBtn] = useState(true);
  const [playedOnce, setPlayedOnce] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const prePlayVideo = () => {
    setPlayedOnce(true);
    setCurrentPlayingVideo(props.videoName);
    if (!isPlaying) {
      setIsPlaying(true);
      setShowPlayBtn(false);
      playVideo(bgVideo, video);
    } else {
      setIsPlaying(false);
      setShowPlayBtn(true);
      pauseVideo(bgVideo, video);
    }
  };
  const prePauseVideo = () => {
    if (isPlaying) {
      setIsPlaying(false);
      setShowPlayBtn(true);
      pauseVideo(bgVideo, video);
      if (video && video.current) video.current.currentTime = 0.1;
    }
  };
  const {currentPlayingVideo, setCurrentPlayingVideo} = useContext(AppContext);

  useEffect(() => {
    if (currentPlayingVideo !== props.videoName) {
      prePauseVideo();
    }
  });

  const containerStyles = props.customStyles ? props.customStyles.container : {};
  const mainStyles = props.customStyles ? props.customStyles.main : {};
  const playIconWithTheme = props.iconTheme === 'white' ? <PlayWhiteSolidIcon /> : <PlayIcon />;
  const pauseIconWithTheme = props.iconTheme === 'white' ? <PauseWhiteSolidIcon /> : <PauseIcon />;

  return (
    <div className={'video-box ' + props.classes?.container} style={{ ...containerStyles }}>
    <div className={clsx('video-with-bg', isPlaying && 'playing', props.iconTheme && `theme-${props.iconTheme}`)}
      style={{
        backgroundColor: props.backgroundColor || '#196d7c',
        // left: props.alignment === 'left' ? 0 : 'auto',
        right: props.alignment === 'right' ? 0 : 'auto',
        // width: props.fullWidth ? '100%' : '50%',
        position: props.position ? props.position : 'absolute',
        ...mainStyles
      }}
      onMouseEnter={() => {setShowPlayBtn(true)}}
      onMouseLeave={() => {setShowPlayBtn(false)}}
      onBlur={() => {setShowPlayBtn(false)}}
      onClick={prePlayVideo}>
      {/* <div dangerouslySetInnerHTML={{ __html: `
        <video
          muted
          playsinline
          class="bg-video"
          ref=${bgVideo}
          poster=${props.posterUrl}
          src=${props.videoUrl}
        />,
      `}}></div> */}
      {/* muted attribute doesn't work */}
      {/* <video ref={bgVideo} className="bg-video" playsInline
        muted poster={props.posterUrl}>
        <source src={props.videoUrl} />
      </video> */}
      <video ref={video} onEnded={prePauseVideo} playsInline
        preload="metadata"
        poster={props.posterUrl}>
        <source src={`${props.videoUrl}${!props.posterUrl ? '#t=0.1' : ''}`} />
      </video>
      {(showPlayBtn || !playedOnce || !isPlaying) &&
        <div
          className={`icon play ${props.iconSize ? `icon-${props.iconSize}` : ''}`}
          onClick={prePlayVideo}
        >
          {isPlaying ? pauseIconWithTheme : playIconWithTheme}
        </div>}
    </div>
      {props.deletable &&
        <div
          className="icon remove"
          style={{
            position: 'absolute',
            top: -24,
            right: -22,
            zIndex: 200,
          }}
          onClick={() => {
            if (props.onDeleteClick) props.onDeleteClick();
          }}
        >
        </div>
      }
    </div>
  );
};

export default VideoWithBg;
