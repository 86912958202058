/**
 * Tracks FB Pixel events
 * @param conversionId - conversion ID for linked in
 */
export const TrackFBPixel = (event, content = {}) => {
  if (event) {
    const host = window.location.host;
    if (host === 'www.useblitz.com'
        || host === 'www.smartblitz.ai'
        || host === 'blitz-qa.useblitz.com'
      ) {
      (window as any).fbq('track', event, content);
    }
  }
};
