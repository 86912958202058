import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { externalLink } from './Posts';

const PostUserContentImportant: FC = () => {

  return (
    <div className="blog-post">
      <p>If you've ever seen an unboxing video, a clothing try-on haul, product reviews, or product tutorials on YouTube, TikTok, or Instagram, you were most likely watching user-generated content (UGC). UGC is content created by <strong>unpaid</strong> contributors to a platform, for educational or entertainment purposes. The posting account is not being compensated for their content, even if they are mentioning specific products, and the content is made entirely independently of the brand's oversight or awareness. The nature of all social media platforms is to feature user generated content that is then served to other users likely to engage with it, and therefore social media became another tool for discovering and researching products.</p>
      <p>
        As brands became aware of organic content featuring their products, they quickly sought ways to hack this ultra viral form of content generation. Especially since the beginning of the COVID-19 pandemic, UGC has taken the marketing world by storm. After lockdown, we have seen consumers not only engage with, but seek out content that inspires, informs and entertains; driven by a much more human urge – the need to connect. Now more than ever people are seeking to create a connection with brands. Consumers are constantly bombarded with content and advertisements, and the only way brands can cut through all that noise is with authentic content. That's where UGC comes into play. Reliability and social proof can become a key requirement when considering making a purchase, and the story-telling nature of UGC creates an influential form of marketing. Furthermore, UGC is the most trustworthy, reliable, and authentic form of content; over 
        {externalLink('https://www.nielsen.com/insights/2012/consumer-trust-in-online-social-and-mobile-advertising-grows/', '90% of the people trust word of mouth marketing')} while making an online purchase.
      </p>

      <h2>Various Forms of User-Generated Content</h2>
      <p>The execution of paid UGC can take many forms. Regardless of the way the initiative is carried out, it is an avenue for marketers and advertisers to communicate with their target audience. It also provides content producers exposure, while simultaneously assisting in the development of a community of like-minded brand adherents. Below are just a few types of UGC campaigns:</p>

      <h3>1: A consumer promotes a brand without any sort of brand-consumer relationship</h3>
      <p>
        One example of this was in 2020 when skincare influencer 
        {externalLink('https://www.lululemon.co.uk/en-gb/home?CID=Google_UK_SRCH_Brand_Pure&ds_rl=1297546&ds_rl=1297546&gclid=CjwKCAjwh4ObBhAzEiwAHzZYUwVyqxyac66mmln_1LKSF0QqcCp2stvdSPyafc47DqPUAX5MYHTVexoCv_MQAvD_BwE&gclsrc=aw.ds', 'Hyram')} started 
        talking about the {externalLink('https://www.cerave.com/', 'CeraVe')} brand on his platforms, and the products subsequently started selling out. This was before the brand and influencer built a business relationship; the creator was only sharing his authentic experience with the brand as any consumer would. Another example of phenomenon is when a “normal” TikTok user Trinidad Sandoval (
        {externalLink('https://www.tiktok.com/@trinidad1967?lang=en', '@trinidad1967', false)}) filmed a {externalLink('https://www.tiktok.com/@trinidad1967/video/7000416114245143813?is_copy_url=1&is_from_webapp=v1&lang=en', 'video')} while getting ready for work, explaining how her under-eye bags make her feel “very self conscious.” She showed her followers the miracle-working 
        {externalLink('https://www.peterthomasroth.com/', 'Peter Thomas Roth')} under eye cream. The viral video shows, in real time, just how effective the eye cream is. One user commented, “This is the kind of skin care/ makeup reviews I wanna see more of! No ads or filters, just a regular person like the rest of us.” Such a testimonial indicates the power of a truly organic testimonial from a trusted user.
      </p>

      <h3>2: A brand encourages consumers to post about their experiences online with hashtags as a motivator</h3>
      <p>
        Some examples of this are the athleisure brand {externalLink('https://www.lululemon.co.uk/en-gb/home?CID=Google_UK_SRCH_Brand_Pure&ds_rl=1297546&ds_rl=1297546&gclid=CjwKCAjwh4ObBhAzEiwAHzZYUwVyqxyac66mmln_1LKSF0QqcCp2stvdSPyafc47DqPUAX5MYHTVexoCv_MQAvD_BwE&gclsrc=aw.ds', 'LuluLemon')} asking followers and brand loyalists to share photos of themselves in LuluLemon garments using 
        {externalLink('https://www.instagram.com/p/CfpodUnONSH/?igshid=MDJmNzVkMjY=', '#TheSweatLife')}. In a similar strategy, sparkling water brand {externalLink('https://www.lacroixwater.com/', 'La Croix')} also uses a hashtag {externalLink('https://www.instagram.com/p/B2pNaRSpv_A/?igshid=MDJmNzVkMjY=', '#LiveLaCroix')} to encourage UGC on their social media channels.
      </p>

      <h3>3: Encouraging UGC by offering a variety of competitions and awards</h3>
      <p>
        Historically, {externalLink('https://gopro.com/en/us/', 'GoPro')} has run many different challenges you could enter to win social media features, GoPro gear, and cash awards. In 2023, 
        they are even debuting a {externalLink('https://gopro.com/en/us/awards/million-dollar-challenge', 'Million Dollar Challenge')} for outstanding videos. Thousands of people submitted their material to GoPro due to these programs, which allowed them to obtain thousands of pieces of UGC. GoPro will typically highlight the GoPro Awards:
        {externalLink('https://www.youtube.com/watch?v=3bRgp_GSyBQ', 'Top 10 Official Selections')} before choosing a winner. Another example is clothing brand {externalLink('https://www.tnuck.com/', 'Tuckernuck')}, who encourages their customers to share images of them wearing their outfits using the hashtag
        {externalLink('https://www.instagram.com/p/CfRW5W8uPtb/?igshid=MDJmNzVkMjY%3D', '#TuckerNucking')} for a chance to be featured in shoppable images on their website; there is a full page dedicated to the hashtag where customers can see how the outfits look on real people and also shop the look.
      </p>

      <h2>How User- Generated Content Differs from Mainstream Advertising</h2>
      <p>It's worth noting that true user-generated content and sponsored posts are very different. Sponsorships imply that there's been a transaction and need to be clearly disclosed with a hashtag stating the creator is a partner and that the post is an ad. These are typically sponsored monetarily or with brands sending influencers free products to show off on their platforms. In return, the brand often expects a level of creative control on the final post. Paid advertisements provide consumers with your brand's perspective; UGC is the exact opposite, allowing users to create videos with their own opinions and to showcase their own experiences. This means users can even share their negative experiences with products, further exemplifying the authenticity of this kind of marketing and increasing the trust when an unsponsored post shares a positive perspective. </p>

      <p>
      UGC is for anyone. When people hear the term influencers or digital creators, they usually imagine celebrities or people with millions of followers. This is not always the case. Influencers can be normal people who have an engaged following of a few hundred to thousands. Before Trinidad Sandoval posted her viral Peter Thomas Roth under eye cream video, she had approximately 70 followers. Her video now has 41.7M views, and she boasts 186.9K followers. Brands are shifting their focus towards working with micro and nano influencers, as people perceive these types of influencers as more authentic and therefore their posts can be more effective on a cost per post basis. A study from
      {externalLink('https://www.bazaarvoice.com/ugc-platform/?&aid=SC40571B2&utm_campaign=win+at+retail&utm_content=brand+uk+resources+page+new&utm_medium=cpc&utm_source=google&gclid=CjwKCAjwh4ObBhAzEiwAHzZYUwMoIZJEPdoZ5sqYGKkVlV0CQl70zOxuSE2KLqtp3iaGQG6NiiRrEBoCq1EQAvD_BwE', 'Bazaarvoice')} found that 56% of people follow smaller regular influencers more than any type of influencer, including social media and traditional celebrities. 
      </p>
      <p>
        While user-generated content can also include text and photos, most of UGC these days is in video form. We're seeing a movement away from static posts; they are becoming an outdated medium. There are several factors contributing to the rise of video ads, and TikTok is a huge component. In 2022, TikTok became the most-downloaded app in the world, quietly surpassing longstanding forebears Instagram and Twitter. By the end of this year, it will overtake YouTube as the social media platform users spend the most time watching. Other social media platforms are mirroring the video aesthetic that dominates TikTok. Instagram, for example, has
        {externalLink('https://www.theverge.com/2021/6/30/22557942/instagram-no-longer-photo-app-video-entertainment-focus', 'accelerated its shift away from its photo-sharing beginnings')}, becoming more heavily invested in video with the arrival of features such as
        {externalLink('https://about.instagram.com/blog/announcements/introducing-instagram-reels-announcement', 'Instagram Reels')}, somewhat of a second-hand version of TikTok videos. On all platforms for marketing you look at across the digital landscape, you can see the rise of video content.
      </p>

      <h2>Benefits of User-Generated Content </h2>
      <p>Aside from the obvious benefits of authenticity, UGC also helps to develop brand trust and loyalty, is cost-effective, and is also mutually beneficial to content creators. </p>

      <h3>Brand Trust and Loyalty</h3>
      <p>
        When customers cannot see or touch your product before buying, they are usually nervous about spending. Fortunately, user-generated content can serve as social proof, which helps remove buyer anxiety and increases conversions. In fact,
        {externalLink('http://www.nielsen.com/us/en/insights/news/2012/consumer-trust-in-online-social-and-mobile-advertising-grows.html', '92% of shoppers')} trust non-paid recommendations from other customers more than any type of advertising. Real-life customers using products compared to staged product photos makes a world of difference. Despite the amount of budget brands allocate toward professional photography and influencer marketing these days, only 19% of consumers find brand-created content to be authentic, and 10% say influencer (paid sponsorships) content resonates as authentic with them. Furthermore, people want to engage with brands; over 60% of consumers say they would be more loyal to and likely to buy from a brand that invited them to join a customer advocacy community to actively help it create more UGC for the brand.
      </p>

      <h3>Cost Effectiveness</h3>
      <p>
        Not only is UGC more effective than highly polished and produced video ads, but it's also cheaper. It can be difficult to regularly create new
        {externalLink('https://www.wedia-group.com/brand-content/what-is-visual-content-marketing/', 'visual content')} especially when you have a limited marketing budget. Fortunately, you can use UGC to supplement branded content with little or no cost because you are curating the images directly from your fans and customers. Some UGC comes at completely no cost to brands, and they can have a huge payoff. For example,
        {externalLink('https://my.dailyvanity.com/news/peter-thomas-roth-eye-cream-tiktok/', "4 days after the viral TikTok of Peter Thomas Roth's eye cream surfaced")}, the product was completely sold out across the US. The reality is that UGC is the biggest source of authentic visual content that marketers have access to. Combining branded content and user-generated content will help brands save time, money, and resources on content creation.
      </p>

      <h3>Beneficial to a UGC Content Creator</h3>
      <p>UGC doesn't just benefit brand's marketing efforts: it also benefits the content creators who post brands' content. One of the advantages of being a UGC content creator, as opposed to a traditional influencer, is that you don't need a huge following to get started. It's more about the quality of the content than the size of your following. Since it's such an up and coming space, it's also a huge opportunity area for UGC creators to grow their followings. Brands are searching for more authentic content to drive sales and traffic. While lots of UGC is curated by accident, there are plenty of websites now that highlight all the ways of how to make UGC. You could be featured on a company's website or social media channels, win compensation and awards, build your personal brand, and more.</p>

      <h2>User Generated Content as the Future of Advertising</h2>
      <p>So why is user generated content so important? Simply put, it's the future of advertising. Since UGC has been so popular and effective, it's no wonder we see a shift in the marketing world. UGC has evoked a fundamental shift in the way users engage with brands and make purchasing decisions. User-generated content is the future of modern marketing.</p>
    </div>
  );
}

export default PostUserContentImportant;
