import { CustomSelect } from 'frontend-core';
import React from 'react'
import { VariantDropDown } from "../model";

const DropDown = (props) => {
  const data: VariantDropDown = props.value;

  const onChange = (e) => {
    data.selected = e.target.value;
    props.setVariant(e.target.value);
  };

  return (
    <CustomSelect
      className="menu-item-select"
      onChange={onChange}
      value={data.selected}
      options={
        data.variants.map((variant: string, index: number) => ({ label: variant, value: index }))
      }
    />
  );
};

export default DropDown;
