import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("Dimension")
export class Dimension {

    @JsonProperty("width")
    width:number = 0;


    @JsonProperty("height")
    height:number = 0;

}