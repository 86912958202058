import React, { Fragment, useState, ChangeEvent, FormEvent } from "react";
import Axios from "axios";

interface IForgotPasswordForm {
  email: string;
  emailError?: string;
  [key: string]: string | undefined;
}

const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  form: IForgotPasswordForm, setForm: Function, setFormIsValid: Function) => {
  form[e.target.name] = e.target.value;
  setForm(form);
  isFormValid(form, setForm, setFormIsValid);
}

const isFormValid = (form: IForgotPasswordForm, setForm: Function,
  setFormIsValid: Function) => {
  const newForm: IForgotPasswordForm = {
    email: form.email,
    emailError: "",
  };
  if (!form.email) {
    newForm.emailError = "Email is required";
  }
  setForm(newForm);
  if (newForm.emailError) {
    setFormIsValid(false);
    return false;
  } else {
    setFormIsValid(true);
  }
  return true;
};

const submitForm = async (event: FormEvent, form: IForgotPasswordForm, setForm: Function,
  setResponse: Function, setFormIsValid: Function, setError: Function) => {
  event.preventDefault();
  if (!isFormValid(form, setForm, setFormIsValid)) return;
  try {
    const data = form;
    delete data.emailError;
    const response = await Axios.post("/api/forgotPassword", data);
    setResponse(response.data);
    setError("");
    console.log(response);
  } catch (error) {
    if (error instanceof Error) {
      const message = error.message;
      setResponse("");
      if(message.includes("400")) {
        setError("Email is not registered.")
      } else {
        setError("Cannot reset password");
      }
    }
    console.error(error);
  }
};

const defaultForm: IForgotPasswordForm = {
  email: "",
  emailError: "",
};

const inputStyles = { width: "100%", height: "40px !important", background: "e9f0f3", border: "none", borderRadius: 4 };

function ForgotPassword(props: any) {
  const [form, setForm] = useState(defaultForm);
  const [response, setResponse] = useState("");
  const [error, setError] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);
  return(
    <Fragment>
      <div className="heading-container">
        <span className="heading">Forgot your password ?</span>
      </div>
      <p className="fp-subtitle">No worries. Enter your email and we will send you a reset link.</p>
      <form onSubmit={(e) => submitForm(e, form, setForm, setResponse, setFormIsValid, setError)} style={{width: '100%'}}>
        <div className="form-control">
          <input className="form-input" name="email" type="email" required onChange={(e) => handleChange(e, form, setForm, setFormIsValid)} 
          style={inputStyles}
          placeholder="Email"
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <button type="submit" className="btn reset-password-btn"
            disabled={!formIsValid}>
            Reset Password
          </button>
        </div>
      </form>
      {response &&
        <p className="h3 forgot-password-msg">Email with instructions to reset your password is sent. Please check your inbox.</p>}
      {error && <p className="center error-text">Email is not registered.</p>}
      {form.emailError &&
        <p className="error-text center"><small>{form.emailError}</small></p>}
    </Fragment>
  );
}

export default ForgotPassword;
