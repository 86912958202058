import { JsonObject } from "json2typescript";
import { Input } from "./Input";
import { InputType } from "./InputType";
import { VideoPlatform } from "../templates/VideoPlatform";
import { Pair } from "./Pair";

@JsonObject("StringInput")
export class StringInput extends Input {

    constructor() {
        super();
        this.type = InputType.STRING;
    }

    variableName(): string {
        return "{{" + this.getName() + "}}";
    }


    getVariables(videoPlatform: VideoPlatform): Pair<string, string>[] {
        let arr:Pair<string, string>[] = [];
        arr.push(new Pair(this.variableName(), this.value.replaceAll("\\\"", "\"").replaceAll("\\'", "'").replaceAll("\"", "\\\"")));
        return arr;
    }

}