import React from 'react';
import Canvas from './Canvas';
import { CanvasAiContext, useCanvasAiContext } from './Common/CanvasAiContext';

interface ICanvasAi {
}

const CanvasAi: React.FC<ICanvasAi> = () => {

  const canvasAiContextValues = useCanvasAiContext();
  return (
    <CanvasAiContext.Provider value={canvasAiContextValues}>
      <div id="canvas-ai">
        <Canvas />
      </div>
    </CanvasAiContext.Provider>
  );
};

export default CanvasAi;
