import { createSlice } from "@reduxjs/toolkit";
import { getProject, deleteTemplateXhr } from "../api/projects-api";
import { SocialEndpoints } from "../common/model";

interface ProjectCampaign {
  socialAdCampaignId: number;
  socialEndpoint: SocialEndpoints;
  userId: number;
  campaignId: string;
  campaignName: string
}

export interface ProjectInfo {
  // todo declare types
  userTemplate: any;
  globalTemplate: any;
  campaigns: ProjectCampaign[];
  variantCount: number;
}

export interface ProjectInfoState {
  loading: boolean;
  error: string;
  projectInfo: ProjectInfo | null;
  deleting: boolean | undefined;
  deleteError: string | undefined;
  deleted: boolean | undefined;
}

const ProjectInfoSlice = createSlice({
  name: 'project-info',
  initialState: {
    loading: true,
    error: '',
    projectInfo: {
      userTemplate: null,
      globalTemplate: null,
      campaigns: [],
      variantCount: 0,
    },
    deleting: false,
    deleteError: '',
    deleted: false,
  } as ProjectInfoState,
  reducers: {
    reset(state, action) {
      state.loading = true;
      state.error = '';
      state.projectInfo = null;
      state.deleting = false;
      state.deleteError = '';
      state.deleted = false;
    },
    getProjectInfoStart(state, action) {
      state.loading = true;
      state.error = '';
      state.projectInfo = null;
      state.deleting = false;
      state.deleteError = '';
      state.deleted = false;
    },
    getProjectInfoSuccess(state, action) {
      state.loading = false;
      state.error = '';
      state.projectInfo = action.payload;
      state.deleting = false;
      state.deleteError = '';
      state.deleted = false;
    },
    getProjectInfoError(state, action) {
      state.loading = false;
      state.error = action.payload.error;
      state.projectInfo = null;
      state.deleting = false;
      state.deleteError = '';
      state.deleted = false;
    },

    deleteProjectInfoStart(state, action) {
      state.deleting = true;
      state.deleteError = '';
      state.deleted = false;
    },
    deleteProjectInfoSuccess(state, action) {
      state.deleting = false;
      state.deleteError = '';
      state.deleted = true;
    },
    deleteProjectInfoError(state, action) {
      state.deleting = false;
      state.deleteError = action.payload.error;
      state.deleted = false;
    },
  },
});

export const {
  reset,
  getProjectInfoStart,
  getProjectInfoSuccess,
  getProjectInfoError,
  deleteProjectInfoStart,
  deleteProjectInfoSuccess,
  deleteProjectInfoError
} = ProjectInfoSlice.actions;

export const fetchProjectInfo = (
  projectId: number
) => async dispatch => {
  try {
    dispatch(getProjectInfoStart(null));
    const projectInfo = await getProject(projectId);
    dispatch(getProjectInfoSuccess(projectInfo));
  } catch (err) {
    dispatch(getProjectInfoError(err));
  }
};

export const resetProjectInfo = () => dispatch => {
  dispatch(reset(null));
};

// todo this is repeated in project-slice also
export const deleteTemplate = (templateId: number) => async dispatch => {
  try {
    dispatch(deleteProjectInfoStart(templateId));
    const response = await deleteTemplateXhr(templateId);
    dispatch(deleteProjectInfoSuccess({ templateId, response }));
  } catch (e) {
    dispatch(deleteProjectInfoError(e));
  }
};

export default ProjectInfoSlice.reducer;
