import { JsonObject } from "json2typescript";
import { Input } from "./Input";
import { InputType } from "./InputType";
import { VideoPlatform } from "../templates/VideoPlatform";
import { Pair } from "./Pair";
import { getQueryParamFormURL, updatedQueryParamForURL } from "../../Common/FileUtil";
import md5Hex from 'md5-hex';
import { Dimension } from "./Dimension";
import { fromJson, toJson } from "../../Common/JsonUtil";
import { getDataUrl, getImageDimension, getImageElem } from "../../Common/ImageUtils";



@JsonObject("ImageInput")
export class ImageInput extends Input {

    folderName:string = '';
    extension:string = '.png';
    cacheName:string = '';
    caching:boolean = false;
    cachedFolderPath:string = '';
    private imageHeight:number = 0;
    private imageWidth:number = 0;
    private imageAR:number = 0.0;
    private imageARCompliment:number = 0.0;
    private image:HTMLImageElement = document.createElement("img");
    private dataUrl:string = '';

    cropParameters:string = '';
    


    constructor() {
        super();
        this.type = InputType.IMAGE;
        this.image.crossOrigin="anonymous";
    }

    private initFolders():void {
        this.cacheName = '';
        this.cachedFolderPath = "/tmp/useblitz/cache/variable/"+this.getName()+"/"+Date. now();
        this.folderName = this.cachedFolderPath; //TODO::PORT
    }

    variableName(): string {
        return "{{" + this.getName() + "}}";
    }



    enableCaching(): void {
        this.caching = true;
    }

    async process(): Promise<void> {
        super.process();
        this.cropParameters = getQueryParamFormURL(this.getValue());
        await this.processUrl();
    }

    getValue(): string {
        if(this.cropParameters != "") {
            return updatedQueryParamForURL(super.getValue(), this.cropParameters);
        }
        return super.getValue();
    }

    private getCacheKey():string {
        return md5Hex(""+this.getValue()+"::"+this.getName());
    }

    async processUrl():Promise<void> {
        
        this.image = await getImageElem(this.getValue());
        let dimension = await getImageDimension(this.getValue());
        this.imageHeight = dimension.height;
        this.imageWidth = dimension.width;
        this.imageAR = this.imageWidth * 1.0 / this.imageHeight;
        this.imageARCompliment = this.imageHeight*1.0/this.imageWidth;
    }


    private async setAdditionalProperties():Promise<void> {
        let cacheName = this.getCacheName();
        let val = localStorage.getItem("IMAGEDIMENSION::"+cacheName);
        let dimension:Dimension;
        if (val === null) {
            dimension = await getImageDimension(this.getValue());
        }
        else {
            dimension = fromJson(val, Dimension);
        }
        this.imageHeight = dimension.height;
        this.imageWidth = dimension.width;
        this.imageAR = this.imageWidth * 1.0 / this.imageHeight;
        this.imageARCompliment = this.imageHeight*1.0/this.imageWidth;
    }

    private getCacheName():string {
        if(this.cacheName == null || this.cacheName === undefined || this.cacheName === '') {
            this.cacheName = md5Hex(this.getValue());
        }
        return this.cacheName;
    }


    public imagePath():string {
        let imagePath:string = this.folderName+"/"+this.getName()+this.extension;
        return updatedQueryParamForURL(imagePath,this.cropParameters);
    }


    public urlPathVariable():string {
        return "{{" + this.getName() + ".url}}";
    }

    getVariables(videoPlatform: VideoPlatform): Pair<string, string>[] {
        let listToReturn: Pair<string, string>[] = [];
        listToReturn.push(new Pair<string, string>("{{" + this.getName() + ".path}}", this.imagePath()));
        listToReturn.push(new Pair<string, string>(this.urlPathVariable(), this.value));
        listToReturn.push(new Pair<string, string>("{{" + this.getName() + ".height}}", this.imageHeight+""));
        listToReturn.push(new Pair<string, string>("{{" + this.getName() + ".width}}", this.imageWidth+""));
        listToReturn.push(new Pair<string, string>("{{" + this.getName() + ".whr}}", this.imageAR+""));
        listToReturn.push(new Pair<string, string>("{{" + this.getName() + ".hwr}}", this.imageARCompliment+""));
        listToReturn.push(new Pair<string, string>("{{" + this.getName() + ".origin}}", this.value));
        return listToReturn;
    }

    dispose(): void {
        this.cacheName = '';
    }

    async reprocess(): Promise<void> {
        this.dispose();
        this.initFolders();
        this.cropParameters ='';
        await this.process();
    }

}