const getEnv = () => {
  const host = window.location.host;
  if (host === 'www.smartblitz.ai' || host === 'useblitz.com') {
    return 'PROD';
  } else if (host === 'blitz-dev.useblitz.com') {
    return 'DEV';
  } else if (host === 'blitz-qa.useblitz.com') {
    return 'QA';
  } else if (host.includes('useblitz.com')) {
    return 'DOCKER';
  } else {
    return 'LOCAL';
  }
};

export const getFullEnv = () => {
  const host = window.location.host;
  if (host === 'www.smartblitz.ai' || host === 'useblitz.com') {
    return 'production';
  } else if (host === 'blitz-dev.useblitz.com') {
    return 'development';
  } else if (host === 'blitz-qa.useblitz.com') {
    return 'qa';
  } else if (host.includes('useblitz.com')) {
    return 'docker';
  } else {
    return 'local';
  }
};

export default getEnv;
