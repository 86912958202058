import React, { useContext, useEffect, useState } from 'react';
import {useStripe, useElements, CardNumberElement} from '@stripe/react-stripe-js';
import CardSection from './card-section';
import { AppContext } from '../../appContext';
import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { fetchCard, fetchSubscriptionInfo, fetchTeamMembers } from '../../data-stores/account-slice';

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { refreshUser, authModal, subscriptionModal } = useContext(AppContext);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorValidationSide, setErrorValidationSide] = useState(false);

  useEffect(() => {
    if (props.setPaymentLoading) {
      props.setPaymentLoading(isLoading);
    }
  }, [isLoading]);

  const setPropsError = (errorTxt) => {
    if (props.setPaymentError) {
      props.setPaymentError(errorTxt);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setPropsError('');

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      console.error('Stripe.js has not yet loaded');
      return;
    }
    setIsLoading(true);
    const card = elements.getElement(CardNumberElement);
    const tokenResult = await stripe.createToken(card);
    if (tokenResult.error) {
      setError(tokenResult.error.message);
      setPropsError(tokenResult.error.message);
      setIsLoading(false);
      setErrorValidationSide(true);
    } else {
      setErrorValidationSide(false);
      try {
        let data = {
          token: tokenResult.token.id,
          planId: props.planId
        };
        let url = '/api/stripe/subscribe';
        if (subscriptionModal.isUpgrading) {
          url = '/api/subscription/upgrade'
        }
        if (props.updateCard) {
          url = '/api/user/updateCard';
          data = {
            token: tokenResult.token.id
          };

          if (props.planId && props.planId !== -1) {
            data['planId'] = props.planId;
          } 
        }


        const result = await Axios.post(url, data);
        setIsLoading(false);
        subscriptionModal.setIsOpen(false);
        if (!props.cancelRefreshUserAfterSuccess) {
          refreshUser();
        }

        if (props.updateCard) {
          props.updateSuccess(result.data);
        }
        else {
          if (props?.onSuccess) {
            props?.onSuccess();
          }
          else {
            authModal.setShowSuccessModal(true);
          }
          window.dispatchEvent(new CustomEvent('subscriptionEvent', {
            bubbles: true,
            detail: { status: true }
          }));
          if (window.reportSubscription) {
            window.reportSubscription(props.planPrice);
          }
          dispatch(fetchSubscriptionInfo());
          dispatch(fetchTeamMembers());
          dispatch(fetchCard());
        }
      } catch (error) {
        console.log('catch error', error);
        setIsLoading(false);
        setError(error.response && error.response.data && error.response.data.errorMessage
          ? error.response.data.errorMessage
          : 'Subscription failed! 😣');
        setPropsError(error.response && error.response.data && error.response.data.errorMessage
          ? error.response.data.errorMessage
          : 'Subscription failed! 😣');
        if (props.updateCard) {
          props.updateError(error);
        }
        else {
          window.dispatchEvent(new CustomEvent('subscriptionEvent', {
            bubbles: false,
            detail: { status: false }
          }));
        }
        console.error('subscription request failed\n', error.data, error.response, error);
      } finally {
        // dispatch(fetchCard());
        // dispatch(fetchSubscriptionInfo());
      }
    }
    // todo handle loading
  };

  return (
    <>
      <form 
        id='checkout-form'
        onSubmit={handleSubmit} 
        style={{ 
          textAlign: 'center', 
          display: 
            isLoading && props.loadingRenderer ? 'none' : (error.length > 0 && props.displayFailedPageInCaseOfError && !isErrorValidationSide) ? 'none' : 'unset' 
        }}>
        {props.updateCard && !props.hideHeading && <h3 style={{ textAlign: 'left' }}>Update Card Details</h3>}
        <CardSection onReady={props.onReady} />
        <p role="alert">
          <small style={{ color: 'red' }}>
            {error && error}
          </small>
        </p>
        <div className={props.updateCard ? 'card-update-footer' : 'footer-bg'}
          style={{ textAlign: props.updateCard ? 'left' : 'center' }}
        >
          <button type="submit"
            className={`${props.saveButtonClass ? props.saveButtonClass : 'btn solid'} `}
            disabled={!stripe || isLoading}>
            {isLoading ? 'Loading... Please Wait' : (props.updateCard ? 'Save' : props?.paymentButtonText ? props?.paymentButtonText : 'Try Now')}
          </button>

          {
            !isLoading && props.onCancel && (
              <button color="default" type="button"
                className={`${props.saveButtonClass ? props.saveButtonClass + ' outlined' : 'btn outlined'} `}
                disabled={!stripe || isLoading}
                onClick={props.onCancel}
              >
                {isLoading ? 'Loading... Please Wait' : 'Cancel'}
              </button>
            )
          }
        </div>
        <div className='card-msg-row'>
          {
            props.subText && <div className='summary-text'>{props.subText}</div>
          }
          {
            props.authText && <div className='summary-text'>{props.authText}</div>
          }
        </div>
      </form>
      {isLoading && props.loadingRenderer ? props.loadingRenderer() : null}
      {
        error.length > 0 && props.displayFailedPageInCaseOfError && !isErrorValidationSide &&
          <div className='payment-failed-container'>
            <div className='failed-img-container'>
              <div className='failed-img' />
            </div>
            Sorry, your credit card details were not authenticated. Please enter valid card details to proceed or Retry.
            <div className='footer-btns'>
              <button className='retry-btn' onClick={handleSubmit}>Retry</button> 
              <button className='enter-btn btn' onClick={() => {
                setError('');
                setPropsError('');
              } }>Enter Valid Card Details</button>
            </div>
          </div>
      }
    </>
  );
}
