import { JsonObject, JsonProperty } from "json2typescript";
import { Component } from "./Component";
import { VideoPlatform } from "../templates/VideoPlatform";
import { alpha, alphaDecimalVal, evaluate } from "../../Common/MathExpressionStatement";

@JsonObject("RectangleComponent")
export class RectangleComponent extends Component {

    @JsonProperty("isShapeFill", String)
    isShapeFill: string = 'false';

    @JsonProperty("shapeColor")
    shapeColor: string = '';


    @JsonProperty("strokeColor")
    strokeColor: string = '';

    @JsonProperty("strokeWidth")
    strokeWidth: string = "3";

    shapeFillVal():boolean {
        return this.isShapeFill.toLowerCase() === 'true';
    }

    async drawComponent(frameNumber: number, previewMultiplier: number, parentImage: HTMLCanvasElement | null, videoPlatform: VideoPlatform): Promise<HTMLCanvasElement> {
        let componentWidth: number = this.componentWidthAsFloat(previewMultiplier, parentImage);
        let componentHeight: number = this.componentHeightAsFloat(previewMultiplier, parentImage);
        let canv: HTMLCanvasElement = this.canvas(componentWidth, componentHeight);
        let strokeWidth: number = this.strokeWidthval(previewMultiplier);
        let graphics2D = canv.getContext('2d');
        if (graphics2D === null) {
            return this.image(canv);
        }
        if (this.shapeFillVal()) {
            let fillStyle = this.shapeColor.startsWith("#") ? this.shapeColor : "#" + this.shapeColor;
            let fillStyleColor = fillStyle.length === 9 ? "#" + fillStyle.slice(3) : fillStyle;
            graphics2D.fillStyle = fillStyleColor;
            graphics2D.globalAlpha = alphaDecimalVal([fillStyle]);
        }
        {
            let strokeStyle = this.strokeColor === '' ? this.shapeColor : this.strokeColor;
            strokeStyle = strokeStyle.startsWith("#") ? strokeStyle : "#" + strokeStyle;
            let strokeStyleColor = strokeStyle.length === 9 ? "#" + strokeStyle.slice(3) : strokeStyle;
            graphics2D.strokeStyle = strokeStyleColor;
            if(!this.shapeFillVal()) {
                graphics2D.globalAlpha = alphaDecimalVal([strokeStyle]);
            }
        }
        if(strokeWidth>1) {
            graphics2D.lineWidth = strokeWidth+2;
        }
        graphics2D.beginPath();
        graphics2D.rect((strokeWidth/2), (strokeWidth/2), (componentWidth -strokeWidth), (componentHeight -strokeWidth));
        if (this.shapeFillVal()) {
            graphics2D.fill();
        }
        else {
            graphics2D.stroke();
        }
        return this.image(canv);
    }



    strokeWidthval(previewMultiplier: number): number {
        try {
            return evaluate(this.strokeWidth) * previewMultiplier;
        }
        catch (error) {
            return 1;
        }
    }
}