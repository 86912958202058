import { JsonObject } from "json2typescript";
import { Input } from "./Input";
import { InputType, InputTypeValueOf } from "./InputType";
import { VideoPlatform } from "../templates/VideoPlatform";
import { Pair } from "./Pair";
import { ImageInput } from "./ImageInput";
import { StringInput } from "./StringInput";

@JsonObject("MultiInput")
export class MultiInput extends Input {

    static VARIABLE_TYPE_DELIM:string = "::=>";
    static VARIABLE_LIST_DELIM:string = "##";

    multiInputList:Input[] = [];
    variableType:InputType = InputType.STRING;

    constructor() {
        super();
        this.type = InputType.MULTI;
    }

    setInputValues(inputValues:string[]):void {
        this.value = this.getInputValueString(this.variableType, inputValues);
        this.setMultiInputList();
    }

    setMultiInputList() {
        if (this.value === '' || this.name === '') {
            return;
        }
        this.value.split(MultiInput.VARIABLE_TYPE_DELIM)[0];
        this.variableType = InputTypeValueOf(this.value.split(MultiInput.VARIABLE_TYPE_DELIM)[0]);
        let inputValueListStr:string = this.value.split(MultiInput.VARIABLE_TYPE_DELIM)[1];
        let inputValueArr:string[] = inputValueListStr.split(MultiInput.VARIABLE_LIST_DELIM);
        this.multiInputList = [];
        for (let i =0; i < inputValueArr.length; i++) {
            let subInput:Input | undefined = undefined;
            switch (this.variableType) {
                case InputType.IMAGE:
                    subInput = new ImageInput();
                    break;
                case InputType.STRING:
                    subInput = new StringInput();
                    break;
            }
            if (subInput === undefined) continue;
            subInput.name = this.name+"."+i+".loop";
            subInput.value = (inputValueArr[i]);
            subInput.label = "";
            this.multiInputList.push(subInput);
        }
    }

    getInputValueString(variableType: InputType, inputValues: string[]): string {
        return variableType+ MultiInput.VARIABLE_TYPE_DELIM +inputValues.join(MultiInput.VARIABLE_LIST_DELIM);
    }

    getInputValueStringPlain(): string {
        return this.variableType+ MultiInput.VARIABLE_TYPE_DELIM +this.multiInputList.map(inp => inp.value).join(MultiInput.VARIABLE_LIST_DELIM);
    }

    enableCaching(): void {
        this.multiInputList.forEach(inp => inp.enableCaching);
    }

    resetInputValueString(): void {
        this.value = this.getInputValueStringPlain();
    }

    async process(): Promise<void> {
        await super.process();
        await this.multiInputList.forEach(async inp => await inp.process());
    }

    getVariables(videoPlatform: VideoPlatform): Pair<string, string>[] {
        let variables:Pair<string, string>[] = [];
        this.multiInputList.forEach(inp => variables.concat(inp.getVariables(videoPlatform)))
        variables.push(new Pair<string, string>("{{"+this.getName()+".count}}", this.multiInputList.length+""));
        return variables;
    }

    dispose(): void {
        this.multiInputList.forEach(inp => inp.dispose());
    }

    async reprocess(): Promise<void> {
        this.multiInputList.forEach(async inp => await inp.reprocess());
    }

    onObjectConversionFromJson(): void {
        this.setMultiInputList();
    }

}