import React, {useContext, useState, useEffect, useRef} from "react";
import axios from "axios";
import { AppContext } from "../../appContext";
import { InlineLoader, Modal } from "frontend-core";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../../common/subscription/checkout";
import { loadStripe } from "@stripe/stripe-js";
import { utils } from "../../common/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserPlan } from "../../api";

const stripePromise = loadStripe(utils.getStripeApiKey());
const PaymentFailureModal = () => {
  const navigate = useNavigate();
  const { 
    user, 
    userPlan, 
    setUserPlan, 
    showPaymentFailureModal, 
    setShowPaymentFailureModal, 
    refreshUser
  } = useContext(AppContext);

  const { pathname } = useLocation();
  const [bodyStatus, setBodyStatus] = useState<string>('failed');
  const [isAPILoading, setAPILoading] = useState<boolean>(false);
  const [isPaymentLoading, setPaymentLoading] = useState<boolean>(false);
  const [paymentError, setPaymentError] = useState<string>('');
  const [modalTitle, setModalTitle] = useState<string>('Payment Authentication Failed');
  const bodyStatusChangedByEvent = useRef<boolean>(false);

  useEffect(() => {
    switch (bodyStatus) {
      case 'failed':
        setModalTitle('Payment Authentication Failed');
        return;
      case 'updateCard':
        setModalTitle('Payment Details');
        return;
      case 'loading':
        setModalTitle('Please Wait');
        return;
      case 'success':
        setModalTitle('Payment Success');
        return;
    }
  }, [bodyStatus]);

  useEffect(() => {
    if (isPaymentLoading) {
      setBodyStatus('loading');
    }
    if (paymentError === 'Your card number is invalid.') {
      setBodyStatus('updateCard');
    }
  }, [isPaymentLoading]);

  useEffect(() => {
    if (user?.premiumTier === 100 && userPlan?.subscriptionInfo?.subscriptionStatus === 'CLOSED') {
      setShowPaymentFailureModal(true);
      if (window.location.pathname !== '/account/subscriptions') {
        navigate('/account/subscriptions');
      }
    }
  }, [userPlan, pathname]);

  useEffect(() => {
    let modalOverlay = document.getElementsByClassName('modal-overlay')[0] as HTMLDivElement;
    if (!modalOverlay) {
      return;
    }
    if (showPaymentFailureModal) {
      modalOverlay.style.zIndex = '9999';
    }
    // else {
    //   modalOverlay.style.zIndex = '1000';
    // }
  }, [showPaymentFailureModal]);

  const handleRetry = () => {
    setBodyStatus('loading');
    axios
      .post('/api/user/subscription/retryPayment', {planId: userPlan?.planInfo.Id})
      .then((response) => {

      })
      .catch((err) => {
        setBodyStatus('failed');
      });
  }

  const handleUpdateCardClick = () => {
    setBodyStatus('updateCard');
  }

  const renderLoading = () => {
    return (
      <div className='payment-loading-container'>
        <div className='loader-container'>
          <div className='circle'>
            <div className="sk-fading-circle">
              <div className="sk-circle1 sk-circle"></div>
              <div className="sk-circle2 sk-circle"></div>
              <div className="sk-circle3 sk-circle"></div>
              <div className="sk-circle4 sk-circle"></div>
              <div className="sk-circle5 sk-circle"></div>
              <div className="sk-circle6 sk-circle"></div>
              <div className="sk-circle7 sk-circle"></div>
              <div className="sk-circle8 sk-circle"></div>
              <div className="sk-circle9 sk-circle"></div>
              <div className="sk-circle10 sk-circle"></div>
              <div className="sk-circle11 sk-circle"></div>
              <div className="sk-circle12 sk-circle"></div>
            </div>
          </div>
        </div>
        <div style={{textAlign: 'center'}}>
          Please wait while we validate your payment information. This may take a few moments, but you'll be automatically redirected once the validation is complete.
        </div>
      </div>
    )
  }

  const renderFailed = () => {
    return (
      <div className="payment-failed-container">
        <div className="failed-img-container">
          <div className="failed-img" />
        </div>
        <div style={{paddingBottom: '25px'}}>
          We're sorry, your payment for the subscription has failed. Please try one of the options below.
        </div>
        <div className="footer-btns">
          <button className="retry-btn" onClick={handleRetry}>Retry</button>
          <button className="enter-btn btn" onClick={handleUpdateCardClick}>Update Card</button>
        </div>
      </div>
    )
  }

  const renderUpdateCard = () => {
    return (
      <></>
    )
  }

  const handleSuccessContinue = () => {
    refreshUser();
    setAPILoading(true);
    getUserPlan()
      .then((response) => {
        setShowPaymentFailureModal(false);
      })
      .finally(() => { setAPILoading(false) })
  }

  const renderSuccess = () => {
    return (
      <div className="payment-failed-container">
        <div className="failed-img-container">
          <div className="success-img" />
        </div>
        <div style={{textAlign: 'center', paddingBottom: '15px'}}>
          Your payment has been successfully processed. Your account has been updated and your subscription is now active. Thank you for choosing our service!
        </div>
        <div className="footer-btns">
          {
            isAPILoading ? 
              <InlineLoader isLoading={true} />
              :
              <button 
                className="btn full-width" 
                onClick={() => handleSuccessContinue()}>
                  Continue using our service
              </button>
          }
        </div>
      </div>
    )
  }

  const renderBody = () => {
    switch (bodyStatus) {
      case 'loading':
        return renderLoading();
      case 'failed':
        return renderFailed();
      case 'updateCard':
        return renderUpdateCard();
      case 'success':
        return renderSuccess();
    }
    return <></>;
  }

  return (
    <Modal 
      show={showPaymentFailureModal} 
      setShow={setShowPaymentFailureModal}
      title={modalTitle}
      className="upgrade-modal"
      showClose={false}
    >
      <div className="upgrade-modal-body">
        <div className="payment-container">
          <div className="plans-list">
            {
              renderBody()
            }

            <div style={{display: bodyStatus === 'updateCard' ? 'unset' : 'none'}}>
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  planId={userPlan?.planInfo?.Id}
                  planPrice={100}
                  setIsOpen={true}
                  hideHeading={true}
                  onReady={() => {}}
                  updateCard={true}
                  updateSuccess={() => setBodyStatus('success')}
                  updateError={() => setBodyStatus('updateCard')}
                  saveButtonClass="btn medium"
                  onCancel={() => setBodyStatus('failed')}
                  setPaymentLoading={setPaymentLoading}
                  authText={'To verify your Credit Card Details, we will temporarily hold $1 to authenticate your card, which will be immediately reversed.'}
                  setPaymentError={setPaymentError}
                />
              </Elements>
            </div>

          </div>
        </div>
      </div>
    </Modal>
  )
}

export default PaymentFailureModal;