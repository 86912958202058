import React, { useState, useContext } from 'react'
import { VariantConfiguration, VariantSection, VariantItemSection, VariantDropDown, ICustomWindow } from "../model";
import { Modal } from 'frontend-core';
import { ReactComponent as RemoveAdsIcon } from '../icons/remove-ads-icon.svg';
import DropDown from './drop-down';
import DeepCopy from '../deep-copy';
import { AppContext } from '../../appContext';

declare let window: ICustomWindow;

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  };
}

const VariantsConfiguration = () => {
  const {isVariantConfigVisible, setIsVariantConfigVisible} = useContext(AppContext);
  const [adsData, setAdsData] = useState('');
  const [defaultConfigData, setDefaultConfigData] = useState('');

  window.showAdvancedConfigModal = (adsConfig: string, defaultConfig: string) => {
    if (!adsConfig || !defaultConfig) {
      console.warn('invalid parameters for showAdvancedConfigurationPopup');
      return;
    }
    if (adsConfig == "" || defaultConfig == "") {
      console.warn('invalid parameters for showAdvancedConfigurationPopup');
      return;
    }
    setIsVariantConfigVisible(true);
    setAdsData(adsConfig);
    setDefaultConfigData(defaultConfig);
  };

  return (
    isVariantConfigVisible && adsData && defaultConfigData
      ?
        <VariantsConfigModal
          adsData={adsData}
          defaultConfigurationData={defaultConfigData}
          onReset={() => {
            setAdsData("");
            setDefaultConfigData("");
          }}
        />
      :
        null
  );
};

const VariantsConfigModal = (props) => {
  const [modalStyle] = React.useState(getModalStyle);

  const {isVariantConfigVisible, setIsVariantConfigVisible} = useContext(AppContext);
  const {adsData, defaultConfigurationData, onReset} = props;

  const defaultConfiguration: VariantConfiguration = JSON.parse(defaultConfigurationData);
  const adsList = JSON.parse(adsData);
  const [ads, setAds] = useState<VariantConfiguration[]>((adsList.length == 0) ? [defaultConfiguration] : adsList);
  const [selectedIndex, setSelectedIndex] = useState(ads.length - 1);
  const [selectedConfiguration, setSelectedConfiguration] = useState<VariantConfiguration | undefined>(ads[ads.length - 1]);

  const addAds = () => {
    const newConfig = DeepCopy<VariantConfiguration>(defaultConfiguration);
    const newAds = [...ads, newConfig];
    setAds(newAds);
    setSelectedIndex(newAds.length - 1);
    setSelectedConfiguration({...newAds[newAds.length - 1]});
  };

  const duplicateAds = () => {
    if (selectedIndex == -1 || selectedIndex > ads.length) {
      return;
    }

    const newConfig = DeepCopy<VariantConfiguration>(ads[selectedIndex]);
    const newAds = [...ads, newConfig];
    setAds(newAds);
    setSelectedIndex(newAds.length - 1);
    setSelectedConfiguration({...newAds[newAds.length - 1]});
  };

  const removeAds = (index: number) => {
    if (selectedIndex == index) {
      setSelectedIndex(-1);
    }

    const oldAds = [...ads]
    if (oldAds.length == 1) {
      setAds([]);
      setSelectedIndex(oldAds.length - 1);
      setSelectedConfiguration(undefined);
    } else {
      const removedAd = oldAds.splice(index, 1);
      setAds(oldAds);
      setSelectedIndex(oldAds.length - 1);
      setSelectedConfiguration({...oldAds[oldAds.length - 1]});
    }
  };

  const onAdSelected = (index: number) => {
    setSelectedIndex(index);
    setSelectedConfiguration({...ads[index]});
  };

  const applyVariants = async () => {
    try {
      window.dispatchEvent(new CustomEvent('VariantConfigEvent', {
        bubbles: false,
        detail: JSON.stringify(ads)
      }));
    } catch (error) {
      console.error('varint config apply failed.', error);
    } finally {
      reset();
    }
  };

  const onCancel = () => {
    reset();
  };

  const onApply = () => {
    applyVariants();
  };

  const reset = () => {
    onReset()
    setAds([]);
    setSelectedIndex(-1);
    setSelectedConfiguration(undefined);
    setIsVariantConfigVisible(false);
  }

  return (
    <Modal
      show={isVariantConfigVisible}
    >

      <div style={modalStyle} className={"paper"}>
        <div className="typography header">
          Advanced Variant Configuration
        </div>

        <div className="typography description">
          Select each variant combination you would like to include for each
          creative.
        </div>

        <div className={"container"}>
          <div className={"adsContainer"}>
            <div
              className={"adsDataContainer"}
              style={{ display: "flex", flexDirection: "column" }}
            >
              {ads.map((ad: VariantConfiguration, index: number) => (
                <div
                  key={index}
                  className={
                    selectedIndex == index
                      ? "adsSelected"
                      : "adsUnselected"
                  }
                >
                  <div
                    className={`typography ${ selectedIndex == index
                      ? "adsLabelSelected"
                      : "adsLabelUnselected"}`
                    }
                    onClick={() => onAdSelected(index)}
                  >
                    Creative {index + 1}
                  </div>
                  <RemoveAdsIcon
                    className={"RemoveAdsIcon"}
                    onClick={() => removeAds(index)}
                  ></RemoveAdsIcon>
                </div>
              ))}
            </div>
            <div className={"adsFooterContainer"}>
              <button className="btn outlined" onClick={addAds}>
                + Creative
              </button>
              <button
                className="btn outlined"
                onClick={duplicateAds}
              >
                Duplicate
              </button>
            </div>
          </div>
          <div
            className={"variantsContainer"}
            style={{ display: "flex", flexDirection: "column" }}
          >
            {selectedConfiguration &&
              selectedConfiguration.configurations.map(
                (variant: VariantSection, sectionIndex: number) => (
                  <div key={sectionIndex}>
                    <div className="typography sectionTitle">
                      {variant.sectionTitle}
                    </div>

                    {variant.sectionItems.map(
                      (
                        variantItemSection: VariantItemSection,
                        variantIndex: number
                      ) => (
                        <div key={variantIndex}>
                          <div className="typography itemTitle">
                            {variantItemSection.variantTitle}
                          </div>

                          <DropDown
                            setVariant={(value: number) => {
                              const copy = [
                                ...selectedConfiguration.configurations,
                              ];
                              copy[sectionIndex].sectionItems[
                                variantIndex
                              ].variantDropDown.selected = value;
                              setSelectedConfiguration({
                                ...selectedConfiguration,
                                configurations: copy,
                              });
                            }}
                            value={variantItemSection.variantDropDown}
                          ></DropDown>
                        </div>
                      )
                    )}
                    {sectionIndex ==
                      selectedConfiguration.configurations.length - 1 && (
                      <div style={{ height: "30px" }} />
                    )}
                  </div>
                )
              )}
          </div>
        </div>

        <div className="modal-footer" style={{ textAlign: "right" }}>
          <button
            className="btn outlined"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            color="primary"
            className="btn filled outlined"
            onClick={onApply}
          >
            Apply
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default VariantsConfiguration;
