export class Pair<T,Z> {
    
    _1:T;
    _2:Z;

    constructor(_1:T, _2: Z) {
        this._1 = _1;
        this._2 = _2;
    }

    setValues(arg0: T, arg1: Z) {
        this._1 = arg0;
        this._2 = arg1;
    }
}