import React from 'react';
import LandingHeader from './LandingHeader';
import LandingFooter from './LandingFooter';

interface ILandingLayout {
  children: JSX.Element
}

const LandingLayout: React.FC<ILandingLayout> = ({ children }) => {

  return (
    <div className="landing">
      <LandingHeader />
      {children}
      <LandingFooter />
    </div>
  );
};

export default LandingLayout;
