const metricAlignments = {
  id: 'left',
  status: 'left',
  name: 'left',
  thumbnailUrl: 'center',
  breakdownValue: 'left',
  objective: 'left',
  strategy: 'left',
  dailyBudget: 'right',
  amountSpent: 'right',
  purchaseConversionValue: 'right',
  purchases: 'right',
  costPerUniquePurchases: 'right',
  purchaseValueByUniquePurchases: 'right',
  purchaseROAS: 'right',
  trialsStarted: 'right',
  costPerStartTrial: 'right',
  impressions: 'right',
  clicks: 'right',
  appInstalls: 'right',
  cpm: 'right',
  cpc: 'right',
  ctr: 'right',
  cpp: 'right',
  costPerAppInstall: 'right',
  installRate: 'right',
  createdTime: 'left',
  updatedTime: 'left',
  totalPurchaseValue: 'right',
  campaignName: 'left',
  campaignId: 'left',
  adName: 'left',
  adId: 'left',
  adSetName: 'left',
  adSetId: 'left',
  frequency: 'right',
  reach: 'right',
  uniquePurchases: 'right',
  uniqueTrialsStarted: 'right',
  uniqueAppInstalls: 'right',
  delivery: 'left',
  attributionSetting: 'left',
  stopTime: 'left',
  accountCurrency:  'left',
  buyingType: 'left',
  canvasAvgViewPercent: 'right',
  canvasAvgViewTime: 'right',
  totalPostbacks: 'right',
  leads: 'right',
  saves: 'right',
  shares: 'right',
  optimizationGoal: 'left',
  socialPlatform: 'left',
  accountName: 'left',
  breakdown: 'left',
  startTime: 'left',
  bidAmount: 'right',
  accountId: 'left',
  creativeId: 'left',
};

export default metricAlignments;
