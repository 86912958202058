import React, { FC } from 'react';
import { Link } from 'react-router-dom';

const PostContentGeneration: FC = () => {

  return (
    <div className="blog-post">
      <p>
        The marketing field is a constantly evolving space; the tempo of change is quickening. We've seen this in recent years with the boom of TikTok and brands valuing more customer-centric marketing. This shift has given rise to a new trend: utilizing user-generated content (UGC) in paid social campaigns, where everyday people are sharing more authentic reactions to products. This marketing trend has proven to be incredibly successful; one survey revealed that 
        <a href="https://www.nielsen.com/insights/2012/consumer-trust-in-online-social-and-mobile-advertising-grows/" rel="noreferrer" target="_blank">92% of shoppers</a> trust non-paid recommendations from other customers more than any type of advertising, so it is a logical next step for advertisers to imitate true customer feedback as much as possible.
      </p>
      <p>At Blitz, we have seen remarkable success producing and running this style of ad. In this post, we'll be sharing insights from our experience with user generated content marketing, our unique twist on this marketing strategy, and the answer to the question of how to get user generated content for our purposes. </p>

      <h2>Typical User-Generated Content </h2>
      <p>
        Classic user-generated content (UGC) involves unpaid content that is created by users on a social media platform and may feature an organic brand mention. UGC comes in many forms, including images, videos, reviews, testimonials, or even podcasts. Traditionally, there's no business relationship between UGC creators and the brands they endorse; content creators simply share products they love with their followers. Since there's no real monetary incentive for these content creators, pure UGC is the most genuine form of marketing, because customers are sincerely sharing their actual experiences. If you want to know more about UGC content you can read our other blog post, 
        <Link to="/blog/why-is-user-generated-content-so-important">Why is user generated content so important?</Link>
      </p>

      <p>
        It's worth mentioning that not all UGC is used for marketing purposes. Since it's just content from everyday people, not ads, UGC dominates your various social media feeds. However, marketers have realized that using brand-driven UGC will help create a sense of community, 
        <a href="https://imagen.io/resources/ugc-video-marketing/" rel="noreferrer" target="_blank">authenticity, and social proof</a>. These are vital components when delivering marketing that wins customers. One example of this is Starbucks's famous #RedCupContest. Every December, Starbucks launches the #RedCupContest, asking customers to submit cool photos of their coffees for the chance to win a Starbucks gift card. For Starbucks, the benefits of user generated content include generating thousands of photos from authentic users, at no cost to them. You can think of UGC as low or no cost effective marketing.
      </p>

      <h2>Growth of User-Generated Content on TikTok</h2>
      <p>UGC really exploded during the pandemic, as brand's customers took to letting their favorite brands know just how much they appreciate them. The worst of the pandemic might be behind us, but we still have the same desires to seek out content that inspires, informs and entertains; driven by a much more human urge – the need to connect. TikTok has established itself as the main hub for user-generated content over the past couple years. TikTok has a successful online presence due to its ability to connect users in an authentic, easy way. In the fast-paced digital age, short and entertaining UGC content sparks joy for users and marketers alike, resulting in the rapid growth of the app. The TikTok creator marketplace where brands can directly source influencers for custom campaigns is booming, and for brands, user-centered content is essential in this day and age. UGC creators have a huge digital presence; from celebrities to influencers to the average social media user, everyone is sharing their experiences online.</p>
      <p>Since UGC performs so well on social media, it's no surprise that brands are taking notice. UGC makes a real difference when it comes to customers making decisions about products. Consumers are constantly bombarded with content and advertisements, and the only way brands can cut through all that noise is with authentic content, like UGC.</p>

      <h2>User-Generated Content in Paid Ads</h2>
      <p>At Blitz, we've taken inspiration from true user-generated content to produce ad creative for paid ads on social media that feels truly native. Since the end consumer trusts content from everyday people just like them over typical polished and highly produced ads, we've come up with a solution: we take the authentic, organic style of UGC and work with brands to produce ads for paid social campaigns that embody brand values while feeling authentic.</p>
      <p>How do we come up with ideas for ads? We monitor trends across social media platforms commonly used in organic content in what users are talking about, what hashtags are commonly used, what platform features are popular. We then use those elements and components as inspiration for the ads we produce, working with the brand to ensure that we're also highlighting the key value proposition for their service and presenting them in a good light. </p>
      <p>For example, the TikTok reply to comment feature allows an account to respond to a comment left on a previous video with a new video and is commonly used to answer questions, announce installments on a video series, etc. An effective ad format we frequently use features a fictional comment box overlaid on a selfie video, with the content geared towards presenting the product as a solution to a problem. Because it's so native, when a user encounters the ad alongside truly organic content, they often engage with the post before realizing it is an advertisement.</p>

      <h2>How to get user generated content for paid social?</h2>
      <p>There are a few easy ways to get this style of content to run in paid campaigns on Facebook, Instagram, TikTok, YouTube, and more. The important thing is that the content looks and feels authentic, which means the creator needs to be friendly, compelling, and a clear fit for the product you are marketing.</p>
      <p>At Blitz, we work with dozens of creators on many different campaigns for many different types of products. Our pool of creators is a mix of professional actors, micro and nano influencers, and models. Our key requirement when sourcing a new creator to generate this type of asset with us is that they must be compelling on camera. Of course, this can take many shapes: for a mental health product, we may seek out creators that have a more grounded and soothing vibe, whereas for an ecommerce app we would search for creators that are energetic and high-energy. Creator fit is critical in generating UGC-style ads that won't fall flat. The audience MUST believe that it is a realistic review.</p>
      <p>To get started, contacting nano and micro influencers in your niche on your platform of choice is a great way to make connections. Be sure to clarify when you approach them that you will be running the content produced from your handle, not theirs, as this will significantly decrease your costs, and when you're looking at small accounts of this size, their organic reach will likely not be meaningfully impactful for your business anyway.</p>

      <h2>How is influencer marketing different from UGC-style ads?</h2>
      <p>You may wonder how this is different from influencer marketing, as we often shoot videos with nano or micro influencers to then use as paid ads. The lines get a bit blurred, especially as the ad platforms each evolve to include crossover features like Spark ads on TikTok and Branded Content ads on Instagram, where influencer posts can be boosted and manipulated within an ad campaign. However, influencer marketing is typically professional quality content posted on an influencer's personal page using #ad or “paid partnership” at the top of the post. Creator or influencer generated content is content that is intentionally created by an influencer in conjunction with a brand that is posted by the brand and run as an ad from their account. The brand has control over the  photo and the message and can track campaign management, and the actor, creator, or influencer receives compensation from the company for their video content in the form of free products or money.</p>
      <p>The way the world of advertising functions is changing. At Blitz, we've seen the evolution of the space from the very beginning of Facebook Advertising, including the initial rise of video as an effective advertising tool and now the shift from polished video ads to more authentic, UGC-inspired posts featuring real people giving real product sentiments. With the rise of short form video and the effectiveness of the TikTok ad platform, the turn towards this shift has accelerated. We're seeing more and more brands embrace this style of content, and in turn the ad platforms are providing us with more features to drive access to their users. We expect UGC to play an increasingly important role in our advertising strategy in the months and years to come.</p>

    </div>
  );
}

export default PostContentGeneration;
