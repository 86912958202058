// const getPreviewPlatformOptions = () => {
//   const platformOptions = {
//     facebook: [
//       {
//         label: (
//           <div className="platform-icon facebook">
//             Facebook
//             <div className="caption">1080x1080 px</div>
//           </div>
//         ),
//         value: 'FB'
//       },
//       {
//         label: (
//           <div className="platform-icon instagram">
//             Instagram Feed
//             <div className="caption">1080x1350 px</div>
//           </div>
//         ),
//         value: 'IG'
//       },
//       {
//         label: (
//           <div className="platform-icon instagram">
//             Instagram Story
//             <div className="caption">1080x1920 px</div>
//           </div>
//         ),
//         value: 'IS'
//       }
//     ],
//     snapchat: [
//       {
//         label: (
//           <div className="platform-icon snapchat">
//             Snapchat Story
//             <div className="caption">1080x1920 px</div>
//           </div>
//         ),
//         value: 'SC'
//       }
//     ],
//     tiktok: [
//       {
//         label: (
//           <div className="platform-icon tiktok">
//             TikTok Story
//             <div className="caption">1080x1920 px</div>
//           </div>
//         ),
//         value: 'TT'
//       }
//     ]
//   };

//   let currentPlatform = platform.current;
//   // @todo need add the google platform options
//   if (currentPlatform === 'google') {
//     currentPlatform = 'facebook'
//   }
//   let options: any[] = [];
//   for (const opts in platformOptions) {
//     options = [...options, ...platformOptions[opts]];
//   }
//   if (options && options[0]) {
//     setSelectedPreviewPlatform(options[0]?.value);
//     setCanvasDimensions(convertCanvasRatios(CANVAS_DIMENSIONS[options[0]?.value]));
//   }
//   return options;
// };

const aiAds = {
  canvasIdPrefix: 'ad_canvas',
  imageIdPrefix: 'ad_image',
  templates: [
    {
      name: 'Instagram Post',
      image: 'https://media.useblitz.com/assets/images/templates/instagram-black-caption-post-without-acme.png',
      elements: [
        {
          id: 'main_image',
          type: 'IMAGE',
          editable: false,
          category: 'MAIN',
          label: 'Main Image',
          position: { x: 0, y: 0 },
          // url: 'https://media.useblitz.com/userUploadData/PROD/27/f545d6d1bc96c4a2d8318993f7236ef11/photo-of-white-city-bike-2277711.jpg',
          url: 'https://media.useblitz.com/userUploadData/PROD/27/f545d6d1bc96c4a2d8318993f7236ef11/photo-of-white-city-bike-2277711.jpg'

        },
        {
          id: 'content',
          type: 'TEXT',
          editable: true,
          maxLength: 100,
          category: 'CONTENT',
          as: 'textarea',
          label: 'Content',
          position: { x: 100, y: 150, maxWidth: 880, lineHeight: 90 },
          text: '"You can find anything you want on ACME!"',
          style: {
            color: '#FFFFFF',
            font: 'bold 60px Arial',
            background: '#000000'
          }
        },
        {
          id: 'logo',
          type: 'IMAGE',
          editable: true,
          category: 'LOGO',
          label: 'Branding Image',
          position: { x: 390, y: 82, width: 300, height: 160, percentage: true },
          url: 'https://media.useblitz.com/userUploadData/PROD/27/f0c1508c858f348918353fb802b1b4656/boldwhite.png',
          isOptional: true,
          display: false,
        }
      ]
    },
    {
      name: 'Five Star Camera',
      image: 'https://media.useblitz.com/assets/images/templates/fivestar-camera-without-acme.png',
      elements: [
        {
          id: 'main_image',
          type: 'IMAGE',
          editable: false,
          category: 'MAIN',
          label: 'Main Image',
          position: { x: 0, y: 0 },
          url: 'https://media.useblitz.com/constants/SparkCameraAsset/StaticAssets/annie-spratt-dDgzChwq04I-unsplash.jpg',
        },
        // dummy transparent overlay to center the content of the ad
        {
          id: 'overlay',
          type: 'OVERLAY',
          position: {
            x: 0,
            y: 50,
            width: 1080,
            height: 1080,
            percentage: true
          },
          style: {
            background: 'transparent',
          }
        },
        {
          id: 'star_image',
          type: 'IMAGE',
          editable: false,
          category: '5STAR',
          label: '5 Star Image',
          position: { x: 50, y: -50, width: 450, height: 100, persist: true },
          url: 'https://media.useblitz.com/constants/SparkCameraAsset/StaticAssets/Star.png'
        },
        {
          id: 'content',
          type: 'TEXT',
          editable: true,
          maxLength: 140,
          category: 'CONTENT',
          as: 'textarea',
          label: 'Content',
          position: { x: 50, y: 170, maxWidth: 880, lineHeight: 65, persist: true },
          text: '"Unsurprisingly, ACME has become the best choice for countless influencers and brands"',
          style: {
            color: '#FFFFFF',
            font: '500 50px sans-serif',
          }
        },
        {
          id: 'username',
          type: 'TEXT',
          editable: true,
          maxLength: 30,
          category: 'USERNAME',
          as: 'input',
          label: 'User Name',
          position: { x: 50, y: 500, persist: true },
          text: '- Jennifer',
          style: {
            color: '#FFFFFF',
            font: '500 50px sans-serif',
          }
        },
        {
          id: 'logo',
          type: 'IMAGE',
          editable: true,
          category: 'LOGO',
          label: 'Branding Image',
          position: { x: 740, y: 85, width: 300, height: 160, percentage: true },
          url: 'https://media.useblitz.com/userUploadData/PROD/27/f0c1508c858f348918353fb802b1b4656/boldwhite.png',
          isOptional: true,
          display: false,
        }
      ]
    },
    {
      name: 'Five Star Mountains',
      image: 'https://media.useblitz.com/assets/images/templates/fivestar-mountains-without-acme.png',
      elements: [
        {
          id: 'main_image',
          type: 'IMAGE',
          editable: false,
          category: 'MAIN',
          label: 'Main Image',
          position: { x: 0, y: 0 },
          url: 'https://media.useblitz.com/userUploadData/PROD/1/f32c92b87d50d4add94aa12923dc61331/mountain.jpg',
        },
        // dummy transparent overlay to center the content of the ad
        {
          id: 'overlay',
          type: 'OVERLAY',
          position: {
            x: 0,
            y: 'center',
            width: 1080,
            height: 1080,
          },
          style: {
            background: 'transparent',
          }
        },
        {
          id: 'logo',
          type: 'IMAGE',
          editable: true,
          category: 'LOGO',
          label: 'Branding Image',
          position: { x: 360, y: 100, width: 360, height: 80, persist: true },
          url: 'https://media.useblitz.com/userUploadData/PROD/27/f0c1508c858f348918353fb802b1b4656/boldwhite.png',
          isOptional: true,
          display: false
        },
        {
          id: 'star_image',
          type: 'IMAGE',
          editable: false,
          category: '5STAR',
          label: '5 Star Image',
          position: { x: -260, y: 200, width: 880, height: 100, persist: true },
          url: 'https://media.useblitz.com/userUploadData/PROD/1/f065297d5a997497589a5cc4d038a3485/Stars.png'
        },
        {
          id: 'quote_1',
          type: 'IMAGE',
          editable: false,
          category: 'QUOTE_1',
          label: 'Quote Image 1',
          position: { x: 0, y: 200, width: 100, height: 100, persist: true },
          url: 'https://media.useblitz.com/userUploadData/PROD/1/fe32eb6da81784adda26d97ca853b6859/12.png'
        },
        {
          id: 'content',
          type: 'TEXT',
          editable: true,
          maxLength: 140,
          category: 'CONTENT',
          as: 'textarea',
          label: 'Content',
          position: {
            x: 100,
            y: 100,
            maxWidth: 730,
            lineHeight: 70,
            persist: true
          },
          text: 'I never thought I could feel this way again, but ACME has amazed me once more. I\'m confident and now ready for any adventure!',
          style: {
            color: '#FFFFFF',
            font: '400 55px Arial',
          }
        },
        {
          id: 'quote_2',
          type: 'IMAGE',
          editable: false,
          category: 'QUOTE_2',
          label: 'Quote Image 2',
          position: { x: 780, y: 400, width: 100, height: 100, persist: true },
          url: 'https://media.useblitz.com/userUploadData/PROD/1/fd4445720bb4c4f25a190a637ad47189f/22.png'
        },
        {
          id: 'username',
          type: 'TEXT',
          editable: true,
          maxLength: 30,
          category: 'USERNAME',
          as: 'input',
          label: 'User Name',
          position: { x: -200, y: 200, persist: true },
          text: '- Jennifer',
          style: {
            color: '#FFFFFF',
            font: 'bold 55px Arial',
          }
        },
      ]
    },
    {
      name: 'Twitter Testimonial',
      image: 'https://media.useblitz.com/assets/images/templates/twitter-ad-template-without-acme.png',
      elements: [
        {
          id: 'main_image',
          type: 'IMAGE',
          editable: false,
          category: 'MAIN',
          label: 'Main Image',
          position: { x: 0, y: 0 },
          url: 'https://media.useblitz.com/userUploadData/PROD/1/fc81e1faf606c491c90f7711de099fde6/dollor.jpeg',
          // useblitz.s3.amazonaws.com
        },
        {
          id: 'overlay',
          type: 'OVERLAY',
          position: {
            x: 80,
            y: 'center',
            width: 920,
            height: 580,
            radius: 50,
          },
          style: {
            background: '#FFFFFF',
          }
        },
        {
          id: 'profile_image',
          type: 'IMAGE',
          editable: true,
          category: 'PROFILE',
          label: 'Profile Picture',
          position: {
            x: 40,
            y: 40,
            width: 150,
            height: 150,
            persist: true,
            round: true
          },
          url: 'https://media.useblitz.com/userUploadData/LOCAL/2/f6a79c158193a418d8a774ed63a6ede47/TwitterProfile.jpg'
        },
        {
          id: 'username',
          type: 'TEXT',
          editable: true,
          maxLength: 30,
          category: 'USERNAME',
          as: 'input',
          label: 'User Name',
          position: { x: 190, y: 40, persist: true },
          text: 'Sammi chen',
          style: {
            color: '#000000',
            font: 'bold 35px Arial',
          }
        },
        {
          id: 'user_id',
          type: 'TEXT',
          editable: true,
          maxLength: 30,
          category: 'USER_HANDLE',
          as: 'input',
          label: 'Twitter ID',
          position: { x: 190, y: 90, persist: true },
          text: '@SammyzCxOxO',
          style: {
            color: '#959090',
            font: 'bold 35px Arial',
          }
        },
        {
          id: 'content',
          type: 'TEXT',
          editable: true,
          maxLength: 140,
          category: 'CONTENT',
          as: 'textarea',
          label: 'Content',
          position: { x: 0, y: 220, maxWidth: 840, lineHeight: 55, persist: true },
          text: 'I never thought I could feel this way again, but ACME has amazed me once more. I\'m confident and now ready for any adventure!',
          style: {
            color: '#000000',
            font: 'bold 45px Arial',
          }
        },
        {
          id: 'date',
          type: 'TEXT',
          editable: true,
          maxLength: 40,
          category: 'DATE',
          as: 'input',
          label: 'Date Text',
          position: { x: 0, y: 500, persist: true },
          text: '1:45 AM . Jan 10, 2023',
          style: {
            color: '#959090',
            font: 'normal 40px Arial',
          }
        },
        {
          id: 'logo',
          type: 'IMAGE',
          editable: true,
          category: 'LOGO',
          label: 'Branding Image',
          position: { x: 790, y: 82, width: 200, height: 160, percentage: true },
          url: 'https://media.useblitz.com/userUploadData/PROD/27/f0c1508c858f348918353fb802b1b4656/boldwhite.png',
          isOptional: true,
          display: false,
        }
      ]
    }
  ],
  // getPreviewPlatformOptions
};

export default aiAds;
