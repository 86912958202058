import { createSlice } from "@reduxjs/toolkit";
import { getCardDetails, getSubscriptionInfo, getTeamMembers } from "../api/account-api";
import { TeamMember, PlanAdditionalParams, SubscriptionType, SubscriptionStatus } from "../common/model";

interface IUserAccountSlice {
  card: {
    loading: boolean;
    error: string;
    data: null | ICardDetails;
  }
  subscriptionInfo: {
    loading: boolean;
    error: string;
    data: null | ISubscriptionInfo;
  }
  teamMembers: {
    loading: boolean;
    error: string;
    data: TeamMember[];
  }
}

interface ICardDetails {
  brand: string;
  country: string;
  customer: string;
  cvcCheck: string;
  expMonth: number;
  expYear: number;
  fingerprint: string;
  funding: string;
  id: string;
  last4: string;
  metadata: any;
  object: string;
}

interface IPlan {
  Id: number;
  additionalParams: PlanAdditionalParams;
  createdAt: string;
  groupId: string;
  isActive: boolean;
  sourcePlanId: string;
  subscriptionSource: string;
  subscriptionType: SubscriptionType;
  updatedAt: string;
}

interface ISubscription {
  Id: number;
  billingCycleCount: number;
  cardFingerPrint: string;
  companyId: number;
  createdAt: string;
  currentPurchaseId: string;
  expiryTime: number;
  isDiscountUsed: boolean;
  isTrialUsed: boolean;
  purchaseTime: number;
  subscriptionPlanId: number;
  subscriptionReferenceId: string;
  subscriptionStatus: SubscriptionStatus;
  updatedAt: string;
  userId: number;
}

interface ISubscriptionInfo {
  planInfo?: IPlan;
  subscriptionInfo?: ISubscription;
}

const UserAccountSlice = createSlice({
  name: 'accounts',
  initialState: {
    card: {
      loading: false,
      error: '',
      data: null,
    },
    subscriptionInfo: {
      loading: false,
      error: '',
      data: null,
    },
    teamMembers: {
      loading: false,
      error: '',
      data: [],
    },
  } as IUserAccountSlice,
  reducers: {
    getCardStart(state, action) {
      state.card.loading = true;
      state.card.error = '';
      state.card.data = null;
    },
    getCardSuccess(state, action) {
      state.card.loading = false;
      state.card.error = '';
      state.card.data = action.payload;
    },
    getCardError(state, action) {
      state.card.loading = false;
      state.card.error = action.payload.error;
      state.card.data = null;
    },

    getSubscriptionInfoStart(state, action) {
      state.subscriptionInfo.loading = true;
      state.subscriptionInfo.error = '';
      state.subscriptionInfo.data = null;
    },
    getSubscriptionInfoSuccess(state, action) {
      state.subscriptionInfo.loading = false;
      state.subscriptionInfo.error = '';
      state.subscriptionInfo.data = action.payload;
    },
    getSubscriptionInfoError(state, action) {
      state.subscriptionInfo.loading = false;
      state.subscriptionInfo.error = action.payload.error;
      state.subscriptionInfo.data = null;
    },

    getTeamMembersStart(state, action) {
      state.teamMembers.loading = true;
      state.teamMembers.error = '';
      state.teamMembers.data = [];
    },
    getTeamMembersSuccess(state, action) {
      state.teamMembers.loading = false;
      state.teamMembers.error = '';
      state.teamMembers.data = action.payload;
    },
    getTeamMembersError(state, action) {
      state.teamMembers.loading = false;
      state.teamMembers.error = action.payload.error;
      state.teamMembers.data = [];
    },
  },
});

export const {
  getCardStart, getCardSuccess, getCardError,
  getSubscriptionInfoStart, getSubscriptionInfoSuccess, getSubscriptionInfoError,
  getTeamMembersStart, getTeamMembersSuccess, getTeamMembersError,
} = UserAccountSlice.actions;

export const fetchCard = () => async dispatch => {
  try {
    // console.info('dispatch(getCardStart)');
    dispatch(getCardStart);
    const data = await getCardDetails();
    dispatch(getCardSuccess(data));
  } catch (err) {
    dispatch(getCardError(err));
  }
};

export const fetchSubscriptionInfo = () => async dispatch => {
  try {
    dispatch(getSubscriptionInfoStart);
    const data = await getSubscriptionInfo();
    dispatch(getSubscriptionInfoSuccess(data));
  } catch (err) {
    dispatch(getSubscriptionInfoError(err));
  }
};

export const fetchTeamMembers = () => async dispatch => {
  try {
    dispatch(getTeamMembersStart);
    const data = await getTeamMembers();
    dispatch(getTeamMembersSuccess(data));
  } catch (err) {
    dispatch(getTeamMembersError(err));
  }
};

export default UserAccountSlice.reducer;
