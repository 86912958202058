import { createSlice } from "@reduxjs/toolkit";
import { createMarketPlaceListingXhr, getCompletedMyListingsXhr, getListingsDetailsXhr, getPendingMyListingsXhr } from "../api/marketplace-api";

interface IMarketplaceSlice {
  creating: {
    loading: boolean;
    error: string;
    success: boolean;
  }
  pendingListings: {
    loading: boolean;
    error: string;
    success: boolean;
    listings: IMListingResponse[];
  }
  completedListings: {
    loading: boolean;
    error: string;
    success: boolean;
    listings: IMListingResponse[];
  }
  listingDetails: {
    loading: boolean;
    error: string;
    success: boolean;
    listing: null | IMListingResponse;
  }
}

export interface IMListingMedia {
  name: string;
  type: 'IMAGE' | 'VIDEO';
  url: string;
}

export interface IMListingPayload {
  name: string;
  globalTemplateId: number,
  maxCreators: number;
  globalTemplateName: string;
  creatorDescription: string;
  // creatorLook: string;
  // shootLocation: string;
  creatorSamples: IMListingMedia[];
  creatorVideoScripts: { script: string }[];
}

export interface IMListingResponse extends IMListingPayload {
  Id: number;
  status?: number;
  compensation: string;
  createdAt: string;
  updatedAt: string;
  creatorsSubmitted: number;
  watermarkImageUrl?: string;
}

const MarketplaceSlice = createSlice({
  name: 'marketplace',
  initialState: {
    creating: { loading: false, error: '', success: false, },
    pendingListings: { loading: false, error: '', success: false, listings: [], },
    completedListings: { loading: false, error: '', success: false, listings: [], },
    listingDetails: { loading: false, error: '', success: false, listing: null },
  } as IMarketplaceSlice,
  reducers: {
    createListingStart(state, action) {
      state.creating.loading = true;
      state.creating.error = '';
      state.creating.success = false;
    },
    createListingSuccess(state, action) {
      state.creating.loading = false;
      state.creating.error = '';
      state.creating.success = true;
    },
    createListingError(state, action) {
      state.creating.loading = false;
      state.creating.error = action.payload.error;
      state.creating.success = false;
    },

    fetchPendingListingsStart(state, action) {
      state.pendingListings.loading = true;
      state.pendingListings.error = '';
      state.pendingListings.success = false;
      state.pendingListings.listings = [];
    },
    fetchPendingListingsSuccess(state, action) {
      state.pendingListings.loading = false;
      state.pendingListings.error = '';
      state.pendingListings.success = true;
      state.pendingListings.listings = action.payload;
    },
    fetchPendingListingsError(state, action) {
      state.pendingListings.loading = false;
      state.pendingListings.error = action.payload.error;
      state.pendingListings.success = false;
    },
    resetPendingListings(state, action) {
      state.pendingListings.loading = true;
      state.pendingListings.error = '';
      state.pendingListings.success = false;
      state.pendingListings.listings = [];
    },

    fetchCompletedListingStart(state, action) {
      state.completedListings.loading = true;
      state.completedListings.error = '';
      state.completedListings.success = false;
      state.completedListings.listings = [];
    },
    fetchCompletedListingSuccess(state, action) {
      state.completedListings.loading = false;
      state.completedListings.error = '';
      state.completedListings.success = true;
      state.completedListings.listings = action.payload;
    },
    fetchCompletedListingError(state, action) {
      state.completedListings.loading = false;
      state.completedListings.error = action.payload.error;
      state.completedListings.success = false;
    },
    resetCompletedListing(state, action) {
      state.completedListings.loading = true;
      state.completedListings.error = '';
      state.completedListings.success = false;
      state.completedListings.listings = [];
    },

    fetchListingDetailsStart(state, action) {
      state.listingDetails.loading = true;
      state.listingDetails.error = '';
      state.listingDetails.success = false;
      state.listingDetails.listing = null;
    },
    fetchListingDetailsSuccess(state, action) {
      state.listingDetails.loading = false;
      state.listingDetails.error = '';
      state.listingDetails.success = true;
      state.listingDetails.listing = action.payload;
    },
    fetchListingDetailsError(state, action) {
      console.error(action.payload.error, action.payload);
      state.listingDetails.loading = false;
      state.listingDetails.error = action.payload.error;
      state.listingDetails.success = false;
    },
    resetListingDetails(state, action) {
      state.listingDetails.loading = true;
      state.listingDetails.error = '';
      state.listingDetails.success = false;
      state.listingDetails.listing = null;
    },
  },
});

export const {
  createListingStart, createListingSuccess, createListingError,
  fetchPendingListingsStart, fetchPendingListingsSuccess, fetchPendingListingsError, resetPendingListings,
  fetchCompletedListingStart, fetchCompletedListingSuccess, fetchCompletedListingError, resetCompletedListing,
  fetchListingDetailsStart, fetchListingDetailsSuccess, fetchListingDetailsError, resetListingDetails,
} = MarketplaceSlice.actions;

export const createMarketplaceListingDispatcher = (payload: IMListingPayload, success: Function) => async dispatch => {
  try {
    dispatch(createListingStart);
    const data = await createMarketPlaceListingXhr(payload);
    success();
    dispatch(createListingSuccess(data));
  } catch (err) {
    dispatch(createListingError(err));
  }
};

export const fetchPendingListingDispatcher = () => async dispatch => {
  try {
    dispatch(fetchPendingListingsStart);
    const data = await getPendingMyListingsXhr();
    dispatch(fetchPendingListingsSuccess(data));
  } catch (err) {
    dispatch(fetchPendingListingsError(err));
  }
};

export const fetchCompletedListingDispatcher = (pageNumber: number, pageSize: number) => async dispatch => {
  try {
    dispatch(fetchCompletedListingStart);
    const data = await getCompletedMyListingsXhr(pageNumber, pageSize);
    dispatch(fetchCompletedListingSuccess(data));
  } catch (err) {
    dispatch(fetchCompletedListingError(err));
  }
};

export const fetchListingDetailsDispatcher = (id: number) => async dispatch => {
  try {
    dispatch(fetchListingDetailsStart);
    const data = await getListingsDetailsXhr(id);
    dispatch(fetchListingDetailsSuccess(data));
  } catch (err: any) {
    console.error('175', err.response, '\n', err);
    dispatch(fetchListingDetailsError(err.response.data));
  }
};

export default MarketplaceSlice.reducer;
