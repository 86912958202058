import { currencyFormat, nonNegativeValue, roundedCurrencyFormat } from 'frontend-core';
// metric can be any one of the below values
// ["amountSpent", "purchases", "trialsStarted", "impressions", "clicks", "appInstalls"]
const analytics = {
  metrics: [
    'amountSpent',
    'purchases',
    'trialsStarted',
    'impressions',
    'clicks',
    'appInstalls',
    'cpm',
    'cpc',
    'cpp',
    'cpa',
    'ctr',
    'costPerStartTrial',
    'ir',
    'cr',
  ],
  metricLabel: {
    amountSpent: 'Amount Spent',
    purchases: 'Purchases',
    trialsStarted: 'Trials Started',
    impressions: 'Impressions',
    clicks: 'Clicks',
    appInstalls: 'App Installs',
    CPM: 'Cost Per Thousand Impressions (CPM)',
    CPC: 'Cost Per Click (CPC)',
    CPP: 'Cost Per Purchase (CPP)',
    CPA: 'Cost Per Install (CPA)',
    CTR: 'Click Through Rate (CTR)',
    costPerStartTrial: 'Cost per Trial',
    IR: 'Install Rate',
    CR: 'Conversion Rate',
    CPI: 'Cost Per Install (CPI)',
    // for the lower cases 
    cpm: 'Cost Per Thousand Impressions (CPM)',
    cpc: 'Cost Per Click (CPC)',
    cpp: 'Cost Per Purchase (CPP)',
    cpa: 'Cost Per Install (CPA)',
    ctr: 'Click Through Rate (CTR)',
    ir: 'Install Rate',
    cr: 'Conversion Rate',
    cpi: 'Cost Per Install (CPI)',
  },
  overviewTabs: [
    'amountSpent',
    'impressions',
    'clicks',
    'appInstalls',
    'purchases',
  ],
  nonNegativeValues: ['purchases', 'trialsStarted', 'impressions', 'clicks', 'appInstalls', 'volume'],
  metricsWithDollar: ['amountSpent-graph', 'CPM-graph', 'CPC-graph', 'CPP-graph', 'CPA-graph', 'CPI-graph', 'costPerStartTrial-graph', 'cost-graph'],
  rateMetrics: ['CTR-graph', 'IR-graph', 'CR-graph', 'conversions-graph', 'conversionRates-graph', 'ctr-graph'],
  metricFormatters: {
    amountSpent: currencyFormat,
    appInstalls: nonNegativeValue,
    impressions: nonNegativeValue,
    clicks: nonNegativeValue,
    purchases: nonNegativeValue,
    trialsStarted: nonNegativeValue,
    CPM: currencyFormat,
    CPC: currencyFormat,
    CPP: currencyFormat,
    CPA: currencyFormat,
    CPI: currencyFormat,
    CTR: nonNegativeValue,
    costPerStartTrial: currencyFormat,
    IR: nonNegativeValue,
    CR: nonNegativeValue,
  },
  adFunnel: [
    'volumeMetrics',
    'costMetrics',
    'conversionRates'
  ],
  adFunnelChartLabels: {
    volumeMetrics: 'Volume',
    costMetrics: 'Cost Metrics',
    conversionRates:'Conversion Rates',
  },
  DATE_FORMAT: 'YYYY-MM-DD',
  graphConfig: {
    rangeSelector: { enabled: false },
    scrollbar: { enabled: false },
    legend: { enabled: false },
    navigator: { enabled: false },
    exporting: { enabled: true }
  },

  constructDevData: (data) => {
    for (const date in data) {
      data[date] = Math.random() * 10;
    }
    return data;
  },
  reportTypes: [
    {
      value: 'ADACCOUNT',
      label: 'Ad Account',
    },
    {
      value: 'CAMPAIGN',
      label: 'Campaign',
    },
    {
      value: 'ADSET',
      label: 'Ad Set',
    },
    {
      value: 'AD',
      label: 'Ad',
    },
    {
      value: 'CREATIVE',
      label: 'Creative',
    }
  ],
  segmentations: [
    {
      value: 'USER',
      label: 'User'
    },
    {
      value: 'PLATFORM',
      label: 'Platform'
    },
    {
      value: 'AD_ACCOUNT',
      label: 'Ad Account'
    },
    {
      value: 'CAMPAIGN',
      label: 'Campaign'
    },
    {
      value: 'ADSET',
      label: 'AdSet'
    },
    {
      value: 'AD',
      label: 'Ad'
    },
    {
      value: 'CREATIVE',
      label: 'Creative'
    }
  ],
  costRelatedMetrics: [
    'cpc',
    'cpm',
    'cpp',
    'costPerStartTrial',
    'totalPurchaseValue',
    'costPerUniquePurchases',
    'PurchaseRoas',
    'costPerAppInstall',
    'costPerResult',
    'purchases',
    'dailyBudget',
    'purchaseValueByUniquePurchases',
  ],
  stringRelatedMetrics: [
    'status',
    'attributionSetting',
    'accountName',
    'campaignName',
    'adSetName',
    'adName',
    'stopTime',
    'delivery',
    'objective',
    'attributionSetting',
    'buyingType',
    'optimizationGoal',
    'socialPlatform',
    'creativeMediaName',
    'mediaUrls'
  ],
  statusFilterOptions: [
    {
      label: 'Active', 
      value: 'ACTIVE'
    }, 
    {
      label: 'Paused', 
      value: 'PAUSED'
    }
  ]
};

export default analytics;
