import { evaluate, roundToTwo } from "../../Common/MathExpressionStatement";
import { DimensionInputType } from "../inputs/DimensionInputType";
import { VideoPlatform } from "../templates/VideoPlatform";
import { AspectType } from "./AspectType";
import { ImageComponent } from "./ImageComponent";

export class CircularImageComponent extends ImageComponent {

    async drawComponent(frameNumber: number, previewMultiplier: number, parentImage: HTMLCanvasElement | null, videoPlatform: VideoPlatform): Promise<HTMLCanvasElement> {
        let componentWidth: number = this.componentWidthAsFloat(previewMultiplier, parentImage);
        let componentHeight: number = this.componentHeightAsFloat(previewMultiplier, parentImage);
        let img: HTMLImageElement = document.createElement("img");
        img.crossOrigin = "anonymous";
        img.src = this.imgUrl;
        await img.decode();
        let canv: HTMLCanvasElement = this.canvas(componentWidth, componentHeight);
        let ctx = canv.getContext("2d");
        if (ctx === null) {
            throw ("exception while getting ctx");
        }
        let sx1 = this.sx1(img, previewMultiplier, parentImage);
        let sy1 = this.sy1(img, previewMultiplier, parentImage);
        let sx2 = this.sx2(img, previewMultiplier, parentImage);
        let sy2 = this.sy2(img, previewMultiplier, parentImage);

        let dx1 = this.dx1(img, previewMultiplier, parentImage);
        let dy1 = this.dy1(img, previewMultiplier, parentImage);
        let dx2 = this.dx2(img, previewMultiplier, parentImage);
        let dy2 = this.dy2(img, previewMultiplier, parentImage);

        ctx.beginPath();
        // ctx.arc(150, 150, 130, 0, Math.PI * 2, false);
        //ellipse(x: number, y: number, radiusX: number, radiusY: number, rotation: number, startAngle: number, endAngle: number, counterclockwise?: boolean): void;
        ctx.ellipse((dx1+dx2)/2, (dy1+dy2)/2, this.getEffectiveWidth(previewMultiplier, parentImage)/2, this.getEffectiveHeight(previewMultiplier, parentImage)/2, 0, 0, Math.PI * 2, false);
        ctx.globalAlpha = 0;
        ctx.stroke();
        ctx.clip();
        ctx.globalAlpha = 1;
        ctx.drawImage(img,
            sx1, sy1,
            sx2 - sx1, sy2 - sy1,
            dx1, dy1,
            dx2 - dx1, 
            dy2 - dy1
        );
        return this.image(canv);
    }
}