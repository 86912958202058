import { Orientation } from './Orientation'
export class VideoPlatform {
    private static AllValues: { [name: string] : VideoPlatform } = {};

    static readonly FACEBOOK =  new VideoPlatform(1080, 1080, "Facebook", Orientation.PORTRAIT, "FB", "FACEBOOK");
    static readonly SNAPCHAT =  new VideoPlatform(1080, 1920, "Snapchat Story", Orientation.PORTRAIT, "SC", "SNAPCHAT");
    static readonly INSTAGRAM =  new VideoPlatform(1080, 1350, "Instagram Feed", Orientation.PORTRAIT, "IG", "INSTAGRAM");
    static readonly INSTA_STORY =  new VideoPlatform(1080, 1920, "Instagram Story", Orientation.PORTRAIT, "IS", "INSTA_STORY");
    static readonly TIKTOK =  new VideoPlatform(1080, 1920, "TikTok Story", Orientation.PORTRAIT, "TK", "TIKTOK");
    static readonly YOUTUBE_LANDSCAPE =  new VideoPlatform(1920, 1080, "Youtube Landscape", Orientation.LANDSCAPE, "YTL", "YOUTUBE_LANDSCAPE");
    static readonly YOUTUBE_PORTRAIT =  new VideoPlatform(1080, 1920, "Youtube Portrait", Orientation.PORTRAIT, "YTP", "YOUTUBE_PORTRAIT");
    static readonly TWITTER_SQUARE =  new VideoPlatform(1080, 1080, "Twitter Square", Orientation.PORTRAIT, "TS", "TWITTER_SQUARE");
    static readonly TWITTER_LANDSCAPE =  new VideoPlatform(1920, 1080, "Twitter Landscape", Orientation.LANDSCAPE, "TL", "TWITTER_LANDSCAPE");

    width:number;
    height:number;
    label:string;
    orientation:Orientation;
    shortName:string;

    private constructor(width: number, height: number, label: string, orientation: Orientation, shortName: string, public readonly displayValue: string) {
        this.width = width;
        this.height = height;
        this.label = label;
        this.orientation = orientation;
        this.shortName = shortName;
        VideoPlatform.AllValues[displayValue] = this;
    }

    public static parseEnum(data: string) : VideoPlatform {
        return VideoPlatform.AllValues[data];
    }

}