import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { externalLink } from './Posts';

const PostMarketingMistakes: FC = () => {

  return (
    <div className="blog-post">
      <p>
        The story of marketing, or helping businesses to grow and flourish via word of mouth or promotion, is a tale as old as civilization itself. The core principles of marketing haven't changed much from displaying your wares appealingly in the market and asking your friends to recommend you to potential buyers. Sure, the tools have evolved from simple word of mouth, to print, to radio, to television, to the internet, and now to fast moving social media, and the way advertisers
        {externalLink('https://www.brafton.com/blog/content-marketing/evolution-of-marketing/', 'approach')} marketing has evolved too. The focus is now not just on telling the customer about a product through compelling story and/or visuals but also to create an ongoing, trackable relationship with the customers through paid ads.
      </p>
      <p>Marketing is your line of direct communication with potential consumers and your opportunity to tell them what you can do for them and why only you can do it for them. Digital marketing is like having thousands of billboards at your disposal, each built specifically for every single passerby to tell them the story most compelling to them, accomplished by integrations at infinite points across a myriad of social media apps.</p>
      <p>Of course, everyone else is ALSO trying to reach the same customers you are via ads in the same locations. So when new businesses try to get in the market, they not only must compete for potential customers but even for impressions on their ads in the ad marketplaces. Not surprisingly, many startups spend vast amounts of money competing for this potential audience, and even less surprisingly many of these companies fail after wasting considerable capital. The question is, how can you market effectively to meet your business goals and do so better than your competitors?</p>
      <p>When used effectively, digital marketing can be the difference between profitability and failure. We see many companies make the same mistakes when setting out to market their products, so let us take you through the most common digital marketing mistakes that are killing your startup's growth:</p>

      <h3>#1: Failing to Define Goals</h3>
      <p>A business without a clearly defined goal is like a ship without a rudder. It will sail for a time, but it will have no control of its destiny and end up at the mercy of the winds. Many times, startups launch marketing campaigns backed by considerable capital without identifying goals for the foreseeable future of the business. We often see this taking the form of aimless development of features in an app without ever completing anything or polishing anything. Testing with real customers is also key before launching a marketing campaign; often teams will build and build without stopping to think if anyone really wants to use their product at all.</p>
      <p>Once you have defined your goals for your business and your product, you must then set corresponding digital marketing goals to lay the foundation for long term growth. If your business goal is inclined toward financial growth, your marketing campaigns should focus on increasing high-value conversions. If you want to disrupt the industry with the newest, hippest casual game, your marketing efforts should revolve around increasing your user base quickly.</p>
      <p>A marketing goal should build on your startup's current success with your existing customers and scale that to the next level, bringing you new growth. Unfortunately, there is no perfect formula to determine a goal, as it varies so widely across industries, stage of company maturity, the product, the problem your product is solving, and a long list of other external factors. </p>
      <p>
        Did that dampen your mood? Well, don't worry, <strong>George T. Doranhas</strong> has outlined a clean methodology to outline a good goal:
        {externalLink('https://www.projectsmart.co.uk/smart-goals/brief-history-of-smart-goals.php', 'S.M.A.R.T')}, <i>(pun intended)</i> i.e., Specific, Measurable, Attainable, Relevant, and Time-Bound. This can serve as your compass.
      </p>
      <p>
        For example, rather than a goal stating <i>'We have to achieve a high number of app installs'</i>, a goal stating <i>'We have to achieve 10K app installs by the end of this quarter'</i> is SMART since it answers to the above key indicators. At the end of the quarter, tracking and reviewing the results against the benchmark will be a seamless process and will pave your way forward.
      </p>

      <h4>Case Study</h4>
      <p>A niche luxury watchmaker hopes to acquire more customers. They deploy thousands of dollars in awareness campaigns across several social media platforms, with very few creatives on each. The hope is to boost traffic to their site in hopes of converting some of those users. However, because the product is so expensive and specific, very few of those visitors convert, as the campaigns are delivering users likely only to click on the ad. Changing their strategy to targeting exclusively high value customers via conversion optimized campaigns allows them to gain site visitors who will actually convert into paying customers by capitalizing on the intelligence of the ad platform machine learning, and launching a wider set of creatives allows the campaigns to deliver successfully to different demographics of users.</p>

      <h3>#2: Targeting the Wrong Audience</h3>
      <p>Marketing is a fairly simple concept. Find the right audience for your product and target them with the right message at the right time. But, it is brutal too: if you get the first point of targeting wrong, you will not be able to deliver at all on the subsequent actions.</p>
      <p>The target audience is the personification of your product or service, the users most likely to buy your product or service. Startups that fail to identify the target audience and launch generic marketing campaigns into the universe of ads end up shooting in the dark and most likely miss.</p>
      <p>Once you analyze your existing customers, you can configure your target audience on all digital advertising platforms like Facebook and Google, which allow you to create various filters based on the users' demographics, interests, and behaviors.</p>
      <p>
        The target audience varies at each stage of the funnel, be it Awareness, Consideration, or Conversion.
        <ul>
          <li>At Awareness, you have to target users who are interested in your product or service as you make them aware of your brand, with the focus on brand recall.</li>
          <li>At Consideration, you have to target users who intend to buy your product or service and influence them to consider your product or service for purchase.</li>
          <li>At the end of the funnel, you must focus on targeting users who have already visited your website or app and drive action to make them buy your product or service.</li>
        </ul>
      </p>
      <p>For example, at the consideration stage, a laptop brand can determine the target audience by creating filters of users who are actively researching and reading reviews about various types of laptops.</p>

      <h4>Case Study</h4>
      <p>One of our clients (servicing into the mental health industry), had burned through their marketing budget by targeting the wrong audience. They had deployed marketing campaigns on various digital platforms targeting users in the age group of 30-60. Once Blitz became involved, our research team identified the personas of potential users for their business. It was then noticed that personas of users varied with each region. For example, the potential of users in the corporate world who were interested in seeking mental health services was greater in the US as compared to users of the same personas in Sweden. Therefore, the target audience must be different for the US and Sweden. Campaign targeting and creatives were adjusted accordingly, leading to the average CPA being halved.</p>

      <h3>#3: Experimenting with all Platforms at Once</h3>
      <p>Every startup, every business, would like the same things: reach billions of users in the shortest possible span of time and convert them all to paying customers. And with these high headed ambitions, they flood their marketing arms with money in hopes they will make this fantasy come true. Teams are hired and money is spent often well in advance of or entirely without systematic testing or innovative thinking.</p>
      <p>
        However, it doesn't work like that. It's not as much about the quantity of money you have at your disposal but what you do with it. That was what contributed to the success of a small-time business from Austria taking over the world of Pepsi and Coca Cola with an absurd idea for their energy drink, which now is the one the highest selling beverages in the world. While they didn't have enough money to compete with Coca Cola, they came up with innovative marketing strategies to outfox them, be it their compelling slogan <i>"It gives you wings"</i> or their omnipresence at the cusp of every adventure sport, be it skating or F1 racing. Yes, we are talking about
        {externalLink('https://www.cascade.app/strategy-factory/studies/redbull-strategy-study', 'Red Bull')}.
      </p>
      <p>Similarly in digital marketing, just splashing cash across all the available digital marketing platforms for the sake of doing it doesn't yield the results you are looking for, especially if you are going up against established products. It doesn't work for big companies and definitely not for startups who are strapped for cash. Too many startups think more is better, and that if they have launched campaigns on Facebook they should immediately turn to Google or TikTok. However, the reality is that campaign performance takes iteration, and iteration takes time, both on creatives and on campaign details. If you can consolidate your budget (especially if it is limited!) on one platform (we usually suggest Facebook, as it has the most sophisticated machine learning) and stick to a tight cycle of launch, test, iterate, relaunch, you are much more likely to hit on a winning tactic for your ad creative and your campaign objectives in a shorter period of time which you can then port to your next platform. There is still no guarantee of success, and not every platform will be the perfect fit for your business, but at least you're not burning cash repeating the same learning cycles in multiple places.</p>
      <p><strong>Example</strong>: If you're marketing a photo editing app, you should start experimenting with social media platforms like Facebook and TikTok rather than launching ads on LinkedIn. However, if you are a B2B startup, LinkedIn might be a better suitor than advertising on Instagram.</p>

      <h3>#4: Not Testing Creatives</h3>
      <p>
        <img
          width="500px"
          src="https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/62d78d97cd0a0de0680b2243_NotTestingCreatives%20(Section%20Header).jpg"
        />
      </p>
      <p>One might repeat the phrase “Creative is King” a million times and it will still not diminish the truth behind it. One thing that's hard for a lot of businesses to swallow is that it is just as much about the quantity of creatives as the quality of creatives, as it is impossible to predict what will become a winner. Startups make one of two mistakes here: they either assume that quantity equals quality and launch a high volume of sub-par assets, or they assume that one highly produced ad is all they need. In reality, you need a diverse set of high quality creative that feels native to the platform and speaks to a variety of value propositions, and even then it is an ever-evolving process to identify new winners. Startups fail to succeed in marketing when they don't test multiple creatives and learn from them to build better-performing creatives.</p>
      <p>For example, in their bid to stay on top of everyday trends, an online retail startup would launch new ads every week. These creatives would pile up week after week without significant spend on most of the individual ads but spending thousands of dollars cumulatively. They would periodically try to remove the underperforming ads, but because so many ads never saw any spend at all it was impossible to tell if they were truly underperformers. Even after 100s and 1000s of creatives, they had a sense for what works, as some performers did rise to the top, but more importantly, they had no idea what HADN'T worked, because they hadn't had enough budget to be tested.</p>
      <p>
        Careful, methodical experimentation is the key to saving money, time, and delivering better value. You can conduct multiple split tests like A/B experiments <i>(You can read more about A/B tests and their benefits from our {externalLink('https://www.useblitz.com/blogs/abcs-of-a-b-testing', 'blog')})</i>. An A/B test helps you identify which creatives or creative elements are performing better, like which background image, color, or soundtrack works well for your business. These small things will help shape the way a potential viewer of your ad will become your long time user.
      </p>

      <h4>Case Study</h4>
      <p>We conducted a split test for our client, a photo editing app, to test which type of ad works best. We tested two types of app demo templates, one with a male voice and the other with a female voice. It turned out that the asset with the female voice got 10x more conversions than the male voice. We saved hundreds of dollars and time, and got greater conversions by delivering the right type of creative.</p>

      <h3>#5: Not strategizing digitally on customer retention</h3>
      <p>The significance of customer retention is not lost on anyone, and hence we see startups and big companies alike subscribe to various CRM tools to retain customers and build brand loyalty. However, in these dynamic digital times, it's equally as essential to focus on keeping the current customers thinking about your brand through every marketing channel as well.</p>
      <p>Some startups launch install and conversion campaigns across various platforms and keep on increasing their bids and budgets as they repeatedly fail to achieve their targets. What they are missing is seeing the importance of customer retention.Therefore, companies that conduct marketing activities on social media platforms must also launch remarketing campaigns to re-engage users who have already interacted with your business or bought your product or service. Companies that fail in doing so lose out on precious long time users.</p>
      <p>Remarketing campaigns (targeting users who clicked on your website and didn't make a purchase or visit again) accelerate the brand's position in the customer's head, and are a prime opportunity to offer deals. Offering promotions and discounts to such customers will influence them to make a conversion. This strategy will lead to a high-quality conversion and reduce the buying process since you will be targeting customers who are already mid funnel. </p>

      <h4>Case Study</h4>
      <p>We launched a remarketing campaign for our client, a sports streaming platform. Our remarketing campaign targeted users who have added the subscription to the cart but have not subscribed. At the end of 15 days, we delivered high-value conversions at a lower rate than our straightforward purchase campaigns.</p>

      <h3>#6: Not Measuring ROI</h3>
      <p>When startups completely understand the role digital marketing plays, their first instinct is to pour in unlimited resources, in terms of money, people and time, to the marketing team and ask them to do wonders. However, this approach is more like crash and burn, even if the team is smart and avoids the mistakes outlined above.</p>
      <p>Digital Marketing is not only about planning and launching a plethora of new marketing campaigns and creatives everyday. It is fundamentally about understanding what works for your business the best, about identifying where you are seeing the maximum value and where you are leaking money. Just like every other aspect of business, ROI plays a critical role in marketing. You need to know how much money you have put in and how much value was driven from that. Each company can have their own criteria to define ROI. It could be in terms of customer base they were able to acquire, or the value brought in by all the customers. But you should be able to analyze all the metrics or KPIs from each and every marketing campaign to know exactly what you achieved from that campaign and if it is worthwhile to continue it.</p>
      
      <h4>Case Study</h4>
      <p>One of our clients, in their bid to maximize the use of every platform available, was delivering many ads across all platforms with large budgets. They never stopped and analyzed the performance of each platform, which platform was responsible for bringing in the most revenue, or which creatives were just draining money, leading to a very high cost per conversion impacting their overall profits.</p>
      <p>Once we onboarded them to Blitz, our analytics and marketing team studied their previous performance and optimized their ads and budgets across all platforms, prioritizing lowering the cost of each conversion while also maintaining user quality. By the end of the year, we had brought down the cost per conversion by 40%. </p>

      <h3>#7: Doing things manually</h3>
      <p>We might not be where machines have completely taken over the world, but that doesn't mean we can't make the most of what the machines can do for us today. Businesses try to manage digital marketing activities manually, spending hours and hours when there are tools and technologies in place to make their job easy and efficient. This is one of the main reasons startups are burning through cash and time; they require expertise, guidance, and manpower to grow. </p>
      <p>This is what was the driving force behind our Blitz tool. Our way of automating was to build our technology in such a way that frees up our time from manual task management and execution so we can better understand our partner businesses, ideate on new creatives, and continuously refine and improve our campaign strategies. Our tool lets us generate hundreds of ads, deploy them across multiple campaigns on multiple platforms, and generate reports at a few clicks of a button. AI-backed tactics like cross platform budget allocation and automatic look alike audience generation also allow your campaigns to reach their fullest potential. Technology and creative hand in hand is the key to successfully marketing on digital platforms.</p>

      <h3>Final Note</h3>
      <p>
        Digital Marketing is the talk of the town these days. In the journey to excellence, many startups tend to forget the impact of marketing on their growth. According to
        {externalLink('https://www.failory.com/blog/startup-failure-rate', 'Failory\'s research')} on 80+ failed startups, Marketing Problems adds up to 56% of the reason behind a startup's failure. There is a clear need for guidance and expertise and even a little bit of magic to successfully deploy performant digital marketing strategies.
      </p>
      <p>
        Since every startup is unique in its approach, there is no blueprint for wild growth and success, but at Blitz we can give you a headstart on performance. We've done the years of experimenting and iterating so you don't have to! All you need to do is
        {externalLink('https://www.useblitz.com/#Contact-Us', 'contact us')}, and we can help you improve your performance and reach your business's fullest potential.
      </p>
    </div>
  );
}

export default PostMarketingMistakes;
