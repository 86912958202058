import axios from 'axios';
import { utils } from '../common/utils';

/**
 * Filters the insight results for campaigns, adsets and ads
 *
 * @param Object - object for payload
 */
export const filterInsights = async ({ platform, category, adAccountId, filters, params }) => {
  try {
    const data = await axios.post(`/api/adAccount/search/`, utils.jsonConcat(params, {
      socialEndpoint: platform,
      accountId: adAccountId,
      activeTab: category,
      searchParams: filters
    }));
    return data;
  } catch (e) {
    console.error('Error while calling filter insights API!', e);
  }
};

/**
 * Gets the user saved campaign column configurations
 * @returns Object
 */
export const getColumnConfigs = async () => {
  try {
    const data = await axios.get(`/api/campaigns/columnConfigurations`);
    return data;
  } catch (e) {
    console.error('Error while getting the campaign column configurations!');
  }
};

/**
 * Gets the user saved campaign column configurations
 * @returns Object
 */
 export const createColumnConfig = async (payload) => {
  try {
    const data = await axios.post('/api/campaigns/columnConfigurations', payload);
    return data;
  } catch (e) {
    throw ('Error while creating the campaign column configurations!');
  }
};

/**
 * Gets the user saved campaign column configurations
 * @returns Object
 */
 export const updateColumnConfig = async (payload) => {
  try {
    const data = await axios.put('/api/campaigns/columnConfigurations', payload);
    return data;
  } catch (e) {
    throw 'Error while updating the campaign column configurations!';
  }
};

/**
 * Deletes the user campaign column configuration
 * @param {number} id - id of column config
 * @returns Object
 */
 export const removeColumnConfig = async (id: number) => {
  try {
    const data = await axios.delete(`/api/campaigns/columnConfigurations/${id}`);
    return data;
  } catch (e) {
    throw 'Error while deleting the campaign column configuration!';
  }
};


/**
 * Updates a given campaign column config as active
 * @param {number} id - id of column config
 * @returns Object
 */
 export const updateActiveColumnConfig = async (id: number, isCampaign: boolean) => {
  try {
    const data = await axios.patch(`/api/campaigns/columnConfigurations/setAsActive/${id}/${isCampaign}`);
    return data;
  } catch (e) {
    throw 'Error while updating the campaign column configuration!';
  }
};

/**
 * Enables ad account
 * @returns axios object
 */
 export const enableAdAccount = async (platform, accountId) => {
  try {
    const params = {
      platform,
      adAccountId: accountId
    };
    const data = await axios.put(
      `/api/user/addPlatformAccount?platform=${platform.toUpperCase()}` +
      `&adAccountId=${accountId}`
    );
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * Disables ad account
 * @returns axios object
 */
 export const disableAdAccount = async (platform, accountId) => {
  try {
    const params = {
      platform,
      adAccountId: accountId
    };
    const data = await axios.delete(
      `/api/user/removePlatformAccount?platform=${platform.toUpperCase()}` +
      `&adAccountId=${accountId}`
    );
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * updates accounts
 * @returns axios object
 */
 export const updateAccountsApi = async (platform, payload) => {
  try {
    const data = await axios.post(
      `/api/user/updatePlatformAccounts/${platform}`,
      payload
    );
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * Gives list of campaigns for a given platform and accountId
 * @param platform - platform string
 * @param accountId - account id
 * @returns axios object
 */
export const getCampaigns = async (platform, accountId) => {
  try {
    const data = await axios.post(
      `/api/campaigns/getAdCampaigns`, 
      {
        socialEndpoint: platform.toLowerCase(),
        accountId: accountId
      }
    );
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * Gives list of ad sets for a given platform, accountId and campaign id
 * @param platform - platform string
 * @param accountId - account id
 * @param campaignId - campaign id
 * @returns axios object
 */
 export const getAdSets = async (platform, accountId, campaignId = '') => {
  try {
    let url = `/api/adAccount/getAdSetsOfAdAccount`;
    let payload: any = {
      socialEndpoint: platform.toLowerCase(),
      accountId: accountId
    }
    if (campaignId) {
      payload = {
        socialEndpoint: platform.toLowerCase(),
        accountId: accountId,
        campaignIds: campaignId
      }
    }
    const data = await axios.post(url, payload);
    return data;
  } catch (e) {
    throw e;
  }
};


/**
 * Gives all pending campaign creation jobs status
 * @returns axios object
 */
 export const getPendingCampaignStatus = async () => {
  try {
    const data = await axios.get(`/api/campaigns/create/all`);
    return data;
  } catch (e) {
    throw e;
  }
};


/**
 * Gives campaign creation job status
 * @param requestId - requestId string
 * @returns axios object
 */

export const pollCampaignStatus = async (requestUrl) => {
 try {
   const data = await axios.get(requestUrl);
   return data;
 } catch (e) {
   throw e;
 }
};

/**
 * Closes the status of given campaign creation job
 * @param requestId - requestId string
 * @returns true or false 
*/

export const closeCampaignRequest = async(requestId : string) => {
  try {
    const data = await axios.post(`/api/campaigns/close/${requestId}`);
    return data;
  } catch (e) {
    throw e;
  }

}

/**
 * Gives campaign creation job status
 * @param platform - platform string
 * @param payload - payload object
 * @returns axios object
 */

export const refreshCampaigns = async (platform: string, payload) => {
  try {
    const data = await axios.post(
      `/api/campaigns/fetchAccountData/${platform}`,
      payload
    );
    return data;
  } catch (e) {
    throw e;
  }
};


/**
 * 
 * @param {string} platform - platform
 * @param {object} payload - payload for the post call
 * @returns 
 */
export const exportToSheets = async (platform: string, payload: any) => {
  try {
    const data = await axios.post(
      `/api/adAccount/exportToSheet/${platform}`,
      payload
    );
    return data;
  } catch (e) {
    throw e;
  }
}


/**
 * create social endpoint
 * @returns axios object
 */
 export const createSocialEndpoint = async (payload) => {
  try {
    const data = await axios.post(
      `/api/campaigns/createSocialEndpoint`,
      payload
    );
    return data;
  } catch (e) {
    throw e;
  }
};


/**
 * remove social endpoint
 * @returns axios object
 */
 export const removeSocialEndpoint = async (payload) => {
  try {
    const data = await axios.post(
      `/api/campaigns/removeSocialEndpoint`,
      payload
    );
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * Removes a campaign
 * @returns axios object
 */
 export const removeCampaigns = async (platform, accountId, campaignType, id) => {
  try {
    const data = await axios.delete(
      `/api/campaigns/${campaignType}/${platform}/${accountId}/${id}`);
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * Gives campaign information for a given platform campaignId and accountId
 * @param { platform, accountId, campaignId } - campaign related info
 * @returns axios object
 */
export const getCampaignInfo = async ({ platform, accountId, campaignId }) => {
  try {
    const data = await axios.get(
      `/api/campaigns/getCampaignInfo/${platform}/${campaignId}?accountId=${accountId}`
    );
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * Gives adset information for a given platform and accountId
 * @param { platform, accountId } - campaign related info
 * @returns axios object
 */
export const getAdSetInfo = async ({ platform, adSetId, accountId }) => {
  try {
    const data = await axios.get(
      `/api/campaigns/getAdsetInfo/${platform}/${adSetId}?accountId=${accountId}`
    );
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * Gives campaign information for a given platform campaignId and accountId
 * @param campaignId - campaign ID
 * @param payload - campaign related info
 * @returns axios object
 */
export const updateCampaign = async (campaignId, payload) => {
  try {
    const data = await axios.put(
      `/api/campaigns/campaign/${payload.platform.value}/${campaignId}`,
      payload
    );
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * Gives campaign information for a given platform campaignId and accountId
 * @param campaignId - campaign ID
 * @param payload - campaign related info
 * @returns axios object
 */
export const updateAdSet = async (campaignId, payload) => {
  try {
    const data = await axios.put(
      `/api/campaigns/adSets/${payload.platform}/${campaignId}`,
      payload
    );
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * Gives campaign information for a given platform campaignId and accountId
 * @param payload - campaign related info
 * @returns axios object
 */
export const createAdSetsInCampaign = async (payload: {
  accountId: string;
  campaignId: string;
  platform: string;
  adSets: any[];
  ads: any[]
}) => {
  try {
    const data = await axios.post(
      '/api/campaigns/createAdSetsInCampaign',
      payload
    );
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * Gives campaign information for a given platform campaignId and accountId
 * @param payload - campaign related info
 * @returns axios object
 */
export const createAdsInAdSets = async (payload: {
  accountId: string;
  adSetId: string;
  platform: string;
  adSets: any[];
  ads: any[]
}) => {
  try {
    const data = await axios.post(
      '/api/campaigns/createAdsInAdSet',
      payload
    );
    return data;
  } catch (e) {
    throw e;
  }
};
