import React, {useState} from 'react';
import {CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';
import {ReactComponent as CardIcon} from '../icons/card-number-icon.svg';
import {ReactComponent as CalendarIcon} from '../icons/calendar-icon.svg';
import {ReactComponent as CvcIcon} from '../icons/cvc-icon.svg';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Libre Franklin", "Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "transparent"
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

interface CardSectionProps {
  onReady: Function;
}

function CardSection(props: CardSectionProps) {
  const [cardBrand, setCardBrand] = useState('no_brand');

  const renderCardBrand = () => {
    const cardArray = ['visa', 'mastercard', 'amex', 'jcb', 'discover', 'diners', 'unionpay'];
    if (cardBrand === 'no_brand' || cardBrand === 'unknown') {
      return ( 
        <>
          <img src='https://media.useblitz.com/assets/images/icons/cards/visa.svg' width={'30'} />
          <img src='https://media.useblitz.com/assets/images/icons/cards/amex.svg' width={'30'} />
          <img src='https://media.useblitz.com/assets/images/icons/cards/mastercard.svg' width={'30'} />
          <img src='https://media.useblitz.com/assets/images/icons/cards/jcb.svg' width={'30'} />
        </>
      )
    }
    else if (cardArray.includes(cardBrand)) {
      return <img src={`https://media.useblitz.com/assets/images/icons/cards/${cardBrand}.svg`} width={'30'} />
    }

    return <></>
  }

  return (
    <div className="card-cont">
      <div className='card-label-row'>
        Card Number
      </div>
      <div className="card-row">
        {/* <CardIcon /> */}
        <CardNumberElement 
          className='card-number-element'
          options={CARD_ELEMENT_OPTIONS}
          onChange={(event) => setCardBrand((event.brand || '').toLowerCase())}
        />
        <div className='brand-logo-container'>
          { renderCardBrand() }
        </div>
      </div>
      <div className='flex gap-10 mt-20'>
        <div className='flex-1'>
          <div className='card-label-row'>MM/YY</div>
          <div className="card-row card-cvv-date-row">
            {/* <CalendarIcon /> */}
            <CardExpiryElement options={CARD_ELEMENT_OPTIONS} className='full-width' />
          </div>
        </div>
        <div className='flex-1'>
          <div className='card-label-row'>CVC</div>
          <div className="card-row card-cvv-date-row">
            {/* <CvcIcon /> */}
            <CardCvcElement options={CARD_ELEMENT_OPTIONS} className='full-width' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSection;
