import { Expression } from "./Expression";
import { ExpressionParams } from "./ExpressionParams";
import { Stack } from "./Stack";

export class MathExpressionStatement {
    pos: number = -1;
    ch: number = 0;

    str: string = '';

    constructor(s: string) {
        this.str = s;
    }

    nextChar(): void {
        this.ch = (++this.pos < this.str.length) ? this.str.charCodeAt(this.pos) : -1;
    }

    eat(charToEat: number): boolean {
        while (this.ch === ' '.charCodeAt(0)) this.nextChar();
        if (this.ch === charToEat) {
            this.nextChar();
            return true;
        }
        return false;
    }

    parse(): number {
        this.nextChar();
        let x: number = this.parseExpression();
        if (this.pos < this.str.length) throw new Error("Unexpected: " + String.fromCharCode(this.ch));
        return x;
    }

    parseExpression(): number {
        let x: number = this.parseTerm();
        for (; ;) {
            if (this.eat('+'.charCodeAt(0))) x += this.parseTerm(); // addition
            else if (this.eat('-'.charCodeAt(0))) x -= this.parseTerm(); // subtraction
            else return x;
        }
    }

    parseTerm(): number {
        let x: number = this.parseFactor();
        for (; ;) {
            if (this.eat('*'.charCodeAt(0))) x *= this.parseFactor(); // multiplication
            else if (this.eat('/'.charCodeAt(0))) x /= this.parseFactor(); // division
            else if (this.eat('%'.charCodeAt(0))) x %= this.parseFactor(); // division
            else return x;
        }
    }

    parseFactor(): number {
        if (this.eat('+'.charCodeAt(0))) return this.parseFactor(); // unary plus
        if (this.eat('-'.charCodeAt(0))) return -this.parseFactor(); // unary minus

        let x: number = 0.0;
        let startPos: number = this.pos;
        if (this.eat('('.charCodeAt(0))) { // parentheses
            x = this.parseExpression();
            this.eat(')'.charCodeAt(0));
        } else if ((this.ch >= '0'.charCodeAt(0) && this.ch <= '9'.charCodeAt(0)) || this.ch == '.'.charCodeAt(0)) { // numbers
            while ((this.ch >= '0'.charCodeAt(0) && this.ch <= '9'.charCodeAt(0)) || this.ch == '.'.charCodeAt(0)) this.nextChar();
            x = Number.parseFloat(this.str.substring(startPos, this.pos));
        } else if (this.ch >= 'a'.charCodeAt(0) && this.ch <= 'z'.charCodeAt(0)) { // functions
            while (this.ch >= 'a'.charCodeAt(0) && this.ch <= 'z'.charCodeAt(0)) this.nextChar();
            let func: string = this.str.substring(startPos, this.pos);
            x = this.parseFactor();
            if (func === ("sqrt")) x = Math.sqrt(x);
            else if (func === ("sin")) x = Math.sin(x);
            else if (func === ("cos")) x = Math.cos(x);
            else if (func === ("tan")) x = Math.tan(x);
            else throw new Error("Unknown function: " + func);
        } else {
            throw new Error("Unexpected: " + String.fromCharCode(this.ch) + " :::::" + this.str);
        }

        if (this.eat('^'.charCodeAt(0))) x = Math.pow(x, this.parseFactor()); // exponentiation

        return x;
    }
}

export function getFuncName(expression: string): string {
    if (expression.startsWith("J")) {
        let commaIndex = expression.indexOf("(");
        return expression.substring(0, commaIndex);
    }
    return "PLAIN";
}


export function getParams(expression: string, functionName: string): string[] {
    if (functionName === ("PLAIN")) {
        return [];
    }
    let parameterSubExpression = expression.substring(expression.indexOf("(") + 1);
    if (!expression.endsWith(")")) {
        return parameterSubExpression.split(",");
    }

    let argumentStack = new Stack<string>();
    let i = 0;
    let args: string[] = [];
    while (i < parameterSubExpression.length) {
        let argument: string = "";
        let subExpressionStack = new Stack<string>();
        do {
            let c = parameterSubExpression.charAt(i);
            let addChar = true;
            switch (c) {
                case ',': {
                    if (subExpressionStack.empty()) {
                        addChar = false;
                    }
                    break;
                }
                case ')': {
                    if (subExpressionStack.empty()) {
                        addChar = false;
                    }
                    else {
                        subExpressionStack.pop();
                    }
                    break;
                }
                case '(': {
                    subExpressionStack.push('(');
                    break;
                }
                default: {

                }
            }

            if (addChar) {
                argumentStack.push(c);
                argument += c;
                i++;
            }
            else {
                break;
            }
        } while (!argumentStack.empty());
        args.push(argument.trim());
        i++;
    }
    return args;
}

export function evaluateExpression(expression: Expression, params: ExpressionParams): number {
    return expression.evaluateExpression(params);
}

export function evaluate(expression: string): number {
    if (expression.trim() !== '') {
        if (expression.trim().startsWith("J")) {
            let funcName = getFuncName(expression);
            let params = getParams(expression, funcName);
            switch (funcName) {
                case "JINERTIALBOUNCE":
                    return inertialBounce(params);
                case "JBEZIERCURVE":
                    return bezierCurve(params);
                case "JCUBICCURVE":
                    return cubicCurve(params);
                case "JLINEAR":
                    return linear(params);
                case "JMAX":
                    return max(params);
                case "JMIN":
                    return min(params);
                case "JADD":
                    return add(params);
                case "JMUL":
                    return mul(params);
                case "JDIV":
                    return div(params);
                case "JPOW":
                    return pow(params);
                case "JBOOL":
                    return bool(params);
                case "JALPHA":
                    return alpha(params);
                case "JRED":
                    return red(params);
                case "JGREEN":
                    return green(params);
                case "JBLUE":
                    return blue(params);
                case "JSBOOL":
                    return signBool(params);
                case "JDIP":
                    return dip(params);
                case "JFV":
                    return frequencyVariation(params);
            }
            return 0;
        }
        else {
            return new MathExpressionStatement(expression).parse();
        }

    }
    else {
        return 0;
    }
}

export function roundToTwo(num: number): number {
    return Math.round(num * 100) / 100;

}


export function roundToOne(num: number): number {
    return Math.round(num * 10) / 10;

}



export function roundVal(num: number): number {
    return Math.round(num)

}




export function frequencyVariation(params: string[]): number {
    let frequency = evaluate(params[0]);
    let length = evaluate(params[1]);
    let expression = params[2];
    let currentFrameNumber = evaluate(params[3]);

    let fi = currentFrameNumber % (frequency + length);
    if (fi >= frequency) {
        expression = expression.replace("{{fi}}", (fi - frequency) + "");
        return evaluate(expression);
    }
    return 0;
}


export function max(args: string[]): number {
    let max = evaluate(args[0]);
    for (let i = 1; i < args.length; i++) {
        let value = evaluate(args[i]);
        if (value > max) {
            max = value;
        }
    }
    return (max);
}



export function min(args: string[]): number {
    let min = (evaluate(args[0]));
    for (let i = 1; i < args.length; i++) {
        let value = (evaluate(args[i]));
        if (value < min) {
            min = value;
        }
    }
    return (min);
}

export function add(args: string[]): number {
    let sum = evaluate(args[0]);
    for (let i = 1; i < args.length; i++) {
        let value = evaluate(args[i]);
        sum += value;
    }
    return (sum);
}


export function mul(args: string[]): number {
    let product = evaluate(args[0]);
    for (let i = 1; i < args.length; i++) {
        let value = (evaluate(args[i]));
        product *= value;
    }
    return (product);
}

export function div(args: string[]): number {
    let arg1 = (evaluate(args[0]));
    let arg2 = (evaluate(args[1]));
    return (arg1 / arg2);
}

export function pow(args: string[]): number {
    let arg1 = (evaluate(args[0]));
    let arg2 = (evaluate(args[1]));
    return (Math.pow(arg1, arg2));
}


export function alpha(args: string[]): number {
    let color = args[0];
    try {
        var num = parseInt(color.replace("#", ""), 16); // Convert to a number
        return num >> 24 & 255;
    }
    catch (e) {

    }
    return 0;
}


export function alphaDecimalVal(args: string[]): number {
    let color = args[0];
    try {
        var num = parseInt(color.replace("#", ""), 16); // Convert to a number
        return (num >> 24 & 255)/255;
    }
    catch (e) {

    }
    return 0;
}

export function red(args: string[]): number {
    let color = args[0];
    try {
        var num = parseInt(color.replace("#", ""), 16); // Convert to a number
        return num >> 16 & 255;
    }
    catch (e) {

    }
    return 0;
}

export function green(args: string[]): number {
    let color = args[0];
    try {
        var num = parseInt(color.replace("#", ""), 16); // Convert to a number
        return num >> 8 & 255;
    }
    catch (e) {

    }
    return 0;
}

export function blue(args: string[]): number {
    let color = args[0];
    try {
        var num = parseInt(color.replace("#", ""), 16); // Convert to a number
        return num & 255;
    }
    catch (e) {

    }
    return 0;
}


export function bool(args: string[]): number {
    let lhs = args[0];
    let rhs = args[1];

    try {
        let lhsD = evaluate(lhs);
        lhsD = isNaN(lhsD)? lhsD: roundToTwo(lhsD);
        lhs = lhsD + "";
    }
    catch (e) {

    }

    try {
        let rhsD = evaluate(rhs);
        rhsD = isNaN(rhsD)? rhsD:roundToTwo(rhsD);
        rhs = rhsD + "";
    }
    catch (e) {

    }

    return lhs === rhs ? 1 : 0;
}



export function signBool(args: string[]): number {
    let lhs = args[0];
    let rhs = args[1];

    try {
        let lhsD = evaluate(lhs);
        lhsD = roundToTwo(lhsD);
        lhs = lhsD + "";
    }
    catch (e) {

    }

    try {
        let rhsD = evaluate(rhs);
        rhsD = roundToTwo(rhsD);
        rhs = rhsD + "";
    }
    catch (e) {

    }

    return lhs === rhs ? 1 : -1;
}



export function inertialBounce(args: string[]): number {
    let amp = (evaluate(args[0]));
    let freq = (evaluate(args[1]));
    let decay = (evaluate(args[2]));
    let currentFrameCount = (evaluate(args[3]));
    let startValue = (evaluate(args[4]));
    let velocity = (evaluate(args[5]));

    let valueToReturn = startValue + velocity * amp * Math.sin(freq * currentFrameCount * Math.PI / 12) / Math.exp(decay * (currentFrameCount * 1.0 / 24));
    return (valueToReturn);
}

export function bezierCurve(args: string[]): number {
    let currentFrameCount = (evaluate(args[0]));
    let curveRange = (evaluate(args[1]));
    let t = (currentFrameCount * 1.0) / (curveRange * 1.0);

    if (t > 1) {
        t = 1;
    }

    let start = (evaluate(args[2]));//0
    let end = (evaluate(args[3]));//1

    let p1M = (evaluate(args[4]));
    let p2M = (evaluate(args[5]));

    let multiplier = 1.0;
    if (args.length > 6) {
        multiplier = (evaluate(args[6]));
    }

    let p0 = start;
    let p3 = end;

    let d = end - start;
    let p1 = start + p1M * d;
    let p2 = start + p2M * d;

    let c = 3 * (p1 - p0);
    let b = 3 * (p2 - p1) - c;
    let a = p3 - p0 - c - b;

    return ((a * Math.pow(t, 3) + b * Math.pow(t, 2) + c * t + p0) * multiplier);

}

export function linear(args: string[]): number {
    let startX = (evaluate(args[0]));
    let startY = (evaluate(args[1]));

    let endX = (evaluate(args[2]));
    let endY = (evaluate(args[3]));

    let m = ((endY - startY) * 1.0) / (endX - startX) * 1.0;
    let c = endY - m * endX;

    let currentFrameCount = (evaluate(args[4]));

    return (m * (currentFrameCount) + c);
}



export function dip(args: string[]): number {
    let startX = (evaluate(args[0]));
    let startY = (evaluate(args[1]));

    let midX = (evaluate(args[2]));
    let midY = (evaluate(args[3]));

    let y2 = midY;
    let y1 = startY;
    let x2 = midX;
    let x1 = startX;

    let currentFrameCount = (evaluate(args[4]));

    if (currentFrameCount >= midX) {
        y1 = midY;
        y2 = startY;

        x1 = midX;
        x2 = midX + (midX - startX);
    }

    let m = ((y2 - y1) * 1.0) / (x2 - x1) * 1.0;
    let c = y2 - m * x2;

    return (m * (currentFrameCount) + c);
}


export function cubicCurve(args: string[]): number {
    let currentFrameCount = (evaluate(args[0]));
    let curveRange = (evaluate(args[1]));
    let t = (currentFrameCount * 1.0) / (curveRange * 1.0);

    if (t > 1) {
        t = 1;
    }

    let startV = (evaluate(args[2]));//0's-value
    let endV = (evaluate(args[3]));//1's-value

    let x1 = (evaluate(args[4]));//<1
    let y1 = (evaluate(args[5]));

    let x2 = (evaluate(args[6]));//<1
    let y2 = (evaluate(args[7]));


    let Y1 = y1 - (startV * Math.pow(1 - x1, 3)) - (endV * Math.pow(x1, 3));
    let Y2 = y2 - (startV * Math.pow(1 - x2, 3)) - (endV * Math.pow(x2, 3));

    let A1 = x1 * Math.pow(1 - x1, 2);
    let A2 = x2 * Math.pow(1 - x2, 2);

    let B1 = Math.pow(x1, 2) * (1 - x1);
    let B2 = Math.pow(x2, 2) * (1 - x2);

    let b = (B2 * Y1 - B1 * Y2) / (B2 * A1 - B1 * A2);
    let c = (A2 * Y1 - A1 * Y2) / (A2 * B1 - A1 * B2);
    let a = startV;
    let d = endV;

    let multiplier = 0.0;
    if (args.length > 8) {
        multiplier = (evaluate(args[8]));
    }

    let offset = 1.0;
    if (args.length > 9) {
        offset = (evaluate(args[8]));
    }

    return ((a * Math.pow(1 - t, 3) + b * Math.pow(1 - t, 2) * t + c * (1 - t) * Math.pow(t, 2) + d * Math.pow(t, 3)) * multiplier + offset);
}

export function getRaiusFromArcMetrics(arcWidht:number, arcHeight:number): number {
    return ((arcWidht*arcWidht)/(8.0*arcHeight))+(arcHeight/2.0);
}