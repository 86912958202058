import React, {useState, useEffect, FormEvent, ChangeEvent, Fragment, useContext} from "react";
import axios from 'axios';
import { Modal, CustomCheckbox, Storage, SessionStorage } from "frontend-core";
import ForgotPassword from "./forgot-password";
import { ICustomWindow } from "../common/model";
import Routes from "../common/routes";
import { AppContext } from "../appContext";
import { useNavigate } from "react-router-dom";
import VerificationModal from "./VerificationModal";
import { verifyCode, resendCode } from "../../src/api/account-api";
import { LI_TRACKING_IDS, TrackLIEvent } from "../common/LinkedInEventTracker";
import { TrackFBPixel } from "../common/FBPixelTracker";

declare const window: ICustomWindow;
interface IAuthProps {
  type?: string;
  updateProp: Function;
  setIsModalVisible: Function;
}

interface IAuthForm {
  userName: string,
  password: string,
  queryParamStr: string,
  [key: string]: string | undefined,
  userNameError?: string,
  passwordError?: string,
  nameError?: string
  // passwordStrength: string,  // todo
}

const Authenticate = (props: IAuthProps) => {
  const dontHaveAccount = (
    <span>Don't have an account? <br></br> <span className="color-orng">Sign up</span></span>
  );

  const alreadHaveAccount = (
    <span>Already have an account? <br></br> <span className="color-orng">Log in</span></span>
  );

  const { authModal, refreshUser } = useContext(AppContext);
  const { setIsModalVisible } = authModal;
  const [method, setMethod] = useState(props.type === "sign-up" ?
    "Sign Up" : "Log In");
  const [heading, setHeading] = useState(props.type === "sign-up" ?
    "Create your account" : "Welcome back");
  const [subheading, setSubheading] = useState(props.type === "sign-up" ?
    alreadHaveAccount : dontHaveAccount);
  const [action2Text, setAction2Text] = useState(props.type === "sign-up" ?
    "Log In" : "Sign Up");
  const [error, setError] = useState("");
  const [submitTryOnce, setSubmitTryOnce] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [user, setUser] = useState({});
  const [rememberMe, setRememberMe] = useState(true);
  const [OTPError, setOTPError] = useState("");
  const [authForm, setAuthForm] = useState({
    userName: "",
    password: "",
    queryParamStr: "",
    userNameError: "",
    passwordError: "",
    nameError: ""
  });
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [codeRequestCount, setCodeRequestCount] = useState(0);
  const [sendingOTP, setSendingOTP] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (props.type === 'sign-up') {
      setMethod('Sign Up');
      setHeading('Create your account');
      setSubheading(alreadHaveAccount);
    }
    else if (props.type === 'sign-in') {
      setMethod('Log In');
      setHeading('Welcome back');
      setSubheading(dontHaveAccount);
    }
  }, [props.type]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>, setFormIsValid: Function,
    authForm: IAuthForm, setAuthForm: Function) => {
    authForm[event.target.name] = event.target.value;
    setAuthForm(authForm);
    setFormIsValid(isFormValid(authForm, setAuthForm));
  }
  
  const isEmailValid = (email: string) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  
  const isFormValid = (formInputs: IAuthForm, setAuthForm: Function) => {
    formInputs.userNameError = "";
    formInputs.passwordError = "";
    formInputs.nameError = "";
    formInputs.queryParamStr = "";
    if (!formInputs.userName) {
      formInputs.userNameError = "Email is required"
    } else if (formInputs.userName.length > 100) {
      formInputs.userNameError = "Email should be less than 50 characters"
    } else if (!isEmailValid(formInputs.userName)) {
      formInputs.userNameError = "Invalid email"
    }
    if (!formInputs.password) {
      formInputs.passwordError = "Password is required"
    } else if (formInputs.password.length < 6) {
      formInputs.passwordError = "Password should be at least 6 characters"
    } else if (formInputs.password.length > 30) {
      formInputs.passwordError = "Password should be less than 30 characters"
    }
    // if(method === "Sign Up" && !formInputs?.name?.trim()){
    //   formInputs.nameError = "Name is required"
    // }
    const newInput = JSON.parse(JSON.stringify(formInputs));
    setAuthForm(newInput);
    if (formInputs.userNameError || formInputs.passwordError || formInputs.nameError) {
      return false;
    }
    return true;
  }
  
  const action2Click = (method: string, setMethod: Function, setHeading: Function,
    setSubheading: Function, setAction2Text: Function, props: IAuthProps,
    setShowForgotPassword: Function, setIsModalVisible: Function) => {
    if(method === "Log In") {
      // disable signup temporarily and show contact us
      /* setIsModalVisible(false);
      const contactUsSection = document.querySelector('#contact-us');
      if (contactUsSection) {
        contactUsSection.scrollIntoView();
      } else {
        window.location.href = '/#contact-us';
      }
      return; */
      setShowForgotPassword(false);
      setMethod("Sign Up");
      setHeading("Create your account");
      setSubheading(alreadHaveAccount);
      setAction2Text("Log In");
      props.updateProp("sign-up");
    } else if (method === "Forgot Password") {
      setShowForgotPassword(true);
    } else {
      setShowForgotPassword(false);
      setMethod("Log In");
      setHeading("Welcome back");
      setSubheading(dontHaveAccount);
      setAction2Text("Sign Up");
      props.updateProp("sign-in");
    }
  }
  
  function getModalStyle() {
    return {
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
    };
  }
  
  const inputStyles = { width: "100%", height: "40px !important", background: "e9f0f3", border: "none", borderRadius: 4 };

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const handleSubmit = (method: string, event: FormEvent) => {
    event.preventDefault();
    setSubmitTryOnce(true);
    setRequestInProgress(true);
    if (!isFormValid(authForm, setAuthForm)) {
      setRequestInProgress(false);
      return;
    }
    let url = '/api/user/register';
    if (method === "Log In") {
      url = '/api/user/login';
    }

    const afterSignUpOrLogin = function() {
      setIsModalVisible(false);
      setRequestInProgress(false);
      if (
        window.location.search.includes('email=')
        && window.location.search.includes('teamId=')
      ) {
        navigate(`${Routes.ACCOUNT}${window.location.search}&invite=success`);
      } else {
        if (method !== 'Log In') {
          navigate(`${Routes.LIBRARY}`);
        } else {
          // else is being handled from route configuration in App.tsx
          // so nothing to be done here.
        }
      }
    }

    const data: IAuthForm = JSON.parse(JSON.stringify({...authForm, remember: rememberMe }));
    delete data.passwordError;
    delete data.userNameError;
    delete data.nameError;
    if (method !== 'Log In') delete data.remember;
    data.queryParamStr = localStorage.getItem("SIGNUPPARAMS") || '';
    setError("");
    axios
      .post(url, data)
      .then(response => {
        if (url === '/api/user/register') {
          localStorage.setItem('is_new_register', JSON.stringify({isNew: true, email: authForm.userName}));
        }

        if (method !== 'Log In') {
          setUser(response.data);
          setCodeRequestCount(prevState => prevState + 1);
          setShowVerificationModal(true);

          TrackFBPixel('CompleteRegistration', {
            content_name: "Blitz SMART",
            currency: 'USD',
            status: "Signup",
            value: 0
          });
        }
        else {
          refreshUser(response.data);
          afterSignUpOrLogin();
        }
      })
      .catch(error => {
        let errorMsg = 'Something went wrong, we\'re sorry. Please try again after some time.';
        if (error && error.response && error.response.data && error.response.data.length < 100) {
          errorMsg = error.response.data;
        }
        setError(errorMsg);
        if(errorMsg === "E-mail verification pending.") setShowVerificationModal(true);
        setRequestInProgress(false);
      });
  };

  const handleOTPConfirmation = async(code) => {
    const res = await verifyCode({ email: authForm.userName, code });
    if(res.data.status === "ALREADY_VERIFIED" || res.data.status === "VERIFIED"){
      setIsModalVisible(false);
      setShowVerificationModal(false);
      refreshUser(undefined);
      // window.reportSignupConversion(afterSignUpOrLogin);
    } else if(res.data.status === "INVALID_CODE") {
      setOTPError("Please enter a valid code");
    }
  }

  const handleResendOTP = async () => {
    setSendingOTP(true);
    const data = await resendCode({ email: authForm.userName });
    if(data.data) {
      setSendingOTP(false);
    }
    setCodeRequestCount(prevState => prevState + 1);
  }

  const body = (
    <div className="login-popup">
      <div
        className="auth-modal-close close"
        onClick={() => {
          props.setIsModalVisible(false);
          SessionStorage.remove('userPlanSelection');
        }}
      >
      </div>
      {!showForgotPassword && <Fragment>
      <div className="heading-container">
        <span className="heading">{heading}</span>
      </div>
      <form 
        data-ga-event="submit"
        data-ga-action={method}
        noValidate
        onSubmit={(e) => handleSubmit(method, e)}>
        {/* {method === "Sign Up" && <div className="form-control">
            <input 
            className="form-input" 
            name="name" 
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, setFormIsValid, authForm, setAuthForm)} 
            placeholder="Name"
            style={inputStyles}
            required/>
        </div>} */}
        <div className="email-pw-row">
          <div className="form-control">
              <input 
                className="form-input" 
                type="email" 
                name="userName" 
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, setFormIsValid, authForm, setAuthForm)} 
                placeholder="Email"
                style={inputStyles}
                required/>
          </div>
          <div className="form-control flex" style={{ gap: "9px" }}>
              <input 
              className="form-input" 
              type="password" 
              name="password" 
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, setFormIsValid, authForm, setAuthForm)}
              placeholder="Password"
              style={inputStyles}
              />
          </div>
        </div>
        <p style={{color:'red'}}>
          <small>{error}</small>
        </p>
        {submitTryOnce && authForm.nameError &&
          <p style={{color: 'red', marginTop: '-1rem'}}>
            <small>{authForm.nameError}</small>
          </p>}
        {submitTryOnce && authForm.userNameError &&
          <p style={{color: 'red', marginTop: '-1rem'}}>
            <small>{authForm.userNameError}</small>
          </p>}
        {submitTryOnce && authForm.passwordError &&
          <p style={{color: 'red', marginTop: '-1rem'}}>
            <small>{authForm.passwordError}</small>
          </p>}
        <button 
          data-ga-event="click"
          data-ga-data={JSON.stringify(
            Object.entries(authForm).reduce((acc, [key, value]) => 
              key !== "password" ? {...acc, [key]: value} : acc, 
              {}
            )
          )}
          data-ga-action={method === 'Sign Up' ? 'SignUpSubmit' : 'SignInSubmit'}
          type="submit"
          disabled={requestInProgress}
          className={`btn full-width auth-btn`}
          onClick={() => {
            if (method === 'Sign Up') {
              TrackLIEvent(LI_TRACKING_IDS.createMyAccount);
            }
            if (method === 'Log In') {
              TrackLIEvent(LI_TRACKING_IDS.signIn);              
            }
          }}
        >
          {method === 'Sign Up' ? 'Create my account' : method}
        </button>
      </form>
      {method === 'Log In' && <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <button
          className="forgot-pw"
          onClick={() => setShowForgotPassword(true)}
        >
          Forgot Password ?
        </button>
      </div>}
      {/* {method === 'Log In' && 
        <div className="other-login">
            <div className="sub-title">- Or log in with -</div>
            <div className="login-btns">
              <button>Google</button>
              <button>Microsoft</button>
            </div>
        </div>
      } */}
      <div className="privacy">
        By {method === 'Log In' ? 'signing' : 'creating'} my account, I agree to <a href="/terms-of-service" target={'_blank'}>Blitz's Terms</a>, including the payment terms and <a href="/privacy-policy" target={'_blank'}> Privacy Policy</a>
      </div>
      <div className="footer-row">
        <p 
        onClick={() => action2Click(method, setMethod, setHeading, setSubheading, setAction2Text,
        props, setShowForgotPassword, props.setIsModalVisible)}
        >
          {subheading}
        </p>
      </div>
      </Fragment>}
      {showForgotPassword &&
      <Fragment>
        <ForgotPassword />
        <div className="footer-row" style={{ textAlign: "center", paddingBottom: '20px' }}>
          <span onClick={() => action2Click("Sign Up", setMethod, setHeading, setSubheading, setAction2Text,
            props, setShowForgotPassword, props.setIsModalVisible)}
          >
            Return to Log In
          </span>
        </div>
      </Fragment>}
    </div>
  );

  return(
    <section>
      <Modal
        className="auth-modal"
        show={authModal.isModalVisible}
      >
        {body}
      </Modal>
      <VerificationModal 
        showVerificationModal={showVerificationModal} 
        setShowVerificationModal={setShowVerificationModal} 
        onOTPConfirm={handleOTPConfirmation} 
        onResendOTP={handleResendOTP} 
        OTPError={OTPError} 
        codeRequestCount={codeRequestCount} 
        sendingOTP={sendingOTP}
        onClose={() => setRequestInProgress(false)}
      />
    </section>
  );
}


export default Authenticate;
