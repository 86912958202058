import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { externalLink } from './Posts';

const PostCreateWinningAd: FC = () => {

  return (
    <div className="blog-post">
      <p>Anyone who has run a successful paid social campaign knows the significance of good ad creative. A winning asset can consistently and cheaply drive thousands of conversions for months on end, continuing to perform seemingly in perpetuity. So of course the question becomes, how do you create that winning ad?</p>
      <p>At Blitz Growth, we generate and test thousands of assets each month. It’s nearly impossible to predict what will perform ahead of time, so we’ve built out our tech and our team to support the maximal volume of creative testing that our clients’ budgets can support. We test creative on all key platforms, in all formats, with different scripts, models, actors, text copies, background music, end caps, CTAs. Out of say 1,000 assets we develop for a client each month, 20-50 will end up driving 80% of conversions, and perhaps 5-10 of those will remain performers for 6+ months.</p>
      <p>What we’ve discovered is that maximizing the volume of good-quality creative bets is the best way to arrive at a winning ad. After testing tens of thousands of assets and deploying them across Facebook, TikTok, Google, Twitter, Snapchat, and more with over $500m in spend,  we can genuinely say that testing large volumes of creative is the difference between creating ads that work - and creating ads that flop. </p>
      <p>So: how do you decide what to test?</p>

      <h4>Ad Formats</h4>
      <p>Every platform is its own beast. Static ads still kill it on Facebook and Twitter, while video reigns on TikTok and YouTube. Carousel ads (especially for e-commerce) remain effective on Facebook and Instagram, especially when you have a product catalog at your disposal. So, how do you decide where to start? </p>
      <p><strong>Static Ads</strong>, while perhaps old-fashioned, still perform well in Facebook and Instagram feeds, and a sequence of still images can even perform in Story placements on Instagram and in TikTok ads. They are generally the easiest to iterate, so if your creative bandwidth is limited, they can be a great resource for testing different language and value props without having to create a video. They are the most direct way to convey the value of your product, so be sure to use compelling imagery and catchy and authentic text. </p>
      <p><strong>Video Ads</strong> offer the unique opportunity to entertain your potential customers while simultaneously conveying your message. Don’t worry about producing premium long-form content here; short and to the point is the answer here. Even a short looping animation showcasing your product in an eye-catching manner can offer great success, and of course user-generated style content will perform well on any platform. Don’t limit your creativity to TikTok; we often use trends that originate organically on TikTok in ads developed for Instagram and see equal success.</p>
      <p><strong>Carousel Ads</strong> allow you to showcase multiple value props or product offerings in the same ad, giving you the opportunity to display a range of products. For e-commerce companies these are an easy choice, and we’ve even seen success with single offering subscription apps showcasing different use cases for a product in each of the carousel images. The carousel is a fun interface for the user to engage with and easily encourages scrolling and tapping.</p>
      <p>Each of these formats offers something unique, and you won’t know what works until you try it. Even if you’re working with a limited budget, you want to test each format available on each platform. Trying a diversity of formats and concepts will tell you what works best for your business and your product.</p>

      <h4>Designing Your Ad</h4>
      <p>Where do you come up with the ideas for your ads? Inspiration can strike in many forms, but there are a few key tactics you can employ to get your creative juices flowing. Analyzing competitors, following content trends on TikTok, and looking at trending animations on design sites like Behance are a great way to begin your creative process. </p>
      <p>
        Analyzing the ads your competitors run is now possible thanks to creative tools provided by ad platforms. Resources like the
        {externalLink('https://www.facebook.com/ads/library', 'Facebook ad library')} and the recently launched
        {externalLink('https://ads.tiktok.com/business/creativecenter', 'TikTok creative center')} grant you easy access to your competition’s creative strategy. Keep a list of relevant companies handy and check out their content at least monthly. These tools both allow you to search by geo and impressions, and TikTok even lets you search by industry and objective. As you audit their content, develop a picture in your mind of their long term content trends; what value props are they repeating? Any creators or actors that reappear, month after month? What formats feature prominently? Any other tactics that are winning them high impression numbers? By having the visibility offered by these easily searchable tools, you can answer these questions and apply the learning already done by your competitors to your own creative strategy.
      </p>
      <p>
        Looking beyond competitors, it’s also important to pay attention to overall trends in the social media world. If you’re running ads on a platform, it’s key to be active on that platform. Keep an eye out for compelling ads and organic content while you scroll through Instagram and TikTok in your non-working hours. Simply familiarizing yourself with the organic content that thrives on platforms like TikTok can give you a serious leg up. If you can identify a trend that makes sense for your product, you should absolutely capitalize on it. Audiences are becoming increasingly familiar with brands participating in trends; take a look at a prime example from
        {externalLink('https://www.tiktok.com/@duolingo/video/7081274278112431402?is_copy_url=1&is_from_webapp=v1&lang=en', 'Duolingo')}, a language learning app that does an amazing job staying top-of-mind with its audience. By simply modifying a trend to play on their product’s value props, their content performs and their product becomes memorable and familiar.
      </p>
      <p>At the end of the day however, these tactics should merely be your starting point, and you should always strive for new and unique content. A healthy creative imagination is as key for developing compelling ads as for any type of content, and ripping off another company is never the answer to top performance, as they will always have the benefit of the first mover. Learn from your competitors and other key players in the market, look to them for inspiration, but your content should always authentically reflect your product and your users.</p>

      <h4>Winning Tactics</h4>
      <p>At Blitz Growth, we’ve identified a few high-level tactics to frame your ad creative that perform consistently across platforms and across industries. </p>
      <p>The entire purpose of an ad campaign is to show your content to a user who is likely to relate to your product in some way and who will then take action on that resonance. To that end, showcasing authentic user-generated content like quotes from user interviews or surveys, App Store or Play Store reviews, genuine social engagements on Twitter, and similar sources employs the power of social proof to convey to your potential customer that your product or service was useful to someone just like them. We refer to these types of ads as <strong>testimonials</strong>, which do well in both static and video formats.</p>
      <p>Combining the relatability of user-generated video content (UGC) with the directness of tutorial style content is another very effective way of converting users; not only do your potential customers see someone just like them talking positively about your product, but they also get a preview of what the product looks like. We call these ads <strong>UGC How To’s</strong>. Having a creator simply show your product in real life or screenshare your app’s functionality stands out in a world of overly produced, often gimmicky ads and cuts directly to the usefulness of your product to your audience. </p>
      <p><strong>Product focused</strong> ads are another powerful tactic for driving cost effective impressions. If you’re a food-delivery app, highlight your offerings in authentic photos; if you’re an e-commerce reseller, showcase top performing products from your app. There is a wealth of data available to most advertisers regarding their best selling products and offerings, and starting with your top 10 is an easy way to leverage existing data in your creative strategy.</p>
      <p>Moving to newer territory, paid acquisition utilizing influencers has begun to emerge as a very effective tactic. There are two main strategies here: 1) posting influencer content as ads from your own account and 2) running influencer content as ads originating from their handle. The former tends to be cheaper and is a great way to get compelling video or imagery from a social-media savvy creator, and the latter is a very strategic way of engaging potential customers with a real person’s account instead of your brand account. If you’re simply using influencers to generate content, looking for influencers with high level of engagement with their organic content is more important than their follower size, as you want a high-quality content producer. If you’re running influencer posts as ads from their own account, labeled on Facebook as <strong>Branded Content Ads</strong> and on TikTok as <strong>Spark Ads</strong>, look for high quality content producers with mid to large followings to capitalize on their recognizability. Because these are advertisements delivered from a partnered creator’s account rather than your own brand’s, they integrate more organically into the feed of your audience and offer high engagement rates. After millions of spend behind these ads, it’s clear to us that this tactic is a winner that all advertisers should at least experiment with.</p>

      <h4>Developing Creative Variants</h4>
      <p>Auditing competitors and jump-starting your creative efforts with market testing creative tactics can certainly give you a head-start, but at the end of the day testing a high volume of creative is the key. At Blitz Growth, we often test out 20+ creative variations of the same advertisement to ensure that the concept is getting a fair shot, and we’ll typically see one or two of those 20+ get promising results. There are, of course, limitless creative options a marketer can iterate on, but there are a few critical components we have found to be the most impactful.</p>
      <p>Let’s start with <strong>creators</strong>. When generating human-centric ads, a marketer should choose an appropriate mix of creators that exemplifies your user base. Test as diverse a set as you can (demographic, style, tone, etc), but ensure that each creator fits within your target audience. When identifying winning creators, brand fit is king. A creator might portray great authenticity and hold plenty of selling power, but if the audience can’t make the connection between them and your product, the efficacy of your ad will suffer. It goes without saying that anyone you consider should be comfortable and compelling on camera and should be capable of authentically and energetically conveying your message. </p>
      <p>Once you’ve identified your creator mix, it’s time to iterate on scripts and/or in-ad language. It’s important to have clear and distinct value propositions in mind as well as the specific personae that would benefit from each of these. The varied benefits to a user of your product should be clear, but it’s equally as important to keep the identity and feelings of your potential customer in mind and to use the language and communication style of each. For example, adding emojis and slang to an ad written from the perspective of a student and adding in personal details about your persona’s daily life will make each creative test specific and unique. At Blitz Growth, we’ve found that breaking down each ad into a very specific persona+value prop allows you to test a wide spectrum of possible messaging, ultimately rewarding you with the insight of your most powerful messaging combo once you test each ad’s performance.</p>
      <p>Finally, what we think of as <strong>visual and audio accessories</strong> can boost a compelling ad’s performance, though we will add the caveat that the creator and messaging are the most critical. There are plenty of components marketers can test here; background audio tracks, on-screen stickers and animations, adding subtitles, end-cap cards, even color schemes can all impact performance for better and for worse. Of these, trendy and ear-catching audio and on-screen stickers can both have the thumb-stopping effect that inspires your potential customer to stop scrolling and take a closer look.</p>
      <p>It’s important to mention here as well that prioritizing the most impactful creative variants is important; if you are limited in bandwidth, focusing on a spread of ad formats with 2-3 high quality creators is a much better starting point than testing the same ad with 10 different background audio tracks.</p>

      <h4>Platform Copy</h4>
      <p>An often-overlooked component of your creative is the copy that appears alongside your asset. While certainly secondary to the asset itself, this copy can nonetheless improve your performance if it is eye-catching, interesting, and complementary to the message in your ad. </p>
      <p>Each platform has a primary and secondary text with different character limits and placements, but you can often duplicate text copies across platforms. <strong>Primary texts</strong> should be short and convey the value of your product in as few words and in as direct a manner as possible, where <strong>secondary texts</strong> should be longer and include more detail, perhaps speaking specifically to one value proposition. Secondary texts are also a great place for emojis, especially in the opening few words, to help capture the attention of your potential user.</p>
      <p>Of course, the answer here too is to test as many clear and distinct variants as possible to arrive at the top performing combinations.</p>

      <h4>Summary</h4>
      <p>In short, the answer to “how to create a winning ad” is to create lots of potential winners, then test and iterate your way to success. Do your research first: check out the competition, learn about your core users and audience, and then lay down as many informed and distinct creative bets as your team can support. Test energetic and compelling creators that fit your demographic, develop a variety of strong scripts and copy combinations, and test a range for formats on each platform. Give each creative a shot, but be rigorous about pruning out under-performers and iterating on promising ones; remaining focused and data driven while you methodically develop your creative pipeline will ultimately reward you with long-lasting, market-tested, top-performing assets.</p>
      <p>
        At Blitz Growth, we’ve developed a proprietary tech stack as well as a global team of creators, copywriters, video editors, and influencers that allows us to support our clients in creative testing and campaign execution at scale. Our ten years of experience in the space have taught us that this progressive approach to creative testing is key for achieving success with paid social campaigns. <strong>If you're interested in supercharging your growth using what we've learned, check out our services {externalLink('https://www.useblitz.com/capabilities', 'here')}.</strong>
      </p>
    </div>
  );
}

export default PostCreateWinningAd;
