import { JsonObject, JsonProperty } from "json2typescript";
import { ShapeComponent } from "./ShapeComponent";
import { alphaDecimalVal, evaluate, getRaiusFromArcMetrics } from "../../Common/MathExpressionStatement";
import { VideoPlatform } from "../templates/VideoPlatform";

@JsonObject("RoundedRectangleComponent")
export class RoundedRectangleComponent extends ShapeComponent {

    @JsonProperty("xBend")
    public xBend:string = '0';

    @JsonProperty("yBend")
    public yBend:string = '0';

    protected xBendVal(previewMultiplier:number):number {
        try {
            return evaluate(this.xBend)*(previewMultiplier);
        }
        catch (e) {
            return 0;
        }
    }

    protected yBendVal(previewMultiplier:number):number {
        try {
            return evaluate(this.yBend)*(previewMultiplier);
        }
        catch (e) {
            return 0;
        }
    }

    async drawComponent(frameNumber: number, previewMultiplier: number, parentImage: HTMLCanvasElement | null, videoPlatform: VideoPlatform): Promise<HTMLCanvasElement> {
        let componentWidth: number = this.componentWidthAsFloat(previewMultiplier, parentImage);
        let componentHeight: number = this.componentHeightAsFloat(previewMultiplier, parentImage);
        let canv: HTMLCanvasElement = this.canvas(componentWidth, componentHeight);
        let strokeWidth: number = this.strokeWidthval(previewMultiplier);
        let graphics2D:any = canv.getContext('2d');
        if (graphics2D === null) {
            return this.image(canv);
        }
        let radius = getRaiusFromArcMetrics(this.xBendVal(previewMultiplier), this.yBendVal(previewMultiplier));
        if (this.isFill()) {
            let fillStyle = this.shapeColor.startsWith("#") ? this.shapeColor : "#" + this.shapeColor;
            let fillStyleColor = fillStyle.length === 9 ? "#" + fillStyle.slice(3) : fillStyle;
            graphics2D.fillStyle = fillStyleColor;
            graphics2D.globalAlpha = alphaDecimalVal([fillStyle]);
        }
        {
            let strokeStyle = this.strokeColor === '' ? this.shapeColor : this.strokeColor;
            strokeStyle = strokeStyle.startsWith("#") ? strokeStyle : "#" + strokeStyle;
            let strokeStyleColor = strokeStyle.length === 9 ? "#" + strokeStyle.slice(3) : strokeStyle;
            graphics2D.strokeStyle = strokeStyleColor;
        }
        if(strokeWidth>1) {
            graphics2D.lineWidth = strokeWidth;
        }
        graphics2D.roundRect(strokeWidth / 2, strokeWidth / 2, componentWidth - strokeWidth, componentHeight - strokeWidth, radius);
        if (this.isFill()) {
            graphics2D.fill();
        }
        else {
            graphics2D.stroke();
        }
        return this.image(canv);
    }
}