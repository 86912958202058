import React from 'react';

type EBProps = { fallback: any; children?: any };
type EBState = { hasError: boolean; };

class ErrorBoundary extends React.Component<EBProps, EBState> {
  state = { hasError: false };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.log('========== error caught ==========');
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
