import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';
import Routes from '../../common/routes';
import { LandingContext } from './LandingContext';
import { AppContext } from '../../appContext';
import { LI_TRACKING_IDS, TrackLIEvent } from '../../common/LinkedInEventTracker';

const LandingHeader: React.FC = () => {

  const location = useLocation();
  const pathName = location.pathname;

  const isActive = (path) => pathName.startsWith(path) ? 'active' : '';
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { setShowContactModal } = useContext(LandingContext);
  const { authModal } = useContext(AppContext);

  const toggleHamburgerMenu = () => setShowMenu(!showMenu);

  const shouldShowLogin = () => {
    // if (window.location.pathname === '/') {
    //   return true;
    // } 
    return ['/product'].some(path => document.URL.includes(path));
  }

  return (
    <div className="landing-header">
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        {/* Ubuntu */}
        <link href="https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap" rel="stylesheet" />
        {/* Inter, Poppins, Roboto */}
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;700;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@100;300;400;500;700;900&display=swap" rel="stylesheet" />
      </Helmet>
      <div className="layout-container">
        <Link to={Routes.HOME}>
          <div className="logo"></div>
        </Link>
        <div
          className={`hamburger ${showMenu ? 'open' : ''}`}
          onClick={toggleHamburgerMenu}
        >
          <div className="top-bun piece"></div>
          <div className="meat piece"></div>
          <div className="bottom-bun piece"></div>
        </div>
        <ul className={`header-links ${showMenu ? 'show' : ''}`}>
          {/* <li className={isActive(Routes.HOME)}>
            <Link to={Routes.HOME}>Home</Link>
          </li> */}
          {/*<li className={isActive(Routes.PRODUCT)}>
            <Link to={Routes.PRODUCT}>Product</Link>
        </li>*/}
          <li className={isActive(Routes.CAPABILITIES)}>
          <Link to={Routes.CAPABILITIES}>Capabilities</Link>
          </li>
          <li className={isActive(Routes.CASE_STUDIES)}>
            <Link to={Routes.CASE_STUDIES}>Case Studies</Link>
          </li>
          <li className={isActive(Routes.ABOUT)}>
            <Link to={Routes.ABOUT}>About Us</Link>
          </li>
          <li className={isActive(Routes.BLOG)}>
            <Link to={Routes.BLOG}>Blog</Link>
          </li>
          <li>
            {
              shouldShowLogin() ?
                <a
                  data-ga-event="click"
                  data-ga-data={''}
                  data-ga-action="SignInModalOpen"
                  href="#"
                  className="btn v2 small"
                  onClick={() => {
                    console.log('clicked!');
                    TrackLIEvent(LI_TRACKING_IDS.signIn);
                    authModal.showModal[0].fn(null, "sign-in");
                  }}
                >
                  Log In
                </a>
                :
                <a
                  data-ga-event="click"
                  data-ga-data={''}
                  data-ga-action="ShowContactModal"
                  href="#"
                  className="btn v2 small"
                  onClick={() => {
                    TrackLIEvent(LI_TRACKING_IDS.contactUs);
                    setShowContactModal(true);
                    window['contactOpenSource'] = 'HomePage::TopNavBar:Contact Us';
                  }}
                >
                  Contact Us
                </a>
            }
            
          </li>

        </ul>
      </div>
    </div>
  );
};

export default LandingHeader;
