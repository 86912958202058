/**
 * Tracks linkedIn events
 * @param conversionId - conversion ID for linked in
 */
export const TrackLIEvent = (conversionId) => {
  if (conversionId) {
    const host = window.location.host;
    if (host === 'www.smartblitz.ai' || host === 'www.useblitz.com') {
      (window as any).lintrk('track', { conversion_id: conversionId });
    }
  }
};

export const LI_TRACKING_IDS = {
  contactUs: 6916876,
  tryFiveDays: 11260356,
  free: 11260388,
  lite: 11260396,
  enterprise: 11260404,
  signIn: 11260420,
  createMyAccount: 11260412,
  emailSub: 11260364,
  boostGrowth: 11260372,
  connectWithUs: 11260380,
  creditCardSuccess: 11669404
};
