import React, { useContext, useState } from "react";
import { Modal } from "frontend-core";
import { AppContext } from "../appContext";
import InputCode from "./InputCode";

interface IVerificationModal {
  showVerificationModal: boolean;
  onOTPConfirm: Function;
  onResendOTP: Function;
  OTPError: string;
  codeRequestCount: number;
  sendingOTP: boolean;
  setShowVerificationModal: Function;
  onClose?: Function;
}

const VerificationModal = (props: IVerificationModal) => {
  const {
    setShowVerificationModal,
    showVerificationModal,
    onOTPConfirm,
    onResendOTP,
    OTPError,
    codeRequestCount,
    sendingOTP,
    onClose=null
  } = props;
  const [code, setCode] = useState("");

  return (
    <Modal
      className="verification-modal"
      show={showVerificationModal}
      onClose={() => {
        setShowVerificationModal(!showVerificationModal);
        if (onClose) {
          onClose();
        }
      }}
    >
      <div className="login-popup">
        <header>
          <img
            src="https://media.useblitz.com/static/blitz/img/blitz-logo-white.svg"
            alt="blitz logo"
          />
        </header>
        <section>
          <h3 style={{ fontSize: "1.375rem" }}>Verify Email</h3>
          <p style={{ fontSize: "16px" }}>
            Check your inbox for your verification code.
          </p>
          <InputCode
            length={6}
            onComplete={(code) => {
              setCode("" + code);
            }}
            onSubmit={() => onOTPConfirm(code)}
          />
          {sendingOTP && <p style={{ fontSize: 14 }}>Sending Code...</p>}
          {OTPError && <p className="error">{OTPError}</p>}
          <p className={`grey-underline-text ${codeRequestCount < 3 ? "" : "inactive"}`}  onClick={() => onResendOTP()}>
            Resend Code
          </p>
          <button
            type="submit"
            className="btn"
            onClick={() => onOTPConfirm(code)}
            disabled={code.length !== 6}
          >
            Confirm
          </button>
        </section>
      </div>
    </Modal>
  );
};

export default VerificationModal;
