import constants from '../constants';

const GaClickTracking = (): void => {
  document.addEventListener('click', (e: any) => {
    const {
      tagName,
      textContent
    } = e.target;
    if (constants.env() !== 'PROD') {
      return;
    }
    if (tagName === 'A' || tagName === 'BUTTON') {
      if (e.target.getAttribute('data-ga-event') === null) {
        let userInfo: string = localStorage.getItem('userInfo') || 'no_data';
        if (userInfo === 'no_data') {
          window.gaTrack(`${textContent} (click)`);
        }
        else {
          // parse user object from the string
          let userObj = JSON.parse(userInfo);

          // We need only limited set of fields which are they in the user object
          const fieldsToPick = ['updatedAt', 'createdAt', 'email', 'productGroupId', 'premiumTier', 'activePlatform', 'aiTextCreditsUsed', 
            'aiImageCreditsUsed', 'aiTextCreditsUsedLifetime', 'aiImageCreditsUsedLifetime', 'freeCreditsExpiryTime', 'creditsResetEpoch',
            'isEnterpriseTrial', 'isEnterpriseTrialUsed', 'enterpriseTrialExpiryEpoch', 'fueState', 'linkAccountStatus', 'linkedAccount',
            'totalAiLifeTimeCreditsUsed', 'isNonAdminPremiumUser', 'isPremiumUser', 'totalAiCreditsUsed', 'userId', 'localLastUpdate'];

          const reducedUserObj = Object.entries(userObj).reduce((result, [key, value]) => {
            if (fieldsToPick.includes(key)) {
              if (key === 'userId') {
                result['user_id'] = value;
              }
              else {
                result[key] = value;
              }
            }
            return result;
          }, {});

          window.gaTrack(`${textContent} (click)`, reducedUserObj);
        }
      }
    }
  });
};

export default GaClickTracking;
