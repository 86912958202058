import React from 'react';
import constants from '../../constants';
import AfterHeader from './AfterHeader';
import LandingLayout from './Layout';

const About: React.FC = () => {

  return (
    <LandingLayout>
      <div className="about">
        <AfterHeader
          title="About Us"
          caption="All About Blitz"
        />

        <div className="team-section-container">
          <div className="team-section layout-container">
            <div className="left-pane">
              <div className="ornament-holder">
                <img className="team-india ornament-for" src={constants.imagesUrl + 'team/team-india-new.png'} />
                <div className="ornament top-left"></div>
              </div>
              <div className="ornament-holder">
                <img className="team-us ornament-for" src={constants.imagesUrl + 'team/team-us.png'} />
                <div className="ornament bottom-right"></div>
              </div>
            </div>
            <div className="right-pane">
              <div className="thin-primary-text-alt">Our Story</div>
              <div className="h2">How It All Started</div>
              <div className="caption-dark mt-20">
                Before spinning Blitz Growth out as a separate venture, our team spent ten years building VC-backed mobile entertainment products. Several of our games were Top 10 Grossing on Android, and our gameshows delighted millions of players on iOS, all supported by our in-house scaled growth marketing.
              </div>
              <div className="caption-dark mt-20">
                We built up experienced internal talent as well as a powerful tech stack to solve user acquisition, and we eventually realized that we could offer these same services to other companies, offering them the same growth we had experienced.
              </div>
              <div className="caption-dark mt-20">
                We've now spent 3 years helping 10+ companies from pre-seed to Series C grow their businesses profitably and effectively, serving as a full-stop replacement for an internal marketing team or as a powerful augmentation, and we're excited to see where Blitz will take us!
              </div>
            </div>
          </div>
        </div>

        <div className="orange-section-container">
          <div className="orange-section">
            <div className="thin-primary-text-alt dark">Create, test, analyze. Repeat.</div>
            <div className="head white mt-20">The Blitz Philosophy</div>
            <ul className="white-cards">
              <li className="card">
                <div className="head orange">
                  Create
                </div>
                <div className="poppins-text mt-20">
                  <strong>Develop and Iterate Creative</strong>
                </div>
                <div className="poppins-text mt-20">
                  The ad platforms are powerful and provide many opportunities for efficiency with different campaign objectives, audience targeting, lookalikes, and continuously improving algorithms, but all your competitors have access to these features, too.
                  <strong> The most powerful tool at any marketer's disposal is creative, because the opportunities for new and inventive messaging are endless. </strong>
                  At Blitz, all our activities are in service of creating better creative, faster.
                </div>
                <div className="poppins-text mt-20">
                  <strong>Performance marketing is storytelling in seconds at scale</strong>
                  , and a body of market-tested creative will drop your cost per acquisition and maximize your revenue more effectively than anything else.
                </div>
              </li>
              <li className="card">
                <div className="head orange">Test</div>
                <div className="poppins-text mt-20">
                  <strong>Fully Managed Ad Campaigns</strong>
                </div>
                <div className="poppins-text mt-20">
                  As important as creative is, deploying it effectively in scaled A/B tests to ensure unbiased optimization is perhaps equally important. We strive to give each asset an earnest chance at success, and to that end we religiously test
                  <strong> campaign objectives </strong>
                  and
                  <strong> new platform features</strong>
                  , deploy
                  <strong> algorithms to clean out underperformers</strong>
                  , create
                  <strong> new audiences</strong>
                  , and
                  <strong> structure campaigns </strong>
                  <strong> to maximize exposure for top assets.</strong>
                </div>
                <div className="poppins-text mt-20">
                  <strong>Properly structuring creative testing </strong>
                  (while of course maximizing results for your business!) is key to understand what's working and to inform future creative cycles.
                </div>
              </li>
              <li className="card">
                <div className="head orange">Analyze</div>
                <div className="poppins-text mt-20">
                  <strong>Analyze Ad Performance</strong>
                </div>
                <div className="poppins-text mt-20">
                  To hit on excellence, we need to know what is working and why, so we emphasize detailed and micro analysis of creative to identify performant strategies (from ad formats to creators to messaging) specific to YOUR product. We track progress towards macro KPI goals, unifying data across ad platforms and reconciling with data from your MMP in our detailed bi-weekly reporting to ensure that we're hitting your overall goals.
                </div>
                <div className="poppins-text mt-20">
                  <strong>Deep and thoughtful analysis is key for a complete picture of creative performance</strong>
                  , ensuring that each new rack of assets tested is an improvement on the last and that your creative is an ever-evolving stable that generates results.
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="team-members-container">
          <div className="teams-ring one"></div>
          <div className="teams-ring two"></div>
          <div className="team-members layout-container">
            <div className="thin-primary-text">The Team</div>
            <div className="h2">Meet the Blitz Team</div>
            <div className="members">
              <div className="member kunal">
                <div className="name">Kunal Kishore</div>
              </div>
              <div className="member cordelia">
                <div className="name">Cordelia Duff</div>
              </div>
              <div className="member sriharsha">
                <div className="name">Sriharsha Reddy</div>
              </div>
              <div className="member praveen">
                <div className="name">Praveen Srinivasaiah</div>
              </div>
              <div className="member parth">
                <div className="name">Parth Agnish</div>
              </div>
              <div className="member arturo">
                <div className="name">Arturo Hurtado</div>
              </div>
              <div className="member deeksha">
                <div className="name">Deeksha M Kotegar</div>
              </div>
              <div className="member anudeep">
                <div className="name">Anudeep RB</div>
              </div>
              <div className="member chetan">
                <div className="name">Chetan Kumar</div>
              </div>
              <div className="member satya">
                <div className="name">Satya Pavan Lingam</div>
              </div>
              <div className="member shreyas">
                <div className="name">Shreyas KR</div>
              </div>
              <div className="member eshwar">
                <div className="name">Eshwar Chandra</div>
              </div>
              <div className="member mohit-sardana">
                <div className="name">Mohit Sardana</div>
              </div>
              <div className="member anjali">
                <div className="name">Anjali NM</div>
              </div>
              <div className="member abhinav">
                <div className="name">Abhinav Sudhendra</div>
              </div>
              <div className="member smarak">
                <div className="name">Smarak Das</div>
              </div>
              <div className="member mohit-wadhwani">
                <div className="name">Mohit Wadhwani</div>
              </div>
              <div className="member ishan">
                <div className="name">Ishan Patil</div>
              </div>
              <div className="member harshan">
                <div className="name">Harshan MK</div>
              </div>
              <div className="member priyanka">
                <div className="name">Priyanka</div>
              </div>
              <div className="member girish">
                <div className="name">Girish Bhat</div>
              </div>
              <div className="member mayank">
                <div className="name">Mayank Kumar</div>
              </div>
              <div className="member varun">
                <div className="name">Varun Hiremath</div>
              </div>
              <div className="member pooja">
                <div className="name">Pooja Raj</div>
              </div>
              <div className="member okan">
                <div className="name">Okan Donmez</div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex jcc mb-60">
          <button
            type="button"
            onClick={() => {}}
            className="btn v2 large hide-imp"
          >
            Join the Team
          </button>
        </div>

      </div>
    </LandingLayout>
  );
};

export default About;
