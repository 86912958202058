import { JsonConverter } from "json2typescript";
import { EnumConverter } from "../../Common/EnumConverter";

export enum DimensionInputType {
    MATCH_PARENT,
    CUSTOM_INPUT
}



export function DimensionInputTypeValueOf(val:string):DimensionInputType {
    switch(val) {
      case "MATCH_PARENT": return DimensionInputType.MATCH_PARENT;
      case "CUSTOM_INPUT": return DimensionInputType.CUSTOM_INPUT;
      default: return DimensionInputType.CUSTOM_INPUT;
    }
  
  }

@JsonConverter
export class DimensionInputTypeConverter extends EnumConverter<DimensionInputType> {
  constructor() {
    super(DimensionInputType, "DimensionInputType");
  }
}