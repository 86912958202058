import { JsonObject, JsonProperty } from "json2typescript";
import { Effect } from "../effects/Effect";
import { DimensionInputType, DimensionInputTypeConverter } from "../inputs/DimensionInputType";
import { ComponentType, ComponentTypeConverter } from "./ComponentType";
import { CompositeEffect } from "../effects/CompositeEffect";
import { fromJson, toJson } from "../../Common/JsonUtil";
import { convertComponent, convertObjToComponent } from "./ComponentFactory";
import { VideoPlatform } from "../templates/VideoPlatform";
import { evaluate, roundToTwo } from "../../Common/MathExpressionStatement";

export { };


@JsonObject("Component")
export class Component {

    @JsonProperty("componentName", String)
    componentName: string = '';

    @JsonProperty("componentType", ComponentTypeConverter)
    componentType: ComponentType = ComponentType.SHAPE_RECTANGLE;

    @JsonProperty("subComponents", [Object])
    subComponents: Object[] = [];

    @JsonProperty("effects", [Effect])
    effects: Effect[] = [];

    @JsonProperty("widthInput", DimensionInputTypeConverter)
    widthInput: DimensionInputType = DimensionInputType.CUSTOM_INPUT;


    @JsonProperty("heightInput", DimensionInputTypeConverter)
    heightInput: DimensionInputType = DimensionInputType.CUSTOM_INPUT;


    @JsonProperty("componentWidth", String)
    componentWidth: string = '';

    @JsonProperty("componentHeight", String)
    componentHeight: string = '';

    @JsonProperty("deltaWidth", Number)
    deltaWidth: number = 0;

    @JsonProperty("deltaHeight", Number)
    deltaHeight: number = 0;

    @JsonProperty("startF", String)
    startF: string = '';

    @JsonProperty("deltaStartF", Number)
    deltaStartF: number = 0;

    @JsonProperty("endF", String)
    endF: string = '';

    @JsonProperty("deltaEndF", Number)
    deltaEndF: number = 0;

    @JsonProperty("isTimeSupported", Boolean)
    isTimeSupported: boolean = true;

    @JsonProperty("isStaticTemplate", Boolean)
    isStaticTemplate: boolean = false;

    @JsonProperty("padding", String)
    padding: string = '0';


    @JsonProperty("offsetF", String)
    offsetF: string = '';

    @JsonProperty("layout", CompositeEffect)
    layout?: CompositeEffect;

    compImage:HTMLCanvasElement = document.createElement("canvas");


    async drawComponent(frameNumber: number, previewMultiplier: number, parentImage: HTMLCanvasElement | null, videoPlatform: VideoPlatform): Promise<HTMLCanvasElement> {
        let img = document.createElement("canvas");
        return img;
    }


    /*
    public float componentWidthAsFloat(BigDecimal previewMultiplier, BufferedImage componentParentImage) {
        if (widthInput == DimensionInputType.MATCH_PARENT && componentParentImage != null) {
            return componentParentImage.getWidth();
        }
        float componentWidth = 0;
        try {
            componentWidth = MathUtil.evaluate(this.componentWidth).multiply(previewMultiplier).setScale(2, RoundingMode.HALF_UP).floatValue();
        } catch (Exception e) {
            logger.error("Exception in componentWidth() MathUtil.evaluate: "+ this.componentWidth+", componentName: " + this.componentName, e);
        }
        return componentWidth;
    }
    */


    paddingAsFloat(previewMultiplier: number): number {
        let padding = 0.0;
        if (this.padding != null) {
            try {
                padding = evaluate(this.padding) * previewMultiplier;
            } catch (error) {
            }
        }
        return roundToTwo(padding);;
    }

    getEffectiveWidth(previewMultiplier: number, parentImage: HTMLCanvasElement | null): number {
        return this.componentWidthAsFloat(previewMultiplier, parentImage) - (2.0 * this.paddingAsFloat(previewMultiplier));
    }


    getEffectiveHeight(previewMultiplier: number, parentImage: HTMLCanvasElement | null): number {
        return this.componentHeightAsFloat(previewMultiplier, parentImage)  - (2.0 * this.paddingAsFloat(previewMultiplier));
    }



    componentWidthAsFloat(previewMultiplier: number, componentParentImage: HTMLCanvasElement | null): number {
        if (this.widthInput === DimensionInputType.MATCH_PARENT && componentParentImage !== undefined && componentParentImage !== null) {
            return componentParentImage.width;
        }
        let val: number = (evaluate(this.componentWidth) * previewMultiplier);
        return roundToTwo(val);
    }


    componentHeightAsFloat(previewMultiplier: number, componentParentImage: HTMLCanvasElement | null): number {
        if (this.widthInput === DimensionInputType.MATCH_PARENT && componentParentImage !== undefined && componentParentImage !== null) {
            return componentParentImage.height;
        }
        let val: number = (evaluate(this.componentHeight) * previewMultiplier);
        return roundToTwo(val);
    }

    canvas(width: number, height: number): HTMLCanvasElement {
        let can = document.createElement("canvas");
        can.width = width;
        can.height = height;
        return can;
    }

    image(canvas: HTMLCanvasElement): HTMLCanvasElement {
        // let img: HTMLImageElement = document.createElement("img");
        // img.crossOrigin = "anonymous";
        // img.src = canvas.toDataURL();
        return canvas;
    }

    startFVal(): number {
        let start: number = this.deltaStartF;
        try {
            if (this.layout === undefined || this.layout === null) {
                start = evaluate(this.startF) + this.deltaStartF;
            }
            else {
                start = this.layout.startFVal();
            }
        } catch (error) {

        }
        return start > 0 ? start : 1;
    }



    endFVal(): number {
        let end: number = this.deltaEndF;
        try {
            if (this.layout === undefined || this.layout === null) {
                end = evaluate(this.endF) + this.deltaEndF;
            }
            else {
                end = this.layout.endFVal();
            }
        } catch (error) {

        }
        return end;
    }



    canvasUrlFromImage(img: HTMLImageElement): string {
        let canv = this.canvas(img.naturalWidth, img.naturalHeight);
        let ctx = canv.getContext("2d");
        if (ctx === null) {
            throw ("exception while getting ctx");
        }
        ctx.drawImage(img,
            0, 0,
            img.naturalWidth, img.naturalHeight,
            0, 0,
            img.naturalWidth, img.naturalHeight
        );

        return canv.toDataURL();
    }

    async render(frameNumber: number, previewMultiplier: number, parentImage: HTMLCanvasElement | null, videoPlatform: VideoPlatform): Promise<HTMLCanvasElement> {
        try {
            this.compImage = await this.drawComponent(frameNumber, previewMultiplier, parentImage, videoPlatform);
            for (let i = 0; i < this.subComponents.length; i++) {
                let component: Component = this.subComponents[i] as Component;
                let subComponentStartF: number = component.startFVal();
                let subComponentEndF: number = component.endFVal();


                
                if (frameNumber >= 0 && subComponentStartF > 0 && frameNumber < subComponentStartF) {
                    continue;
                }
                if (frameNumber >= 0 && subComponentEndF >= 0 && frameNumber > subComponentEndF) {
                    continue;
                }

                try {
                    this.compImage = await component.render(frameNumber, previewMultiplier, this.compImage, videoPlatform);
                }
                catch (e) {
                }
            }

            if (this.layout !== undefined && this.layout !== null) {
                this.compImage = this.layout.applyLayout(frameNumber, previewMultiplier, this.compImage, parentImage);
            }
            return this.compImage;
        } catch (error) {
            console.log(error);
            if (error instanceof Error) {
                console.log(error.stack);
            }
            throw error;
        }
    }

    layoutImages(elem1: HTMLCanvasElement, elem2: HTMLCanvasElement): HTMLCanvasElement {
        let can: HTMLCanvasElement = this.canvas(elem1.width, elem1.height);
        let ctx = can.getContext('2d');
        if (ctx === null) {
            return elem1;
        }
        ctx.drawImage(elem1, 0, 0);
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.drawImage(elem2, 0, 0);
        return this.image(can);
    }

    /*
    public BufferedImage render(int frameNumber, BigDecimal previewMultiplier, BufferedImage parentImage, VideoPlatform videoPlatform) {
        try {
            this.componentImage = drawComponentAndApplyPreEffects(frameNumber, previewMultiplier, parentImage, videoPlatform);
            List<Effect> postEffects = this.effects.stream().filter(effect -> !effect.isComponentEffect() && effect.shouldApplyPostRenderingSubComponents()).collect(Collectors.toList());

            if (!disableDraw) {
                for (Component component : subComponents) {
                    int subComponentStartF = component.startF();
                    int subComponentEndF = component.endF();
                    if(component instanceof ImageComponent && component.isVideo()){
                        subComponentStartF = ((ImageComponent) component).absoluteStartF();
                        subComponentEndF = ((ImageComponent) component).absoluteEndF();
                    }
                    if (frameNumber >= 0 && subComponentStartF > 0 && frameNumber < subComponentStartF) {
                        continue;
                    }
                    if (frameNumber >= 0 && subComponentEndF >= 0 && frameNumber > subComponentEndF) {
                        continue;
                    }
                    this.componentImage = component.render(frameNumber, previewMultiplier, this.componentImage, videoPlatform);
                }
            }
            List<Effect> immediateEffects = postEffects.stream().filter(effect -> effect.shouldApplyWithoutStitchingToParent()).collect(Collectors.toList());
            List<Effect> relativeEffects = postEffects.stream().filter(effect -> !effect.shouldApplyWithoutStitchingToParent()).collect(Collectors.toList());
            for (Effect effect : immediateEffects) {
                this.componentImage = effect.apply(frameNumber, previewMultiplier, this.componentImage, parentImage);
            }
            for (Effect effect : relativeEffects) {
                this.componentImage = effect.apply(frameNumber, previewMultiplier, this.componentImage, parentImage);
            }
            if (layout != null) {
                this.componentImage = layout.apply(frameNumber, previewMultiplier, this.componentImage, parentImage);
            }
            return this.componentImage;
        }
        catch (Exception e) {
            logger.error("Unable to render component "+ this.componentName+" at frame " + frameNumber, e);
            throw new RuntimeException("Unable to render component "+ this.componentName+" at frame " + frameNumber,  e);
        }
        finally {
            this.dispose();
        }
    }
    */

}
