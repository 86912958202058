import axios from 'axios';


/**
 * Tranforms zero values to empty objects
 * @param data {Object}
 * @returns {Object}
 */
const transformZerosToObject = (data) => {
  if (data.data) {
    for (const k in data.data) {
      if (data.data[k] === 0) {
        data.data[k] = {};
      }
    }
  }
  return data;
};

/**
 * Gives graph data for analytics for given platform
 * @param platform - platform string
 * @param payload - payload object
 * @returns axios object
 */
 export const getGraphData = async (platform: string, payload) => {
  try {
    const data = await axios.post(
      `/api/adAccount/fetchDashboardData/${platform}`,
      payload
    );
    return transformZerosToObject(data);
  } catch (e: any) {
    throw e;
  }
};

/**
 * Gives overall grapha data for analytics
 * @param payload - payload object
 * @returns axios object
 */
 export const getOverallGraphData = async (payload) => {
  try {
    const data = await axios.post(
      `/api/adAccount/fetchTotalDashboardDataNew`,
      payload
    );
    return transformZerosToObject(data);
  } catch (e: any) {
    throw e;
  }
};

/**
 * Gives Custom graph data for analytics
 * @param payload - payload object
 * @returns axios object
 */
 export const getCustomGraphData = async (payload) => {
  try {
    const data = await axios.post(
      `/api/adAccount/fetchChartDataForCustomMetric`,
      payload
    );
    return transformZerosToObject(data);
  } catch (e: any) {
    throw e;
  }
};

/**
 * Gives reports data for analytics
 * @returns axios object
 */
 export const getReportsForAnalytics = async () => {
  try {
    const data = await axios.get('/api/reports/userReports');
    return data;
  } catch (e: any) {
    throw e;
  }
};

/**
 * Fetches filter configuration for filters component population
 * @returns axios object
 */
 export const fetchFilterConfiguration = async () => {
  try {
    const data = await axios.get('/api/adAccount/fetchFilterConfiguration');
    return data;
  } catch (e: any) {
    throw e;
  }
};

/**
 * Gives individual report details
 * @returns axios object
 */
 export const getReportDetails = async (reportName: string) => {
  try {
    const data = await axios.get(`/api/reports/userReport/${reportName}`);
    return data;
  } catch (e: any) {
    throw e;
  }
};


/**
 * Gives individual report details
 * @returns axios object
 */
 export const getDynamicReport = async (payload) => {
  try {
    const data = await axios.post(`/api/adAccount/fetchDynamicReportData`, payload);
    return data;
  } catch (e: any) {
    throw e;
  }
};

/**
 * Gives individual report cumulative data
 * @returns axios object
 */
 export const getDynamicReportCumulative = async (payload) => {
  try {
    const data = await axios.post(`/api/adAccount/fetchDynamicReportData/cumulativeStats`, payload);
    return data;
  } catch (e: any) {
    throw e;
  }
};

/**
 * BI log events
 */
const genericPost = async (url, data, successCallback) => {
  try {
    const res = await axios.post(url, data);
    if(res.status === 200) {
      successCallback();
    }
    return res;
  } catch (e: any) {
    throw e;
  }
}

export const sendBIEvent = (data) => {
  var url = 'https://www.smartblitz.ai/api/logBiEvent';

  genericPost(url, data, () => {
    console.log("BI Log success");
  });
}

/**
 * Fetches analytics metrics
 * @returns axios object
 */
 export const fetchMetrics = async () => {
  try {
    const data = await axios.get(`/api/adAccount/fetchMetricsList`);
    return data;
  } catch (e: any) {
    throw e;
  }
};

/**
 * Creates Custom Metric
 * @returns axios object
 */
 export const createCustomMetric = async (payload) => {
  try {
    const data = await axios.post(`/api/adAccount/customMetrics`, payload);
    return data;
  } catch (e: any) {
    throw e;
  }
};

/**
 * Edit Custom Metric
 * @returns axios object
 */
 export const editCustomMetric = async (id, payload) => {
  try {
    const data = await axios.put(`/api/adAccount/customMetrics/${id}`, payload);
    return data;
  } catch (e: any) {
    throw e;
  }
};

/**
 * Deletes Custom Metric
 * @returns axios object
 */
 export const deleteCustomMetric = async (metricId) => {
  try {
    const data = await axios.delete(`/api/adAccount/customMetrics/${metricId}`);
    return data;
  } catch (e: any) {
    throw e;
  }
};

/**
 * Rename Report
 * @returns axios object
 */
 export const renameReport = async (payload) => {
  try {
    const data = await axios.post(`/api/reports/renameUserReport`, payload);
    return data;
  } catch (e: any) {
    return e.response;
  }
};

/**
 * Fetch conversion metrics (overview tabs)
 * @returns axios object
 */
 export const fetchConversionMetrics = async (payload) => {
  payload.hardReload = payload.hardReload || false;
  try {
    const data = await axios.post(`/api/adAccount/fetchConversionMetrics`, payload);
    return data;
  } catch (e: any) {
    return e.response;
  }
};

/**
 * Fetches chart data
 * @returns axios object
 */
 export const fetchChartData = async (payload) => {
  try {
    const data = await axios.post(`/api/adAccount/fetchChartData`, payload);
    return data;
  } catch (e: any) {
    return e.response;
  }
};

/**
 * Fetches filter configuration for filters component population
 * @returns axios object
 */
export const fetchFilters = async (payload) => {
  try {
    const data = await axios.post(
      '/api/adAccount/fetchFilterConfigurationV2',
      payload
    );
    return data;
  } catch (e: any) {
    throw e;
  }
};

/**
 * send the fb pixel data on completion of the user registration if fbp and fbc values are captured
 * @returns axios object
 */
export const sendFbPixelToServer = async (payload) => {
  try {
    const data = await axios.post(
      '/api/user/analytics/info',
      payload
    );
    return data;
  } catch (e: any) {
    throw e;
  }
};
