export class ExpressionParams {

    start: number = 0;
    end: number = 0;
    relativeStart: number = 0;
    relativeEnd: number = 0;
    superFrameNumber: number = 0;
    relativeFrameNumber: number = 0;


    public ExpressionParams() {

    }


    public superFrameNumberVal(): number {
        return this.superFrameNumber;
    }

    public i(): string {
        return this.superFrameNumber + "";
    }

    ri(): string {
        return this.relativeFrameNumber + "";
    }

    si(): string {
        return this.start + "";
    }

    ei(): string {
        return this.end + "";
    }

    rsi(): string {
        return this.relativeStart + "";
    }

    rei(): string {
        return this.relativeEnd + "";
    }

    li(): string {
        return (this.end - this.start) + "";
    }

    rli(): string {
        return (this.relativeEnd - this.relativeStart) + "";
    }

    substitute(expression: string): string {
        return expression
            .replace("{{i}}", this.i())
            .replace("{{ri}}", this.ri())
            .replace("{{si}}", this.si())
            .replace("{{rsi}}", this.rsi())
            .replace("{{ei}}", this.ei())
            .replace("{{rei}}", this.rei())
            .replace("{{li}}", this.li())
            .replace("{{rli}}", this.rli())
            ;
    }
}

export const defaultExpressionParams: ExpressionParams = new ExpressionParams();
export class ExpressionParamsBuilder {

    expressionParams: ExpressionParams = new ExpressionParams();

    constructor(expressionParams: ExpressionParams | null) {
        if (expressionParams !== null) {
            this.expressionParams.start = expressionParams.start;
            this.expressionParams.end = expressionParams.end;
            this.expressionParams.relativeStart = expressionParams.relativeStart;
            this.expressionParams.relativeEnd = expressionParams.relativeEnd;
            this.expressionParams.relativeFrameNumber = expressionParams.relativeFrameNumber;
            this.expressionParams.superFrameNumber = expressionParams.superFrameNumber;
        }
    }

    start(start: number): ExpressionParamsBuilder {
        this.expressionParams.start = start;
        return this;
    }

    end(end: number): ExpressionParamsBuilder {
        this.expressionParams.end = end;
        return this;
    }

    relativeStart(relativeStart: number): ExpressionParamsBuilder {
        this.expressionParams.relativeStart = relativeStart;
        return this;
    }


    relativeEnd(relativeEnd: number): ExpressionParamsBuilder {
        this.expressionParams.relativeEnd = relativeEnd;
        return this;
    }


    superFrameNumber(superFrameNumber: number): ExpressionParamsBuilder {
        this.expressionParams.superFrameNumber = superFrameNumber;
        return this;
    }


    relativeFrameNumber(relativeFrameNumber: number): ExpressionParamsBuilder {
        this.expressionParams.relativeFrameNumber = relativeFrameNumber;
        return this;
    }

    params(): ExpressionParams {
        return this.expressionParams;
    }

}