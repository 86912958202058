import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getProjects, deleteTemplateXhr } from "../api/projects-api";

type TemplateTypes = string;
type UserTypes = 'USER';
type SocialEndpoint = 'SNAPCHAT' | 'FACEBOOK';

export interface Template {
  name: string;
  url: string;
  thumbnailUrl: string;
  promoUrl: string;
  description: string;
  isEdited: boolean;
  templateType: TemplateTypes ;
  Id: number;
  type: UserTypes;
  createdAt: string;
  updatedAt: string;
  deleting: boolean | undefined;
  deleteError: string | undefined;
  deleted: boolean | undefined;
}

export interface Campaign {
  socialAdCampaignId: number;
  socialEndpoint: SocialEndpoint;
  userId: number;
  campaignId: string;
  campaignName: string;
  templateId: number;
}

export interface Project {
  template: Template;
  campaigns: Campaign[];
  email: string | null;
}

export interface ProjectsState {
  loading: boolean;
  error: string;
  projects: Project[];
}

export interface Creative {
  templateID: string;
  mediaUrls: string[];
  isFromDropbox: boolean;
  isThumbnailRequired: boolean;
}

const getProjectsThunk = createAsyncThunk(
  'projects/getProjects',
  async (userId, thunkAPI) => {
    const response = await getProjects(0, 10);
    return response.data;
  }
);

const ProjectsSlice = createSlice({
  name: 'projects',
  initialState: {
    loading: true,
    error: '',
    projects: [],
    deletingTemplate: false,
    deleteTemplateError: '',
  } as ProjectsState,
  reducers: {
    getProjectsStart(state) {
      state.loading = true;
      state.error = '';
      state.projects = [];
    },
    getProjectsSuccess(state, action) {
      state.loading = false;
      state.error = '';
      state.projects = action.payload;
    },
    getProjectsError(state, action) {
      state.loading = false;
      state.error = action.payload.error;
      state.projects = [];
    },

    deleteTemplateStart(state, action) {
      const project = state.projects.find(project =>
        project.template.Id === action.payload);
      if (project) {
        project.template.deleting = true;
        project.template.deleteError = '';
        project.template.deleted = false;
      }
    },
    deleteTemplateSuccess(state, action) {
      const project = state.projects.find(project =>
        project.template.Id === action.payload.templateId);
      if (project) {
        project.template.deleting = false;
        project.template.deleteError = '';
        project.template.deleted = true;
      }
    },
    deleteTemplateError(state, action) {
      const project = state.projects.find(project => project.template.Id === action.payload );
      if (project) {
        project.template.deleting = false;
        project.template.deleteError = action.payload.error;
        project.template.deleted = false;
      }
    },
  },
});

export const {
  getProjectsStart,
  getProjectsSuccess,
  getProjectsError,
  deleteTemplateStart,
  deleteTemplateSuccess,
  deleteTemplateError
} = ProjectsSlice.actions;

export const fetchProjects = (search: string | undefined) => async dispatch => {
  try {
    dispatch(getProjectsStart());
    const projects = await getProjects(0, 0, search);
    dispatch(getProjectsSuccess(projects));
  } catch (err) {
    dispatch(getProjectsError(err));
  }
};

export const deleteTemplate = (templateId: number) => async dispatch => {
  try {
    dispatch(deleteTemplateStart(templateId));
    const response = await deleteTemplateXhr(templateId);
    dispatch(deleteTemplateSuccess({templateId, response}));
    dispatch(fetchProjects(""));
  } catch (e) {
    dispatch(deleteTemplateError(e));
  }
};

export default ProjectsSlice.reducer;
