import React from 'react';
import LandingLayout from './Layout';

interface IAfterHeader {
  title: string;
  caption: string;
  smallCaption?: boolean;
  children?: JSX.Element;
}

const AfterHeader: React.FC<IAfterHeader> = ({
  title,
  caption,
  children,
  smallCaption = false
}) => {

  return (
    <div className="after-header-container">
      <div className="layout-container after-header">
        <div className="head-pane">
          <div className="title">{title}</div>
          <div className={`description ${smallCaption ? 'small' : ''}`}>
            {caption}
          </div>
        </div>
        <div className="content-pane">
          {children}
        </div>
      </div>
    </div>
  );
};

export default AfterHeader;
