import React, { useContext } from 'react';
import { LI_TRACKING_IDS, TrackLIEvent } from '../../common/LinkedInEventTracker';
import AfterHeader from './AfterHeader';
import { LandingContext } from './LandingContext';
import LandingLayout from './Layout';

const Capabilities: React.FC = () => {

  const { setShowContactModal } = useContext(LandingContext);

  return (
    <LandingLayout>
      <div className="capabilities">
        <AfterHeader
          smallCaption={true}
          title="Capabilities"
          caption="Three key pillars power your growth via ad excellence."
        >
          <button
            data-ga-event="click"
            data-ga-data={'Boost Your Growth'}
            data-ga-action="ShowContactModal"
            type="button"
            className="btn v2"
            onClick={() => {
              TrackLIEvent(LI_TRACKING_IDS.boostGrowth);
              setShowContactModal(true);
            }}
          >
            Boost Your Growth
          </button>
        </AfterHeader>

        <div className="section-container">
          <div className="layout-container section">
            <div className="left-pane">
              <div className="thin-primary-text-alt">Capabilities</div>
              <div className="h2">AI-Powered Creative</div>
              <div className="caption-dark mt-30">
                Generating high-quality assets for digital marketing campaigns is hard to pull off, especially at scale. Companies from pre-seed to post-IPO struggle to power their marketing campaigns with enough creative to learn quickly enough to iterate meaningfully month over month.
              </div>
              <div className="caption-dark mt-20">
                That's where Blitz comes in. We leverage our
                <strong> creator network, proprietary software, and generative AI </strong>
                to create thousands of unique assets for your business. UGC videos for TikTok, polished product shots for Google, scaled influencer deals; we do it all.
              </div>
              <div className="mt-30">
                <button
                  type="button"
                  className="btn v2 hide-imp"
                  onClick={() => {}}
                >
                  Get Creative
                </button>
              </div>
            </div>
            <div className="right-pane">
              <div className="ornament-holder">
                <div className="section-hero-image ornament-for">
                  <div className="hero-image creative"></div>
                </div>
                <div className="ornament bottom-right"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-container ornament-holder">
          <div className="layout-container section reverse ornament-for">
            <div className="left-pane">
              <div className="thin-primary-text-alt">Capabilities</div>
              <div className="h2">Campaign Management</div>
              <div className="caption-dark mt-30">
                All the creative in the world is useless unless it can be deployed effectively. We leverage our
                <strong> in-house tech </strong>
                to expedite campaign setup, and call on the power of
                <strong> machine learning </strong>
                to allocate spend across platforms, consolidate performers and cycle out fatigued assets, test audiences and campaign targets, and try out new campaign structures. Speed is in our DNA - we can set up a new campaign with dozens of assets in just minutes, meaning more results for your business, faster.
              </div>
              <div className="caption-dark mt-20">
                We handle your digital advertising end-to-end, so you can focus on iterating your product and rely on us to manage your growth effectively and strategically with a performance-driven mindset.
              </div>
              <div className="mt-30">
                <button
                  type="button"
                  className="btn v2 hide-imp"
                  onClick={() => {}}
                >
                  Get Campaign Management
                </button>
              </div>
            </div>
            <div className="right-pane">
              <div className="ornament-holder">
                <div className="section-hero-image ornament-for">
                  <div className="hero-image manage-campaign"></div>
                </div>
                <div className="ornament bottom-right"></div>
              </div>
            </div>
          </div>
          <div className="ornament top-left"></div>
          <div className="ornament bottom-right"></div>
        </div>

        <div className="section-container">
          <div className="layout-container section">
            <div className="left-pane">
              <div className="thin-primary-text-alt">Capabilities</div>
              <div className="h2">AI-Driven Insights</div>
              <div className="caption-dark mt-30">
                We use past performance to automatically inform new creative, closing the loop between action and learning and ensuring that each new bet is an assured improvement on the last.
              </div>
              <div className="caption-dark mt-20">
                For clients that want to be involved in the analysis, we provide exhaustive reports on creative, campaign performance, and market trends.  Creative is the most powerful lever a marketer has at their disposal, and we cannot emphasize enough how important it is to understand it. Identifying winning asset components (scripts, text copies, models, visual styles etc), promising audiences, competitor creatives, new platforms, market trends -- these are all key tactics to developing an ever-improving body of creative that drives results for your business.
              </div>
              <div className="mt-30">
                <button
                  type="button"
                  className="btn v2 hide-imp"
                  onClick={() => {}}
                >
                  Get Analytics
                </button>
              </div>
            </div>
            <div className="right-pane">
              <div className="ornament-holder">
                <div className="section-hero-image ornament-for">
                  <div className="hero-image analytics"></div>
                </div>
                <div className="ornament bottom-right"></div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </LandingLayout>
  );
};

export default Capabilities;
