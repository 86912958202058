import Axios from "axios";

export interface IGlobalFont {
  Id: number;
  name: string;
  type: string;
  url: string;
}

export async function getTeamAssetsXhr() {
  try {
    const { data } = await Axios.get('/api/user/team/assets/all');
    return data;
  } catch (e) {
    console.error('getTeamAssetsXhr failed\n', e);
  }
}

export async function updateLogoXhr(
  assetUrlCode: string,
  assetName: string,
  assetType: string
) {
  try {
    const { data } = await Axios.post('/api/user/team/assets/endcap', {
      assetUrlCode,
      assetName,
      assetType,
    });
    return data;
  } catch (e) {
    console.error('updateLogoXhr failed\n', e);
  }
}

export async function addColorXhr(
  assetUrlCode: string,
  assetName: string
) {
  try {
    const { data } = await Axios.post('/api/user/team/assets/color', {
      assetUrlCode,
      assetName,
    });
    return data;
  } catch (e) {
    console.error('addColorXhr failed\n', e);
  }
}

export async function deleteColorXhr(colorId: number) {
  try {
    const { data } = await Axios.delete(`/api/user/team/assets/color/${colorId}`);
    return data;
  } catch (e) {
    console.error('deleteColorXhr failed\n', e);
  }
}

export async function makeColorDefaultXhr(assetId: number) {
  try {
    const { data } = await Axios.put(`/api/user/team/assets/color/default/${assetId}`);
    return data;
  } catch (e) {
    console.error('makeColorDefaultXhr failed\n', e);
  }
}

export async function addFontXhr(
  name: string,
  url: string
) {
  try {
    const { data } = await Axios.post('/api/user/team/assets/font', {
      name,
      url,
      isTeamWide: true,
    });
    return data;
  } catch (e) {
    console.error('addFontXhr failed\n', e);
  }
}

export async function deleteFontXhr(assetId: number) {
  try {
    const { data } = await Axios.delete(`/api/user/font/${assetId}`);
    return data;
  } catch (e) {
    console.error('deleteFontXhr failed\n', e);
  }
}

export async function makeFontDefaultXhr(assetId: number) {
  try {
    const { data } = await Axios.put(`/api/user/team/assets/font/default/${assetId}`);
    return data;
  } catch (e) {
    console.error('makeFontDefaultXhr failed\n', e);
  }
}

export async function getGlobalFontsXhr(): Promise<undefined | IGlobalFont[]> {
  try {
    const { data } = await Axios.get('/api/admin/fonts');//api/user/globalfonts');
    return data;
  } catch (e) {
    console.error('getGlobalFontsXhr failed\n', e);
  }
}
