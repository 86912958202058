import axios from 'axios';

/**
 * Returns plans for given group id
 * @returns axios object
 */
 export const getPlansByGroup = async (groupId) => {
  if (!groupId) {
    return;
  }
  try {
    let url = '/api/subscriptions/plans';
    const storedCouponData = JSON.parse(localStorage.getItem('couponData') || '{}' );
    if (storedCouponData.couponCode) {
      var couponCode = storedCouponData.couponCode;
      const expirationDate = storedCouponData.expirationDate;
      if (expirationDate < Date.now()) {
        // Coupon has expired
      } else {
        url += '?couponCode=' + couponCode;
      }
    }


    const data = await axios.get(url);
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * Creates new user and an subscribe a plan
 * @returns axios object
 */
 export const subscribeAndLogin = async (payload) => {
  // const data = {
  //   token: "StriptokenID",
  //   planId: "planId",
  //   userName : "emailID"
  // };
  if (!payload.token || !payload.planId || !payload.userName) {
    throw 'Bad Request';
  }
  try {
    const data = await axios.post('/api/stripe/subscribeAndLogin', payload);
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * Returns plans for given group id
 * @returns axios object
 */
export const getUserPlan = async () => {
  try {
    const data = await axios.get(`/api/user/subscription/info`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const getPaymentHistory = async () => {
  try {
    const data = await axios.get(`/api/user/subscription/invoices`);
    return data;
  } catch (e) {
    throw e;
  }
};
