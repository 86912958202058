import axios from 'axios';
import { Loader, Modal } from 'frontend-core';
import React, { ChangeEvent, Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react';
import { TrackFBPixel } from '../../common/FBPixelTracker';
import { LI_TRACKING_IDS, TrackLIEvent } from '../../common/LinkedInEventTracker';
import { ICustomWindow } from '../../common/model';
import Events from "../creativeLanding/Events";

declare const window: ICustomWindow;
// const captchaKey = process.env.NODE_ENV === 'production';
const captchaKey = window.location.hostname === 'www.useblitz.com' ?
  '6LcvM6MZAAAAAIAMVpKaBNE_fa0IcX78dAD8HwJm' :
  '6LcuZqUZAAAAAOYv3eobTn_1_zwn6Au-GDOASCN6';

const loadReCaptchaScript = () => {
  const s = document.createElement('script');
  s.src = `https://www.google.com/recaptcha/api.js?render=${captchaKey}`;
  document.querySelector('body')?.appendChild(s);
};

interface IContactModal {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

interface IContactUsForm {
  name: string;
  email: string;
  company: string;
  message: string;
  adBudget: string;
  nameError?: string;
  emailError?: string;
  companyError?: string;
  messageError?: string;
  adBudgetError?: string;
  [key: string]: string | any;
}

const ContactModal: React.FC<IContactModal> = ({ show, setShow }) => {

  const isFormValid = (contactForm: IContactUsForm, setContactForm: Function) => {
    const newForm = JSON.parse(JSON.stringify(contactForm));
    newForm.nameError = "";
    newForm.emailError = "";
    newForm.companyError = "";
    newForm.messageError = "";
    newForm.phoneError = "";
    newForm.productTypeError = "";
    newForm.marketingBudgetError = "";
    if (!newForm.name) {
      newForm.nameError = "Name is required.";
    }
    if (newForm.name.length > 30) {
      newForm.nameError = "Name should be less than 30 characters.";
    }
    if (!isEmailValid(newForm.email)) {
      newForm.emailError = "Email is invalid.";
    }
    if (newForm.company.length === 0) {
      newForm.companyError = "Company name is required.";
    }
    if (newForm.company.length > 50) {
      newForm.companyError = "Company should be less than 50 characters.";
    }
    const validationInfo = {
      formInputs: newForm,
      state: true,
    }
    if (newForm.nameError || newForm.emailError || newForm.companyError || newForm.messageError) {
      validationInfo.state = false;
    }
    setContactForm(newForm);
    return validationInfo;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setFormIsValid: Function,
    contactForm: IContactUsForm, setContactForm: Function
  ) => {
    const newForm = JSON.parse(JSON.stringify(contactForm));
    newForm[e.target.name] = e.target.value;
    setContactForm(newForm);
    const valid = isFormValid(newForm, setContactForm);
    setFormIsValid(valid.state);
  };

  const form: IContactUsForm = {
    name: '',
    email: '',
    adBudget: '',
    company: '',
    message: '',
    nameError: '',
    emailError: '',
    companyError: '',
    messageError: '',
    adBudgetError: '',
  };
  const [contactForm, setContactForm] = useState(form);
  const [formIsValid, setFormIsValid] = useState(false);
  const [backendError, setBackendError] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [submittedOnce, setSubmittedOnce] = useState(false);


  const contactCall = (e) => {
    TrackLIEvent(LI_TRACKING_IDS.connectWithUs);
    submitContactForm(
      e,
      setBackendError,
      setRequestInProgress,
      setShowSuccessMessage,
      contactForm,
      setContactForm,
      () => {},
      setSubmittedOnce
    );
  };

  const isEmailValid = (email: string) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const submitContactForm = (e: FormEvent, setBackendError: Function, setRequestInProgress: Function,
    showSuccessMessage: Function, contactForm: IContactUsForm, setContactForm: Function,
    showCustomPlanEnquirySuccess: Function, setSubmittedOnce: Function) => {
    e.preventDefault();
    Object.keys(contactForm).forEach((key) => {
      contactForm[key] = contactForm[key].trim();
    });
    setContactForm(JSON.parse(JSON.stringify(contactForm)));
    const validationInfo = isFormValid(contactForm, setContactForm);
    setSubmittedOnce(true);
    if (!validationInfo.state) {
      return;
    }
    setRequestInProgress(true);
    window.grecaptcha.ready(function() {
      try {
        window.grecaptcha.execute(captchaKey, {action: 'submit'})
        .then(function(token: any) {
          const data: IContactUsForm = JSON.parse(JSON.stringify(contactForm));
          Events.track('Lead', {
            name: data.name || "",
            email: data.email || "",
            company: data.company || "",
            adBudget: data.adBudget || "",
            message: data.message || "",
          });
          data.token = token;
          data.queryParamStr = localStorage.getItem("SIGNUPPARAMS") || '';
          delete data.nameError;
          delete data.emailError;
          delete data.companyError;
          delete data.messageError;

          if (window['contactOpenSource']) {
            data.source = window['contactOpenSource'];
          }

          const endpoint = 'https://www.smartblitz.ai/api/contactus';
          //console.log("url-> "+endpoint);
          axios
            .post(endpoint, data)
            .then(response => {
              const data: IContactUsForm = JSON.parse(JSON.stringify(contactForm));
              TrackFBPixel('Contact', {
                name: data.name,
                email: data.email
              });

              setBackendError("");
              setRequestInProgress(false);
              window.gaTrack("request_consultation" ,{
                name: data.name || "",
                email: data.email || "",
                phone: data.phone || "",
                company: data.company || "",
                productType: data.productType || "",
                marketingBudget: data.marketingBudget || "",
                adBudget: data.adBudget || "",
                message: data.message || ""
              });
              const resettedForm: IContactUsForm = {
                name: "",
                email: "",
                adBudget: "",
                company: "",
                queryParamStr: "",
                message: "",
                nameError: "",
                emailError: "",
                companyError: "",
                messageError: "",
              };
              setContactForm(resettedForm);
              if (localStorage.getItem('isSubscribeModalClosable')) {
                showCustomPlanEnquirySuccess(false);
              }

              if (window.reportContactUsConversion) {
                window.reportContactUsConversion();
              }

              showSuccessMessage("Thanks for contacting us. We will get back to you shortly.");
            })
            .catch(error => {
              console.error(error);
              setBackendError(error.response.data);
              setRequestInProgress(false);
              showSuccessMessage("Couldn't send enquiry. Please try again.");
            });

        })
        .catch((e: any) => {
          console.error('grecaptcha then then', e);
        });
      } catch (e) {
        console.error('grecaptcha e', e);
      }
    });
  };

  useEffect(() => {
    loadReCaptchaScript();
  }, []);

  return (
    <Modal
      show={show}
      showClose={true}
      setShow={setShow}
      className="landing-modal"
      title={(
        <div className="h2">Connect with us</div>
      )}
    >
      {!showSuccessMessage && (
        <div className="landing-form">
          <div className="landing-form-control">
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={contactForm.name}
              className={(contactForm.nameError && submittedOnce) ? "input-error" : "contact-input"}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {handleChange(e, setFormIsValid, contactForm, setContactForm)}}
              required
            />
            <input
              type="text"
              name="company"
              placeholder="Company"
              value={contactForm.company}
              className={(contactForm.companyError && submittedOnce) ? "input-error" : "contact-input"}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {handleChange(e, setFormIsValid, contactForm, setContactForm)}}
              required
            />
          </div>
          <div className="landing-form-control">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={contactForm.email}
              className={(contactForm.emailError && submittedOnce) ? "input-error" : "contact-input"}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {handleChange(e, setFormIsValid, contactForm, setContactForm)}}
              required
            />
            <input
              type="text"
              name="adBudget"
              placeholder="Ad Budget"
              value={contactForm.adBudget}
              className={(contactForm.adBudgetError && submittedOnce) ? "input-error" : "contact-input"}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {handleChange(e, setFormIsValid, contactForm, setContactForm)}}
            />
          </div>
          <div className="landing-form-control">
            <textarea
              name="message"
              id="message"
              placeholder="Message"
              value={contactForm.message}
              className={(contactForm.messageError && submittedOnce) ? "contact-message-error full-width" : "full-width contact-input"}
              // helperText={contactForm.userNameError}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {handleChange(e, setFormIsValid, contactForm, setContactForm)}}
              required
            >{contactForm.message}</textarea>
          </div>
          <div style={{textAlign: 'center'}} className="blitz-premium-page">
            <div>
              {(backendError && submittedOnce) &&
                <div className="error-text">
                  {backendError}
                </div>
              }
              {(contactForm.nameError && submittedOnce) &&
                <div className="error-text">
                  {contactForm.nameError}
                </div>
              }
              {(contactForm.emailError && submittedOnce) &&
                <div className="error-text">
                  {contactForm.emailError}
                </div>
              }
              {(contactForm.companyError && submittedOnce) &&
                <div className="error-text">
                  {contactForm.companyError}
                </div>
              }
              {(contactForm.messageError && submittedOnce) &&
                <div className="error-text">
                  {contactForm.messageError}
                </div>
              }
              {(contactForm.marketingBudgetError && submittedOnce) &&
                <div className="error-text">
                  {contactForm.marketingBudgetError}
                </div>
              }
              {(contactForm.phoneError && submittedOnce) &&
                <div className="error-text">
                  {contactForm.phoneError}
                </div>
              }
              {(contactForm.productTypeError && submittedOnce) &&
                <div className="error-text">
                  {contactForm.productTypeError}
                </div>
              }
            </div>
          </div>
          <div className="mt-40 mb-20">
            <button
              data-ga-event="click"
              data-ga-data={JSON.stringify(contactForm)}
              data-ga-action="ContactSubmit"
              type="button"
              onClick={contactCall}
              className="btn v2 submit"
              disabled={(!formIsValid || requestInProgress) && submittedOnce}
            >
              Submit
            </button>
          </div>
          <div className="note mb-20">
            This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.
          </div>
          <Loader isLoading={requestInProgress} />
        </div>
      )}
      {showSuccessMessage &&
        <p className="h3 contact-page-success text-center">
          {showSuccessMessage}
        </p>
      }
    </Modal>
  );
};

export default ContactModal;
