import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AfterHeader from '../AfterHeader';
import LandingLayout from '../Layout';
import Posts, { IBlogPost } from './Posts';
import Routes from '../../../common/routes';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { shuffleArray } from '../../../common/utils';
import RenderPosts from './RenderPosts';
import Authenticate from '../../Authenticate';
import { AppContext } from '../../../appContext';

const BlogPost: FC = () => {

  const params = useParams();
  const navigate = useNavigate();
  const { authModal } = useContext(AppContext);
  const [post, setPost] = useState<IBlogPost | null>(null);

  const getBlogPost = () => {
    const { slug } = params;
    const blogPost = Posts.find(p => p.slug === slug);
    if (blogPost) {
      setPost(blogPost);
    } else {
      navigate(Routes.BLOG);
    }
  };

  const getRandomPosts = useCallback(() => {
    const { slug } = params;
    const posts = shuffleArray(Posts.filter(p => p.slug !== slug));
    return [posts[0], posts[1], posts[2]];
  }, []);

  useEffect(() => {
    getBlogPost();
  }, []);

  useEffect(() => {
    getBlogPost();
  }, [params.slug]);

  return (
    <LandingLayout>
      <>
        {post && (
          <Helmet>
            <title>{post.title}</title>
            <meta name="description" content={`${post.subHead} - ${post.title}`} />
          </Helmet>
        )}

        <AfterHeader
          title="Resources"
          caption="Blitz Blog"
        />

        <div className="blog">
          {post?.image && (
            <div className="blog-hero-image">
              <Link to={Routes.BLOG} className="back-link">&lt; Back</Link>
              <img src={post.image} />
            </div>
          )}
          {post && (
            <div className="blog-content">
              <div className="blog-details">
                <div className="author">
                  {post.authorImage && (
                    <div
                      className="author-image"
                      style={{ backgroundImage: `url(${post.authorImage})` }}
                    ></div>
                  )}
                  By {post.author}
                </div>
                <div className="date">
                  {post.date}
                </div>
              </div>
              <div className="sub-head">{post.subHead}</div>
              <h2 className="title">{post.title}</h2>
              {post.content}
            </div>
          )}

          <div className="h-rule mt-50 mb-50" />

          <RenderPosts posts={getRandomPosts()} className="jc-center" />

        </div>

        <Authenticate
          type={authModal.linkType}
          updateProp={authModal.setLinkType}
          setIsModalVisible={authModal.setIsModalVisible}
        />
      </>
    </LandingLayout>
  )
};

export default BlogPost;
