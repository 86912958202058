
export class Margin {
    private  top:number = 0 ;
    private  bottom:number = 0;
    private  left:number = 0;
    private  right:number = 0;

    constructor(top:number,bottom:number,left:number, right:number){
        this.top = top;
        this.bottom = bottom;
        this.left = left;
        this.right = right;
    }

    getTop():number {
        return this.top;
    }

    public setTop(top:number) {
        this.top = top;
    }

    public getBottom():number {
        return this.bottom;
    }

    public setBottom(bottom:number) {
        this.bottom = bottom;
    }

    public getLeft():number {
        return this.left;
    }

    public setLeft(left:number) {
        this.left = left;
    }

    public getRight():number {
        return this.right;
    }

    public setRight(right:number) {
        this.right = right;
    }
}
