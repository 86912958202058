const metricWidths = {
  id: 53,
  status: 72,
  name: 432,
  thumbnailUrl: 80,
  breakdownValue: 162,
  objective: 132,
  strategy: 112,
  dailyBudget: 132,
  amountSpent: 132,
  purchaseConversionValue: 102,
  purchases: 115,
  costPerUniquePurchases: 112,
  purchaseValueByUniquePurchases: 166,
  purchaseROAS: 85,
  trialsStarted: 90,
  costPerStartTrial: 112,
  impressions: 112,
  clicks: 82,
  appInstalls: 82,
  cpm: 82,
  cpc: 82,
  ctr: 117,
  cpp: 112,
  costPerAppInstall: 100,
  installRate: 107,
  createdTime: 232,
  updatedTime: 232,
  stopTime: 232,
  totalPurchaseValue: 128,
  reach: 115,
  frequency: 115,
  uniqueTrialsStarted: 150,
  uniqueAppInstalls: 150,
  campaignId: 170,
  adId: 170,
  adSetId: 170,
  creativeId: 170,
  accountId: 170,
  commonWidth: 150,
  delivery: 80,
  socialPlatform: 150,
  creativeName: 220,
};

export default metricWidths;
