import { JsonObject, JsonProperty } from "json2typescript";
import { Component } from "./Component";
import { VideoPlatform } from "../templates/VideoPlatform";
import { alpha, alphaDecimalVal, evaluate, getRaiusFromArcMetrics, min, roundToOne, roundToTwo } from "../../Common/MathExpressionStatement";
import { Margin } from "../../Common/Margin";
import { Pair } from "../inputs/Pair";
import { DimensionInputType } from "../inputs/DimensionInputType";


@JsonObject("TextComponent")
export class TextComponent extends Component {

    @JsonProperty("text", String)
    public text: string = "Hello";

    @JsonProperty("textColor", String)
    public textColor: string = "#00000000";


    @JsonProperty("textFontName", String)
    textFontName: string = '';

    @JsonProperty("textSize", String)
    textSize: string = '';

    @JsonProperty("fontWeightVal", String)
    fontWeightVal: string = '';

    @JsonProperty("containerColor", String)
    public containerColor: string = "#00000000"

    @JsonProperty("isItalic", String)
    public isItalic: string = "false";

    @JsonProperty("lineSpacing", String)
    lineSpacing: string = "5";


    @JsonProperty("marginTop", String)
    marginTop: string = "0";

    @JsonProperty("marginBottom", String)
    marginBottom: string = "0";


    @JsonProperty("marginEnd", String)
    marginEnd: string = "0";


    @JsonProperty("marginStart", String)
    marginStart: string = "0";



    @JsonProperty("paddingTop", String)
    paddingTop: string = "0";

    @JsonProperty("paddingBottom", String)
    paddingBottom: string = "0";


    @JsonProperty("paddingRight", String)
    paddingRight: string = "0";


    @JsonProperty("paddingLeft", String)
    paddingLeft: string = "0";

    @JsonProperty("containerArchHeight", String)
    containerArchHeight: string = "0";

    @JsonProperty("containerArchWidth", String)
    containerArchWidth: string = "0";

    @JsonProperty("isStrikeThrough", String)
    isStrikeThrough: string = "false";

    @JsonProperty("alignmentType", String)
    alignmentType: string = "LEFT_TOP";

    @JsonProperty("containerType", String)
    containerType: string = "REGULAR";

    @JsonProperty("textOverFlowType", String)
    textOverFlowType: string = "TOP";



    lineSpacingVal(previewMultiplier: number): number {
        try {
            let val = roundToTwo(evaluate(this.lineSpacing) * (previewMultiplier));
            if (val <= 0) {
                val = 1;
            }
            return val;
        } catch (e) {
            return 0;
        }
    }



    containerArcHeightVal(previewMultiplier: number): number {
        try {
            return roundToTwo(evaluate(this.containerArchHeight) * (previewMultiplier));
        } catch (e) {
            return 0;
        }
    }


    containerArchWidthVal(previewMultiplier: number): number {
        try {
            return roundToTwo(evaluate(this.containerArchWidth) * (previewMultiplier));
        } catch (e) {
            return 0;
        }
    }


    public marginTopVal(previewMultiplier: number): number {
        try {
            return evaluate(this.marginTop) * (previewMultiplier);
        } catch (e) {
            return 0;
        }
    }


    public marginStartVal(previewMultiplier: number): number {
        try {
            return evaluate(this.marginStart) * (previewMultiplier);
        } catch (e) {
            return 0;
        }
    }


    public marginBottomVal(previewMultiplier: number): number {
        try {
            return evaluate(this.marginBottom) * (previewMultiplier);
        } catch (e) {
            return 0;
        }
    }


    public marginEndVal(previewMultiplier: number): number {
        try {
            return evaluate(this.marginEnd) * (previewMultiplier);
        } catch (e) {
            return 0;
        }
    }


    public paddingTopVal(previewMultiplier: number): number {
        try {
            return evaluate(this.paddingTop) * (previewMultiplier);
        } catch (e) {
            return 0;
        }
    }


    public paddingStartVal(previewMultiplier: number): number {
        try {
            return evaluate(this.paddingLeft) * (previewMultiplier);
        } catch (e) {
            return 0;
        }
    }


    public paddingBottomVal(previewMultiplier: number): number {
        try {
            return evaluate(this.paddingBottom) * (previewMultiplier);
        } catch (e) {
            return 0;
        }
    }


    public paddingEndVal(previewMultiplier: number): number {
        try {
            return evaluate(this.paddingRight) * (previewMultiplier);
        } catch (e) {
            return 0;
        }
    }

    public margin(previewMultiplier: number): Margin {
        return new Margin(this.marginTopVal(previewMultiplier),
            this.marginBottomVal(previewMultiplier),
            this.marginStartVal(previewMultiplier),
            this.marginEndVal(previewMultiplier));
    }



    public textPadding(previewMultiplier: number): Margin {
        return new Margin(this.paddingTopVal(previewMultiplier),
            this.paddingBottomVal(previewMultiplier),
            this.paddingStartVal(previewMultiplier),
            this.paddingEndVal(previewMultiplier));
    }


    public isStrikeThroughVal(): boolean {
        try {
            return this.isStrikeThrough === 'true';
        } catch (e) {
            return false;
        }
    }

    componentWidthAsFloat(previewMultiplier: number, componentParentImage: HTMLCanvasElement | null): number {
        if (this.widthInput == DimensionInputType.MATCH_PARENT && componentParentImage != null) {
            return componentParentImage.width;
        }
        let componentWidth = 0;
        try {
            componentWidth = evaluate(this.componentWidth) * (previewMultiplier);
        } catch (e) {
        }
        return componentWidth + this.deltaWidth * previewMultiplier;
    }

    componentHeightAsFloat(previewMultiplier: number, componentParentImage: HTMLCanvasElement | null): number {
        if (this.heightInput == DimensionInputType.MATCH_PARENT && componentParentImage != null) {
            return componentParentImage.height;
        }
        let componentHeight = 0;
        try {
            componentHeight = evaluate(this.componentHeight) * (previewMultiplier);
        } catch (e) {
        }
        return componentHeight + this.deltaHeight * previewMultiplier;
    }

    generateRandomString(length: number = 10): string {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let randomString = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomString += characters[randomIndex];
        }

        return randomString;
    }

    convertToAlphabetsOnly(inputString) {
        const alphanumericOnly = inputString.replace(/[^A-Za-z]/g, '');
        const length = alphanumericOnly.length;
        const startIndex = length <= 30 ? 0 : length - 30;
        return alphanumericOnly.slice(startIndex, length);
    }



    async drawComponent(frameNumber: number, previewMultiplier: number, parentImage: HTMLCanvasElement | null, videoPlatform: VideoPlatform): Promise<HTMLCanvasElement> {
        let componentWidth: number = this.componentWidthAsFloat(previewMultiplier, parentImage);
        let componentHeight: number = this.componentHeightAsFloat(previewMultiplier, parentImage);
        let canv: HTMLCanvasElement = this.canvas(componentWidth, componentHeight);
        canv.style.visibility = 'hidden';
        canv.style.position = 'absolute';
        document.body.append(canv);

        try {

            const ctx1 = canv.getContext('2d');

            if (ctx1 == null) {
                return this.image(canv);
            }
            const font = this.textFontName;
            let fontFamily = this.convertToAlphabetsOnly(this.textFontName);
            if (document.fonts.check(`1em ${fontFamily}`)) {
                //DO NOTHING
            }
            else {
                const fontFace = new FontFace(fontFamily, `url(${font})`);
                (document as any).fonts.add(fontFace);
                await fontFace.load();
            }
            let ctx: CanvasRenderingContext2D = ctx1;
            let isBoldVal = this.fontWeightVal.toLowerCase() === 'bold';
            // let letterSpacing = roundToOne(0.8 * (evaluate(this.textSize) / 60.0) * (isBoldVal ? 1 : 4 / 9));
            // ctx.canvas.style.letterSpacing = letterSpacing + "px";
            let fontVal: string = (this.isItalic === "true" ? 'italic ' : "") + (isBoldVal ? "900" : "400") + ' ' + evaluate(this.textSize) + 'px ' + fontFamily + '';

            ctx.font = fontVal;
            ctx.textBaseline = 'middle';
            let allLines = this.text.split("\n");
            let textPadding = this.textPadding(previewMultiplier);
            let margin = this.margin(previewMultiplier);

            let lines: string[] = [];
            for (let i = 0; i < allLines.length; i++) {
                let line = allLines[i];
                lines.push.apply(lines, this.wrapText(ctx, line, componentWidth - (textPadding.getLeft() + textPadding.getRight() + margin.getLeft() + margin.getRight())));
            }

            {
                let fillStyle = this.containerColor.startsWith("#") ? this.containerColor : "#" + this.containerColor;
                let fillStyleColor = fillStyle.length === 9 ? "#" + fillStyle.slice(3) : fillStyle;
                ctx.fillStyle = fillStyleColor;
                ctx.globalAlpha = alphaDecimalVal([fillStyle]);

            }

            this.drawTextContainer(lines, previewMultiplier, ctx, componentWidth, componentHeight);
            {
                let fillStyle = this.textColor.startsWith("#") ? this.textColor : "#" + this.textColor;
                let fillStyleColor = fillStyle.length === 9 ? "#" + fillStyle.substring(3) : fillStyle;
                ctx.fillStyle = fillStyleColor;
                ctx.globalAlpha = alphaDecimalVal([fillStyle]);
            }
            let lineSpacing = this.lineSpacingVal(previewMultiplier);
            let marginHorizontalDiff = margin.getLeft() - margin.getRight();
            let textLayout: TextMetrics = ctx.measureText("TEST");
            let textAscent = textLayout.fontBoundingBoxAscent;
            let textDescent = textLayout.fontBoundingBoxDescent;
            let leading = 0.0;
            switch (this.textOverFlowType) {
                case "TOP":
                    {
                        let heightOfSubtext = (lines.length - 1) * (textAscent + textDescent + leading + lineSpacing);
                        for (let i = 0; i < lines.length; i++) {
                            let line = lines[i];
                            ctx.beginPath();
                            ctx.moveTo(0, 0);
                            let textLayout = ctx.measureText(line);
                            let width = textLayout.width;
                            let height = textLayout.fontBoundingBoxAscent + textLayout.fontBoundingBoxDescent;
                            let centerX = (componentWidth - width) / 2.0;
                            let centerY = (componentHeight - height) / 2.0 + textLayout.fontBoundingBoxAscent;
                            let marginVerticalDiff = margin.getTop() - margin.getBottom() - heightOfSubtext;
                            let point = this.getDrawingCordinate(centerX, centerY, marginHorizontalDiff, marginVerticalDiff, width, textAscent, textDescent, componentWidth, componentHeight);
                            ctx.fillText(line, point._1, point._2);
                            if (this.isStrikeThroughVal()) {
                                ctx.fillRect(point._1, point._2, textLayout.width, (this.fontWeightVal.toLowerCase() === 'bold' ? 9 : 4));
                            }
                            heightOfSubtext = heightOfSubtext - (textLayout.fontBoundingBoxAscent + textLayout.fontBoundingBoxDescent) - lineSpacing;
                        }

                    }
                    break;
                case "BOTTOM":
                    {
                        let heightOfSubtext = 0;
                        for (let i = 0; i < lines.length; i++) {

                            let line = lines[i];

                            ctx.beginPath();
                            ctx.moveTo(0, 0);
                            let textLayout = ctx.measureText(line);
                            let width = textLayout.width;
                            let height = textLayout.fontBoundingBoxAscent + textLayout.fontBoundingBoxDescent;
                            let centerX = (componentWidth - width) / 2.0;
                            let centerY = (componentHeight - height) / 2.0 + textLayout.fontBoundingBoxAscent;
                            let marginVerticalDiff = margin.getTop() - margin.getBottom() + heightOfSubtext;
                            let point = this.getDrawingCordinate(centerX, centerY, marginHorizontalDiff, marginVerticalDiff, width, textLayout.fontBoundingBoxAscent, textLayout.fontBoundingBoxDescent, componentWidth, componentHeight);
                            ctx.fillText(line, point._1, point._2);
                            if (this.isStrikeThroughVal()) {
                                ctx.fillRect(point._1, point._2, textLayout.width, (this.fontWeightVal.toLowerCase() === 'bold' ? 9 : 4));
                            }
                            heightOfSubtext = heightOfSubtext + textLayout.fontBoundingBoxAscent + textLayout.fontBoundingBoxDescent + lineSpacing;
                        }
                    }
                    break;
                case "CENTER":
                    {
                        let heightOfSubtext = 0;
                        let totalHeight = (lines.length * (textAscent + leading + textDescent + lineSpacing));
                        for (let i = 0; i < lines.length; i++) {
                            let line = lines[i];
                            ctx.beginPath();
                            ctx.moveTo(0, 0);
                            let textLayout = ctx.measureText(line);
                            let width = textLayout.width;
                            let height = textLayout.fontBoundingBoxAscent + textLayout.fontBoundingBoxDescent;
                            let centerX = (componentWidth - width) / 2.0;
                            let centerY = (componentHeight - height) / 2.0 + textLayout.fontBoundingBoxAscent;
                            let marginVerticalDiff = margin.getTop() - margin.getBottom() + heightOfSubtext - totalHeight / 2.0 + textAscent + leading;
                            let point = this.getDrawingCordinate(centerX, centerY, marginHorizontalDiff, marginVerticalDiff, width, textAscent, textDescent, componentWidth, componentHeight);
                            ctx.fillText(line, point._1, point._2);
                            if (this.isStrikeThroughVal()) {
                                ctx.fillRect(point._1, point._2, textLayout.width, (this.fontWeightVal.toLowerCase() === 'bold' ? 9 : 4));
                            }
                            heightOfSubtext = heightOfSubtext + textLayout.fontBoundingBoxAscent + textLayout.fontBoundingBoxDescent + lineSpacing;
                        }
                    }
                    break;
            }

            return this.image(canv);

        }
        finally {
            canv.remove();
        }
    }

    public getDrawingCordinate(centerX: number, centerY: number, marginHorizontalDiff: number, marginVerticalDiff: number, boundWidth: number, textAscent: number, textDescent: number, componentWidth: number, componentHeight: number): Pair<number, number> {
        let point: Pair<number, number> = new Pair(0.0, 0.0);
        switch (this.alignmentType) {
            case "CENTER":
                point.setValues(centerX + marginHorizontalDiff, marginVerticalDiff + centerY);
                break;
            case "LEFT_TOP":
                point.setValues(marginHorizontalDiff, marginVerticalDiff + textAscent);
                break;
            case "LEFT_BOTTOM":
                point.setValues(marginHorizontalDiff, marginVerticalDiff + componentHeight - textDescent);
                break;
            case "RIGHT_TOP":
                point.setValues(marginHorizontalDiff + componentWidth - boundWidth, marginVerticalDiff + textAscent);
                break;
            case "RIGHT_BOTTOM":
                point.setValues(marginHorizontalDiff + componentWidth - boundWidth, marginVerticalDiff + componentHeight - textDescent);
                break;
            case "CENTER_LEFT":
                point.setValues(marginHorizontalDiff, marginVerticalDiff + centerY);
                break;
            case "CENTER_TOP":
                point.setValues(marginHorizontalDiff + centerX, marginVerticalDiff + textAscent);
                break;
            case "CENTER_BOTTOM":
                point.setValues(marginHorizontalDiff + centerX, marginVerticalDiff + componentHeight - textDescent);
                break;
            case "CENTER_RIGHT":
                point.setValues(marginHorizontalDiff + componentWidth - boundWidth, marginVerticalDiff + centerY);
                break;
            default:
                break;

        }
        return point;
    }

    drawTextContainer(texts: string[], previewMultiplier: number, ctx, componentWidth: number, componentHeight: number): void {
        let lineSpacing = this.lineSpacingVal(previewMultiplier);
        let margin = this.margin(previewMultiplier);
        let marginHorizontalDiff = margin.getLeft() - margin.getRight();

        let textPadding = this.textPadding(previewMultiplier);

        let rectX = 999999;
        let rectY = 999999;
        let rectXE = 0;
        let rectYE = 0;
        let containerArchWidth = this.containerArchWidthVal(previewMultiplier);
        let containerArcHeight = this.containerArcHeightVal(previewMultiplier);
        let radius = getRaiusFromArcMetrics(containerArchWidth, containerArcHeight);
        let textLayout: TextMetrics = ctx.measureText("TEST");
        let textAscent = textLayout.fontBoundingBoxAscent;
        let textDescent = textLayout.fontBoundingBoxDescent;
        let leading = 0.0;
        switch (this.textOverFlowType) {
            case "TOP":
                {
                    let heightOfSubtext = (texts.length - 1) * (textAscent + textDescent + leading + lineSpacing);
                    for (let i = 0; i < texts.length; i++) {
                        let text = texts[i];
                        ctx.beginPath();
                        let textLayout: TextMetrics = ctx.measureText(text);
                        let width = textLayout.width;
                        let height = textLayout.fontBoundingBoxAscent + textLayout.fontBoundingBoxDescent;
                        let centerX = (componentWidth - width) / 2.0;
                        let centerY = (componentHeight - height) / 2.0 + textLayout.fontBoundingBoxAscent;
                        let marginVerticalDiff = margin.getTop() - margin.getBottom() - heightOfSubtext;
                        let point: Pair<number, number> = this.getDrawingCordinate(centerX, centerY, marginHorizontalDiff, marginVerticalDiff, width, textAscent, textDescent, componentWidth, componentHeight);
                        if (this.containerType === "FIT") {
                            ctx.fill();
                            ctx.roundRect(point._1 - textPadding.getLeft(),
                                point._2 - textPadding.getTop() - textLayout.fontBoundingBoxAscent,
                                width + textPadding.getRight(),
                                height + textPadding.getBottom(),
                                radius
                            );
                        }
                        ctx.fill();
                        rectX = Math.min(point._1, rectX);
                        rectY = Math.min(point._2, rectY);
                        rectXE = Math.max(rectXE, point._1 + width);
                        rectYE = Math.max(rectYE, point._2);
                        heightOfSubtext = heightOfSubtext - (textAscent + leading + textDescent) - lineSpacing;
                    }
                }
                break;
            case "BOTTOM":
                {
                    let heightOfSubtext = 0;
                    for (let i = 0; i < texts.length; i++) {
                        let text = texts[i];
                        ctx.beginPath();
                        let textLayout: TextMetrics = ctx.measureText(text);
                        let width = textLayout.width;
                        let height = textLayout.fontBoundingBoxAscent + textLayout.fontBoundingBoxDescent;
                        let centerX = (componentWidth - width) / 2.0;
                        let centerY = (componentHeight - height) / 2.0 + textLayout.fontBoundingBoxAscent;
                        let marginVerticalDiff = margin.getTop() - margin.getBottom() + heightOfSubtext;
                        let point: Pair<number, number> = this.getDrawingCordinate(centerX, centerY, marginHorizontalDiff, marginVerticalDiff, width, textLayout.fontBoundingBoxAscent, textLayout.fontBoundingBoxDescent, componentWidth, componentHeight);
                        if (this.containerType === "FIT") {
                            ctx.fill();
                            ctx.roundRect(point._1 - textPadding.getLeft(),
                                point._2 - textPadding.getTop() - textLayout.fontBoundingBoxAscent,
                                width + textPadding.getRight(),
                                height + textPadding.getBottom(),
                                radius
                            );
                        }
                        ctx.fill();
                        rectX = Math.min(point._1, rectX);
                        rectY = Math.min(point._2, rectY);
                        rectXE = Math.max(rectXE, point._1 + width);
                        rectYE = Math.max(rectYE, point._2);
                        heightOfSubtext = heightOfSubtext + textLayout.fontBoundingBoxAscent + textLayout.fontBoundingBoxDescent + lineSpacing;
                    }
                }
                break;
            case "CENTER":

                {
                    let heightOfSubtext = 0;
                    let totalHeight = (texts.length * (textAscent + leading + textDescent + lineSpacing));
                    for (let i = 0; i < texts.length; i++) {
                        let text = texts[i];
                        ctx.beginPath();
                        let textLayout: TextMetrics = ctx.measureText(text);
                        let width = textLayout.width;
                        let height = textLayout.fontBoundingBoxAscent + textLayout.fontBoundingBoxDescent;
                        let centerX = (componentWidth - width) / 2.0;
                        let centerY = (componentHeight - height) / 2.0 + textLayout.fontBoundingBoxAscent;
                        let marginVerticalDiff = margin.getTop() - margin.getBottom() + heightOfSubtext - (totalHeight / 2.0) + textAscent + leading;
                        let point: Pair<number, number> = this.getDrawingCordinate(centerX, centerY, marginHorizontalDiff, marginVerticalDiff, width, textAscent, textDescent, componentWidth, componentHeight);
                        if (this.containerType === "FIT") {
                            ctx.fill();
                            ctx.roundRect(point._1 - textPadding.getLeft(),
                                point._2 - textPadding.getTop() - textLayout.fontBoundingBoxAscent,
                                width + textPadding.getRight(),
                                height + textPadding.getBottom(),
                                radius
                            );
                        }
                        ctx.fill();
                        rectX = Math.min(point._1, rectX);
                        rectY = Math.min(point._2, rectY);
                        rectXE = Math.max(rectXE, point._1 + width);
                        rectYE = Math.max(rectYE, point._2);
                        heightOfSubtext = heightOfSubtext + textLayout.fontBoundingBoxAscent + textLayout.fontBoundingBoxDescent + lineSpacing;
                    }
                }
                break;
        }
        rectY = rectY - textAscent;
        rectYE = rectYE + textDescent + leading;
        if (this.containerType === "REGULAR") {
            ctx.roundRect(rectX - textPadding.getLeft(), rectY - textPadding.getTop(), rectXE - rectX + textPadding.getRight(), rectYE - rectY + textPadding.getBottom(), containerArchWidth, containerArcHeight);
            ctx.fill();
        }

    }

    wrapText(ctx: CanvasRenderingContext2D, text: string, maxWidth: number): string[] {
        // First, start by splitting all of our text into words, but splitting it into an array split by spaces
        let words = text.split(" ");
        let line = ''; // This will store the text of the current line
        let testLine = ''; // This will store the text when we add a word, to test if it's too long
        let lineArray: string[] = []; // This is an array of lines, which the function will return

        // Lets iterate over each word
        for (var n = 0; n < words.length; n++) {
            // Create a test line, and measure it..
            testLine += `${words[n]}`;
            let metrics = ctx.measureText(testLine);
            let testWidth = metrics.width;
            // If the width of this test line is more than the max width
            if (testWidth > maxWidth * 1 && n > 0) {
                // Then the line is finished, push the current line into "lineArray"
                let preLine = line;
                let postLine = "";

                metrics = ctx.measureText(preLine);
                testWidth = metrics.width;
                if (testWidth > maxWidth) {
                    postLine = " "
                    while (preLine.length > 1 && testWidth > maxWidth) {
                        postLine = preLine.substring(preLine.length - 1, preLine.length) + postLine;
                        preLine = preLine.substring(0, preLine.length - 1);
                        metrics = ctx.measureText(preLine);
                        testWidth = metrics.width;
                    }
                }
                lineArray.push(preLine);
                // Increase the line height, so a new line is started
                // Update line and test line to use this word as the first word on the next line
                line = `${postLine}${words[n]}`;
                testLine = `${postLine}${words[n]}`;
            }
            else {
                // If the test line is still less than the max width, then add the word to the current line
                line = testLine;
            }
            // If we never reach the full max width, then there is only one line.. so push it into the lineArray so we return something
            if (n === words.length - 1) {
                lineArray.push(line);
            }
            testLine += ' ';
        }
        // Return the line array
        return lineArray;
    }


}