import { JsonConvert, JsonObject, PropertyConvertingMode } from "json2typescript";
import { Input } from "../render/inputs/Input";
import { VideoPlatform } from "../render/templates/VideoPlatform";
import { Component } from "../render/components/Component";
import { Pair } from "../render/inputs/Pair";
import { convertComponent } from "../render/components/ComponentFactory";

export const jsonConvert: JsonConvert = new JsonConvert();
jsonConvert.ignorePrimitiveChecks = false; // don't allow assigning number to string etc.
jsonConvert.mapUndefinedToNull = true;
jsonConvert.propertyConvertingMode = PropertyConvertingMode.IGNORE_NULLABLE;

export function fromJson<T extends Object>(jsonObject: any, classReference: { new(): T }): T {
    let obj: T = jsonConvert.deserializeObject(jsonObject, classReference);
    if (typeof obj["onObjectConversionFromJson"] === 'function') {
        obj["onObjectConversionFromJson"]();
    }
    return obj;
}

export function toJson(object: Object): any {
    return jsonConvert.serializeObject(object);
}

export function absoluteComponent(component: Component, inputList: Input[], videoPlatform: VideoPlatform): Component {
    let jsonString: string = JSON.stringify(component);
    let variables: Pair<string, string>[] = [];
    for (let i = 0; i < inputList.length; i++) {
        variables = variables.concat(inputList[i].getVariables(videoPlatform));
    }
    inputList.forEach(inp => variables.concat(inp.getVariables(videoPlatform)));
    for (let i = 0; i < variables.length; i++) {
        jsonString = replaceVariablesWithValues(jsonString, variables[i]);
    }
    try {
        return convertComponent(JSON.parse(jsonString));
    }
    catch(e) {
        throw e;
    }
}

function replaceVariablesWithValues(jsonString: string, variable: Pair<string, string>): string {
    if (variable._1 === undefined || variable._2 === undefined) {
        return jsonString;
    }
    let val = jsonString.replace(new RegExp(variable._1, 'g'), variable._2);
    return val;
}
