const graph = {
  colors: ['#ef4834', '#4392f1', '#ffca00', '#a73224', '#280a9f'],
  // colors: ['blue', 'red', 'green', 'orange', '#b9709f', '#218d8f', '#70cdbf','#000000', '#cf7d45', '#63741e', '#2a2014', '#120ba2', '#473287', '#cd093f', '#64bb35', '#1a559e', '#9e9c66', '#b18820', '#a1431c', '#b62e94', '#63abc2', '#546f33', '#e6c646', '#870ce0', '#3fcf71', '#67154c', '#a22db0', '#01c5f3', '#eca8ec'],
  exporting: {
    enabled: true
  },
  legend: {
    enabled: true,
  },
  valueDecimals: 2,
  xAxis: {
    type: 'datetime',
    gridLineWidth: 1,
    ordinal: false,
    // labels: {
    //   align: 'right',
    //   overflow: 'justify'
    // }
  },
  yAxis: {
    min: 0,
    labels: {
      align: 'left',
    }
  },
  rangeSelector: {
    selected: 13,
    // y: 40,
    inputEnabled: false,
    buttons: [
      {
        type : 'hour',
        count : 1,
        text : '1h'
      }, {
        type : 'hour',
        count : 6,
        text : '6h'
      }, {
        type : 'hour',
        count : 12,
        text : '12h'
      }, {
        type : 'day',
        count : 1,
        text : '1d'
      }, {
        type : 'day',
        count : 2,
        text : '2d'
      }, {
        type : 'day',
        count : 3,
        text : '3d'
      }, {
        type : 'week',
        count : 1,
        text : '1w'
      }, {
        type : 'week',
        count : 2,
        text : '2w'
      }, {
        type : 'month',
        count : 1,
        text : '1m'
      },{
        type : 'month',
        count : 2,
        text : '2m'
      },{
        type : 'month',
        count : 3,
        text : '3m'
      }, {
        type : 'month',
        count : 6,
        text : '6m'
      }, {
        type : 'year',
        count : 1,
        text : '1y'
      }, {
        type : 'all',
        text : 'All'
      }
    ]
  },
  credits: {
    enabled: false
  }
};

export default graph;
