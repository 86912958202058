import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { externalLink } from './Posts';

const PostPlatformsNft: FC = () => {

  // {externalLink('', '

  return (
    <div className="blog-post">
      <p>
        If you're looking to market an NFT giveaway, you're going to want to use a performance marketing agency like Blitz to achieve your specific conversion goals through high quality ad creative deployed at scale, detailed performance tracking, and continuous campaign iteration. However, an NFT project launch is also about building your NFT community to incentivize ownership and ensure long-term growth of your project. If you're hoping to make a name for yourself in the NFT world, preminting is a great way to create special incentives for users while building your community at the same time.
      </p>
      <p>
        The {externalLink('https://interexy.com/nft-market-growth/', 'market growth of NFTs')} is skyrocketing: the market size is expected to increase by 360% just from 2022 to 2027. Minting an NFT in general can be quite competitive, and it can be difficult to even know where to begin. Preminting is a new concept in this field that allows for a more seamless minting experience.
      </p>
      <p>
        {externalLink('https://hype.day/', 'HYPE')}, {externalLink('http://www.premint.xyz/', 'PREMINT')}, {externalLink('http://onemint.io/', 'Onemint')}, and {externalLink('http://joinlist.io/', 'Joinlist')} are just some of the platforms in this space. Their software allows you to create an allowlist, also known as whitelists or “premints,” which are a list of wallet addresses collected that allow certain community members a guaranteed spot for minting a new NFT collection.
      </p>

      <h2>Benefits of Preminting</h2>
      <p>
        As a NFT creator, preminting also ensures the creation of a genuine community. One of the biggest issues with NFT sales are
        {externalLink('https://www.kasada.io/nft-bots/', 'malicious bots')} that can manipulate blockchain-based digital assets, including NFTs and cryptocurrencies. As a result, programmed bots can take spots on NFT auctions from genuine users. Preminting is the perfect way to ensure collectors, not bots, are participating in your auctions.
      </p>
      <p>As a NFT collector, preminting gives you access to web3's most popular allowlists. Instead of sifting through thousands of Discord channels and posts, you can track the hottest projects in one spot, ensure access to NFT collection bids, and immerse yourself in an NFT community.</p>

      <h2>Optimizing your Mint</h2>
      <p>
        {externalLink('https://hype.day/', 'HYPE.DAY')} was the first of its kind, founded in June 2022. It is used by NFT artists, communities, brands, and celebrities to build allowlists, giveaways and community pages for top NFT projects. Allowlists are formed after vetting a user by authenticating their crypto wallets, and then creators determine the criteria of what qualifies someone for their project. For example, it could be following the project on Twitter, joining their Discord before a certain date and participating in the project's Discord in meaningful ways, granting them advanced Discord roles, holding an NFT form a collaborating NFT collection in your wallet, having a minimum amount of funds in your wallet, and more. There are three things that are important to users when it comes to allowlists: good prices, guaranteed security, and community. Whether you want to Premint for Solana or Premint for Flow, you've come to the right place.
      </p>

      <h3>Cheapest Premint Alternatives</h3>
      <p>
        PREMINT is another commonly used allow listing platform founded in late 2021; however, it comes at a price. On its website, PREMINT says its holders need at least 0.1 ETH to mint. Online users are looking for cheaper alternatives, one
        {externalLink('https://www.reddit.com/r/NFT/comments/uh760a/i_am_searching_for_an_alternative_for_premintxyz/', 'Reddit user posted')}: “Hey guys, would you happen to know any tool that helps me manage wallet address for an NFT project? Looking for an alternative that is cheaper than premint .xyz.” There are several other PREMINT competitors, but most come with a price.
        {externalLink('http://www.gleam.io/', 'Gleam')} is an example; however, this platform comes with a price starting at $97/month. Similarly, Joinlist is another Premint alternative; their free version is capped at 1,000 entries, and the Pro version is £100. Onemint, another Premint competitor, is also free, but only up to 100 entries, and the cost is $199 to buy more NFT collections.
      </p>
      <p>
        Users want free Premint alternatives, and
        {externalLink('https://hype.day/', 'HYPE')} is the only Premint alternative that is truly free. HYPE can be used in various blockchains: you can Premint for Flow, Solana, Polygon, Avalanche, Klaytn, and Ethereum projects. Especially if you want to mint an NFT in a layer 2 blockchain, where there are already low fees, with HYPE you can Premint for Polygon and Premint for Avalanche.
      </p>

      <h3>Secure NFT Minting</h3>
      <p>
        Security is a huge concern for buyers and sellers alike when minting NFTs. Some transactions can be upwards of millions of dollars. Allowlists are meant to make the minting process easier, not more complicated, but sadly that is not always the reality. On July, 17, 2022, a JavaScript file was manipulated on PREMINT by an unknown third party that led to users being presented with a wallet connection that was malicious. This led to total
        {externalLink('https://cryptoslate.com/nft-platform-premint-users-lose-over-400k-nfts-to-hack/', 'losses of around $400,000')} for users who clicked on that link. While PREMINT has since vowed to make changes to their process to combat this issue and repaid users who lost money, it could take some time for PREMINT to prove its security in the long run. One
        {externalLink('https://twitter.com/DolphinWhale6/status/1549109199208337408', 'user took to Twitter')} saying: “Not sure I trust premint just yet would hate to have a wallet get drained.” Another
        {externalLink('https://twitter.com/CharredJawa/status/1548631361832964097', 'Twitter user noted')}: “The Premint hack is a great reminder you cannot neglect to review every transaction you sign, every time.” PREMINT users could be a bit paranoid using the platform for a while. PREMINT competitors have an opportunity to gain rapport and trust with online users looking to premint NFT Collections.
      </p>

      <h3>NFT Community</h3>
      <p>
        The whole motivation for a transition from Web 2 to Web 3 is to create true community-based internet powered by mass collaboration powered by collective self-interests. Allowlists are meant to bring people together based on NFT Collections they are most interested in. However, some of the authenticating processes to eliminate bots are annoying users. PREMINT users are getting flustered that they have to retweet certain tweets to get access to NFT Collections. One
        {externalLink('https://twitter.com/ogi_eth/status/1555582666908602369', 'Twitter user said')}: “I hate 'rt pinned tweet' when i join some raffles on premint.”
        {externalLink('https://twitter.com/xsgrill', 'Another user said')}: “I feel like projects that rely heavily on premint raffles to fill up their WL [whitelist] attract the wrong type of buyer. As much as I hate discord grinding it at least allowed people that had true interest in the project to connect.” Relying heavily on users mindlessly retweeting random tweets is almost robotic and goes against the underlying theme of web3 of democratization and collaboration.
        {externalLink('https://twitter.com/MoonOwlsNFTs/status/1556671794785943553', 'Moon Owls NFT')} called out PREMINT by saying “Organic growth with real supporters &gt; Premint + fake hype. For the early supporters, we see you.”
      </p>
      <p>Fortunately, innovative allowlist platforms are pushing the envelope on other proof of engagement activities. HYPE authenticates users through Twitter and Discord, and your crypto wallet requires a minimum balance that <strong>project</strong> specifies. HYPE also has unique features such as a Software Development Kit (SDK) and Email Record Capture (ERC), which is a way of collecting emails with the goal of turning those people into customers. Standard email collection would involve getting random emails, but email capture focuses on collecting emails from people with higher odds of becoming customers, or in this case, with a genuine interest in NFT collections. With HYPE, building a real community and preminting are not mutually exclusive.</p>
      <p>Looking at PREMINT vs. various competitors, HYPE brings the best of all three aspects of pricing, security, and community. The ethos of web3 is autonomy, and the Hype's free platform has that at its core: it's accessible to anyone.</p>
    </div>
  );
}

export default PostPlatformsNft;
