import { JsonConverter } from "json2typescript";
import { EnumConverter } from "../../Common/EnumConverter";

export enum EffectType {
    CHROMA,
    GREEN_SCREEN,
    COMPOSITE,
    ALPHA,
    CIRCLE,
    WINDOW,
    MOTION_BLUR,
    BRIGHTNESS,
    FONTSIZE,
    CHARSPACING,
    PREALPHA,
    FREEFALL,
    TEXTWAVE,
    SKEW,
    REVERSE_FREEFALL,
    ZIG_ZAG,
    CIRCULAR_CROP,
    ROUND_CORNER_CROP,
    SIMPLE_LUT,
    RECTANGULAR_LUT,
    CIRCULAR_LUT,
    MASKING
}


export function EffectTypeValueOf(val: string): EffectType {
    switch (val) {
        case "CHROMA": return EffectType.CHROMA;
        case "GREEN_SCREEN": return EffectType.GREEN_SCREEN;
        case "COMPOSITE": return EffectType.COMPOSITE;
        case "ALPHA": return EffectType.ALPHA;
        case "CIRCLE": return EffectType.CIRCLE;
        case "WINDOW": return EffectType.WINDOW;
        case "MOTION_BLUR": return EffectType.MOTION_BLUR;
        case "BRIGHTNESS": return EffectType.BRIGHTNESS;
        case "FONTSIZE": return EffectType.FONTSIZE;
        case "CHARSPACING": return EffectType.CHARSPACING;
        case "PREALPHA": return EffectType.PREALPHA;
        case "FREEFALL": return EffectType.FREEFALL;
        case "TEXTWAVE": return EffectType.TEXTWAVE;
        case "SKEW": return EffectType.SKEW;
        case "REVERSE_FREEFALL": return EffectType.REVERSE_FREEFALL;
        case "ZIG_ZAG": return EffectType.ZIG_ZAG;
        case "CIRCULAR_CROP": return EffectType.CIRCULAR_CROP;
        case "ROUND_CORNER_CROP": return EffectType.ROUND_CORNER_CROP;
        case "SIMPLE_LUT": return EffectType.SIMPLE_LUT;
        case "RECTANGULAR_LUT": return EffectType.RECTANGULAR_LUT;
        case "CIRCULAR_LUT": return EffectType.CIRCULAR_LUT;
        case "MASKING": return EffectType.MASKING;
        default: return EffectType.COMPOSITE;
    }

}





@JsonConverter
export class EffectTypeConverter extends EnumConverter<EffectType> {
  constructor() {
    super(EffectType, "EffectType");
  }
}