import Axios from "axios";

/**
 * Connects the social account to user's account by updating the access token
 * @param platform - string - type of social platform
 * @param payload - object - payload for API
 * @param data - object | any
 */
export const connectSocialAccount = async (platform: string, payload: any) => {
  const url = `/api/${platform}/updateAccessToken`;
  try {
    const { data } = await Axios.post(url, payload);
    return data;
  } catch (e) {
    console.error('Error while connecting social account !\n', e);
  }
};

/**
 * Disconnects the social account to user's account by updating the access token
 * @param platform - string - type of social platform
 * @param payload - object - payload for API
 * @param data - object | any
 */
export const disconnectSocialAccount = async (platform: string, payload: any) => {
  const url = `/api/${platform}/revokeToken`;
  try {
    const { data } = await Axios.post(url, payload);
    return data;
  } catch (e) {
    console.error('Error while disconnecting social account!\n', e);
  }
};
