import React from 'react';
import { Helmet } from 'react-helmet';

function MetaTags () {
  return(
    <Helmet>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <title>Blitz: Create & Manage Video Ads on Facebook & Snapchat Easily</title>
      <meta
        name="description"
        content="Footage in hand, we rapidly design, develop, and deliver hundreds of video creatives."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#2bd2ff" />
      {/* Twitter Card data */}
      <meta name="twitter:card" content="summary" />
      {/* <meta name="twitter:site" content="@OnJoyride" /> */}
      <meta
        name="twitter:title"
        content="Blitz: Create & Manage Video Ads on Facebook & Snapchat Easily"
      />
      <meta
        name="twitter:description"
        content="Footage in hand, we rapidly design, develop, and deliver hundreds of video creatives."
      />
      {/* <meta name="twitter:creator" content="@OnJoyride" /> */}
      {/* Twitter Summary card images must be at least 120x120px */}
      <meta
        name="twitter:image"
        content="https://media.useblitz.com/static/blitz/img/illustration.png"
      />
      {/* Open Graph data */}
      <meta
        property="og:title"
        content="Blitz: Create & Manage Video Ads on Facebook & Snapchat Easily"
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.useblitz.com"
      />
      <meta
        property="og:image"
        content="https://media.useblitz.com/static/blitz/img/illustration.png"
      />
      <meta
        property="og:description"
        content="Footage in hand, we rapidly design, develop, and deliver hundreds of video creatives."
      />
      <meta property="og:site_name" content="useblitz" />
      {/*<meta property="fb:admins" content="Facebook numeric ID" />*/}
      <meta
        name="p:domain_verify"
        content="18aa2f400ae0ab7ee8fd5ddebbec543f"
      />
    </Helmet>
  );
}

export default MetaTags;
