import { fromJson, jsonConvert, toJson } from "../../Common/JsonUtil";
import { CompositeEffect } from "../effects/CompositeEffect";
import { BeakRoundedRectangleComponent } from "./BeakRoundedRectangleComponent";
import { CircularComponent } from "./CircularComponent";
import { CircularImageComponent } from "./CircularImageComponent";
import { Component } from "./Component";
import { ComponentType, ComponentTypeValueOf } from "./ComponentType";
import { ImageComponent } from "./ImageComponent";
import { RectangleComponent } from "./RectangleComponent";
import { RoundedRectangleComponent } from "./RoundedRectangleComponent";
import { TextComponent } from "./TextComponent";

export function convertComponent(comp: Component): Component {
    let elemJson = JSON.parse(JSON.stringify(comp));
    return convertComponentFromJson(elemJson);
}


export function convertComponentFromJson(elemJson: any): Component {
    let type: string = elemJson["componentType"];
    let comp: Component = fromJson(elemJson, Component);
    switch (ComponentTypeValueOf(type)) {
        case ComponentType.SHAPE_RECTANGLE:
            comp = fromJson(elemJson, RectangleComponent);
            break;
        case ComponentType.IMAGE:
            comp = fromJson(elemJson, ImageComponent);
            break;
        case ComponentType.TEXT:
        case ComponentType.INTRO_TEXT:
        case ComponentType.FREEFALL_TEXT:
            comp = fromJson(elemJson, TextComponent);
            break;
        case ComponentType.SHAPE_BEAK_ROUNDED_RECTANGLE:
            comp = fromJson(elemJson, BeakRoundedRectangleComponent);
            break;
        case ComponentType.CIRCULAR_IMAGE:
            comp = fromJson(elemJson, CircularImageComponent);
            break;
        case ComponentType.SHAPE_CIRCLE:
            comp = fromJson(elemJson, CircularComponent);
            break;
        case ComponentType.SHAPE_ROUNDED_RECTANGLE:
            comp = fromJson(elemJson, RoundedRectangleComponent);
    }
    if (comp.subComponents.length > 0) {
        let subComps: Component[] = [];
        if (comp.subComponents.length > 0) {
            for (let i = 0; i < comp.subComponents.length; i++) {
                subComps.push(convertComponent(convertObjToComponent(comp.subComponents[i])));
            }
            comp.subComponents = subComps;
        }
    }
    return comp;
}

export function convertObjToComponent(obj: Object): Component {
    let elemJson: any = obj;
    let type: string = elemJson["componentType"];
    switch (ComponentTypeValueOf(type)) {
        case ComponentType.SHAPE_RECTANGLE:
            return fromJson(elemJson, RectangleComponent);
        case ComponentType.IMAGE:
            return fromJson(elemJson, ImageComponent);
        case ComponentType.SHAPE_BEAK_ROUNDED_RECTANGLE:
            return fromJson(elemJson, BeakRoundedRectangleComponent);
        case ComponentType.TEXT:
        case ComponentType.INTRO_TEXT:
        case ComponentType.FREEFALL_TEXT:
            return fromJson(elemJson, TextComponent);
        case ComponentType.CIRCULAR_IMAGE:
            return fromJson(elemJson, CircularImageComponent);
        case ComponentType.SHAPE_CIRCLE:
            return fromJson(elemJson, CircularComponent);
        case ComponentType.SHAPE_ROUNDED_RECTANGLE:
            return fromJson(elemJson, RoundedRectangleComponent);
    }

    return fromJson(elemJson, Component);

}

export function allComponents(component: Component): Component[] {
    let toReturn: Component[] = [];
    toReturn.push(component);
    if (component.subComponents.length === 0) {
        return toReturn;
    }
    else {
        for (let i = 0; i < component.subComponents.length; i++) {
            toReturn.push.apply(toReturn, allComponents(component.subComponents[i] as Component));
        }
    }
    return toReturn;
}
