import React, { useContext } from 'react';
import { Modal } from 'frontend-core';
import { AppContext } from '../appContext';

function SubscriptionSuccess () {
  const appContext = useContext(AppContext);
  
  const footer = (
    <button
      className="btn"
      onClick={() => appContext.authModal.setShowSuccessModal(false)}
    // autoFocus
    >
      Get Started
    </button>
  );

  return(
    <Modal
      footer={footer}
      title="Subscription Purchased"
      show={appContext.authModal.showSuccessModal}
      onClose={() => appContext.authModal.setShowSuccessModal(false)}
    >
      Congratulations, you have upgraded to a Blitz Premium account &nbsp; 🎉<br/>
      Explore premium features like variant support and watermark-free download and add friends or coworkers to your team &nbsp;👯‍♀️
    </Modal>
  );
}

export default SubscriptionSuccess;
