import React, { useState, useContext, useEffect, useRef } from 'react';
import CheckoutForm from '../../common/subscription/checkout';
import { Modal, InlineLoader, CustomCheckbox, Dropdown, SessionStorage } from 'frontend-core'
import { upgradeSubscription } from '../../api';
import { AppContext } from '../../appContext';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { utils } from '../../common/utils';
import { Permissions } from '../../common/model';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { LI_TRACKING_IDS, TrackLIEvent } from '../../common/LinkedInEventTracker';
import { TrackFBPixel } from '../../common/FBPixelTracker';


const stripePromise = loadStripe(utils.getStripeApiKey());
const SelectSubscriptionModal = (props) => {
  const { 
    user, 
    userPlan, 
    isSelectSubModalOpen, 
    setSelectSubModalOpen,
  } = useContext(AppContext);
  const [modalTitle, setModalTitle] = useState(user?.premiumTier === -1 ? 'Select Plan' : 
    userPlan?.subscriptionInfo?.subscriptionStatus === 'CANCEL' ? 'Renew Plan' : 'Change Plan');
  
  let location = useLocation();

  useEffect(() => {
    if (user?.permissions?.includes(Permissions.SUPER_USER)) {
      return;
    }

    if (window.location.pathname.includes('manage') || 
          window.location.pathname.includes('projects') || 
          window.location.pathname.includes('analytics')) {
      if (user?.premiumTier === -1) {
        setSelectSubModalOpen(true);
      }
    }

    // if (window.location.pathname.includes('manage')) {
    //   if (user?.premiumTier === -1) {
    //     setSelectSubModalOpen(true);
    //   }
    // }
    // else {
    //   setSelectSubModalOpen(false);
    // }

  }, [user, location]);

  const handleClose = () => {
    if (user?.premiumTier === -1) {
      if(window.location.host === "blitz-qa.useblitz.com") {
        window.location.href = 'https://blitz-qa.useblitz.com';
      }
      else if(window.location.host === "www.smartblitz.ai") {
        window.location.href = 'https://useblitz.com';
      }
      else if(window.location.host === 'localhost:3000') {
        window.location.href = 'http://localhost:3000';
      }
      return;
    }

    setSelectSubModalOpen(false);
  }

  return (
    <div className="upgrade-modal-container">
      <Modal
        className="upgrade-modal"
        title={modalTitle}
        show={isSelectSubModalOpen}
        showClose={true}
        onClose={handleClose}
      >
        <SelectSubscriptionBody 
          displayAllPlans={userPlan?.subscriptionInfo?.subscriptionStatus === 'CANCEL' ? true : false}
          modalTitle={modalTitle}
          setModalTitle={setModalTitle} 
          handleClose={handleClose}
        />
      </Modal>
    </div>
  )
}

const SelectSubscriptionBody = (props) => {
  const bodyStatuses = {
    LOADING: 'loading',
    SELECT_PLAN: 'select_plan',
    PAYMENT: 'payment',
    PAYMENT_FREE: 'payment_free',
    SUCCESS: 'success'
  }

  const { 
    user, 
    refreshUser, 
    userPlan,
    isPlansFetching,
    subscriptionPlans,
    userCard,
    isSelectSubModalOpen,
    setSelectSubModalOpen,
    connectedAccounts,
  } = useContext(AppContext);

  const [isUpgradingPlan, setUpgradingPlan] = useState(false);
  const [selectionStatus, setSelectionStatus] = useState(bodyStatuses.LOADING);
  const selectionStatusRef = useRef(selectionStatus);
  selectionStatusRef.current = selectionStatus;

  const [periodSelection, setPeriodSelection] = useState<string>('Annual');
  const [discountRate, setDiscountRate] = useState('');
  
  const [selectedPlan, setSelectedPlan] = useState<any>({});
  const [forceDropdownClose, setForceDropdownClose] = useState<boolean>(false);

  const [freeCreditValue, setFreeCreditValue] = useState<any>(100);
  const [isFreeLoading, setFreeLoading] = useState<boolean>(false);

  const [liteCreditValue, setLiteCreditValue] = useState<any>(0);
  const [freePlanDetails, setFreePlanDetails] = useState<any>({});
  const [enterprisePlanDetails, setEnterprisePlanDetails] = useState<any>({});
  const [freePlanMinMax, setFreePlanMinMax] = useState({min: 0, max: 0});
  const [litePlanMinMax, setLitePlanMinMax] = useState({min: 500, max: 2000});
  const [pricePerHundered, setPricePerHundered] = useState(10);

  const [isEnterpriseLoading, setEnterpriseLoading] = useState<boolean>(false);
  const [congratModalBody, setCongratModalBody] = useState('');
  const [ctaButtonTexts, setCTAButtonTexts] = useState({
    free: {
      initial: 'Get Started'
    },
    lite: {
      trialText: 'Start Free Trial',
      upgradeText: 'Upgrade Plan',
      selectPlanText: 'Select Plan',
    },
    enterprise: {
      trialText: 'Contact Sales',
      contactText: 'Contact Sales'
    }
  });
  const [popupTitle, setPopupTitle] = useState<string>('');
  const [isPaymentLoading, setPaymentLoading] = useState<false>(false);
  const [paymentError, setPaymentError] = useState<string>(''); 
  const [paymentSuccess, setPaymentSuccess] = useState<boolean>(false);
  const hasUpgradeCalledRef = useRef<boolean>(false);
  
  const hasUserPlanSelected = useRef(false);

  const rangeOnChange = (e) => {
    let t = e.target
    
    //Assign range input's properties into variables
    const min = t.min; 
    const max = t.max;  
    const val = t.value;  
    
    /* Adjust range progress as the thumb moves while avoiding overflows */
    t.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'; 
  }

  useEffect(() => {
    if (props.setModalTitle) {
      props.setModalTitle(popupTitle);
    }
  }, [popupTitle]);

  useEffect(() => {
    const plan: any = subscriptionPlans.find((p:any) => p.parentPlanId !== -1);
    const parentPlan: any = subscriptionPlans.find((p:any) => p.parentPlanId === -1);

    // let discount = ((plan?.additionalParams?.price - parentPlan?.additionalParams.price / 12) / plan?.additionalParams?.price ) * 100;
    let discount = (plan?.additionalParams.price * 12 ) - parentPlan?.additionalParams.price;
    setDiscountRate(discount.toFixed(2));
    
  }, [subscriptionPlans]);

  const freePlanCalledRef = useRef<boolean>(false);
  useEffect(() => {
    let userPlanSelection = SessionStorage.get('userPlanSelection') || 'no_data';

    if (userPlanSelection.includes('LITE') && subscriptionPlans.length === 0) {
      return;
    }

    if (userPlanSelection !== 'no_data') {
      if (userPlanSelection.includes('LITE')) {
        const period = userPlanSelection.split('_')[1];
        setPeriodSelection(period.charAt(0).toUpperCase() + period.slice(1, period.length).toLowerCase());
      }

      if (userPlanSelection === 'FREE_PLAN') {
        setSelectionStatus(bodyStatuses.LOADING);
        handleFreePlanSelection();
        // setSelectionStatus(bodyStatuses.PAYMENT_FREE);
      }
      else if (userPlanSelection === 'ENTERPRISE_PLAN') {
        handleEnterpriseSelection();
      }
      else {
        const period = userPlanSelection.split('_')[1];
        if (period === 'ANNUAL') {
          let prevSelectedPlan = subscriptionPlans.find(p => p.subscriptionType === 'YEARLY');
          handleSelectPlan(prevSelectedPlan);
        }
        else {
          let prevSelectedPlan = subscriptionPlans.find(p => p.subscriptionType === 'MONTHLY');
          handleSelectPlan(prevSelectedPlan);
        }
      }

      SessionStorage.remove('userPlanSelection');
      hasUserPlanSelected.current = true;
    }
    else {
      if (user?.premiumTier === -1 && !freePlanCalledRef.current) {
        freePlanCalledRef.current = true;
        setSelectionStatus(bodyStatuses.LOADING);
        handleFreePlanSelection();
      }
    }
  }, [subscriptionPlans]);

  // Effect for setting the lite plan CTA button texts
  useEffect(() => {
    let litePlanCtaBtnTexts = subscriptionPlans.find(p => p.tier === 100)?.additionalParams?.ctaButtons;
    if (litePlanCtaBtnTexts) {
      let clonedCtaButtonTexts = JSON.parse(JSON.stringify(ctaButtonTexts));
      clonedCtaButtonTexts.lite = litePlanCtaBtnTexts;
      setCTAButtonTexts(clonedCtaButtonTexts);
    }
  }, [subscriptionPlans]);

  // Effect for setting the enterprise plan CTA button texts
  useEffect(() => {
    if (enterprisePlanDetails.ctaButtons) {
      let clonedCtaButtonTexts = JSON.parse(JSON.stringify(ctaButtonTexts));
      clonedCtaButtonTexts.enterprise = enterprisePlanDetails.ctaButtons;
      setCTAButtonTexts(clonedCtaButtonTexts);
    }
  }, [enterprisePlanDetails]);

  // Effect for setting the enterprise plan CTA button texts
  useEffect(() => {
    if (freePlanDetails.ctaButtons) {
      let clonedCtaButtonTexts = JSON.parse(JSON.stringify(ctaButtonTexts));
      clonedCtaButtonTexts.free = freePlanDetails.ctaButtons;
      setCTAButtonTexts(clonedCtaButtonTexts);
    }
  }, [freePlanDetails]);

  useEffect(() => {
    if (user?.premiumTier === -1) {
      axios.get('/api/user/infraParam/FREE_PLAN_INFO')
        .then((response) => {
          setFreePlanDetails(JSON.parse(response?.data?.parameterValue));
        }).catch(error => {
          console.error(error);
        });
    }

    if (user?.premiumTier !== 9000) {
      axios.get('/api/user/infraParam/ENTERPRISE_PLAN_INFO')
        .then((response) => {
          setEnterprisePlanDetails(JSON.parse(response?.data?.parameterValue));

          setTimeout(() => {
            if (selectionStatusRef.current === bodyStatuses.LOADING && !freePlanCalledRef.current) {
              setSelectionStatus(bodyStatuses.SELECT_PLAN);
            }
          }, 500);
        }).catch(error => {
          console.error(error);
        });
    }

    axios.get('/api/user/infraParam/SUBSCRIPTION_AI_CREDIT_LIMITS')
        .then((response) => {
          if (response?.data?.parameterValue) {
            let responseData = JSON.parse(response?.data?.parameterValue).LITE;
            setLitePlanMinMax({min: responseData.lower, max:responseData.upper});
            setLiteCreditValue(responseData.lower);
          }
        }).catch(error => {
          console.error(error);
        });

    axios.get('/api/user/infraParam/CREDIT_PRICING_PER_HUNDRED')
      .then((response) => {
        setPricePerHundered(parseInt(JSON.parse(response?.data?.parameterValue)));
      }).catch(error => {
        console.error(error);
      });

    axios.get('/api/user/infraParam/FREE_PLAN_CREDITS_LIMIT')
      .then((response) => {
        setFreePlanMinMax({min: 0, max: parseInt(JSON.parse(response?.data?.parameterValue))});
      }).catch(error => {
        console.error(error);
      });
  }, [user]);

  useEffect(() => {
    if (props.setModalTitle) {
      if (selectionStatus === bodyStatuses.SELECT_PLAN) {
        if (subscriptionPlans.findIndex(plan => plan.isEligibleForTrial) !== -1 || 
              (!user?.isEnterpriseTrialUsed && user?.premiumTier === -1)) {
                let couponCode = localStorage.getItem('couponData') || '';
                if (subscriptionPlans.length === 0) {
                  props.setModalTitle(`Upgrade Plan`);
                  // props.setModalTitle('Try for Free');
                }
                else {
                  let trialDays = subscriptionPlans.find(p => p.tier === 100)?.additionalParams?.trialDays;
                  if (trialDays) {
                    props.setModalTitle(`Upgrade Plan`);
                    // props.setModalTitle(`Try Free for ${trialDays} day${trialDays === 1 ? '' : 's'}`);
                  }
                }
          return;
        }

        if (user?.premiumTier !== -1) {
          if (userPlan?.subscriptionInfo?.subscriptionStatus === 'CANCEL') {
            props.setModalTitle('Renew Plan');
          }
          else {
            props.setModalTitle('Upgrade Plan');
          }
        }

      }
      else if (selectionStatus === bodyStatuses.PAYMENT) {
        if (!paymentSuccess) {
          props.setModalTitle('Payment Details');
        }
      }
      else if (selectionStatus === bodyStatuses.PAYMENT_FREE) {
        props.setModalTitle('Start Free Plan');
      }
      else if (window.hasOwnProperty('lockedElementClicked') && window['lockedElementClicked']) {
        window['lockedElementClicked'] = false;
        props.setModalTitle('Upgrade Plan');
      }
      else {
        console.log('user', user?.premiumTier);
        if (user?.premiumTier === -1) {
          props.setModalTitle('Welcome to Blitz SMART!');
        }
        else {
          props.setModalTitle('Congratulations!');
        }
      }
    }
  }, [selectionStatus, isSelectSubModalOpen, subscriptionPlans]);

  useEffect(() => {
    if (props.setModalTitle) {
      if (isPaymentLoading) {
        props.setModalTitle('Please Wait');
      }
      else if (paymentError.length > 0) {
        props.setModalTitle('Payment Authentication Failed');
      }
    }
  }, [isPaymentLoading, paymentError]);

  const isUserConnectedAnyAccount = () => {
    if (Object.keys(connectedAccounts.credentialsMap).length === 0) {
      return false;
    }
    return true;
  }
  
  const handleUpgradePlan = (plan) => {
    hasUpgradeCalledRef.current = true;
    let userUpgradingPeriod = false;
    if (user?.premiumTier === 100 && 
        userPlan?.planInfo?.subscriptionType === 'MONTHLY' && 
          plan.tier === 100 &&
            plan.subscriptionType === 'YEARLY') {
              userUpgradingPeriod = true;
    }

    setUpgradingPlan(true);

    upgradeSubscription(plan.Id)
      .then((response) => {
        if (userUpgradingPeriod) {
          setCongratModalBody('Congratulations, You have successfully switched the billing cycle to annual.');
        }
        else {
          setCongratModalBody('Congratulations, you have successfully upgraded to the Startup plan.');
        }
        setSelectionStatus(bodyStatuses.SUCCESS); 
        refreshUser();
  
        if (props?.forceUpdate) {
          props?.forceUpdate();
        }

      })
      .catch((err) => {
        console.log('err', err);
      })
      .finally(() => {
        setUpgradingPlan(false);
      });
  }

  const handleSelectPlan = (plan) => {
    if (user?.premiumTier === -1) {
      setSelectedPlan(plan);
      setSelectionStatus(bodyStatuses.PAYMENT);

      if (props?.setModalTitle) {
        props?.setModalTitle('Payment Details')
      }
    }
    else {
      if (userCard !== 'no_card') {
        if (user?.premiumTier === 10) {
          TrackFBPixel(
            'StartTrial',
            {
              currency: "USD",
              predicted_ltv: (selectedPlan?.subscriptionType === 'MONTHLY'
                ? selectedPlan?.additionalParams?.price * 12 : selectedPlan?.additionalParams?.price) || 0,
              value: selectedPlan?.additionalParams?.price || 0
            }
          );
        }
        handleUpgradePlan(plan);
      }
      else {
        setSelectedPlan(plan);
        setSelectionStatus(bodyStatuses.PAYMENT);
      }
    }
  }

  const handleEnterpriseSelection = () => {
    setEnterpriseLoading(true);
    axios.post('/api/subscriptions/enterprisePlan/contactUs')
      .then((response) => {
        if (response?.data?.makeUserEnterprise) {
          TrackFBPixel('StartTrial', 
            { 
              currency: "USD", 
              predicted_ltv: 0, 
              value: 0,
              content_type: "product",
              content_category: "Blitz SMART",
              content_ids: ['ENTERPRISE'] 
            });

          if (isUserConnectedAnyAccount()) {
            setCongratModalBody(`Congratulations, you have been successfully registered for the Enterprise plan and we will contact you over email soon.`);
            setSelectionStatus(bodyStatuses.SUCCESS);
          }
          else {
            setSelectSubModalOpen(false);
            window['linkAccTitle'] = 'Congratulations, your enterprise plan request has been successfully registered and you will be contacted on your email shortly. Meanwhile, explore the product and discover its potential.<br> Get started by linking your ad accounts.';
          }

          refreshUser(); 

          if (props?.forceUpdate) {
            props?.forceUpdate();
          }
        }
        else {
          setCongratModalBody(`We'll be in touch within 24 hours to schedule your first consultation and get the engines going on your creatives 🚀🚀🚀`);
          setSelectionStatus(bodyStatuses.SUCCESS);

          TrackFBPixel('Contact', {
            email: user?.email,
            message: 'User requested contact for enterprise plan'
          });
        }
      })
      .catch((err) => {
        console.warn('Error while updating user account type', err);
      })
      .finally(() => {
        setEnterpriseLoading(false);
      })
  }

  const updateFUEState = async (newState) => {
    return axios.post('/api/user/updateFue', { fueState: newState});
  }

  const handleFreePlanSelection = async () => {
    setFreeLoading(true)
    axios.post('/api/user/upgrade/freePlan', {paymentDetailsAdded : false})
      .then(async (response) => {
        await updateFUEState('FUE_SUBSCRIBED');

        if (isUserConnectedAnyAccount()) {
          props.setModalTitle('Congratulations');
          setCongratModalBody(`Congratulations, you're now on the free plan`);
          setSelectionStatus(bodyStatuses.SUCCESS);
        } 
        else {
          window['linkAccTitle'] = 'Congratulations, you can now explore our Free plan. Get started by linking your ad accounts.';
          setSelectSubModalOpen(false);
        }
        TrackFBPixel('AddToWishlist', { currency: "USD", predicted_ltv: 0, value: 0 });
        refreshUser();
      })
      .catch((e) => {
        console.warn('Error while selecting free account type');
      })
      .finally(() => {
        setFreeLoading(false);
      });
  }

  const renderSelectPlan = () => {
    const renderBillingText = () => {
      if (periodSelection === 'Annual') {
        return (
          <>
            Billing: <span className='discount-rate'>{discountRate !== '' ? `Save $${discountRate} per year` : ''}</span> with Annual Plan
          </>
        )
      }
      else {
        return (
          <>
            Billing:
          </>
        )
      }
    }

    const handlePeriodSelection = (val) => {
      setPeriodSelection(val);
      setForceDropdownClose(true);
    }

    const renderFreePlan = () => {
      if (freePlanDetails.planEnabled === 'false') {
        return <></>;
      }

      return (
        <div className={`offer-card`}>
          <div className='top-section'>
            <span className='pro title chip-bg'>Free</span>
            <div className='price-container'>
              <span className='price'>{freePlanDetails.planTitle}</span>
            </div>
           <div className='sub-details'>
            <div className='top-panel-details'>
              {
                freePlanDetails?.topPanel?.map((detail, index) => 
                  <div key={index}><div>•</div> <div>{detail}</div></div>
                )
              }
            </div>
            <div className='sub-detail-card'>
              {freePlanMinMax.max} AI assets (text copy or images)
            </div>
            <div>
              <input
                className='slider v-hidden'
                type={'range'}
                min={freePlanMinMax.min}
                max={freePlanMinMax.max}
                value={freeCreditValue}
                onChange={(e) => setFreeCreditValue(e.target.value)}
              />
              <div className='flex jc-space-between v-hidden'>
                <div>{freePlanMinMax.min}</div>
                <div>{freePlanMinMax.max}</div>
              </div>
            </div>
           </div>
          </div>
          <div className='bottom-section'>
            {
              freePlanDetails?.bottomPanel?.map((detail, index) => 
                <div className='offer-option' key={index}>
                  <div className="green-circle-checkmark" />
                  <span className='offer-text'>{detail}</span>
                </div>
              )
            }
          </div>
          <div className='footer-section'>
            {
              isFreeLoading ?
                <InlineLoader isLoading={true} /> 
                :
                <button
                  onClick={() => {
                    handleFreePlanSelection();
                    // if (userCard === 'no_card') {
                    //   setSelectionStatus(bodyStatuses.PAYMENT_FREE);
                    // }
                    // else {
                    //   handleFreePlanSelection();
                    // }
                  }}
                >
                  {ctaButtonTexts.free.initial}
                </button>
            }    
          </div>
        </div>
      )
    }

    const renderLitePlan = () => {
      if (user?.premiumTier === 100 && userPlan?.planInfo?.subscriptionType === 'YEARLY' && userPlan?.subscriptionInfo?.subscriptionStatus !== 'CANCEL') {
        return <></>
      }

      let typeFinder = periodSelection === 'Annual' ? 'YEARLY' : 'MONTHLY';
      const plan = subscriptionPlans.find(plan => plan.subscriptionType === typeFinder);

      const renderPrice = (price) => {
        price = parseInt(price);
        if (periodSelection === 'Annual') {
          price = price / 12;
        }
  
        let extraCredits = parseInt(liteCreditValue) - litePlanMinMax.min;
        let extraPrice = Math.ceil(extraCredits / 100) * pricePerHundered;
        price += extraPrice;
        price = price.toFixed(2);
  
        return price.toLocaleString();
      }

      const renderButtonText = () => {
        if (plan?.isEligibleForTrial) {
          return ctaButtonTexts.lite.trialText;
        }
        else if (user?.premiumTier === 10) {
          return ctaButtonTexts.lite.upgradeText;
        }

        return ctaButtonTexts.lite.selectPlanText;
      }

      const isPlanHover = () => {
        if (user?.premiumTier === -1) {
          if (!hasUserPlanSelected.current) {
            return true;
          }
        }

        return false;
      }
      
      return (
        <div className={`offer-card ${isPlanHover() ? 'active-plan' : ''} `}>
          <div className='top-section'>
            <span className='pro title chip-bg'>Start Up</span>
            <div className='price-container'>
              <span className='price'>${renderPrice(plan?.additionalParams?.price)}</span>
              <span className='muted-month'>/Month</span>
              {periodSelection === 'Annual' ? <span className='muted-annual'>- Billed Annually</span> : <></>} 
            </div>
           <div className='sub-details'>
            <div className='top-panel-details'>
              {
                plan?.additionalParams?.details?.topPanel?.map((detail, index) => 
                  <div key={index}><div>•</div> <div>{detail}</div></div>
                )
              }
            </div>
            <div className='sub-detail-card'>
              {liteCreditValue} AI assets (text copy or images)
            </div>
            <div>
              <input
                className='slider'
                type={'range'}
                min={litePlanMinMax.min}
                max={litePlanMinMax.max}
                value={liteCreditValue}
                onChange={(e) => {
                  rangeOnChange(e);
                  setLiteCreditValue(e.target.value);
                }}
                step="100"
              />
              <div className='flex jc-space-between'>
                <div>{litePlanMinMax.min}</div>
                <div>{litePlanMinMax.max}</div>
              </div>
            </div>
           </div>
          </div>
          <div className='bottom-section'>
            {
              plan?.additionalParams?.details?.bottomPanel?.map((detail, index) => 
                <div className='offer-option' key={index}>
                  <div className="green-circle-checkmark" />
                  <span className='offer-text'>{detail}</span>
                </div>
              )
            }
          </div>
          <div className='footer-section'>
            {
              (Object.keys(userPlan).length && userPlan?.planInfo?.Id === plan.Id && userPlan?.subscriptionInfo?.subscriptionStatus !== 'CANCEL') ?
                <div className='warning-text'>You are already in this plan</div>
                :
                isUpgradingPlan ? 
                  <InlineLoader isLoading={true} />
                  :
                  <button
                    onClick={() => handleSelectPlan(plan)}
                  >
                    {renderButtonText()}
                  </button>
            }    
          </div>
        </div>
      )
    }

    const renderEnterprisePlan = () => {
      if (enterprisePlanDetails.planEnabled === 'false') {
        return <></>;
      }

      return (
        <div className={`offer-card`}>
          <div className='top-section'>
            <span className='pro title chip-bg'>Enterprise</span>
            <div className='price-container'>
              <span className='price'>{enterprisePlanDetails.planTitle}</span>
            </div>
           <div className='sub-details'>
           <div className='top-panel-details'>
              {
                enterprisePlanDetails?.topPanel?.map((detail, index) => 
                  <div key={index}><div>•</div> <div>{detail}</div></div>
                )
              }
            </div>
            <div className='sub-detail-card'>
              Custom Plan & Price
            </div>
            <div>
              <input
                className='slider v-hidden'
                type={'range'}
                min={0}
                max={100}
              />
              <div className='flex jc-space-between v-hidden'>
                <div>0</div>
                <div>100</div>
              </div>
            </div>
           </div>
          </div>
          <div className='bottom-section'>
            {
              enterprisePlanDetails?.bottomPanel?.map((detail, index) => 
                <div className='offer-option' key={index}>
                  <div className="green-circle-checkmark" />
                  <span className='offer-text'>{detail}</span>
                </div>
              )
            }
          </div>
          <div className='footer-section'>
            {
              isEnterpriseLoading ? 
                <InlineLoader isLoading={true} />
                :
                <button
                  onClick={handleEnterpriseSelection}
                >
                  {!user?.isEnterpriseTrialUsed ? 
                      user?.premiumTier === -1 ? ctaButtonTexts.enterprise.trialText : ctaButtonTexts.enterprise.contactText : ctaButtonTexts.enterprise.contactText}
                </button>
            }    
          </div>
        </div>
      )    
    }

    return (
      <>
        {isPlansFetching ?
          <InlineLoader isLoading={isPlansFetching}/>
            :
            <>
              <div className='period-selection-area'>
                {
                  userPlan?.planInfo?.subscriptionType !== 'YEARLY' || userPlan?.subscriptionInfo?.subscriptionStatus === 'CANCEL' ?
                    <>
                      <span className='period-title'>
                        {renderBillingText()}
                      </span>
                      {
                        user?.premiumTier === 100 && userPlan?.planInfo?.subscriptionType === 'MONTHLY' ?
                          <></>
                          :
                          <span className='ml-10'>
                            <Dropdown
                              className='period-selection-dd'
                              title={periodSelection}
                              forceClose={forceDropdownClose}
                              setForceClose={setForceDropdownClose}
                              onClose={() => {}}
                            >
                              <button className='option-btn' onClick={() => handlePeriodSelection('Annual')}>Annual</button>
                              <button className='option-btn' onClick={() => handlePeriodSelection('Monthly')}>Monthly</button>
                            </Dropdown>
                          </span>
                      }
                    </>
                    :
                    <></>
                }
              </div>
              <div className='flex jc-center direction-row gap-10'>
                {user?.premiumTier === -1 ? renderFreePlan() : <></>}
                {renderLitePlan()}
              </div>
            </>
        }
      </>
    )
  }

  const renderPayment = () => {
    const handleBack = () => {
      setSelectionStatus(bodyStatuses.SELECT_PLAN);
    }

    const renderTrialFinishDate = () => {
      var today = new Date();
      var futureDate = new Date();
      futureDate.setDate(today.getDate() + selectedPlan?.additionalParams?.trialDays);
      let options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
      return futureDate.toLocaleDateString('en-US', options);
    }

    const onPaymentSuccess = () => {
      if (user?.premiumTier === -1 || user?.premiumTier === 10) {
        // for MONTHLY plan predicted is 12 * price of the plan
        // for YEARLY plan ltv is same as price
        TrackFBPixel(
          'StartTrial',
          {
            currency: "USD",
            predicted_ltv: (selectedPlan?.subscriptionType === 'MONTHLY'
              ? selectedPlan?.additionalParams?.price * 12 : selectedPlan?.additionalParams?.price) || 0,
            value: selectedPlan?.additionalParams?.price || 0
          }
        );
      }
      if (user?.premiumTier === 10) {
        setCongratModalBody('Congratulations, you have successfully upgraded to the Startup plan.');
      }
      setSelectionStatus(bodyStatuses.SUCCESS);
      refreshUser();

      if (props?.setModalTitle) {
        props?.setModalTitle('Congratulations');
      }

      if (props?.forceUpdate) {
        props?.forceUpdate();
      }
    }

    const renderPrice = () => {
      return `${selectedPlan?.additionalParams?.price.toLocaleString()} / ${selectedPlan?.subscriptionType === 'YEARLY' ? 'Year' : 'Month'}`;
    }

    const renderPaymentLoading = () => {
      return (
        <div className='payment-loading-container'>
          <div className='loader-container'>
            <div className='circle'>
              <div className="sk-fading-circle">
                <div className="sk-circle1 sk-circle"></div>
                <div className="sk-circle2 sk-circle"></div>
                <div className="sk-circle3 sk-circle"></div>
                <div className="sk-circle4 sk-circle"></div>
                <div className="sk-circle5 sk-circle"></div>
                <div className="sk-circle6 sk-circle"></div>
                <div className="sk-circle7 sk-circle"></div>
                <div className="sk-circle8 sk-circle"></div>
                <div className="sk-circle9 sk-circle"></div>
                <div className="sk-circle10 sk-circle"></div>
                <div className="sk-circle11 sk-circle"></div>
                <div className="sk-circle12 sk-circle"></div>
              </div>
            </div>
          </div>
          <div>
            Please wait while we validate your payment information. This may take a few moments, but you'll be automatically redirected once the validation is complete.
          </div>
        </div>
      )
    }
    
    const renderSubText = () => {
      if (selectedPlan?.isEligibleForTrial) {
        return `After your trial ends, you will be charged USD${renderPrice()} 
        starting ${renderTrialFinishDate()}. You can always cancel before then.`
      }
      else if (user?.premiumTier === 10) {
        return `You will be charged USD${renderPrice()} starting ${renderTrialFinishDate()}.`;
      }

      return ''
    }

    const renderPaymentButtonText = () => {
      if (selectedPlan?.isEligibleForTrial) {
        return `Start Trial`
      }
      else if (user?.premiumTier === 10) {
        return `Upgrade Plan`;
      }

      return ''
    }

    if (paymentSuccess) {
      return (
        <div className='payment-container'>
          <div data-testid="plansList" className="plans-list">
            <div className="payment-failed-container">
              <div className="failed-img-container">
                <div className="success-img" />
              </div>
              <div style={{textAlign: 'center', paddingBottom: '15px'}}>
                Your payment has been successfully processed. Your account has been updated and your subscription is now active. Thank you for choosing our service!
              </div>
              <div className="footer-btns">
                <button 
                  className="btn full-width" 
                  onClick={() => onPaymentSuccess()}>
                    Start using our service
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className='payment-container'>
        <div className='back-container'>
          {
            isPaymentLoading ? null : paymentError.length > 0 ? null : <button onClick={handleBack} disabled={isPaymentLoading}>← Select Plan</button>
          }
        </div>
        
          <div data-testid="plansList" className="plans-list">
            <Elements stripe={stripePromise}>
              <CheckoutForm
                planId={selectedPlan?.Id}
                planPrice={selectedPlan?.additionalParams?.price || 100}
                onReady={() => {}}
                saveButtonClass={'pay-btn'}
                authText={'To verify your Credit Card Details, we will temporarily hold $1 to authenticate your card, which will be immediately reversed.'}
                onSuccess={() => {
                  TrackLIEvent(LI_TRACKING_IDS.creditCardSuccess);
                  TrackFBPixel(
                    'AddPaymentInfo',
                    {
                      content_category: "Blitz SMART",
                      content_ids: ['LITE'],
                      contents: "LITE",
                      currency: "USD",
                      value: selectedPlan?.additionalParams.price || 0
                    }
                  );
                  if (props.setModalTitle) {
                    props.setModalTitle('Payment Success');
                  }
                  setPaymentSuccess(true);
                }}
                paymentButtonText={'Activate Plan'}
                setPaymentLoading={setPaymentLoading}
                loadingRenderer={renderPaymentLoading}
                displayFailedPageInCaseOfError={true}
                setPaymentError={setPaymentError}
              />
            </Elements>
          </div>
        
      </div>
    )
  }

  const renderPaymentFree = () => {
    const handleUpdateSuccess = (updateCard) => {
      setFreeLoading(true)
        axios.post('/api/user/upgrade/freePlan', {paymentDetailsAdded: updateCard})
          .then((response) => {
            TrackFBPixel('AddToWishlist', { currency: "USD", predicted_ltv: 0, value: 0 });

            if (isUserConnectedAnyAccount()) {
              setCongratModalBody(`Congratulations, you're now on the free plan`);
              setSelectionStatus(bodyStatuses.SUCCESS);
            }
            else {
              window['linkAccTitle'] = 'Congratulations, you can now explore our Free plan. Get started by linking your ad accounts.';
              setSelectSubModalOpen(false);
            }
            refreshUser();
          })
          .catch((e) => {
            console.warn('Error while selecting free account type');
          })
          .finally(() => {
            setFreeLoading(false);
          });
    }

    // deprecated
    return (
      <div className='payment-container'>
        <div className='back-container flex jc-space-between mt-10'>
          <button onClick={() => setSelectionStatus(bodyStatuses.SELECT_PLAN)}>← Select Plan</button>
          {
            isFreeLoading ? 
              <div className='w-p30'>
                <InlineLoader isLoading={true} /> 
              </div>
              :
              <button onClick={() => handleUpdateSuccess(false)}>Skip Payment</button>
          }
        </div>
        <div data-testid="plansList" className="plans-list">
          <Elements stripe={stripePromise}>
            <CheckoutForm
              planId={-1}
              planPrice={100}
              setIsOpen={true}
              hideHeading={true}
              onReady={() => {}}
              updateCard={true}
              updateSuccess={() => {
                TrackFBPixel(
                  'AddPaymentInfo',
                  {
                    content_category: "Blitz SMART",
                    content_ids: ['FREE'],
                    contents: "FREE",
                    currency: "USD",
                    value: 0
                  }
                );
                handleUpdateSuccess(true)}
              }
              updateError={() => {}}
              saveButtonClass="btn medium"
              onCancel={() => setSelectionStatus(bodyStatuses.SELECT_PLAN)}
              cancelRefreshUserAfterSuccess={true}
            />
          </Elements>
        </div>
      </div>
    )
  }

  const renderOnSuccess = () => {
    return (
      <div className='success-body'>
        {
          congratModalBody.length > 0 ? 
            congratModalBody 
            : 
            <>Congratulations, you have successfully upgraded to the Startup plan.</>
        }
        <div className='footer-area'>
          <button onClick={props.handleClose} className='btn'>Okay</button>
        </div>
      </div>
    )
  }

  const renderLoading = () => {
    return (
      <div>
        <InlineLoader isLoading={true} />
      </div>
    )
  }

  const renderBody = () => {
    switch (selectionStatus) {
      case bodyStatuses.SELECT_PLAN:
        return renderSelectPlan();
      case bodyStatuses.PAYMENT:
        return renderPayment();
      case bodyStatuses.PAYMENT_FREE:
        return renderPaymentFree();
      case bodyStatuses.SUCCESS:
        return renderOnSuccess();
      case bodyStatuses.LOADING:
        return renderLoading();
    }

    return <></>
  }

  return (
    <div className='upgrade-modal-body'>
      { renderBody() }
    </div>
  )
}

export {SelectSubscriptionModal, SelectSubscriptionBody};