import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { externalLink } from './Posts';

const PostThreeCourseDinner: FC = () => {

  return (
    <div className="blog-post">
      <p>
        This post kicks off our new series, Ad of the week, where we will highlight our most successful ads, while going in depth to explain what has contributed to their positive results. At
        {externalLink('https://www.useblitz.com/', 'Blitz')} we're constantly testing new creative concepts, and we want to share our insights with the broader community too. Digital marketing experts estimate that most Americans are exposed to around
        {externalLink('http://www.redcrowmarketing.com/2015/09/10/many-ads-see-one-day/', '4,000 to 10,000 ads each day')}, so what makes an ad stand? Each week, we'll dive into the reasons a particular ad worked well.
      </p>

      <h3>This Week's Highlight: 3 Course Dinner</h3>
      <div className="center">
        <iframe allowFullScreen frameBorder="0" scrolling="no" src="https://www.tiktok.com/embed/v2/7153416459446701358" title="" style={{ height: '760px', width: '325px' }}></iframe>
      </div>
      <p>
        This week's highlight is a TikTok ad that we produced for {externalLink('https://www.gopuff.com/gb/?utm_source=google&utm_medium=paid_search&campaignid=15375919367&adgroupid=135792496048&adid=608924232864&utm_content=performance_marketing&gclid=Cj0KCQjwteOaBhDuARIsADBqReh3sYCvWEAfLxBIv0cb2nUEi1xLNaRF6mVXlDAukjEDoFW1dvxsRlYaAoaWEALw_wcB', 'Gopuff')}, posted on October 12 in collaboration with influencer Maegan Bellassai
        {externalLink('https://www.tiktok.com/@maeganbella', '@maeganbella')}, a Musical Theater major at Ithaca College. Within the first week, this ad had a 3x better 
        {externalLink('https://www.wordstream.com/click-through-rate', 'click through rate (CTR)')} than the account average on TikTok, quickly indicating the compelling nature of the content and format. CTR measures the rate at which viewers of the ad are clicking on it, indicating the strength of the ad itself. This ad was also posted as a
        {externalLink('https://ads.tiktok.com/help/article?aid=10001881', 'Spark Ad')}, a type of {externalLink('https://www.outbrain.com/native-advertising/', 'native ad')} where the ads match the look, feel, and of the media format where they appear. As this post is first posted organically on an influencer's feed before being configured via ads manager, it fits “natively” and seamlessly in your feed.
      </p>

      <h3>What are Spark Ads on TikTok?</h3>
      <p>Spark Ads are a unique solution for brands to build a substantial presence with their community genuinely and authentically, hybridizing more traditional influencer marketing tactics with paid media advertising. To configure a Spark Ad, an influencer posts a video on behalf of a brand to their organic feed, giving an advertiser permission via the platform to run that post as an ad. The brand can then configure the post alongside other ads within the TikTok ads manager, setting custom targeting, bid caps, and other delivery details as they wish. Since Spark Ads are posted from the influencer's organic account, users will not immediately recognize it as an ad in the way they would a sponsored post from a brand. Most social media users instinctively swipe past pop up ads, but since Spark Ads are regular posts within your TikTok feed with authentic endorsements from influencers, consumers are more likely to engage with the post or follow an influencer's recommendation.</p>
      
      <h3>Why did this ad perform so well?</h3>
      <p>As mentioned, Maegan is a college student majoring in Musical Theater, and her content is typically themed around college living: dorm essentials, get ready with me videos, unboxing videos, and clothing hauls. She boasts 13.1k followers and 821.8k likes. Maegan has a busy lifestyle, and she shares her intimate, everyday routines with her followers, including refreshing her dorm room, traveling, and going to class. Her content is well-suited to Gopuff, and the ad is a natural extension of her personal brand.</p>
      <p>Showcasing the products in the video is also a strong recommendation. Maegan not only features the products prominently, but also <strong>shows</strong> her followers how they can be used. She ordered popular products on the platform with this demo: pizza rolls, ramen, and an ice cream cake. She's relatable, energetic, and visually engaging, but it doesn't come off as disingenuous because it is an obvious fit: her creator content matches her vibe and energy.</p>
      <p>The structure of the video in particular gives the ad its captivating, authentic feel. The video starts with Maegan talking about how she doesn't like eating in the dining hall, and Gopuff is a lifesaver because she can quickly order dinner. In a screen recording of the app experience, she explicitly shows TikTok users how she can order bagel bites, ramen, and an ice cream cake to share with friends. She also uses the selfie camera to include an introduction and outro, giving it a more genuine, intimate feel. The video is only 30 seconds long, and it ends with her meal in front of her. The ad isn't overly pushy, and feels just like a college student getting a quick meal, while showing you how to do the same.</p>
      <p>TikTok users between the ages of 16-24 are TikTok's largest age demographic and are likely users of this brand as well . Using a Spark Ad with a relatable college student will engage other busy students looking for quick and easy food delivery options. It's a great way to captivate users' attention in a genuine way, and some users scrolling past Maegan's post might not even realize her Gopuff video is an ad. Eating ramen as a broke American college student is a tale as old as time. If you're scrolling on TikTok and see a way to get ramen more easily wouldn't you want to know more?</p>
    </div>
  );
}

export default PostThreeCourseDinner;
