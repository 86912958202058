import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  Navigate,
  BrowserRouter
} from "react-router-dom";

import Home from './pages/Landing/Home';
import About from './pages/Landing/About';
import CaseStudies from './pages/Landing/CaseStudies';
import Capabilities from './pages/Landing/Capabilities';
import Creator from './pages/creator';
import ResetPassword from './pages/reset-password';
import { AppContextProvider, IAppContext, useAppContext } from './appContext';
import checkRequests from "./HOC/CheckRequests"
import AppRoutes from './common/routes';
import loadable from "@loadable/component";
import SubscriptionSuccess from './common/subscription-success';
import CustomPlanEnquirySuccess from './common/custom-plan-enquiry-success';
import MetaTags from './common/meta-tags'
import { Provider } from 'react-redux';
import Store from './data-stores';
import VariantsConfiguration from './common/variants-configuration';
import PopulateListings from './common/populate-listings';
import { CreateAdsContextProvider } from './pages/manage/createAdsContext';
import { useCreateAdsContext } from './pages/manage/createAdsContext';

import 'frontend-core/src/styles/App.scss';
import './scss/App.scss';
import { Loader } from 'frontend-core';
import SnapchatStory from './pages/manage/snapchat-story';
import { CreateAdsContext } from './pages/manage/createAdsContext';
import { Permissions } from './common/model';
import { SelectSubscriptionModal } from './pages/manage/SelectSubscriptionModal';
import { useLandingContext, LandingContext } from './pages/Landing/LandingContext';
import Product from './pages/Landing/Product';
import SupportBubble from './common/SupportBubble';
import ScrollToTop from './common/ScrollTop';
import GaClickTracking from './common/GaTracker';
import FUEController from './common/fue-controller';
import CanvasAi from './pages/CanvasAi';
import ExhaustedCreditsModal from './pages/manage/ExhaustedCreditsModal';
import PaymentFailureModal from './pages/account-settings/PaymentFailureModal';
import TopupCreditsModal from './pages/manage/TopupCreditsModal';
import BlogPost from './pages/Landing/Blog/BlogPost';
import Blog from './pages/Landing/Blog';

GaClickTracking();

const loader = (
  <Loader isLoading={true} />
);

const Studio = loadable(() => import('./pages/studio'), {
  fallback: loader,
});
const TermsOfService = loadable(() => import('./pages/terms-service'), {
  fallback: loader,
});
const PrivacyPolicy = loadable(() => import('./pages/privacy-policy'), {
  fallback: loader,
});
const Manage = loadable(() => import('./pages/manage'), {
  fallback: loader,
});
const CreateAds = loadable(() => import('./pages/manage/create-ads'), {
  fallback: loader,
});
const Library = loadable(() => import('./pages/library'), {
  fallback: loader,
});
const ProjectPreview = loadable(() => import('./pages/library/project'), {
  fallback: loader,
});
const AccountSettings = loadable(() => import('./pages/account-settings'), {
  fallback: loader,
});
const BrandKit = loadable(() => import('./pages/brand-kit'), {
  fallback: loader,
});
const MarketplaceTabs = loadable(() => import('./pages/marketplace/marketplace-tabs'), {
  fallback: loader,
});
// const ListingDetails = loadable(() => import('./pages/marketplace/listing-details'), {
//   fallback: loader,
// });
// const CreateListing = loadable(() => import('./pages/marketplace/create-listing'), {
//   fallback: loader,
// });
const CreativeLanding = loadable(() => import('./pages/creativeLanding'), {
  fallback: loader,
});
const CreativeLandingV3 = loadable(() => import('./pages/creativeLanding/v3'), {
  fallback: loader,
});
const Contact = loadable(() => import('./pages/contact'), {
  fallback: loader,
});
const LoadingScreen = loadable(() => import('./pages/loading'), {
  fallback: loader,
});
const BiDashBoard = loadable(() => import('./pages/bi-dashboard'), {
  fallback: loader,
});
const Analytics = loadable(() => import('./pages/analytics'), {
  fallback: loader,
});
const LiteCampaigns = loadable(() => import('./pages/manage/LiteCampaigns'), {
  fallback: loader,
});
const AiAdsPage = loadable(() => import('./pages/manage/AiAds'), {
  fallback: loader,
});
// const SubscriptionSuccess = loadable(() => import('./common/subscription-success'), {
//   fallback: <div>loading...</div>,  // todo implement loading screen
// });
// const CustomPlanEnquirySuccess = loadable(() => import('./common/custom-plan-enquiry-success'), {
//   fallback: <div>loading...</div>,  // todo implement loading screen
// });
// const Nav = loadable(() => import('./pages/nav'), {
//   fallback: <div>loading...</div>,  // todo implement loading screen
// });
// const Footer = loadable(() => import('./pages/home/footer'), {
//   fallback: <div>loading...</div>,  // todo implement loading screen
// });

function getUserInfo() {
  const userInfoStr = localStorage.getItem("userInfo");
  if (userInfoStr) {
    localStorage.removeItem("userInfo");
  }
  return null;
}

function loggedIn() {
  return (getUserInfo() != null);
}

function App(props: any) {
  const appContext: IAppContext = useAppContext();
  const createAdsContext = useCreateAdsContext();
  var blitzAppId: string = "-1";
  if (appContext.user != null && appContext.user.blitzAppId != null) {
    blitzAppId = appContext.user.blitzAppId;
  }

  const { isLiteUser } = appContext;

  useEffect(() => {
    /*if (window.location.host === "blitz-qa.useblitz.com") {
      const script = document.createElement("script");
      script.src = "https://widget.sonetel.com/SonetelWidget.min.js";
      script.async = true;
      script.id = "slcLiveChat";
      script.setAttribute("data-account-id", "207930260");
      document.head.appendChild(script);
    }*/
    return () => {
      if (window.location.host === "blitz-qa.useblitz.com") {
        const script = document.getElementById("slcLiveChat");
        if (script) {
          document.head.removeChild(script);
        }
      }
    };
  }, []);

  useEffect(() => {
    const ac = new AbortController();
    window.dispatchEvent(new CustomEvent('subscriptionEvent', {
      bubbles: false,
      detail: { status: appContext.user?.isPremiumUser || false },
    }));

    if (appContext.user && !appContext.user.userId) {
      // backward compatibility fix
      // update userInfo if userId is not stored in localStorage
      appContext.refreshUser();
    }

    // populating accounts
    if (appContext.user != null) {
      // need to find another better solution down the line.
      // not calling populate accounts in case of campaigns/adsets/ads/creatives
      if (window.location.href.indexOf('/manage') === -1) {
        appContext.populateAccounts();
      }
    }
    return () => ac.abort();
  }, [appContext.user]);

  const isUserAllowed = (route) => {
    return true;
    // previously user was not allowed to reach analytics and creative page, but changed.
    // if (appContext?.user?.permissions.includes(Permissions.SUPER_USER)) {
    //   return true;
    // }

    // if ([100, 10, -1].includes(appContext.user?.premiumTier || -1)) {
    //   if (route.includes('/analytics')) {
    //     return false;
    //   }
    //   else if (route.includes('/projects')) {
    //     return false;
    //   }
    //   else if (route.includes('/account') && appContext.user?.premiumTier === -1) {
    //     return true;
    //   }
    // }
    // return true
  }
  const landingContextValue = useLandingContext();

  appContext.getPurchaseEvent();

  return (
    <Provider store={Store}>
      <AppContextProvider value={appContext}>
        <CreateAdsContextProvider value={createAdsContext}>
          <LandingContext.Provider value={landingContextValue}>
            <MetaTags />
            <BrowserRouter>
              <ScrollToTop />
              <Routes>
                {/* hiding creators tab */}
                {/* <Route path={AppRoutes.CREATOR} element={(
                    !loggedIn() && !appContext.isUserSuperAdmin ? (
                      <Navigate to="/"/>
                    ) : (
                      <Creator/>
                    )
                )}/> */}
                <Route path={AppRoutes.RESET_PASSWORD} element={
                  <ResetPassword />
                }/>
                <Route path={AppRoutes.TERMS_OF_SERVICE} element={
                  <TermsOfService isLoggedIn={loggedIn()} />
                } />
                <Route path={AppRoutes.PRIVACY_POLICY} element={
                  <PrivacyPolicy isLoggedIn={loggedIn()} />
                } />

                {/* todo move user to context/redux */}
                <Route path={AppRoutes.ADD_ADSET} element={(
                  !loggedIn()
                    ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                    : isLiteUser()
                      ? <LiteCampaigns initialStep={2} />
                      : <CreateAds user={getUserInfo()} />
                )}/>
                <Route path={AppRoutes.ADD_ADS} element={(
                  !loggedIn()
                    ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                    : isLiteUser()
                      ? <LiteCampaigns initialStep={3} />
                      : <CreateAds user={getUserInfo()} />
                )}/>
                <Route path={AppRoutes.ADD_ADS_PROJECT} element={(
                  !loggedIn()
                    ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                    : isLiteUser()
                      ? <LiteCampaigns initialStep={3} />
                      : <CreateAds user={getUserInfo()} />
                )}/>
                <Route path={AppRoutes.EDIT_CAMPAIGN} element={(
                    !loggedIn()
                      ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                      : isLiteUser()
                        ? <LiteCampaigns />
                        : <CreateAds user={getUserInfo()} />
                )}/>
                <Route path={AppRoutes.COPY_CAMPAIGN} element={(
                    !loggedIn()
                      ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                      : <CreateAds user={getUserInfo()} />
                )}/>
                <Route path={AppRoutes.EDIT_ADSET} element={(
                  !loggedIn()
                    ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                    : isLiteUser()
                      ? <LiteCampaigns initialStep={2} />
                      : <CreateAds user={getUserInfo()} />
                )}/>
                <Route path={AppRoutes.COPY_ADSET} element={(
                  !loggedIn()
                    ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                    : <CreateAds user={getUserInfo()} />
                )}/>
                <Route path={AppRoutes.EDIT_AD} element={(
                  !loggedIn()
                    ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                    : <CreateAds user={getUserInfo()} />
                )}/>
                <Route path={AppRoutes.COPY_AD} element={(
                  !loggedIn()
                    ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                    : <CreateAds user={getUserInfo()} />
                )}/>
                <Route path={AppRoutes.VIEW_CAMPAIGNS_TAB_BY_ACCOUNT} element={(
                  !loggedIn()
                    ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                    : <Manage user={getUserInfo()} />
                )}/>
                <Route path={AppRoutes.VIEW_CAMPAIGNS_BY_ACCOUNT} element={(
                  !loggedIn()
                    ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                    : <Manage user={getUserInfo()} />
                )}/>
                <Route path={AppRoutes.VIEW_CAMPAIGNS_BY_PLATFORM} element={(
                  !loggedIn()
                    ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                    : <Manage user={getUserInfo()} />
                )}/>
                <Route path={AppRoutes.CREATE_CAMPAIGNS} element={(
                    !loggedIn()
                      ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                      : isLiteUser()
                        ? <LiteCampaigns />
                        : <CreateAds user={getUserInfo()} />
                )}/>
                {/* to be removed pushing the code */}
                <Route path="/manage3/create/:platform" element={(
                  !loggedIn()
                    ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                    : <LiteCampaigns user={getUserInfo()} />
                )}/>
                {/* to be removed pushing the code */}
                <Route path={AppRoutes.CREATE_CAMPAIGNS_WITH_PROJECT} element={(
                    !loggedIn()
                      ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                      : <CreateAds user={getUserInfo()} />
                )}/>
                <Route path={AppRoutes.CREATE_CAMPAIGNS_FOR_PLATFORM} element={(
                    !loggedIn()
                      ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                      : <CreateAds user={getUserInfo()} />
                )}/>
                <Route path={AppRoutes.MANAGE} element={(
                  !loggedIn()
                    ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                    : <Manage user={getUserInfo()} />
                )}/>
                <Route path={AppRoutes.BI_DASHBOARD_WITH_SLUG} element={(
                  !loggedIn()
                    ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                    : <BiDashBoard blitzAppId={blitzAppId} />
                )}/>
                <Route path={AppRoutes.BI_DASHBOARD} element={(
                  !loggedIn()
                    ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                    : <BiDashBoard blitzAppId={blitzAppId} />
                )}/>
                <Route path={AppRoutes.PROJECT_PREVIEW} element={(
                  !loggedIn()
                    ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                    : isUserAllowed(AppRoutes.PROJECT_PREVIEW) ? <ProjectPreview /> : <Navigate to="/"/>
                )}/>
                <Route path={AppRoutes.LIBRARY} element={(
                  !loggedIn()
                    ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                    : isUserAllowed(AppRoutes.LIBRARY) ? <Library /> : <Navigate to="/"/>
                )}/>
                <Route path={AppRoutes.LIBRARY_SUBSCRIBED} element={(
                  !loggedIn()
                    ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                    : isUserAllowed(AppRoutes.LIBRARY_SUBSCRIBED) ? <Library /> : <Navigate to="/"/>
                )}/>
                <Route path={AppRoutes.ACCOUNT_SETTINGS_TAB} element={(
                  !loggedIn()
                    ? <Navigate to={`${AppRoutes.HOME}`} />
                    : isUserAllowed(AppRoutes.ACCOUNT_SETTINGS_TAB) ? <AccountSettings /> : <Navigate to="/"/>
                )}/>
                <Route path={AppRoutes.ACCOUNT} element={(
                  !loggedIn()
                    ? <Navigate to={`${AppRoutes.HOME}`} />
                    : isUserAllowed(AppRoutes.ACCOUNT) ? <AccountSettings /> : <Navigate to="/"/>
                )}/>
                <Route path={AppRoutes.BRAND_KIT} element={(
                  !loggedIn()
                    ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                    : <BrandKit />
                )}/>
                <Route path={AppRoutes.TEAM_INVITE} element={
                  getUserInfo() ?
                    <Navigate to={`${AppRoutes.ACCOUNT}${window.location.search}&invite=success`} />
                    :
                    <Navigate to={`${AppRoutes.HOME}${window.location.search}&login=1`} />
                } />
                {/* creators page is hidden, it will show coming soon */}
                <Route path={AppRoutes.MARKETPLACE} element={(
                  !loggedIn()
                    ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                    : <MarketplaceTabs />
                )}/>
                {/* <Route path={AppRoutes.LISTING_DETAILS} element={(
                  !loggedIn()
                    ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                    : <ListingDetails />
                )}/>
                <Route path={AppRoutes.CREATE_LISTING} element={(
                  !loggedIn()
                    ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                    : <CreateListing />
                )}/> */}
                {/* <Route path={AppRoutes.AGENCY}  element={(
                    loggedIn()
                      ? <Navigate to={`${AppRoutes.LIBRARY}${window.location.search}`} />
                      : <Home />
                )} /> */}
                {/* changing route to campaigns page after login */}
                <Route path={AppRoutes.HOME} element={(
                  loggedIn()
                    ? <Navigate to={`${AppRoutes.LIBRARY}`} />
                    : <Home />
                )} />
                
                <Route path={AppRoutes.CREATIVE_LANDING_V3} element={<CreativeLandingV3/>} />
                <Route path={AppRoutes.CREATIVE_LANDING_V3_WITH_SLUG} element={<CreativeLandingV3/>} />
                <Route path={AppRoutes.CREATIVE_LANDING} element={<CreativeLanding/>} />
                <Route path={AppRoutes.CONTACT} element={<Contact/>} />
                <Route path={AppRoutes.ANALYTICS_BASE} element={(
                  !loggedIn()
                    ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                    : isUserAllowed(AppRoutes.ANALYTICS_BASE) ? <Analytics /> : <Navigate to="/"/>
                )}/>
                <Route path={AppRoutes.ANALYTICS} element={(
                  !loggedIn()
                    ? <Navigate to={`${AppRoutes.HOME}?login=1`} />
                    : isUserAllowed(AppRoutes.ANALYTICS_BASE) ? <Analytics /> : <Navigate to="/"/>
                )}/>
                <Route path="" element={
                  <h1>Not Found</h1>
                } />
                <Route path={AppRoutes.LOADING_SCREEN} element={<LoadingScreen />} />
                <Route path={AppRoutes.HOME} element={
                  loggedIn() ? <Navigate to="/"/> : <Home />
                  } />
                {/*<Route path={AppRoutes.PRODUCT} element={
                  loggedIn() ? <Navigate to="/"/> : <Product />
                } />*/}
                <Route path={AppRoutes.CAPABILITIES} element={
                  loggedIn() ? <Navigate to="/"/> : <Capabilities />
                } />
                <Route path={AppRoutes.CASE_STUDIES} element={
                  loggedIn() ? <Navigate to="/"/> : <CaseStudies />
                } />
                <Route path={AppRoutes.ABOUT} element={
                  loggedIn() ? <Navigate to="/"/> : <About />
                } />
                <Route path={AppRoutes.BLOG} element={
                  loggedIn() ? <Navigate to="/"/> : <Blog />
                } />
                <Route path={AppRoutes.BLOG_POSTS} element={<BlogPost />} />
                <Route path={AppRoutes.AI_ADS} element={
                  loggedIn() ? <AiAdsPage /> : <Navigate to="/"/>
                } />

                <Route path={AppRoutes.CANVAS} element={<CanvasAi />} />
              </Routes>
              {/* todo: use Nav from here
              <Route children={({ match }) => <Nav loggedIn={loggedIn() !== null} match={match} />} /> */}
              <SelectSubscriptionModal />
              <ExhaustedCreditsModal />
              <TopupCreditsModal />
              {appContext.user && appContext.user?.fueState !== 'FUE_COMPLETE' && <FUEController />}
              <SupportBubble />
              <PaymentFailureModal />
            </BrowserRouter>
            {/* <PopulateListings isLoggedIn={loggedIn()} /> */}
            <SubscriptionSuccess />
            <CustomPlanEnquirySuccess />
            <VariantsConfiguration />
          </LandingContext.Provider>
        </CreateAdsContextProvider>
      </AppContextProvider>
    </Provider>
  );
}

export default checkRequests(App);
