import { JsonConverter } from "json2typescript";
import { EnumConverter } from "../../Common/EnumConverter";

export function InputTypeValueOf(val:string):InputType {
  switch(val) {
    case "VIDEO": return InputType.VIDEO;
    case "IMAGE": return InputType.IMAGE;
    case "LUT3D": return InputType.LUT3D;
    case "AUDIO": return InputType.AUDIO;
    case "STRING": return InputType.STRING;
    case "MULTI": return InputType.MULTI;
    default: return InputType.STRING;
  }

}

export enum InputType {
   VIDEO,
   IMAGE,
   LUT3D,
   AUDIO,
   STRING,
   MULTI
}



@JsonConverter
export class InputTypeConverter extends EnumConverter<InputType> {
  constructor() {
    super(InputType, "InputType");
  }
}