import { templateJson as template1 } from "../pages/CanvasAi/Components/templatejsons/template1";
import { templateJson as template2 } from "../pages/CanvasAi/Components/templatejsons/template2";
import { templateJson as template3 } from "../pages/CanvasAi/Components/templatejsons/template3";
import { templateJson as template4 } from "../pages/CanvasAi/Components/templatejsons/template4";
import { templateJson as template5 } from "../pages/CanvasAi/Components/templatejsons/template5";
import { templateJson as template6 } from "../pages/CanvasAi/Components/templatejsons/template6";
import { templateJson as template7 } from "../pages/CanvasAi/Components/templatejsons/template7";
import { templateJson as template8 } from "../pages/CanvasAi/Components/templatejsons/template8";
import { templateJson as template9 } from "../pages/CanvasAi/Components/templatejsons/template9";
import { templateJson as template10 } from "../pages/CanvasAi/Components/templatejsons/template10";
import { templateJson as template11 } from "../pages/CanvasAi/Components/templatejsons/template11";
import { templateJson as template12 } from "../pages/CanvasAi/Components/templatejsons/template12";
import { templateJson as template13 } from "../pages/CanvasAi/Components/templatejsons/template13";
import { templateJson as template14 } from "../pages/CanvasAi/Components/templatejsons/template14";
import { templateJson as template15 } from "../pages/CanvasAi/Components/templatejsons/template15";
import { templateJson as template16 } from "../pages/CanvasAi/Components/templatejsons/template16";
import { templateJson as template17 } from "../pages/CanvasAi/Components/templatejsons/template17";
import { templateJson as template18 } from "../pages/CanvasAi/Components/templatejsons/template18";
import { templateJson as template19 } from "../pages/CanvasAi/Components/templatejsons/template19";
import { templateJson as template20 } from "../pages/CanvasAi/Components/templatejsons/template20";
import { templateJson as template21 } from "../pages/CanvasAi/Components/templatejsons/template21";

export const aiAdTemplates = [
  {
    id: 1,
    name: "Bottom Footer User Testimonial Template",
    thumb: 'https://media.useblitz.com/templates/qa/templates/template1/thumbnail.jpg',
    FB: 'https://media.useblitz.com/templates/qa/templates/template1/fb.png',
    IG: 'https://media.useblitz.com/templates/qa/templates/template1/insta.png',
    IS: 'https://media.useblitz.com/templates/qa/templates/template1/story.png',
    json: template1
  },
  {
    id: 2,
    name: "Bottom Overlapping User Testimonial - 1 Template",
    thumb: 'https://media.useblitz.com/templates/qa/templates/template2/thumbnail.jpg',
    FB: 'https://media.useblitz.com/templates/qa/templates/template2/fb.png',
    IG: 'https://media.useblitz.com/templates/qa/templates/template2/insta.png',
    IS: 'https://media.useblitz.com/templates/qa/templates/template2/story.png',
    json: template2
  },
  {
    id: 3,
    name: "Bottom Text with Footer Template",
    thumb: 'https://media.useblitz.com/templates/qa/templates/template3/thumbnail.jpg',
    FB: 'https://media.useblitz.com/templates/qa/templates/template3/fb.png',
    IG: 'https://media.useblitz.com/templates/qa/templates/template3/insta.png',
    IS: 'https://media.useblitz.com/templates/qa/templates/template3/story.png',
    json: template3
  },
  {
    id: 4,
    name: "Multi-Component Layout Template",
    thumb: 'https://media.useblitz.com/templates/qa/templates/template4/thumbnail.jpg',
    FB: 'https://media.useblitz.com/templates/qa/templates/template4/fb.png',
    IG: 'https://media.useblitz.com/templates/qa/templates/template4/insta.png',
    IS: 'https://media.useblitz.com/templates/qa/templates/template4/story.png',
    json: template4
  },
  {
    id: 6,
    name: "Bottom Area Embedded Text Template",
    thumb: 'https://media.useblitz.com/templates/qa/templates/template6/thumbnail.jpg',
    FB: 'https://media.useblitz.com/templates/qa/templates/template6/fb.png',
    IG: 'https://media.useblitz.com/templates/qa/templates/template6/insta.png',
    IS: 'https://media.useblitz.com/templates/qa/templates/template6/story.png',
    json: template6
  },
  {
    id: 7,
    name: "Right-Aligned Triple Text Template",
    thumb: 'https://media.useblitz.com/templates/qa/templates/template7/thumbnail.jpg',
    FB: 'https://media.useblitz.com/templates/qa/templates/template7/fb.png',
    IG: 'https://media.useblitz.com/templates/qa/templates/template7/insta.png',
    IS: 'https://media.useblitz.com/templates/qa/templates/template7/story.png',
    json: template7
  },
  {
    id: 8,
    name: "Left-Aligned Full Text Template",
    thumb: 'https://media.useblitz.com/templates/qa/templates/template8/thumbnail.jpg',
    FB: 'https://media.useblitz.com/templates/qa/templates/template8/fb.png',
    IG: 'https://media.useblitz.com/templates/qa/templates/template8/insta.png',
    IS: 'https://media.useblitz.com/templates/qa/templates/template8/story.png',
    json: template8
  },
  {
    id: 9,
    name: "Left-Aligned Triple Text Template",
    thumb: 'https://media.useblitz.com/templates/qa/templates/template9/thumbnail.jpg',
    FB: 'https://media.useblitz.com/templates/qa/templates/template9/fb.png',
    IG: 'https://media.useblitz.com/templates/qa/templates/template9/insta.png',
    IS: 'https://media.useblitz.com/templates/qa/templates/template9/story.png',
    json: template9
  },
  {
    id: 10,
    name: "Footer Text Only Template",
    thumb: 'https://media.useblitz.com/templates/qa/templates/template10/thumbnail.jpg',
    FB: 'https://media.useblitz.com/templates/qa/templates/template10/fb.png',
    IG: 'https://media.useblitz.com/templates/qa/templates/template10/insta.png',
    IS: 'https://media.useblitz.com/templates/qa/templates/template10/story.png',
    json: template10
  },
  {
    id: 11,
    name: "Center Overlapping Text Template",
    thumb: 'https://media.useblitz.com/templates/qa/templates/template11/thumbnail.jpg',
    FB: 'https://media.useblitz.com/templates/qa/templates/template11/fb.png',
    IG: 'https://media.useblitz.com/templates/qa/templates/template11/insta.png',
    IS: 'https://media.useblitz.com/templates/qa/templates/template11/story.png',
    json: template11
  },
  {
    id: 17,
    name: "Five Star Camera",
    thumb: 'https://media.useblitz.com/templates/qa/templates/template17/thumbnail.jpg',
    FB: 'https://media.useblitz.com/templates/qa/templates/template17/fb.png',
    IG: 'https://media.useblitz.com/templates/qa/templates/template17/insta.png',
    IS: 'https://media.useblitz.com/templates/qa/templates/template17/story.png',
    json: template17
  },
  {
    id: 18,
    name: "Bottom Overlapping User Testimonial - 2 Template",
    thumb: 'https://media.useblitz.com/templates/qa/templates/template18/thumbnail.jpg',
    FB: 'https://media.useblitz.com/templates/qa/templates/template18/fb.png',
    IG: 'https://media.useblitz.com/templates/qa/templates/template18/insta.png',
    IS: 'https://media.useblitz.com/templates/qa/templates/template18/story.png',
    json: template18
  },
  {
  id: 19,
  name: "Centered Embedded Text Template",
  thumb: 'https://useblitz.s3.amazonaws.com/templates/qa/templates/template19/thumbnail.jpg',
  FB: 'https://useblitz.s3.amazonaws.com/templates/qa/templates/template19/fb.png',
  IG: 'https://useblitz.s3.amazonaws.com/templates/qa/templates/template19/insta.png',
  IS: 'https://useblitz.s3.amazonaws.com/templates/qa/templates/template19/story.png',
  json: template19
  },
  {
  id: 20,
  name: "Five Star Nature",
  thumb: 'https://useblitz.s3.amazonaws.com/templates/qa/templates/template20/thumbnail.jpg',
  FB: 'https://useblitz.s3.amazonaws.com/templates/qa/templates/template20/fb.png',
  IG: 'https://useblitz.s3.amazonaws.com/templates/qa/templates/template20/insta.png',
  IS: 'https://useblitz.s3.amazonaws.com/templates/qa/templates/template20/story.png',
  json: template20
  },
  {
  id: 21,
  name: "Twitter Testimonial",
  thumb: 'https://useblitz.s3.amazonaws.com/templates/qa/templates/template21/thumbnail.jpg',
  FB: 'https://useblitz.s3.amazonaws.com/templates/qa/templates/template21/fb.png',
  IG: 'https://useblitz.s3.amazonaws.com/templates/qa/templates/template21/insta.png',
  IS: 'https://useblitz.s3.amazonaws.com/templates/qa/templates/template21/story.png',
  json: template21
  }
];

export default aiAdTemplates;
