import React, { useContext, useEffect } from 'react';
import { CanvasAiContext } from './Common/CanvasAiContext';
import { CANVAS_DIMENSIONS } from './Common/Constants';
import DrawCanvas from './Components/DrawCanvas';

interface ICanvas {
}

const Canvas: React.FC<ICanvas> = () => {

  const { isLoading } = useContext(CanvasAiContext);

  useEffect(() => {
    const c = new DrawCanvas('ai-canvas');
    c.test();
  }, []);

  return (
    <canvas
      id="ai-canvas"
      width={1080}
      height={300}
    ></canvas>
  );
};

export default Canvas;
