import { configureStore, combineReducers, ThunkAction, Action } from "@reduxjs/toolkit";
import logger from 'redux-logger';
import userAccountReducer from '../data-stores/account-slice';
import projectsReducer from '../data-stores/project-slice';
import exportsReducer from '../data-stores/exports-slice';
import footagesReducer from '../data-stores/footages-slice';
import projectInfoReducer from '../data-stores/project-info-slice';
import brandKitReducer from '../data-stores/brand-kit-slice';
import marketplaceReducer from '../data-stores/marketplace-slice';

const rootReducer = combineReducers({
  userAccount: userAccountReducer,
  projects: projectsReducer,
  projectInfo: projectInfoReducer,
  projectExports: exportsReducer,
  footages: footagesReducer,
  brandKit: brandKitReducer,
  marketplace: marketplaceReducer,
});

const Store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof rootReducer>

export type AppDispatch = typeof Store.dispatch;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

export default Store;
