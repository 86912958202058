enum Routes {
  // AGENCY = '/agency',
  // HOME = AGENCY,
  HOME = '/',
  PRODUCT = '/product',
  CAPABILITIES = '/capabilities',
  CASE_STUDIES = '/case-studies',
  ABOUT = '/about',
  BLOG = '/blog',
  BLOG_POSTS = '/blog/:slug',
  STUDIO_PAGE = '/',
  RESET_PASSWORD = '/forgot',
  TERMS_OF_SERVICE = '/terms-of-service',
  PRIVACY_POLICY = '/privacy-policy',
  CREATE = '/create',
  // CREATOR = '/creator',
  CONTACT = '/contact',
  CREATIVE_LANDING = '/creative',
  CREATIVE_LANDING_V3 = '/creative/v3',
  CREATIVE_LANDING_V3_WITH_SLUG = '/creative/v3/:slug',
  MANAGE = '/manage',
  BI_DASHBOARD_PATH_PREFIX = '/bi-dashboard/',
  BI_DASHBOARD = '/bi-dashboard/:analyticsAppId',
  BI_DASHBOARD_WITH_SLUG = '/bi-dashboard/:analyticsAppId/:slug',
  CREATE_CAMPAIGNS = '/manage2/create/:platform',
  CREATE_CAMPAIGNS_WITH_PROJECT = '/manage2/create/:platform/:projectId',
  CREATE_CAMPAIGNS_FOR_PLATFORM = '/manage2/create',
  VIEW_CAMPAIGNS_BY_PLATFORM = '/manage/:platform',
  VIEW_CAMPAIGNS_BY_ACCOUNT = '/manage/:platform/:adAccountId',
  VIEW_CAMPAIGNS_TAB_BY_ACCOUNT = '/manage/:platform/:adAccountId/:slug',
  ADD_ADSET = '/manage/:adAccountId/campaign/:platform/:id/addAdSet',
  ADD_ADS =  '/manage/:adAccountId/adSet/:platform/:createAdSetId/addAds',
  ADD_ADS_PROJECT = "/manage/:projectId/:adAccountId/adSet/:platform/:createAdSetId/addAds",
  EDIT_CAMPAIGN = '/manage/:adAccountId/campaign/:platform/:campaignId/edit',
  COPY_CAMPAIGN = '/manage/:adAccountId/campaign/:platform/:copyCampaignId/copy',
  EDIT_ADSET = '/manage/:adAccountId/adSet/:platform/:adSetId/edit',
  COPY_ADSET = '/manage/:adAccountId/adSet/:platform/:id/:copyAdSetId/copy',
  EDIT_AD  = '/manage/:adAccountId/ad/:platform/:adId/edit',
  COPY_AD = '/manage/:adAccountId/ad/:platform/:copyAdParentId/:copyAdId/copy',
  LIBRARY = '/projects',
  LIBRARY_SUBSCRIBED = '/projects/:subscribedModal',
  PROJECT_PREVIEW = '/projects/:projectId/:type',
  ACCOUNT = '/account',
  ACCOUNT_SETTINGS_TAB = '/account/:slug',
  BRAND_KIT = '/brand-kit',
  TEAM_INVITE = '/teaminvite',
  //creators page is hidden, it will show coming soon
  MARKETPLACE = '/marketplace',
  CREATE_LISTING = '/marketplace/create-listing',
  LISTING_DETAILS = '/marketplace/listing/:id',
  ANALYTICS_BASE = '/analytics',
  ANALYTICS = '/analytics/:slug',
  TICKERS = '/analytics/tickers',
  REPORTS = '/analytics/reports',
  ADVERTISING_FUNNEL = '/analytics/v2/advertising_funnel',
  LOADING_SCREEN = '/loading',
  CANVAS = '/canvas',
  AI_ADS = '/ai-ads'
}

export default Routes;
