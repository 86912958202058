import Axios from "axios";

import { TemplateType } from "../common/model";
import { Template, Creative } from '../../src/data-stores/project-slice';

export async function  getProjects(
    pageNumber: number,
    pageSize: number,
    searchQuery?: string
  ) {
  const search = searchQuery ? `&search=${searchQuery}` : '';
  const url = `/api/user/projects?pageNumber=${pageNumber}&pageSize=${pageSize}${search}`;
  // const { data } = await axios.get<Project>(url);
  const { data } = await Axios.get(url);
  return data;
}

export async function getProject(projectId: number) {
  const url = `/api/user/project/${projectId}/info`;
  const { data } = await Axios.get(url);
  return data;
}

export async function getFootages(
    pageNumber: number,
    pageSize: number,
    projectId: number,
    searchQuery?: string | undefined,
    filterCriteria?: string[] | undefined
  ) {
  const search = searchQuery ? `&search=${searchQuery}` : '';
  const filter = Array.isArray(filterCriteria) && filterCriteria.length ? `&platform=${filterCriteria.join('|')}` : '';
  const url = `/api/user/project/${projectId}/footages?pageNumber=${pageNumber}&pageSize=${pageSize}${search}${filter}`;
  const { data } = await Axios.get(url);
  return data;
}

export async function getExports(
    pageNumber: number,
    pageSize: number,
    projectId: number,
    searchQuery?: string | undefined,
    filterCriteria?: string[] | undefined
  ) {
  const search = searchQuery ? `&search=${searchQuery}` : '';
  const filter = Array.isArray(filterCriteria) && filterCriteria.length ? `&platform=${filterCriteria.join('|')}` : '';
  const url = `/api/user/project/${projectId}/exports?pageNumber=${pageNumber}&pageSize=${pageSize}${search}${filter}`;
  const { data } = await Axios.get(url);
  return data;
}

export async function deleteTemplateXhr(
  templateId: number
) {
  const url = `/api/user/template/${templateId}`;
  const { data } = await Axios.delete(url);
  return data;
}

export async function uploadAsset(
  name: string,
  url: string,
  type: string,
  templateType: TemplateType
) {
  let mediaType = '';
  if (type.startsWith('image/')) mediaType = 'IMAGE';
  if (type.startsWith('video/')) mediaType = 'VIDEO';
  if (!mediaType) {
    console.warn('invalid file type for uploading asset', type);
    return;
  }
  return await Axios.post(
  '/api/user/hostVideos',
  {
    footages: [
      {
        name,
        url,
        mediaType,
      }
    ],
    isCompanyWide: false,
    templateType,
  },
  );
}

export async function cloneTemplate(
  templateId: number,
  name: string
) {
  if (!name) {
    console.warn('invalid tenplate name', name);
    return;
  }
  const url = `/api/user/template/${templateId}/clone`;
  const { data } =  await Axios.post(
  url,
  {
    name,
  },
  );
 return data;
};

/**
 * Deletes asset for a given asset id
 * @param id - id of the asset that needs to be deleted
 * @returns Axios object
 */
export const deleteAsset = async (id) => {
  try {
    const data = await Axios.delete(`/api/user/hostVideo/${id}`);
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * Deletes creative for a given creative id
 * @param id - id of the creative that needs to be deleted
 * @returns Axios object
 */
 export const deleteCreative = async (id) => {
  try {
    const data = await Axios.delete(`/api/user/project/creative/${id}`);
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * Renames a creative
 * @param id - id of the creative that needs to be deleted
 * @returns Axios object
 */
 export const renameCreative = async (id, payload) => {
  try {
    const data = await Axios.put(
      `/api/user/project/creative/${id}/rename`,
      payload
    );
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * Renames an asset
 * @param id - id of the creative that needs to be deleted
 * @returns Axios object
 */
 export const renameAsset = async (id, payload) => {
  try {
    const data = await Axios.put(
      `/api/user/project/asset/${id}/rename`,
      payload
    );
    return data;
  } catch (e) {
    throw e;
  }
};

/* 
* Creates a new Project Template
*/
export async function createTemplate(payload) {
  const template: Template = payload;
  
  return await Axios.post('/api/user/template', template);
}

/* 
* Adds creatives to existing Project
*/
export async function addCreatives(
  templateID: string,
  mediaUrls: string[],
  isFromDropbox: boolean,
  isThumbnailRequired: boolean
) {
  return await Axios.post(
  `/api/user/addCreatives/${templateID}`,
  {
    mediaUrls,
    isFromDropbox,
    isThumbnailRequired
  },
  );
}
