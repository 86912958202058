import axios from "axios";
import React, {useEffect, useState, useContext} from "react";
import { AppContext } from "../appContext";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Routes from "./routes";

const FUEController = () => {
  const { user, refreshUser } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (user?.fueState === 'FUE_SUBSCRIBED') {
      navigate('/projects/subscribedModal');
    }
    else if (user?.fueState === 'FUE_CREATION') {
      let transferredMedia = localStorage.getItem('transferredMedia') || 'no_data';
      if (transferredMedia === 'no_data') {
        navigate(Routes.AI_ADS);
      }
      else {
        navigate('/projects/linkAccounts');
      }
    }
    else if (user?.fueState === 'FUE_PRODUCT_TOUR') {
      navigate('/projects/productTourModal');
    }
  }, [user]);

  return <></>;
};

export default FUEController;

