import React from "react";
import axios from "axios";

const checkRequests = (Wrapped:any) => {
    function CheckRequests(props:any) {
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (!error.response || !error.response.status) {
                return Promise.reject(error);
            }
            switch (error.response.status) {
                case 401:
                    localStorage.removeItem("userInfo");
                    window.location.href = '/';
                    break
                default:
                    break
            }
            // Do something with response error
            return Promise.reject(error);
        });

        return (
            <Wrapped {...props} />
        )
    }
    return CheckRequests
}

export default checkRequests
