import { JsonObject, JsonProperty } from "json2typescript";
import { Component } from "./Component";
import { VideoPlatform } from "../templates/VideoPlatform";
import { AspectType, AspectTypeConverter } from "./AspectType";
import { Pair } from "../inputs/Pair";

@JsonObject("ImageComponent")
export class ImageComponent extends Component {

    @JsonProperty("imgUrl")
    imgUrl: string = '';

    @JsonProperty("aspectType", String)
    aspectType = "ASPECT_SCALE";



    getScaledDimension(image: HTMLImageElement, previewMultiplier: number, parentImage: HTMLCanvasElement | null): Pair<number, number> {

        let original_width = image.naturalWidth;
        let original_height = image.naturalHeight;
        let bound_width = this.getEffectiveWidth(previewMultiplier, parentImage);
        let bound_height = this.getEffectiveHeight(previewMultiplier, parentImage);

        switch (this.aspectType) {
            case "ASPECT_FIT": {
                /*int new_width = original_width;
                int new_height = original_height;
                if (original_width > bound_width) {
                    new_width = bound_width;
                    new_height = (int) ((new_width * original_height * 1.0) / original_width);
                }

                if (new_height > bound_height) {
                    new_height = bound_height;
                    new_width = (int) ((new_height * original_width * 1.0) / original_height);
                }

                return new Dimension(new_width, new_height);*/
                let new_width = bound_width;
                let new_height = ((new_width * original_height * 1.0) / original_width);

                if (new_height > bound_height) {
                    new_height = bound_height;
                    new_width = ((new_height * original_width * 1.0) / original_height);
                }
                return new Pair<number, number>(new_width, new_height);
            }
            case "ASPECT_FILL": {
                let new_width = bound_width;
                let new_height = ((new_width * original_height * 1.0) / original_width);

                if (new_height < bound_height) {
                    new_height = bound_height;
                    new_width = ((new_height * original_width * 1.0) / original_height);
                }
                return new Pair<number, number>(new_width, new_height);
            }
            default:
                return new Pair<number, number>(bound_width, bound_height);
        }
    }

    getEffectiveWidth(previewMultiplier: number, parentImage: HTMLCanvasElement | null): number {
        return this.componentWidthAsFloat(previewMultiplier, parentImage) - (2.0 * this.paddingAsFloat(previewMultiplier));
    }


    getEffectiveHeight(previewMultiplier: number, parentImage: HTMLCanvasElement | null): number {
        return this.componentHeightAsFloat(previewMultiplier, parentImage) - (2.0 * this.paddingAsFloat(previewMultiplier));
    }


    dx1(image: HTMLImageElement, previewMultiplier: number, parentImage: HTMLCanvasElement | null): number {
        if (this.aspectType === null || this.aspectType !== "ASPECT_FIT") {
            return 0;
        }
        return Math.round((this.getEffectiveWidth(1, parentImage) - this.getScaledDimension(image, 1, parentImage)._1) * previewMultiplier / 2.0);
    }


    dy1(image: HTMLImageElement, previewMultiplier: number, parentImage: HTMLCanvasElement | null): number {
        if (this.aspectType === null || this.aspectType !== "ASPECT_FIT") {
            return 0;
        }
        return Math.round((this.getEffectiveHeight(1, parentImage) - this.getScaledDimension(image, 1, parentImage)._2) * previewMultiplier / 2.0);
    }


    dx2(image: HTMLImageElement, previewMultiplier: number, parentImage: HTMLCanvasElement | null): number {
        if (this.aspectType === null || this.aspectType !== "ASPECT_FIT") {
            return this.componentWidthAsFloat(previewMultiplier, parentImage);
        }
        return Math.round((this.getEffectiveWidth(1, parentImage) + this.getScaledDimension(image, 1, parentImage)._1) * previewMultiplier / 2.0);
    }

    dy2(image: HTMLImageElement, previewMultiplier: number, parentImage: HTMLCanvasElement | null): number {
        if (this.aspectType === null || this.aspectType !== "ASPECT_FIT") {
            return this.componentHeightAsFloat(previewMultiplier, parentImage);
        }
        return Math.round((this.getEffectiveHeight(1, parentImage) + this.getScaledDimension(image, 1, parentImage)._2) * previewMultiplier / 2.0);
    }




    sx1(image: HTMLImageElement, previewMultiplier: number, parentImage: HTMLCanvasElement | null): number {
        if (this.aspectType === null || this.aspectType !== "ASPECT_FILL") {
            return 0;
        }
        let scaledDimension = this.getScaledDimension(image, previewMultiplier, parentImage);
        let value = (image.naturalWidth * 1.0 / scaledDimension._1) * (scaledDimension._1 - this.getEffectiveWidth(previewMultiplier, parentImage)) / 2.0;
        return Math.round(value);
    }


    sy1(image: HTMLImageElement, previewMultiplier: number, parentImage: HTMLCanvasElement | null): number {
        if (this.aspectType === null || this.aspectType !== "ASPECT_FILL") {
            return 0;
        }

        let scaledDimension = this.getScaledDimension(image, previewMultiplier, parentImage);
        let value = (image.naturalHeight * 1.0 / scaledDimension._2) * (scaledDimension._2 - this.getEffectiveHeight(previewMultiplier, parentImage)) / 2.0;
        return Math.round(value);
    }


    sx2(image: HTMLImageElement, previewMultiplier: number, parentImage: HTMLCanvasElement | null): number {
        if (this.aspectType === null || this.aspectType !== "ASPECT_FILL") {
            return image.naturalWidth;
        }
        let scaledDimension = this.getScaledDimension(image, previewMultiplier, parentImage);
        let value = (image.naturalWidth * 1.0 / scaledDimension._1) * (scaledDimension._1 + this.getEffectiveWidth(previewMultiplier, parentImage)) / 2.0;
        return Math.round(value);
    }

    sy2(image: HTMLImageElement, previewMultiplier: number, parentImage: HTMLCanvasElement | null): number {
        if (this.aspectType === null || this.aspectType !== "ASPECT_FILL") {
            return image.naturalHeight;
        }
        let scaledDimension = this.getScaledDimension(image, previewMultiplier, parentImage);
        let value = (image.naturalHeight * 1.0 / scaledDimension._2) * (scaledDimension._2 + this.getEffectiveHeight(previewMultiplier, parentImage)) / 2;
        return Math.round(value);
    }

    async drawComponent(frameNumber: number, previewMultiplier: number, parentImage: HTMLCanvasElement | null, videoPlatform: VideoPlatform): Promise<HTMLCanvasElement> {
        let componentWidth: number = this.componentWidthAsFloat(previewMultiplier, parentImage);
        let componentHeight: number = this.componentHeightAsFloat(previewMultiplier, parentImage);
        let img: HTMLImageElement = document.createElement("img");
        img.crossOrigin = "anonymous";
        img.src = this.imgUrl;
        await img.decode();
        let canv: HTMLCanvasElement = this.canvas(componentWidth, componentHeight);
        let ctx = canv.getContext("2d");
        if (ctx === null) {
            throw ("exception while getting ctx");
        }
        let sx1 = this.sx1(img, previewMultiplier, parentImage);
        let sy1 = this.sy1(img, previewMultiplier, parentImage);
        let sx2 = this.sx2(img, previewMultiplier, parentImage);
        let sy2 = this.sy2(img, previewMultiplier, parentImage);

        let dx1 = this.dx1(img, previewMultiplier, parentImage);
        let dy1 = this.dy1(img, previewMultiplier, parentImage);
        let dx2 = this.dx2(img, previewMultiplier, parentImage);
        let dy2 = this.dy2(img, previewMultiplier, parentImage);
        
        ctx.drawImage(img, 
            sx1, sy1, 
            sx2-sx1, sy2-sy1, 
            dx1, dy1, 
            dx2-dx1,dy2-dy1
        );
        return this.image(canv);
    }

}