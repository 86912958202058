import { createSlice } from "@reduxjs/toolkit";
import { addColorXhr, addFontXhr, deleteColorXhr, getTeamAssetsXhr,
  updateLogoXhr, deleteFontXhr, getGlobalFontsXhr, IGlobalFont } from "../api/brand-kit-api";

interface IBrandKitAsset {
  updatedAt: string;
  createdAt: string;
  assetId: number;
  teamId: number;
  lastEditedUserId: number;
  assetType: string;
  assetUrlOrCode: string;
  assetName: string;
  isDefault: boolean;
}

interface IDeletableAssetMeta {
  loading: boolean;
  error: string;
  deleting: boolean;
  deleteError: string;
}

interface IBrandKitState {
  loading: boolean;
  error: string;
  assets: {
    COLOR: IBrandKitAsset[];
    FONT: IBrandKitAsset[];
    WATERMARK: IBrandKitAsset[];
    LOGO: IBrandKitAsset[];
  }
  logo: {
    loading: boolean;
    error: string;
  }
  color: IDeletableAssetMeta;
  font: IDeletableAssetMeta;
  globalFonts: {
    loading: boolean;
    error: string;
    fonts: IGlobalFont[];
  };
}

const initialState: IBrandKitState = {
  loading: false,
  error: '',
  assets: {
    COLOR: [],
    FONT: [],
    WATERMARK: [],
    LOGO: [],
  },
  logo: {
    loading: false,
    error: '',
  },
  color: {
    loading: false,
    error: '',
    deleting: false,
    deleteError: '',
  },
  font: {
    loading: false,
    error: '',
    deleting: false,
    deleteError: '',
  },
  globalFonts: {
    loading: false,
    error: '',
    fonts: [],
  },
};

const BrandKitSlice = createSlice({
  name: 'brand-kit',
  initialState,
  reducers: {
    getTeamAssetsStart(state, action) {
      state.loading = true;
      state.error = '';
      state.assets = { ...initialState.assets };
    },
    getTeamAssetsSuccess(state, action) {
      state.loading = false;
      state.error = '';
      state.assets = action.payload;
    },
    getTeamAssetsError(state, action) {
      state.loading = false;
      state.error = action.payload.error;
      state.assets = { ...initialState.assets };
    },

    updateLogoStart(state, action) {
      state.logo.loading = true;
      state.logo.error = '';
    },
    updateLogoSuccess(state, action) {
      state.logo.loading = false;
      state.logo.error = '';
      state.assets.LOGO = [action.payload];
    },
    updateLogoError(state, action) {
      state.logo.loading = false;
      state.logo.error = action.payload.error;
    },

    addColorStart(state, action) {
      state.color.loading = true;
      state.color.error = '';
    },
    addColorSuccess(state, action) {
      state.color.loading = false;
      state.color.error = '';
      state.assets.COLOR = [...state.assets.COLOR, action.payload];
    },
    addColorError(state, action) {
      state.color.loading = false;
      state.color.error = action.payload.error;
    },

    deleteColorStart(state, action) {
      state.color.deleting = true;
      state.color.deleteError = '';
    },
    deleteColorSuccess(state, action) {
      state.color.deleting = false;
      state.color.deleteError = '';
      console.info(action);
      const deletedIndex = state.assets.COLOR.findIndex(color => color.assetId === action.payload.colorId);
      state.assets.COLOR.splice(deletedIndex, 1);
    },
    deleteColorError(state, action) {
      state.color.deleting = false;
      state.color.deleteError = action.payload.error;
    },

    addFontStart(state, action) {
      state.font.loading = true;
      state.font.error = '';
    },
    addFontSuccess(state, action) {
      state.font.loading = false;
      state.font.error = '';
      state.assets.FONT = [...state.assets.FONT, action.payload];
    },
    addFontError(state, action) {
      state.font.loading = false;
      state.font.error = action.payload.error;
    },

    deleteFontStart(state, action) {
      state.font.deleting = true;
      state.font.deleteError = '';
    },
    deleteFontSuccess(state, action) {
      state.font.deleting = false;
      state.font.deleteError = '';
      const deletedIndex = state.assets.FONT.findIndex(font => font.assetId === action.payload.assetId);
      state.assets.FONT.splice(deletedIndex, 1);
    },
    deleteFontError(state, action) {
      state.font.deleting = false;
      state.font.deleteError = action.payload.error;
    },

    getGlobalFontsStart(state, action) {
      state.globalFonts.loading = true;
      state.globalFonts.error = '';
    },
    getGlobalFontsSuccess(state, action) {
      state.globalFonts.loading = false;
      state.globalFonts.fonts = action.payload;
    },
    getGlobalFontsError(state, action) {
      state.globalFonts.loading = false;
      state.globalFonts.error = action.payload.error;
    },
  },
});

export const {
  getTeamAssetsStart,
  getTeamAssetsSuccess,
  getTeamAssetsError,

  updateLogoStart,
  updateLogoSuccess,
  updateLogoError,

  addColorStart,
  addColorSuccess,
  addColorError,

  deleteColorStart,
  deleteColorSuccess,
  deleteColorError,

  addFontStart,
  addFontSuccess,
  addFontError,

  deleteFontStart,
  deleteFontSuccess,
  deleteFontError,

  getGlobalFontsStart,
  getGlobalFontsSuccess,
  getGlobalFontsError,
} = BrandKitSlice.actions;

export const fetchTeamAssets = () => async dispatch => {
  try {
    dispatch(getTeamAssetsStart);
    const assets = await getTeamAssetsXhr();
    dispatch(getTeamAssetsSuccess(assets));
  } catch (err) {
    dispatch(getTeamAssetsError(err));
  }
};

export const updateLogoDispatcher = (
  assetUrlCode: string,
  assetName: string,
  assetType: string
) => async dispatch => {
  try {
    dispatch(updateLogoStart);
    const assets = await updateLogoXhr(assetUrlCode, assetName, assetType);
    dispatch(updateLogoSuccess(assets));
    dispatch(fetchTeamAssets());
  } catch (err) {
    dispatch(updateLogoError(err));
  }
};

export const addColorDispatcher = (
  assetUrlCode: string,
  assetName: string
) => async dispatch => {
  try {
    dispatch(addColorStart);
    const color = await addColorXhr(assetUrlCode, assetName);
    dispatch(addColorSuccess(color));
  } catch (err) {
    dispatch(addColorError(err));
  }
};

export const deleteColorDispatcher = (colorId: number) => async dispatch => {
  try {
    dispatch(deleteColorStart);
    const response = await deleteColorXhr(colorId);
    dispatch(deleteColorSuccess({ colorId, response }));
  } catch (err) {
    dispatch(deleteColorError(err));
  }
};

export const addFontDispatcher = (
  fontName: string,
  fontUrl: string
) => async dispatch => {
  try {
    dispatch(addFontStart);
    const font = await addFontXhr(fontName, fontUrl);
    dispatch(addFontSuccess(font));
  } catch (err) {
    dispatch(addFontError(err));
  }
};

export const deleteFontDispatcher = (assetId: number) => async dispatch => {
  try {
    dispatch(deleteFontStart);
    const response = await deleteFontXhr(assetId);
    dispatch(deleteFontSuccess({ assetId, response }));
  } catch (err) {
    dispatch(deleteFontError(err));
  }
};

export const fetchGlobalFonts = () => async dispatch => {
  try {
    dispatch(getGlobalFontsStart);
    const assets = await getGlobalFontsXhr();
    dispatch(getGlobalFontsSuccess(assets));
  } catch (err) {
    dispatch(getGlobalFontsError(err));
  }
};

export default BrandKitSlice.reducer;
