import { createSlice } from "@reduxjs/toolkit";
import { getFootages } from "../api/projects-api";

export interface IAsset {
  Id: number;
  createdAt: string | null;
  description: string | null;
  friendlyName: string | null;
  isPremium: boolean;
  name: string;
  templateType: string;
  type: string;
  updatedAt: string | null;
  url: string;
}

export interface FootagesState {
  loading: boolean;
  error: string;
  footages: IAsset[];
}

const FootagesSlice = createSlice({
  name: 'footages',
  initialState: {
    loading: true,
    error: '',
    footages: []
  } as FootagesState,
  reducers: {
    getExportsStart(state, action) {
      state.loading = true;
      state.error = '';
      state.footages = [];
    },
    getExportsSuccess(state, action) {
      state.loading = false;
      state.error = '';
      state.footages = action.payload;
    },
    getExportsError(state, action) {
      state.loading = false;
      state.error = action.payload.error;
      state.footages = [];
    },
  },
});

export const {
  getExportsStart,
  getExportsSuccess,
  getExportsError
} = FootagesSlice.actions;

export const fetchFootages = (
  projectId: number,
  searchQuery?: string | undefined,
  filterCriteria?: string[] | undefined
) => async dispatch => {
  try {
    dispatch(getExportsStart);
    const footages = await getFootages(0, 0, projectId, searchQuery, filterCriteria);
    dispatch(getExportsSuccess(footages));
  } catch (err) {
    dispatch(getExportsError(err));
  }
};

export default FootagesSlice.reducer;
