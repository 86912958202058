export function getQueryParamFormURL(url:string):string {
    if (url === undefined || url === '') {
        return "";
    }
    let count:number = url.length;
    let foundQuestionMark:boolean = false;
    let position:number = 0;
    for (let i:number = count-1; i>=0; i--) {
        if (url.charAt(i) === '?') {
            foundQuestionMark = true;
            position = i;
            break;
        }
    }
    let resultString:string = url;
    if (foundQuestionMark) {
        return resultString.substring(position+1,url.length);
    }
    return "";
}

export function updatedQueryParamForURL(url:string, query:string):string {
    if (url === undefined || url === '') {
        return "";
    }
    let count:number = url.length;
    let foundQuestionMark:boolean = false;
    let position:number = 0;
    for (let i:number = count-1; i>=0; i--) {
        if (url.charAt(i) === '?') {
            foundQuestionMark = true;
            position = i;
            break;
        }
    }
    let resultString:string = url;
    if (foundQuestionMark) {
        if (query !== undefined && query !== '') {
            resultString = resultString.substring(0, position) + "&" + query.trim();
        }
    } 
    else {
        if (query !== undefined && query !== '') {
            resultString = resultString + "?" + query.trim();
        }
    }
    return resultString;
}