import constants from '../../constants';
import { sendBIEvent } from "../../api/analytics-api";

declare const window: any;

const track = (event, content = {} ) => {
  const pageDetails = { pageLocation:  window.location.href, pagePath: window.location.pathname, pageTitle: document.title };
  const initialContent = { eventName: "web_event", action: event, page: pageDetails };
  if (constants.env() === 'PROD') {
    window.creativeFbPixel = window.fbq;
    window.creativeFbPixel && window.creativeFbPixel('track', event, content);
    sendBIEvent({...initialContent, ...content });
  }
};

const Events = {
  track
};

export default Events;
