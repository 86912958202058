import { alphaDecimalVal } from "../../Common/MathExpressionStatement";
import { VideoPlatform } from "../templates/VideoPlatform";
import { ShapeComponent } from "./ShapeComponent";

export class CircularComponent extends ShapeComponent {

    async drawComponent(frameNumber: number, previewMultiplier: number, parentImage: HTMLCanvasElement | null, videoPlatform: VideoPlatform): Promise<HTMLCanvasElement> {
        let componentWidth: number = this.componentWidthAsFloat(previewMultiplier, parentImage);
        let componentHeight: number = this.componentHeightAsFloat(previewMultiplier, parentImage);
        let strokeWidth: number = this.strokeWidthval(previewMultiplier);
        
        let canv: HTMLCanvasElement = this.canvas(componentWidth, componentHeight);
        let ctx = canv.getContext("2d");
        if (ctx === null) {
            throw ("exception while getting ctx");
        }
        ctx.globalAlpha = this.shapeColor === '0' ? 0 : alphaDecimalVal([this.shapeColor]);
        if (this.isFill()) {
            ctx.fillStyle = this.shapeColor;
        }
        ctx.strokeStyle = this.strokeColor === '' ? "#"+this.shapeColor : "#"+this.strokeColor;
        if (strokeWidth > 1) {
            ctx.lineWidth = strokeWidth;
        }
        ctx.ellipse(componentWidth / 2 , componentHeight / 2 , (componentWidth - strokeWidth)/2, (componentHeight - strokeWidth)/2, 0, 0, Math.PI * 2, false);
        if (this.isFill()) {
            ctx.fill();
        }
        else {
            ctx.stroke();
        }
        return this.image(canv);
    }
}