import React from 'react';

import PostContentGeneration from "./PostContentGeneration";
import PostUserContentImportant from './PostUserContentImportant';
import PostPlatformsNft from './PostPlatformsNft';
import PostThreeCourseDinner from './PostThreeCourseDinner';
import PostRiseOfTikTok from './PostRiseOfTikTok';
import PostMentalHealth from './PostMentalHealth';
import PostMarketingMistakes from './PostMarketingMistakes';
import PostInfluencerMarketing from './PostInfluencerMarketing';
import PostABTesting from './PostABTesting';
import PostCreateWinningAd from './PostCreateWinningAd';


export const externalLink = (url, text, space = true) => {
  return (
    <>
      {space ? ' ' : ''}<a href={url} rel="noreferrer" target="_blank">{text}</a>
    </>
  );
};

export type IBlogPost = {
  slug: string;
  subHead: string;
  title: string;
  image: string;
  author: string;
  date: string;
  content: JSX.Element;
  authorImage?: string;
};

const Posts: IBlogPost[] = [
  {
    slug: 'how-to-get-user-generated-content-for-paid-social',
    subHead: 'Creative',
    title: 'Content Generation 101: How to get User Generated Content for Paid Social',
    image: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/637bd45a4f019be4667ab97d_Content%20Generation%20101%20How%20to%20get%20User%20Generated%20Content%20for%20Paid%20Social-p-1080.png',
    author: 'Kaylie Mings',
    date: 'November 21, 2022',
    content: <PostContentGeneration />
  },
  {
    slug: 'why-is-user-generated-content-so-important',
    subHead: 'Marketing Strategy',
    title: 'Why is user generated content so important?',
    image: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/636eae39adc7c1d0b6adaca2_2%20(1)-p-1080.png',
    author: 'Kaylie Mings',
    date: 'November 12, 2022',
    content: <PostUserContentImportant />
  },
  {
    slug: 'ad-of-the-week-mental-health-matters',
    subHead: 'Ad of the Week',
    image: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/636ea85bad2b3030c75cf46f_JeffreyMarsh-p-1080.png',
    title: 'Ad of the Week: Mental Health Matters',
    author: 'Kaylie Mings',
    date: 'November 11, 2022',
    content: <PostMentalHealth />
  },
  {
    slug: 'best-community-building-platforms-for-nft-drops',
    subHead: 'web3',
    image: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/636aa851c5bb9c5d40067fb2_2-p-1080.png',
    title: 'Best Community Building Platforms for NFT Drops',
    author: 'Kaylie Mings',
    date: 'November 7, 2022',
    content: <PostPlatformsNft />
  },
  {
    slug: 'ad-of-the-week-3-course-dinner',
    subHead: 'Creative',
    image: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/6365a52f3b3b336190d5c22f_Untitled%20design%20(13)-p-1080.png',
    title: 'Ad of the Week: 3 Course Dinner',
    author: 'Kaylie Mings',
    date: 'November 4, 2022',
    content: <PostThreeCourseDinner />
  },
  {
    slug: 'the-rise-of-tiktok',
    subHead: 'Marketing Strategy',
    image: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/62f643b4de8e3e6eb14194b8_The%20Rise%20of%20TikTok-p-1080.jpg',
    title: 'The Rise of TikTok',
    author: 'Mayank Kumar',
    date: 'August 12, 2022',
    authorImage: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/62d929ac66c187a91ac2a4cf_Mayank.jpg',
    content: <PostRiseOfTikTok />
  },
  {
    slug: 'digital-marketing-mistakes-that-are-killing-your-startup',
    subHead: 'Marketing Strategy',
    image: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/62d78c79795e0756ad8cefde_BlogPost1-p-1080.jpg',
    title: 'Digital Marketing Mistakes That Are Killing Your Startup',
    author: 'Pooja Raj',
    date: 'July 19, 2022',
    authorImage: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/62d7e383c2b0092bec213e77_Pooja%20Raj.jpg',
    content: <PostMarketingMistakes />
  },
  {
    slug: 'influencer-marketing-101',
    subHead: 'Influencers',
    image: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/62bb7736c1a8e06fdfba13ea_social-media-gbee6aa59d_1920-p-1080.jpeg',
    title: 'Effective Influencer Marketing 101',
    author: 'Arturo Hurtado',
    date: 'June 28, 2022',
    authorImage: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/62bb78b2e140bf23177020a9_IMG_6078%203.jpg',
    content: <PostInfluencerMarketing />
  },
  {
    slug: 'abcs-of-a-b-testing',
    subHead: 'Campaign Management',
    image: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/62da882067f1ce662d1356cb_A_B%20Testing-p-1080.jpg',
    title: "The ABC's of A/B Testing",
    author: 'Mohit Sardana',
    date: 'June 22, 2022',
    authorImage: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/62de48bf63185e70eb6dbb29_Mohit%20Sardana.jpg',
    content: <PostABTesting />
  },
  {
    slug: 'how-to-create-a-winning-ad',
    subHead: 'Creative',
    image: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/62e13eb26ee492afe5ae3f4c_winnning%20ad-p-1080.jpg',
    title: 'How to Create a Winning Ad',
    author: 'Cordelia Duff',
    date: 'May 27, 2022',
    authorImage: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/62a9040e157663272e198593_cordeliaduff.jpeg',
    content: <PostCreateWinningAd />
  }
];

export default Posts;