// todo refactor usage https://reacttraining.com/react-router/web/guides/scroll-restoration
import React, { useState, useEffect, useRef } from 'react';
import { nonNegativeValue, roundedCurrencyFormat, Storage, twoDecimals } from "frontend-core";
import _ from "lodash";
import ReactHtmlParser from 'html-react-parser';
import { PublishingPlatforms } from './model';

export const scrollToTop = () => {
  // scroll to top of page when user comes to this page
  // this function should be called from `useEffect` with `location` as a dependency
  try {
    window.scroll({
      top: 0,
      left: 0,
    });
  } catch (e) {
    // for legacy browsers
    window.scrollTo(0, 0);
  }
};

const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
};

const srcToDataURL = (url) => fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }));

interface IUtils {
  formatCurrency: Function;
  reverseString: Function;
  capitalizeFirstLetter: Function;
  getDollars: Function;
  getFormattedDate: Function;
  isoToLocalDateConverter: Function;
  getSupportedVideoFormats: Function;
  isSupportedVideoFormat: Function;
  getSupportedImageFormats: Function;
  isSupportedImageFormat: Function;
  getStripeApiKey: Function;
  validateEmail: (email: string) => boolean;
  removeSpecialCharacters: Function;
  removeEmoji: Function;
  getFileNameWithoutExtension: Function;
  getExtension: Function;
  renameFile: (file: File,nameLimit : string) => File;
  isEmpty: Function;
  hasDuplicates: (arr : string[]) => boolean;
  getDueDate: (createdAt: string) => string;
  getMonthAndDate: (date: string) => string;
  useInterval: Function;
  clearColumnConfig: Function;
  clearDateSelection: Function;
  renderMetricLabel: Function;
  renderByMetricType: Function;
  stripHtmlTags: Function;
  jsonConcat: Function;
  dataURLtoFile: Function;
  srcToDataURL: Function;
  srcToFile: Function;
}

export const utils: IUtils = {
  reverseString: function(input: string): string {
    input = input + '';
    const stack: string[] = [];
    const inputLength = input.length;
    input.split('').forEach(c => stack.push(c));
    let i = 0;
    input = '';
    while (i < inputLength) {
      input += stack.pop();
      i++;
    }
    return input;
  },
  formatCurrency: function(amount: string | number): number {
    // console.log(amount);
    let input = amount + '';  // convert to string
    const inputLength = input.length;
    input = this.reverseString(input);
    const stack: string[] = [];
    const digits = input.split('');
    digits.forEach((d: string, i) => {
      if (i % 3 === 0 && i > 0 && i < inputLength) {stack.push(',')}
      stack.push(d);
    });
    // console.log(this.reverseString(stack.join('')));
    return this.reverseString(stack.join(''));
  },
  capitalizeFirstLetter: (string: string) => {
    if (!string) return '';
    if (typeof string !== 'string') {
      return '';
    }
    string = string.toLowerCase();
    while (string.indexOf('_') > 0) {
      string = string.replace('_', ' ');
    }
    return string.split(' ').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ');
  },
  getDollars: (amount: number | string, platform: string): number => {
    if (typeof amount === 'string') amount = parseInt(amount);
    return platform === 'Snapchat' ? amount / 1000000 : amount / 100;
  },
  getFormattedDate: (input: string): string => {
    try {
    const date = new Date(input);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear() % 100}`;
    } catch (e) {
      console.error('getFormattedDate error\n', e);
      return input;
    }
  },
  isoToLocalDateConverter: (dateAsStr: string): string => {
    try {
      // Check if the string matches the expected date format
      // If not, change it for compatibility
      if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(dateAsStr)){
        dateAsStr = dateAsStr.replace(/-/g, "/")
      }

      return new Date(dateAsStr).toLocaleTimeString(navigator.language, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    } catch (e) {
      console.warn(
        "An error occurred while converting from ISO format to Locale date."
      );
      return dateAsStr; 
    }
  },
  getSupportedVideoFormats: function(): string[] {
    // todo this data is getting repeated in backend also
    return [".mp4", ".m4a", ".m4v", ".f4v", ".f4a", ".m4b", ".m4r", ".f4b", ".mov", ".3gp", ".3gp2", ".3g2", ".3gpp", ".3gpp2", ".ogg", ".oga", ".ogv", ".ogx", ".wmv", ".wma", ".asf", ".webm", ".flv", ".avi"];
  },
  isSupportedVideoFormat: function (format: string): boolean {
    const extensions = this.getSupportedVideoFormats();
    for (const index in extensions) {
      if (format.toLowerCase().endsWith(extensions[index])) {
        return true;
      }
    }
    return false;
  },
  getSupportedImageFormats: function(): string[] {
    // todo this data is getting repeated in backend also
    return [".jpeg", ".png", ".apng", ".svg", ".bmp", ".jpg"];
  },
  isSupportedImageFormat: function(format: string): boolean {
    const extensions = this.getSupportedImageFormats();
    for (const index in extensions) {
      if (format.toLowerCase().endsWith(extensions[index])) {
        return true;
      }
    }
    return false;
  },
  getStripeApiKey: (): string => {
    const host = window.location.hostname;
    let stripeKey = "pk_test_sBzisbAAYKFB8N1mFC66iUb300biWf5jOJ";
    if(host) {
        switch(host) {
          case "www.smartblitz.ai":
            stripeKey="pk_live_Cn8Rqgrf37KpBbqwcWUeVX6f00wtRAte3a";
            break;
        }
    }
    return stripeKey;
  },
  validateEmail: (email: string): boolean => {
    // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  removeSpecialCharacters: function(str: string | null) : string {
    if (str) {
      return str.replace(/[^a-zA-Z0-9_-]/ig, "");
    }
    return "";
  },
  removeEmoji: function(str: string | null) : string {
    if (str) {
      return str.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
    }
    return '';
  },
  getFileNameWithoutExtension: function(str: string | null) : string {
    if (str) {
      return str.replace(/\.[^/.]+$/, "");
    }
    return "";
  },
  getExtension: function(str: string | null) : string {
    // var re = /(?:\.([^.]+))?$/;
    if (str) {
      return str.substr(str.lastIndexOf('.') + 1);
    }
    return "";
  },
  renameFile: function(originalFile: File,nameLimit: string): File {
    let newName = originalFile.name;
    let nameLimitInt = parseInt(nameLimit);
    if (newName.length > nameLimitInt) {
      var extension = newName.substring(newName.lastIndexOf('.'));
      newName = ((newName.replace(extension,'').substring(0,nameLimitInt-extension.length))+extension);
    }
    newName = newName.replaceAll(' ', '-');
    newName = this.removeSpecialCharacters(newName.split('.')[0]) + '.' + newName.split('.')[1];
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
  },
  isEmpty: function(str) {
    return (!str || 0 === str.length);
  },
  clearColumnConfig: function() {
    Storage.remove("column_configuration");
  },
  clearDateSelection: function() {
    localStorage.removeItem('analyticsDateRangeSelection');
  },
   hasDuplicates: function(arr) {
   return arr.filter((item, index) => arr.indexOf(item) != index).length > 0
  },
  getDueDate:
   (createdAt: string): string => {
    if (!createdAt) return '';
    const monthMap = { '0': 'Jan', '1': 'Feb', '2': 'Mar', '3': 'Apr', '4': 'May', '5': 'Jun', '6': 'Jul', '7': 'Aug', '8': 'Sep', '9': 'Oct', '10': 'Nov', '11': 'Dec' };
    let date = new Date(createdAt).getTime();
    date += 1000 * 60 * 60 * 24 * 7;
    const d2 = new Date(date);
    return `${monthMap[d2.getMonth()]} ${d2.getDate()}`;
  },
  getMonthAndDate:
   (date: string): string => {
    const monthMap = { '0': 'Jan', '1': 'Feb', '2': 'Mar', '3': 'Apr', '4': 'May', '5': 'Jun', '6': 'Jul', '7': 'Aug', '8': 'Sep', '9': 'Oct', '10': 'Nov', '11': 'Dec' };
    let d2 = new Date(date);
    return `${monthMap[d2.getMonth()]} ${d2.getDate()}`;
  },
  /**
 * Splititng the string in two parts 
 */
  renderMetricLabel: (metric, simpleString: false): any => {
    const parts = metric.split('</strong>');
    if (parts.length === 1) {
      return metric;
    }
    const p = parts.at(0) + '</strong>';
    const head = simpleString? p : ReactHtmlParser(p);
    const tail = parts.at(1);
    return { head, tail };
  },
  stripHtmlTags: (str) => {
    if (str === null || str === '') {
      return false;
    }
    str = str.toString();

    return str.replace(/(<([^>]+)>)/gi, '');
  },
  renderByMetricType: (allMetrics, propertyID, value) => {
    let metricType = allMetrics.find(m => m.propertyID === propertyID)?.metricType;
    if (metricType) {
      switch (metricType) {
        case 'CURRENCY':
          return roundedCurrencyFormat(value);
        case 'NUMERIC_VALUE':
          return nonNegativeValue(value);
        case 'STRING_VALUE':
          return value;
        case 'PERCENTAGE':
          return twoDecimals(value);
        case 'DATE_VALUE':
          return utils.isoToLocalDateConverter(value);
      }
    }
    return value;
  },
  jsonConcat(o1, o2) {
    for (var key in o2) {
      o1[key] = o2[key];
    }
    return o1;
  },
  
  useInterval : function(callback,delay ) {
    const savedCallback = useRef(callback);

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== -1) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  },

  dataURLtoFile,

  srcToDataURL,

  srcToFile: (src, callback) => {
    const name = `temp-` + String(Math.random() * 1000);
    srcToDataURL(src).then(dataUrl => {
      if (callback) {
        callback(dataURLtoFile(dataUrl, name));
      }
    });
  }
};

  //function for replacing the keys of array to the same name
export function replaceKeysDeep(obj: any, keysMap: any) {
    return _.transform(obj, function (result, value, key) {
      var currentKey = keysMap[key] || key;
      result[currentKey] = _.isObject(value) ? replaceKeysDeep(value, keysMap) : value;
    });
  };

export const getAgeRange = (platform: string, minAge: boolean) => {
  if (
    platform.toUpperCase() === PublishingPlatforms[PublishingPlatforms.SNAPCHAT]
  ) {
    let maxRange = minAge ? 35 : 49;
    const range = ["13"];
    let age = 14;
    while (age <= maxRange) {
      range.push(age + "");
      age++;
    }
    if(!minAge) {
      range.push("50");
    }
    return range;
  } else if (
    platform.toUpperCase() === PublishingPlatforms[PublishingPlatforms.FACEBOOK]
  ) {
    const range = ["13"];
    let age = 14;
    while (age < 65) {
      range.push(age + "");
      age++;
    }
    range.push("65");
    return range;
  } else {
    const range = ["13"];
    let age = 14;
    while (age < 100) {
      range.push(age + "");
      age++;
    }
    range.push("100");
    return range;
  }
};

export const shuffleArray = (arr) => {
  const array = [...arr];
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};
