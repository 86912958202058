import { JsonObject, JsonProperty } from "json2typescript";
import { RoundedRectangleComponent } from "./RoundedRectangleComponent";
import { alphaDecimalVal, evaluate, getRaiusFromArcMetrics } from "../../Common/MathExpressionStatement";
import { VideoPlatform } from "../templates/VideoPlatform";

@JsonObject("BeakRoundedRectangleComponent")
export class BeakRoundedRectangleComponent extends RoundedRectangleComponent {


    @JsonProperty("beakX1")
    public beakX1: string = "0";

    @JsonProperty("beakY1")
    public beakY1: string = "0";

    @JsonProperty("beakX2")
    public beakX2: string = "0";

    @JsonProperty("beakY2")
    public beakY2: string = "0";

    @JsonProperty("beakX3")
    public beakX3: string = "0";

    @JsonProperty("beakY3")
    public beakY3: string = "0";

    @JsonProperty("rectStartX")
    public rectStartX: string = "0";


    @JsonProperty("rectStartY")
    public rectStartY: string = "0";

    @JsonProperty("rectWidth")
    public rectWidth: string = "0";

    @JsonProperty("rectHeight")
    public rectHeight: string = "0";


    rsx(previewMultiplier: number): number {
        try {
            return previewMultiplier * evaluate(this.rectStartX);
        }
        catch (e) {
            return 0;
        }
    }


    rsy(previewMultiplier: number): number {
        try {
            return previewMultiplier * evaluate(this.rectStartY);
        }
        catch (e) {
            return 0;
        }
    }


    rsw(previewMultiplier: number): number {
        try {
            return previewMultiplier * evaluate(this.rectWidth);
        }
        catch (e) {
            return 0;
        }
    }


    rsh(previewMultiplier: number): number {
        try {
            return previewMultiplier * evaluate(this.rectHeight);
        }
        catch (e) {
            return 0;
        }
    }


    bX1(previewMultiplier: number): number {
        try {
            return previewMultiplier * evaluate(this.beakX1);
        }
        catch (e) {
            return 0;
        }
    }

    bY1(previewMultiplier: number): number {
        try {
            return previewMultiplier * evaluate(this.beakY1);
        }
        catch (e) {
            return 0;
        }
    }

    bX2(previewMultiplier: number): number {
        try {
            return previewMultiplier * evaluate(this.beakX2);
        }
        catch (e) {
            return 0;
        }
    }

    bY2(previewMultiplier: number): number {
        try {
            return previewMultiplier * evaluate(this.beakY2);
        }
        catch (e) {
            return 0;
        }
    }

    bX3(previewMultiplier: number): number {
        try {
            return previewMultiplier * evaluate(this.beakX3);
        }
        catch (e) {
            return 0;
        }
    }

    bY3(previewMultiplier: number): number {
        try {
            return previewMultiplier * evaluate(this.beakY3);
        }
        catch (e) {
            return 0;
        }
    }

    async drawComponent(frameNumber: number, previewMultiplier: number, parentImage: HTMLCanvasElement | null, videoPlatform: VideoPlatform): Promise<HTMLCanvasElement> {
        let componentWidth: number = this.componentWidthAsFloat(previewMultiplier, parentImage);
        let componentHeight: number = this.componentHeightAsFloat(previewMultiplier, parentImage);
        let canv: HTMLCanvasElement = this.canvas(componentWidth, componentHeight);
        let strokeWidth: number = this.strokeWidthval(previewMultiplier);
        let graphics2D: any = canv.getContext('2d');
        if (graphics2D === null) {
            return this.image(canv);
        }
        let radius = getRaiusFromArcMetrics(this.xBendVal(previewMultiplier), this.yBendVal(previewMultiplier));
        graphics2D.globalAlpha = this.shapeColor === '0' ? 0 : alphaDecimalVal([this.shapeColor]);
        if (this.isFill()) {
            graphics2D.fillStyle = this.shapeColor;
        }
        graphics2D.strokeStyle = this.strokeColor === '' ? this.shapeColor : this.strokeColor;
        if (strokeWidth > 1) {
            graphics2D.lineWidth = strokeWidth;
        }
        let sx = this.rsx(previewMultiplier);
        let sy = this.rsy(previewMultiplier);
        let w = this.rsw(previewMultiplier);
        let h = this.rsh(previewMultiplier);

        
        if (this.isFill()) {
            let fillStyle = this.shapeColor.startsWith("#") ? this.shapeColor : "#" + this.shapeColor;
            let fillStyleColor = fillStyle.length === 9 ? "#" + fillStyle.slice(3) : fillStyle;
            graphics2D.fillStyle = fillStyleColor;
            graphics2D.globalAlpha = alphaDecimalVal([fillStyle]);
        }
        {
            let strokeStyle = this.strokeColor === '' ? this.shapeColor : this.strokeColor;
            strokeStyle = strokeStyle.startsWith("#") ? strokeStyle : "#" + strokeStyle;
            let strokeStyleColor = strokeStyle.length === 9 ? "#" + strokeStyle.slice(3) : strokeStyle;
            graphics2D.strokeStyle = strokeStyleColor;
        }

        graphics2D.beginPath();
        graphics2D.roundRect(strokeWidth / 2 + sx, strokeWidth / 2 + sy, w - strokeWidth, h - strokeWidth, radius);
        graphics2D.moveTo(this.bX1(previewMultiplier), this.bY1(previewMultiplier));
        graphics2D.moveTo(this.bX2(previewMultiplier), this.bY2(previewMultiplier));
        graphics2D.moveTo(this.bX3(previewMultiplier), this.bY3(previewMultiplier));
        graphics2D.closePath();
        console.log(strokeWidth / 2 + sx, strokeWidth / 2 + sy, w - strokeWidth, h - strokeWidth, radius);


        if (this.isFill()) {
            graphics2D.fill();
            console.log("HERE", graphics2D.fillStyle);
        }
        else {
            let strokeStyle = this.strokeColor === '' ? this.shapeColor : this.strokeColor;
            strokeStyle = strokeStyle.startsWith("#") ? strokeStyle : "#" + strokeStyle;
            let strokeStyleColor = strokeStyle.length === 9 ? "#" + strokeStyle.slice(3) : strokeStyle;
            graphics2D.strokeStyle = strokeStyleColor;
            
            graphics2D.stroke();
            graphics2D.globalCompositeOperation = 'destination-out';

            // fill all rects
            // This "erases" all but the outline stroke
            graphics2D.roundRect(strokeWidth / 2 + sx, strokeWidth / 2 + sy, w - strokeWidth, h - strokeWidth, radius);
            graphics2D.beginPath();
            graphics2D.moveTo(this.bX1(previewMultiplier), this.bY1(previewMultiplier));
            graphics2D.moveTo(this.bX2(previewMultiplier), this.bY2(previewMultiplier));
            graphics2D.moveTo(this.bX3(previewMultiplier), this.bY3(previewMultiplier));
            graphics2D.closePath();
            graphics2D.fill();

            graphics2D.globalCompositeOperation = 'source-over';
        }
        return this.image(canv);
    }
}