import moment from "moment-timezone";

const getDaysArray = (start, end) => {
  for (var arr: any = [], dt = new Date(end); dt >= start; dt.setDate(dt.getDate() - 1)) {
    arr.push(moment(new Date(dt)).format(common.DATE_FORMAT));
  }
  return arr;
};

const getDaysObject = (start, end) => {
  for (var obj: any = {}, dt = new Date(end); dt >= start; dt.setDate(dt.getDate() - 1)) {
    obj[moment(new Date(dt)).format(common.DATE_FORMAT)] = Math.random() * 10;
  }
  return obj;
};

const common = {
  DATE_FORMAT: 'YYYY-MM-DD',
  getDaysObject,
  getDaysArray
};

export default common;
