import { JsonObject, JsonProperty } from "json2typescript";
import { evaluate } from "../../Common/MathExpressionStatement";
import { Component } from "./Component";

@JsonObject("ShapeComponent")
export class ShapeComponent extends Component {

    @JsonProperty("shapeColor")
    public shapeColor:string = '';

    @JsonProperty("isShapeFill")
    public isShapeFill:string = 'true';

    @JsonProperty("strokeColor")
    public strokeColor:string = '';

    @JsonProperty("strokeWidth")
    public strokeWidth:string ="3";

    strokeWidthval(previewMultiplier:number): number {
        try {
            return evaluate(this.strokeWidth) * (previewMultiplier);
        }
        catch (e) {
            return 1;
        }
    }

    isFill():boolean {
        try {
            return (this.isShapeFill) === 'true';
        }
        catch (e) {
            return false;
        }
    }
}