import { JsonObject, JsonProperty } from "json2typescript";
import { Expression } from "../../Common/Expression";
import { evaluate, evaluateExpression, roundVal } from "../../Common/MathExpressionStatement";
import { Effect } from "./Effect";
import { toJson } from "../../Common/JsonUtil";


@JsonObject("CompositeEffect")
export class CompositeEffect extends Effect {



    @JsonProperty("relativePivotX", Expression)
    relativePivotX: Expression = new Expression("0.5");

    @JsonProperty("relativePivotY", Expression)
    relativePivotY: Expression = new Expression("0.5");

    @JsonProperty("pivotX", Expression)
    pivotX: Expression = new Expression("0");

    @JsonProperty("pivotY", Expression)
    pivotY: Expression = new Expression("0");

    @JsonProperty("shearX", Expression)
    shearX: Expression = new Expression("0");

    @JsonProperty("shearY", Expression)
    shearY: Expression = new Expression("0");

    @JsonProperty("width", Expression)
    width: Expression = new Expression("0");

    @JsonProperty("height", Expression)
    height: Expression = new Expression("0");

    @JsonProperty("scaleFactor", Expression)
    scaleFactor: Expression = new Expression("1");

    @JsonProperty("angle", Expression)
    angle: Expression = new Expression("0");



    relativePivotXVal(frameNumber: number) {
        return evaluateExpression(this.relativePivotX, this.params(frameNumber));
    }


    relativePivotYVal(frameNumber: number) {
        return evaluateExpression(this.relativePivotY, this.params(frameNumber));
    }

    pivotXVal(frameNumber: number, previewMultiplier: number) {
        return evaluateExpression(this.pivotX, this.params(frameNumber)) * (previewMultiplier);
    }


    pivotYVal(frameNumber: number, previewMultiplier: number) {
        return evaluateExpression(this.pivotY, this.params(frameNumber)) * (previewMultiplier);
    }

    widthVal(frameNumber: number, previewMultiplier: number) {
        return evaluateExpression(this.width, this.params(frameNumber)) * (previewMultiplier);
    }


    heightVal(frameNumber: number, previewMultiplier: number) {
        return evaluateExpression(this.height, this.params(frameNumber)) * (previewMultiplier);
    }



    angleVal(frameNumber: number, previewMultiplier: number) {
        return evaluateExpression(this.angle, this.params(frameNumber));
    }

    scale(frameNumber: number) {
        let scaleFactor = 1.0;
        if (this.scaleFactor != null) {
            scaleFactor = evaluateExpression(this.scaleFactor, this.params(frameNumber));
        }
        return scaleFactor;
    }


    applyLayout(frameNumber: number, previewMultiplier: number, componentImage: HTMLCanvasElement, componentParentImage: HTMLCanvasElement | null): HTMLCanvasElement {
        let bufferedImage = componentImage;
        let parentImage = componentParentImage;
        let targetImage: HTMLCanvasElement = parentImage === null ? componentImage : parentImage;
        let isStitchingToParentImage = true;
        if (parentImage === null || this.shouldApplyWithoutStitchingToParent() || !this.shouldApplyPostRenderingSubComponentsVal()) {
            targetImage = bufferedImage;
            isStitchingToParentImage = false;
        }

        if (this.startFVal() > frameNumber || this.endFVal() < frameNumber) {
            if (parentImage === null) {
                return bufferedImage;
            }
            else {
                return targetImage;
            }
        }

        let canvasImage = isStitchingToParentImage ? this.canvasFromImage(targetImage) : this.canvas(targetImage.width, targetImage.height);
        let g = canvasImage.getContext("2d");
        if (g === null) {
            throw ("Error");
        }
        g.beginPath();
        g.moveTo(0,0);
        
        /**
         * Draw background image at location (0,0)
         * You can change the (x,y) value as required
         */
        let alpha = this.alpha.evaluateExpression(this.params(frameNumber));
        // Composite composite = AlphaComposite.SrcOver; //default
        // if (alpha < 1.0f) {
        // 	composite = AlphaComposite.SrcOver.derive(alpha);
        // }
        // if(this.blendType != null) {
        //     String blendTypeString = blendType.name();
        //     if (blendTypeString != null) {
        //         try {
        //             BlendComposite.BlendingMode blendingMode = BlendComposite.BlendingMode.valueOf(blendTypeString);
        //             if(blendingMode != BlendComposite.BlendingMode.DEFAULT) {
        //                 BlendComposite blendComposite = BlendComposite.getInstance(blendingMode);
        // 				if (alpha < 1.0f) {
        // 					blendComposite = blendComposite.derive(alpha);
        // 				}
        //                 composite = blendComposite;
        // 			}
        //         } catch (Exception e) {
        //             e.printStackTrace();
        //         }
        //     }
        // }
        g.globalAlpha = (alpha);
        let relativePivotX = this.relativePivotXVal(frameNumber);
        let relativePivotY = this.relativePivotYVal(frameNumber);

        let pivotX = this.pivotXVal(frameNumber, previewMultiplier);
        let pivotY = this.pivotYVal(frameNumber, previewMultiplier);

        // let shearX = evaluateExpression(this.shearX, this.params(frameNumber));
        // let shearY = evaluateExpression(this.shearY, this.params(frameNumber));

        let width = this.widthVal(frameNumber, previewMultiplier);
        let height = this.heightVal(frameNumber, previewMultiplier);


        let scaleFactor = this.scale(frameNumber);

        let targetWidth = scaleFactor * width;
        let targetHeight = scaleFactor * height;


        let startX = roundVal(-1 * relativePivotX * targetWidth);
        let endX = roundVal(startX + targetWidth);

        let startY = roundVal(-1 * relativePivotY * targetHeight);
        let endY = roundVal(startY + targetHeight);

        // let angle = this.angleVal(frameNumber, previewMultiplier);
        // g.rotate(angle);
        g.translate(pivotX, pivotY);
        g.drawImage(bufferedImage, 0, 0, bufferedImage.width, bufferedImage.height, startX, startY, endX-startX, endY-startY);
        return this.image(canvasImage);
    }



    canvasUrlFromImage(img: HTMLImageElement | null): string {
        if (img === null) {
            return "EMPTY";
        }
        let canv = this.canvas(img.naturalWidth, img.naturalHeight);
        let ctx = canv.getContext("2d");
        if (ctx === null) {
            throw ("exception while getting ctx");
        }
        ctx.drawImage(img,
            0, 0,
            img.naturalWidth, img.naturalHeight,
            0, 0,
            img.naturalWidth, img.naturalHeight
        );

        return canv.toDataURL();
    }
}