import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { externalLink } from './Posts';

const PostInfluencerMarketing: FC = () => {

  return (
    <div className="blog-post">
      <p>
        If you had asked what influencer marketing was 10 years ago, most people wouldn't have the slightest clue. Today, influencer marketing is one of the most recognizable forms of marketing and sits at a $13 billion dollar market size. It's clear that influencer marketing has quickly grown to be one of the most dynamic and effective marketing strategies we have available, and this growth is expected to continue past a
        {externalLink('https://www.grandviewresearch.com/industry-analysis/influencer-marketing-platform-market', '$100 billion market size by 2030')}.
      </p>
      <p>Clearly, this new form of marketing is here to stay, and as marketers, we've got to keep up or risk being left behind.</p>
      <p>So a few questions come up: How do we navigate this new form of marketing that just one decade ago, didn't even exist? How do we ensure that our influencer marketing is effective? How do we pick influencers?  What are the different types of influencer marketing? At Blitz, we've managed hundreds of influencer campaigns for our clients on platforms like Facebook, Instagram, TikTok, Twitter, and more. Now, we're ready to share what we've learned and answer these questions for you one step at a time.</p>

      <h3>Format 1 - Organic Influencer Promotions</h3>
      <p>There are two main forms that influencer marketing takes nowadays. Let's start with what you're likely most familiar with: organic influencer promotions.</p>
      <p>Think of these as the gold standard: an influencer promoting a product to their followers with the goal of converting amongst their audience. Utilizing their creativity, relatability, and celebrity status, influencers can be very persuasive. At Blitz, we've worked with clients that shifted the majority of their budget to organic influencer promotions after seeing a 50-60% lower campaign CPI than paid social across the board.</p>
      <p>However, the persuasiveness of this format can flop if the approach is wrong. For example, how many times have you seen an influencer reciting a sponsored script robotically and receiving a fraction of their usual engagement? Not only does a failed promo cut into your budget with no results to show for it, it reflects poorly on the influencer as well. Let's walk through how we can avoid these campaign flops and how to run a successful organic influencer promotion.</p>
      <p>Let's start at the core of influencer marketing: the influencers themselves.</p>

      <h4>Metrics</h4>
      <p>
        <i>"How many followers does this influencer have?"</i> is often the first question clients ask us when we're proposing a specific influencer. In reality, this question is not as important as you'd expect. There are other metrics that are much more indicative of a potentially amazing influencer campaign.
      </p>
      <h5>Reach</h5>
      <p>
        <strong>Reach</strong> refers to the amount of people that consistently see your influencer's posts; in other words, it tells you how many people you'll actually be promoting to. Follower count and reach are often seen as interchangeable, but like we've mentioned, follower count is not as relevant as traditional influencer marketing would have you think. Reach, on the other hand, definitely matters.
      </p>
      <p>Looking at the amount of people your influencer reaches on a regular basis should be your number one priority. When you only look at an influencer's follower count, you risk paying for a promotion that gets served to a tiny fraction of their follower base, as the platform algorithms do not necessarily serve every post to an influencer's followers. There are many ways of artificially boosting follower numbers, and you don't want to be overpaying for an influencer who doesn't have as much actual influence as their follower count would lead you to believe. Point being; don't get caught up on the followers!</p>
      <p>
        There are plenty of useful {externalLink('https://influencity.com/', 'third party tools')} that grant you insights into your prospective influencer's reach, and even some native platforms like TikTok's
        {externalLink('https://creatormarketplace.tiktok.com/', 'Content Creator Marketplace')} that provide insight as well. Looking at your influencer's reach and cross checking throughout multiple platforms will give you the peace of mind of knowing that you aren't hiring an influencer to promote your product to an empty theater of inactive followers.
      </p>
      <p>
        <strong>Consistency of reach</strong> is just as important. With many third party platforms offering means instead of medians when it comes to reach, it's easy to make mistakes. Let's look at TikTok for example. It's not unusual for a TikTok influencer to have a few outlying videos with millions of views throughout their history. The issue lies in how these videos affect their average views. Since most platforms only identify the mean, you may accidentally sign up an influencer that regularly pulls only 5-10% of their highlighted “average” reach. That's why verifying consistency is a must. You can do this by using
        {externalLink('https://www.heepsy.com/', 'platforms')} that identify medians, or by manually checking your influencers, which often proves to be necessary.
      </p>
      <p>We also have to consider <strong>reach in the context of your niche</strong>. What would be considered high levels of reach for a fishing influencer for example would not be for a beauty influencer. Therefore, it's unwise to set a standard reach requirement across the board, including segmenting all influencers by mega, macro, and micro levels.</p>
      <p>
        Mega, Macro, and Micro are often the terms used by marketers to segment influencers by size (see {externalLink('https://www.theluxelens.com/blogs/instagram-influencers/how-to-become-a-social-media-influencer', 'here')} for an example). A fishing company as referenced above would find a very limited pool of “mega” influencers to work with if they set their requirements based on this graphic. This isn't because there are no fishing creators with a huge amount of influence in the space. It simply means that with a smaller potential audience pool, the influencers that captivate a large portion of the market might not meet your idea of “Mega” level reach. If you want to work with the most influential content creators within your niche, you should always adjust your requirements.
      </p>
      <p>
        We recommend conducting an analysis of your niche and coining standard influencer sizes specific to that niche based on that analysis. Here's the process we follow:
        <ul>
          <li>Locate the influencers with the highest levels of reach within your niche utilizing a third party platform or other market research tool. </li>
          <li>Set an upper level based on the influencers you discover: these will be your “mega” influencers since they're at the top of your industry regardless of their reach</li>
          <li>Scale down from the upper level and establish the lower levels</li>
        </ul>
      </p>
      <p>This analysis will likely take time as you familiarize yourself with your niche's influencer space, but if your goal is to utilize influencer marketing effectively, it's key that you truly understand your pool of potential influencer partners.</p>
      <p>
        <strong>Reach Demographics + Geos</strong> are also key parts of identifying the right influencer. These metrics are pretty straightforward and reflect the requirements for successful paid social campaigns as well. Simply put, it's important to ensure your targeting is on point. This doesn't necessarily mean being overly restrictive but does mean ensuring that your influencer's audience lines up with your desired geos and demographics.
      </p>
      <p>For example, it of course makes sense to filter out influencers whose audience is outside of the countries where your product is available. On the other hand, filtering out influencers by audience interests often ends up being counterproductive. Much like in running paid social campaigns, we've found that applying only the most basic and necessary filters opens you up to the most promising pool of potential influencer partners, and often the best influencers are slightly outside of the core demographic. You don't want to lose out on your star influencer due to over filtering!</p>

      <h5>Engagement</h5>
      <p>As we've established, reach is critical, but engagement is just as important. Looking at engagement rates and the type of engagement your influencer has can be very indicative of the conversions and interactions you'll see with your sponsored content. After all, if the influencer's audience isn't willing to interact with the influencer's usual content, why would they listen to that influencer when it comes to a sponsored post?</p>

      <h5>Engagement Rates</h5>
      <p>Probably the most well-known metric aside from reach, <strong>engagement rate</strong> simply means the number of interactions divided by follower count or the percent of followers that actually interact with a post. Earlier we mentioned follower counts weren't that important, and while that's true as an absolute metric, in the context of engagement rates follower count does play a role. Luckily, you can just look at engagement rates by themselves as they already account for followers.</p>
      <p>Engagement rates tell you how interactive an audience is with the influencer's content and gives you an idea of how likely it is they'll interact with your sponsored content. It's standard for sponsored content to slightly underperform when compared to the influencer's usual content, so aiming for influencers with higher engagement rates is always ideal.</p>
      <p>As always, engagement rates vary depending on your niche, though from what we've seen the averages tend to range between:</p>

      <div className="mt-40">
        <p>
          <i>Instagram</i><br/>
          &lt;1,000 followers: 7%<br/>
          &lt;10,000 followers: 4-5%<br/>
          &lt;100,000 followers: 1.5-2%<br/>
          &lt;1,000,000 followers: 1-1.5%<br/>
        </p>
        <p>
          <i>TikTok</i><br/>
          &lt;1,000 followers: 17-20%<br/>
          &lt;10,000 followers: 14-17%<br/>
          &lt;100,000 followers: 10-14%<br/>
          &lt;1,000,000 followers: 7-10%<br/>
        </p>

        <h5>Engagement Types</h5>
        <p>At Blitz, we've identified that influencers receiving more engagement from their comments than likes are more likely to produce results. This stems from the fact that comments are already a type of engagement that requires audiences to take time from their day to complete, commanding more attention than tapping a quick like. Since most sponsored content steers the audience towards completing an action, users that were already willing to spend that time to comment are by nature more likely to consider taking time to check out what your influencer is promoting.</p>
        <p>Some influencer platforms like {externalLink('http://heepsy.com/', 'Heepsy')} even include comment to like ratios during the influencer discovery phase, which can be helpful in identifying influencers with great levels of this engagement type. For example, we've often found the most success working with Instagram influencers with comment:like ratios that range from 1:100 - 1:200.</p>

        <h4>Campaign Strategy</h4>
        <p>When it comes to organic influencer promotions, most campaigns flop due to the strategy. If influencer marketing was as easy as writing a script and slapping an #ad onto the post, it wouldn't be the effective machine it is today. Let's get into what makes a good organic influencer marketing strategy.</p>

        <h5>Concepts & Scripting</h5>
        <p>If you've signed up an influencer, you probably already have an idea of your campaign goals, whether that be selling a product or simply getting your brand name out there. You may also have an exact idea of what you want your influencer to say or do in their video. This is where brands often make their first mistake.</p>

        <p>Influencer marketing should almost always be a collaborative effort between the brand and the influencer (the exceptions here are promos for highly regulated industries like alcohol sales and medicine where language, copy and overall messaging is heavily restricted). Rarely do you find an entirely scripted campaign that works well and feels authentic. Since your goal is to convert amongst your influencer's audience, you should always seek the influencer's input as to what would resonate best. After all, they probably want the deal to work out just as much as you do.</p>
        <p>Our highest performing campaigns have come from offering influencers talking points and brainstorming collaboratively from there. Since relationship building is such a significant part of influencer marketing as well, you'll likely score some points here too by not being overly controlling and give yourself a great chance of being able to work with that influencer again in the future on perhaps even more favorable terms. Rather than approaching the talking points from your perspective as a brand, it's always helpful to put yourself in the audience and influencer's shoes.</p>
        <p>
          When it comes to creating these talking points, we usually ask ourselves a few questions:
          <ul>
            <li>Will these talking points resonate with the audience?</li>
            <li>Will these talking points overwhelm the audience?</li>
            <li>Can these talking points be conveyed by the influencer in an authentic manner?</li>
          </ul>
        </p>

        <p>Rather than approaching the talking points from your perspective as a brand, it's always helpful to put yourself in the audience and influencer's shoes.</p>
        <p>Authenticity of content is especially important on TikTok, where an influencer's reach can easily be stifled if their audience doesn't recognize their content as truly being their own. This is why you'll frequently see heavily scripted sponsored posts get a fraction of the influencer's mean / median views. Their audience sees the post, fails to interact with it, and as a result TikTok's algorithm stops pushing your video as they usually would.</p>

        <h3>Format 2 - Branded Content Ads</h3>
        <p>These posts began at Meta (Facebook) and are essentially influencer posts where the partnered brand amplifies the content delivery by setting them up as  paid ads (also known as Spark ads on TikTok). This format is becoming increasingly popular; the native and organic feel of a human being popping up on your feed and promoting something from their own handle, in their own creative way, has proven to be very effective. Potential users are much more likely to stop scrolling and engage with content that feels native, and branded content ads allow brands to run ads that look and feel like a regular post.</p>
        <p>Some of our most engaging ads have been in this format, and with every platform starting to explore a form of branded content ads, it's important to stay up to date on how to make these work for you. Unlike boosted posts, branded content ads allow you far more flexibility and customization, since they essentially operate as a regular paid advertisement. At Blitz, we've seen branded content & spark ads quickly rise amongst the top performers for some of our clients.</p>
        <p>Much like organic influencer marketing, your influencer in this case will play an integral role, though your selection process will look vastly different.</p>

        <h4>Metrics</h4>
        <p>Interestingly enough, the metrics that mattered most for organic promotions don't hold the same level of importance when it comes to Branded Content ads. For example, there are plenty of influencers with high levels of reach and engagement might not create compelling branded content ads. Rather than looking at things like reach and engagement, it's important to focus on content quality and do a deeper dive into the influencer themselves.</p>

        <h5>Engagement</h5>
        <p>As we mentioned, engagement is less important for Branded Content than it is for Organic influencer promotions. While engagement levels can sometimes be an indicator of content quality, there are plenty of quality content creators capable of making great advertisements that fail to see much engagement.</p>
        <p>This is due to how variable organic reach is. For example, a relatable creator with quality equipment might fail to meet your engagement expectations purely due to the style of content they typically post that affects their algorithm push on platforms like TikTok and Instagram. This matters in the context of filtering; if you find yourself filtering out these potentially great content creators due to their engagement rate, you might just miss out on a top performer!</p>
        <p>Similarly, if you exclusively identify quality creators based off of engagement, you might run into re-post and reactionary influencers.  Re-post influencers simply repost others' content which can lead to high levels of engagement with a very lacking content creation skillset. Reactionary influencers are similar in that they react to others' content, relying less on their own capability to make content and more on how they react to it. Both of these are examples of influencers that can thrive on platforms like TikTok and Instagram while lacking the abilities you need for branded content. Your main focus should be on content quality and its components.</p>

        <h5>Content Quality</h5>
        <p>The truth is, for most brands to reap the benefits of branded content ads, you don't need to consider the influencer's reach at all in the selection process. You'll likely be pushing the influencer's post to millions through paid advertising anyways, so as long as you work with quality content creators, the amount of views they're getting organically shouldn't really matter.</p>
        <p>What definitely does matter is the quality of their content and their ability to make quality advertisements. Locating influencers who will perform in Branded Content requires a qualitative approach; simply looking at their content and deciding if it meets your standards is the way to go. Aspects like camera quality, their way of speaking, their movements, their equipment, their ability to use trends are all components you should examine and decide if they fit your brand and feel compelling. For example, a brand looking to show off their food product might look to work with a {externalLink('https://www.tiktok.com/@henry.park?lang=en', 'creator that has great camera quality, and is used to filming food in the trendy tiktok way')}. Working with a relatable creator that fits your niche is great, but if they're filming on an old phone in a closet, their advertisement will probably flop.</p>

        <h5>Campaign Strategy</h5>
        <p>For branded content ads, it's best to apply best practices that you'd use for paid social and ask your influencers to apply them to the content they're making for you. Unlike organic influencer promotions, this format leaves more room for scripting.</p>
        <p>If your influencer can successfully integrate these best practices, you can now reap the native and organic benefits of branded content ads while still applying all the learning you've gotten from your standard advertisements. For example, using thumb-stoppers, calls to action, background music, or whatever else you've identified as winning strategies on paid social for your product can all be compelling additions to your branded content ads.</p>

        <h5>Concepts & Scripting</h5>
        <p>Once you've identified a quality content creator, it's time to guide them in the creation of your advertisement. It's important to approach these creators with more rigid requirements in mind, just like you would when generating regular user generated content for ads. As the marketer, you'll be most familiar with what works for your brand in the world of paid social, so structuring your advertisement with the CTAs, thumbstoppers and formats you know work well is always a good move.</p>
        <p>That being said, there's a reason your content creator resonated with you, so it doesn't hurt to adapt your usual style of content to the creator's style. This can mean using a winning paid social template but adding a few modifiers that your creator usually integrates. This might mean their way of using on-screen copy or a signature greeting that makes the content itself seem less like an ad, and more like just another piece of content on your audience's feed.</p>
        <p>When it comes to scripting, there is one last consideration: <strong>ensure the influencer knows how you plan on using their content!</strong> Influencers tend to think of influencer marketing just in the context of organic influencer promotions. If influencers don't know you specifically want the advertisement to promote via branded content, they'll structure their video and the campaign as a whole thinking you want their audience to buy their product. This might mean them throwing in a “Link in bio” in the video, or a CTA button on their post which would only confuse whoever the ad gets served to later once your influencer has removed the link / the button has expired.</p>

        <h3>Summary</h3>
        <p>Influencer marketing is all about working with the right creators and knowing how to use them.</p>
        <p>With the right organic strategy applied to the right creators, it's not unusual to even outperform paid social.  With the right branded content strategy, you might also start to edge out your current winning creative.</p>
        <p>Like everything in marketing, you'll need to dive in head first and start learning. The influencer marketing space is a brand new industry that's constantly shifting, and every brand's influencer needs will differ. All of that being said, the sooner you start, the sooner you'll be able to utilize this new form of marketing that's proven so effective for so many.</p>
        <p>The information we shared above will set you up for a great start, but if you want to supercharge your influencer strategy and scale aggressively, we're happy to help!</p>
      </div>
    </div>
  );
}

export default PostInfluencerMarketing;
