const sheets = {
  columns: [
    { key: 'A', value: 'A' },
    { key: 'B', value: 'B' },
    { key: 'C', value: 'C' },
    { key: 'D', value: 'D' },
    { key: 'E', value: 'E' },
    { key: 'F', value: 'F' },
    { key: 'G', value: 'G' },
    { key: 'H', value: 'H' },
    { key: 'I', value: 'I' },
    { key: 'J', value: 'J' },
    { key: 'K', value: 'K' },
    { key: 'L', value: 'L' },
    { key: 'M', value: 'M' },
    { key: 'N', value: 'N' },
    { key: 'O', value: 'O' },
    { key: 'P', value: 'P' },
    { key: 'Q', value: 'Q' },
    { key: 'R', value: 'R' },
    { key: 'S', value: 'S' },
    { key: 'T', value: 'T' },
    { key: 'U', value: 'U' },
    { key: 'V', value: 'V' },
    { key: 'W', value: 'W' },
    { key: 'X', value: 'X' },
    { key: 'Y', value: 'Y' },
    { key: 'Z', value: 'Z' },
    { key: 'AA', value: 'AA' },
    { key: 'AB', value: 'AB' },
    { key: 'AC', value: 'AC' },
    { key: 'AD', value: 'AD' },
    { key: 'AE', value: 'AE' },
    { key: 'AF', value: 'AF' },
    { key: 'AG', value: 'AG' },
    { key: 'AH', value: 'AH' },
    { key: 'AI', value: 'AI' },
    { key: 'AJ', value: 'AJ' },
    { key: 'AK', value: 'AK' },
    { key: 'AL', value: 'AL' },
    { key: 'AM', value: 'AM' },
    { key: 'AN', value: 'AN' },
    { key: 'AO', value: 'AO' },
    { key: 'AP', value: 'AP' },
    { key: 'AQ', value: 'AQ' },
    { key: 'AR', value: 'AR' },
    { key: 'AS', value: 'AS' },
    { key: 'AT', value: 'AT' },
    { key: 'AU', value: 'AU' },
    { key: 'AV', value: 'AV' },
    { key: 'AW', value: 'AW' },
    { key: 'AX', value: 'AX' },
    { key: 'AY', value: 'AY' },
    { key: 'AZ', value: 'AZ' },
    { key: 'BA', value: 'BA' },
    { key: 'BB', value: 'BB' },
    { key: 'BC', value: 'BC' },
    { key: 'BD', value: 'BD' },
    { key: 'BE', value: 'BE' },
    { key: 'BF', value: 'BF' },
    { key: 'BG', value: 'BG' },
    { key: 'BH', value: 'BH' },
    { key: 'BI', value: 'BI' },
    { key: 'BJ', value: 'BJ' },
    { key: 'BK', value: 'BK' },
    { key: 'BL', value: 'BL' },
    { key: 'BM', value: 'BM' },
    { key: 'BN', value: 'BN' },
    { key: 'BO', value: 'BO' },
    { key: 'BP', value: 'BP' },
    { key: 'BQ', value: 'BQ' },
    { key: 'BR', value: 'BR' },
    { key: 'BS', value: 'BS' },
    { key: 'BT', value: 'BT' },
    { key: 'BU', value: 'BU' },
    { key: 'BV', value: 'BV' },
    { key: 'BW', value: 'BW' },
    { key: 'BX', value: 'BX' },
    { key: 'BY', value: 'BY' },
    { key: 'BZ', value: 'BZ' },
  ],
};

export default sheets;
