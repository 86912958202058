import { createSlice } from "@reduxjs/toolkit";
import { getExports } from "../api/projects-api";
import { SocialEndpointsAll } from "../common/model";

export interface IProjectCreative {
  updatedAt: string | null;
  createdAt: string;
  Id: number;
  adId: number;
  userTemplateId: number;
  templateType: string;  // TODO: Create type
  adConfig: string;
  batchId: string;
  adUrl: string;
  adName: string | null;
  mediaId: string | null;
  adAccountId: string | null;
  platformType: SocialEndpointsAll;
  campaignIds: number[];
}

export interface ProjectsState {
  loading: boolean;
  error: string;
  projectExports: IProjectCreative[];
  isProcessingAsset: boolean;
}

const ExportsSlice = createSlice({
  name: 'exports',
  initialState: {
    loading: true,
    error: '',
    projectExports: [],
    isProcessingAsset: false,
  } as ProjectsState,
  reducers: {
    getExportsStart(state, action) {
      state.loading = true;
      state.error = '';
      state.projectExports = [];
    },
    getExportsSuccess(state, action) {
      state.loading = false;
      state.error = '';
      state.projectExports = action.payload;
    },
    getExportsError(state, action) {
      state.loading = false;
      state.error = action.payload.error;
      state.projectExports = [];
    },
    setProcessingAssetStatus(state, action) {
      state.isProcessingAsset = action.payload;
    },
  },
});

export const {
  getExportsStart,
  getExportsSuccess,
  getExportsError,
  setProcessingAssetStatus
} = ExportsSlice.actions;

export const fetchExports = (
  projectId: number,
  searchQuery?: string | undefined,
  filterCriteria?: string[] | undefined
) => async dispatch => {
  try {
    dispatch(getExportsStart);
    const exports = await getExports(0, 0, projectId, searchQuery, filterCriteria);
    dispatch(getExportsSuccess(exports));
  } catch (err) {
    dispatch(getExportsError(err));
  }
};

export default ExportsSlice.reducer;
