import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { LI_TRACKING_IDS, TrackLIEvent } from '../../common/LinkedInEventTracker';
import Routes from '../../common/routes';
import ContactModal from './ContactModal';
import { LandingContext } from './LandingContext';
import { ICustomWindow } from '../../common/model';
import Events from "../creativeLanding/Events";
import axios from 'axios';
import { Modal } from 'frontend-core';

declare const window: ICustomWindow;
// const captchaKey = process.env.NODE_ENV === 'production';
const captchaKey = window.location.hostname === 'www.useblitz.com' ?
  '6LcvM6MZAAAAAIAMVpKaBNE_fa0IcX78dAD8HwJm' :
  '6LcuZqUZAAAAAOYv3eobTn_1_zwn6Au-GDOASCN6';

const LandingFooter: React.FC = () => {
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  const {
    showContactModal,
    setShowContactModal
  } = useContext(LandingContext);

  const handleEmailSubmit = () => {
    setLoading(true);
    let contactForm = {
      adBudget: '0',
      company: email,
      email: email,
      message: email,
      name: email,
    }
    window.grecaptcha.ready(function() {
      try {
        window.grecaptcha.execute(captchaKey, {action: 'submit'})
        .then(function(token: any) {
          const data = JSON.parse(JSON.stringify(contactForm));
          Events.track('Lead', {
            email: data.email || "",
          });
          data.token = token;
          data.queryParamStr = localStorage.getItem("SIGNUPPARAMS") || '';
          const endpoint = '/api/contactus';
          axios
            .post(endpoint, data)
            .then(response => {
              setEmail('');
              setShowSuccessModal(true);
              setLoading(false)
              window.gaTrack("request_email_subscribe" ,{
                email: data.email || ""
              });
            })
            .catch(error => {
              setLoading(false);
              console.error(error);
            });

        })
        .catch((e: any) => {
          console.error('grecaptcha then then', e);
        });
      } catch (e) {
        setLoading(false);
        console.error('grecaptcha e', e);
      }
    });
  }

  return (
    <div className="landing-footer">
      <div className="top">
        <div className="circle one"></div>
        <div className="circle two"></div>
        <div className="layout-container flex">
          <div className="title">Take your business to the next level.</div>
          <button
            data-ga-event="click"
            data-ga-data={'Contact Us'}
            data-ga-action="ShowContactModal"
            type="button"
            className="btn v2 bright large"
            onClick={() => {
              TrackLIEvent(LI_TRACKING_IDS.contactUs);
              setShowContactModal(true);
              window['contactOpenSource'] = 'HomePage::Bottom Bar:Contact Us';
            }}
          >
            Contact Us
          </button>
        </div>
      </div>
      <div className="bottom">
        <div className="layout-container flex footer-separator">
          <div className="left">
            <div className="logo white"></div>
            <div className="text mt-35 mb-20">Sign up for growth hacks, creative insights, and special offers.</div>
          </div>
          <div className="right">
            <div className="input-cta">
              <input
                className='email-input'
                type="text"
                name="email"
                value={email}
                placeholder="Enter your email here"
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                style={{pointerEvents: isLoading ? 'none' : 'unset'}}
                data-ga-event="click"
                data-ga-data={JSON.stringify({email: email})}
                data-ga-action="EmailSubscribeSubmit"
                type="button"
                className="btn cta bright"
                onClick={() => {
                  if (email.trim().length > 0) {
                    handleEmailSubmit()
                  }
                  TrackLIEvent(LI_TRACKING_IDS.emailSub);
                }}
              ></button>
            </div>
          </div>
        </div>
        <div className="layout-container flex mt-35">
          <div className="left text">
          &copy; Copyright 2022 Blitz.com |  All rights reserved.
          </div>
          <div className="right">
            <ul className="footer-links">
              <li>
                <Link to={Routes.TERMS_OF_SERVICE}>
                  <strong>
                    Terms and Condtions
                  </strong>
                </Link>
              </li>
              <li>
                <Link to={Routes.PRIVACY_POLICY}>
                  <strong>
                    Privacy Policy
                  </strong>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ContactModal show={showContactModal} setShow={setShowContactModal} />
      <Modal
        show={showSuccessModal}
        showClose={true}
        setShow={setShowSuccessModal}
        className="landing-modal"
      >
        <div className='mt-10 text-center'>
          Thank you for subscribing. You'll now receive our latest insights and offers straight to your inbox.
        </div>
      </Modal>
    </div>
  );
};

export default LandingFooter;
