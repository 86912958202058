import { JsonConverter } from "json2typescript";
import { EnumConverter } from "../../Common/EnumConverter";

export function ComponentTypeValueOf(val:string):ComponentType {
  switch(val) {
    case "IMAGE": return ComponentType.IMAGE;
    case "CIRCULAR_IMAGE": return ComponentType.CIRCULAR_IMAGE;
    case "ROUND_CORNERED_IMAGE": return ComponentType.ROUND_CORNERED_IMAGE;
    case "TEXT": return ComponentType.TEXT;
    case "SHAPE_ARC": return ComponentType.SHAPE_ARC;
    case "SHAPE_CIRCLE": return ComponentType.SHAPE_CIRCLE;
    case "SHAPE_RECTANGLE": return ComponentType.SHAPE_RECTANGLE;
    case "SHAPE_ROUNDED_RECTANGLE": return ComponentType.SHAPE_ROUNDED_RECTANGLE;
    case "SHAPE_BEAK_ROUNDED_RECTANGLE": return ComponentType.SHAPE_BEAK_ROUNDED_RECTANGLE;
    case "LINE": return ComponentType.LINE;
    case "FREEFALL_TEXT": return ComponentType.FREEFALL_TEXT;
    case "INTRO_TEXT": return ComponentType.INTRO_TEXT;
    case "FADE_TEXT": return ComponentType.FADE_TEXT;
    case "LOTTIE": return ComponentType.LOTTIE;
    case "STACK": return ComponentType.STACK;
    case "TEXT_WRAP": return ComponentType.TEXT_WRAP;
    default: return ComponentType.SHAPE_RECTANGLE;
  }

}

export enum ComponentType {
  IMAGE,
  CIRCULAR_IMAGE,
  ROUND_CORNERED_IMAGE,
  TEXT,
  SHAPE_ARC,
  SHAPE_CIRCLE,
  SHAPE_RECTANGLE,
  SHAPE_ROUNDED_RECTANGLE,
  SHAPE_BEAK_ROUNDED_RECTANGLE,
  LINE,
  FREEFALL_TEXT,
  INTRO_TEXT,
  FADE_TEXT,
  LOTTIE,
  STACK,
  TEXT_WRAP
}



@JsonConverter
export class ComponentTypeConverter extends EnumConverter<ComponentType> {
  constructor() {
    super(ComponentType, "ComponentType");
  }
}