import React, {useEffect, useState, useContext} from "react";
import { AppContext } from "../appContext";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { InlineLoader, Tooltip } from "frontend-core";
import { TrackFBPixel } from "./FBPixelTracker";


const SupportBubble = () => {
  const {
    user,
    hasProductTourActive,
    productTourStep
  } = useContext(AppContext);

  const [statusSuccess, setStatusSuccess] = useState<boolean>(false);
  const [problemText, setProblemText] = useState<string>('');
  const [isInProgress, setIsInProgress] = useState<boolean>(false);
  const [forceRender, setForceRender] = useState<boolean>(false);
  const [isFormDisplay, setFormDisplay] = useState<boolean>(false);

  const location = useLocation();

  useEffect(() => {
    setForceRender(!forceRender);
  }, [location]);

  if (!user) {
    return <></>;
  }

  const getFixedVal = (componentType, direction) => {
    const isInCreation = ['/create/', '/addAdSet', '/addAds'].some(flag => document.URL.includes(flag));
    
    if (componentType === 'form') {
      if (isInCreation) {
        return direction === 'right' ? '10px' : '165px';
      }
      else {
        return direction === 'right' ? '10px' : '50px';
      }
    }
    else {
      if (isInCreation) {
        return direction === 'right' ? '10px' : '120px';
      }
      else {
        return '10px';
      }
    }
  }

  const handleContact = () => {
    setIsInProgress(true);
    axios.post('/api/user/support/contactUs', { message: problemText })
      .then((response) => {
        TrackFBPixel('Contact', {
          email: user?.email,
          message: 'User requested contact with this message: ' + problemText
        });

        setProblemText('');
        setStatusSuccess(true);
      })
      .catch((e) => {
        console.log('error on contact request', e)
      })
      .finally(() => {
        setIsInProgress(false);
      });
  }

  return (
    <>
      <div 
        className="support-form-container"
        style={{
          scale: isFormDisplay ? '1' : '0',
          opacity: isFormDisplay ? '1' : '0',
          bottom: getFixedVal('form', 'bottom'),
          right: getFixedVal('form', 'right')
        }}
        >
          <div className="form-title">
            <span>Need help? Connect with us</span>
            <span className="close-button" onClick={() => setFormDisplay(false)}>x</span>
          </div>
          {
            !statusSuccess ? 
            <>
              <textarea
                disabled={isInProgress}
                placeholder="Please explain your issue here. We'll be in touch soon"
                value={problemText}
                onChange={(e) => setProblemText(e.target.value)}
              />
              <div className="flex-end">
                {
                  isInProgress &&
                    <div className="w-p30 mr-1 mt-10">
                      <InlineLoader isLoading={true} />
                    </div>
                }
              </div>
            </>
            :
            <div className="success-msg"> 
              Thanks for your concern. Support will connect with you soon
              {/* <div
                className="back-btn"
                onClick={() => setStatusSuccess(false)}  
              >
                ← Back
              </div> */}
            </div>
          }
      </div>
      <Tooltip
        title={`If you need any more help, just reach out to us. Otherwise, you're good to go!`}
        isDisplayControlled={true}
        placement={'top'}
        className={'need-help-tooltip'}
        show={hasProductTourActive && productTourStep === 5}
      >
        <div className={`${!isFormDisplay ? 'vertical' : ''}`}>
          <div
            className={`support-btn ${hasProductTourActive && productTourStep === 5 ? 'tutorial-btn pe-none' : ''}`}
            style={{
              bottom: getFixedVal('btn', 'bottom'),
              right: getFixedVal('btn', 'right')
            }}
            onClick={() => {
              if (isFormDisplay) {
                if (statusSuccess) {
                  setStatusSuccess(false);
                  setFormDisplay(!isFormDisplay)  
                }
                else {
                  handleContact()
                }
              }
              else {
                if (statusSuccess) {
                  setStatusSuccess(false);
                }
                setFormDisplay(!isFormDisplay)
              }
            }}  
          >
            {isFormDisplay ? statusSuccess ? 'Need Help?' : 'Submit' : 'Need Help?'}
          </div>
        </div>
      </Tooltip>
    </>
  )
}

export default SupportBubble;