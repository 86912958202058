import { createContext, Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { IAdMedia, IAdSetQueueObject, CampaignForm, AdSetForm, AdsForm, IAdQueueObject, IScStoryAttachment, IScStoryPreviewAttachment, PlayBack } from '../../common/model';

interface ObjectStoreUrls {
  androidPackageName: string;
  iosAppId: string;
  google_play: string;
  itunes: string;
  websiteUrl: string;
  instant_game: string;
}

export interface FbApp {
  id: string;
  name: string;
  permitted_tasks: [];
  object_store_urls: ObjectStoreUrls;
  adsAccountId :string;
}

export interface TikTokApp {
  advertiser_id: string;
  app_platform_id: string;
  app_name: string;
  platform: string;
  package_name : string;
  app_id : string;
}

export interface StoryFormSwitch {
  customIos: boolean;
  discoverTile: boolean;
  adCreation: boolean;
}

interface ICreateAdsContext {
  selectedAds: IAdMedia[];
  setSelectedAds: Dispatch<SetStateAction<IAdMedia[]>>;
  selectedAdsError: string;
  setSelectedAdsError: Function;
  activeStep: number;
  setActiveStep: Function;
  adSetQueue: IAdSetQueueObject[];
  setAdSetQueue: Function;
  adsQueue: IAdQueueObject[];
  setAdsQueue: Function;
  selectedFbApp: FbApp;
  setSelectedFbApp: Function;
  selectedTikTokApp : TikTokApp;
  setSelectedTikTokApp : Function;
  campaignForm: CampaignForm;
  setCampaignForm: Function;
  adSetForm: AdSetForm;
  setAdSetForm: Function;
  adsForm: AdsForm;
  setAdsForm: Function;
  defaultAdSetForm: AdSetForm;
  defaultAdsForm: AdsForm;
  defaultStoryDiscoverForm  : IScStoryPreviewAttachment,
  defaultStoryFormSwitch : StoryFormSwitch
  snapStoryForm: IScStoryAttachment;
  setSnapStoryForm: Function;
  storyDiscoverTile: IScStoryPreviewAttachment;
  setStoryDiscoverTile: Function;
  storyFormSwitch: StoryFormSwitch;
  setStoryFormSwitch: Function;
  adsAccountId: string;
  setAdsAccountId: Function;
  playbackStyle: string;
  setPlaybackStyle: Function;
  adsetType: string,
  setAdsetType: Function;
  storyHeadline: string;
  setStoryHeadline: Function;
  loadFormStatesFromCache: Function;
}

const initialCampaignForm: CampaignForm = {
  platform: {
    value: 'facebook',
    error: '',
  },
  type: {
    value: 'app',
    error: '',
  },
  name: {
    value: '',
    error: '',
  },
  snapchatAccountId: {
    value: '',
    error: '',
  },
  facebookAccountId: {
    value: '',
    error: '',
  },
  tikTokAccountId: {
    value: '',
    error: '',
  },
  objective: {
    value: '',
    error: '',
  },
  budget: {
    value: 0,
    error: '',
  },
  bidStrategy : {
    value : '',
    error : '',
  },
  appProfileStatus : {
    value:false,
    error:'',
  }
};

const initialAdSetForm: AdSetForm = {
  name: {
    value: '',
    error: '',
  },
  type: {
    value : 'app',
    error : '',
  },
  pageId: {
    value: { value: '', label: '' },
    error: '',
  },
  fbAppId: {
    value: { value: '', label: '' },
    error: '',
  },
  instaAppId: {
    value: { value: '', label: '' },
    error: '',
  },
  tiktokAppId: {
    value: { value: '', label: '' },
    error: '',
  },
  operatingSystem: {
    value: '',
    error: '',
  },
  pixelEnabled: {
    value: false,
    error: '',
  },
  pixelId: {
    value: '',
    error: '',
  },
  iosVersion: {
    value: '14.1',
    error: '',
  },
  androidVersion: {
    value: '9.0',
    error: '',
  },
  budget: {
    value: 1,
    error: '',
  },
  bidControl: {
    value: 1,
    error: '',
  },
  budgetPeriod: {
    value: 'Daily',
    error: '',
  },
  optimisationGoal: {
    value: '',
    error: '',
  },
  minAge: {
    value: '18',
    error: '',
  },
  maxAge: {
    value: '35',
    error: '',
  },
  gender: {
    value: 'all',
    error: '',
  },
  country: {
    value: ['US'],
    error: '',
  },
  countryGroup: {
    value: [],
    error: '',
  },
  language: {
    value: ['en'],
    error: '',
  },
  detailedTargeting: {
    value: [],
    error: '',
  },
  customAudiences : {
    value : [],
    error : '',
  },
  customAudiencesExclude : {
    value : [],
    error : '',
  },
  savedAudience : {
    value : { value: '', label: '' },
    error : '',
  },
  customDetailedTargeting: {
    value: '',
    error: '',
  },
  targetingTextCode : {
    value: '',
    error: '',
  },
  customText: {
      value: '',
      error: '',
  },
  customOptimisationGoalEvent: {
    value: '',
    error: '',
  },
  adSetDynamicStatus : {
    value: false,
    error: '',
  },
  adSetState: {
    value: false,
    error: '',
  },
  bidStrategy: {
    value: 'TARGET_COST',
    error : '',
  }
};

export const initialAdsForm: AdsForm = {
  names: {
    value: '',
    error: '',
  },
  selectedAdSetIndex: {
    value: '0',
    error: '',
  },
  adType: {
    value: 'single',
    error: '',
  },
  appNames: {
    value: '',
    error: '',
  },
  appIcons: {
    value: '',
    error: '',
  },
  headline: {
    value: [''],
    error: '',
  },
  description: {
    value: [''],
    error: '',
  },
  ctaText: {
    value: [''],
    error: '',
  },
  iosEnabled: {
    value: false,
    error: '',
  },
  iosAppId: {
    value: '',
    error: '',
  },
  androidEnabled: {
    value: false,
    error: '',
  },
  androidPackageName: {
    value: '',
    error: '',
  },
  websiteEnabled: {
    value: false,
    error: '',
  },
  websiteUrl: {
    value: '',
    error: '',
  },
  brandName: {
    value: '',
    error: '',
  },
  textCode: {
    value: '',
    error: '',
  },
  displayLink : {
    value: '',
    error: '',
  },
  adState: {
    value: true,
    error: '',
  },
  pageId: {
    value: { value: '', label: '' },
    error: '',
  },
  instaAppId: {
    value: { value: '', label: '' },
    error: '',
  },

};

const initialSelectedFbApp: FbApp = {
  id: 'string',
  name: 'string',
  permitted_tasks: [],
  object_store_urls: {
    androidPackageName: 'string',
    iosAppId: 'string',
    google_play: 'string',
    itunes: 'string',
    websiteUrl: 'string',
    instant_game: 'string',
  },
  adsAccountId : '',
};

const initialSelectedTikTokApp: TikTokApp = {
  advertiser_id: 'string',
  app_platform_id: 'string',
  app_name: 'string',
  platform: 'string',
  package_name : 'string',
  app_id : 'string',
};

const initialStoryForm: IScStoryAttachment = {
  id: 0,
  type : '',
  mediaId : '',
  mediaUrl : '',
  headline : '',
  cta : '',
  appName : '',
  iosAppId : '',
  androidAppUrl : '',
  appIconUrl : '',
  websiteUrl : '',
};

const initialStoryDiscoverForm: IScStoryPreviewAttachment = {
  isAttached: true,
  mediaUrl: '',
  headline: '',
  logoUrl: '',
};

const initialStoryFormSwitch: StoryFormSwitch = {
  customIos: true,
  discoverTile: true,
  adCreation: true,
};

const defaultContext: ICreateAdsContext = {
  selectedAds: [],
  setSelectedAds: () => {},
  selectedAdsError: '',
  setSelectedAdsError: () => {},
  activeStep: 0,
  setActiveStep: () => {},
  adSetQueue: [],
  setAdSetQueue: () => {},
  adsQueue: [],
  setAdsQueue: () => {},
  selectedFbApp: initialSelectedFbApp,
  setSelectedFbApp: () => {},
  selectedTikTokApp : initialSelectedTikTokApp,
  setSelectedTikTokApp: () => {},
  campaignForm: initialCampaignForm,
  setCampaignForm: () => {},
  adSetForm: initialAdSetForm,
  setAdSetForm: () => {},
  adsForm: initialAdsForm,
  setAdsForm: () => {},
  defaultAdSetForm: initialAdSetForm,
  defaultAdsForm: initialAdsForm,
  defaultStoryDiscoverForm  : initialStoryDiscoverForm,
  defaultStoryFormSwitch : initialStoryFormSwitch,
  snapStoryForm: initialStoryForm,
  setSnapStoryForm: () => {},
  storyDiscoverTile: initialStoryDiscoverForm,
  setStoryDiscoverTile: () => {},
  storyFormSwitch: initialStoryFormSwitch,
  setStoryFormSwitch: () => {},
  adsAccountId: '',
  setAdsAccountId: () => {},
  playbackStyle: '',
  setPlaybackStyle: () => {},
  adsetType: '',
  setAdsetType: () => {},
  storyHeadline: '',
  setStoryHeadline: () => {},
  loadFormStatesFromCache: () => {}
};

const CreateAdsContext = createContext<ICreateAdsContext>(defaultContext);

const CreateAdsContextProvider = CreateAdsContext.Provider;

const CreateAdsContextConsumer = CreateAdsContext.Consumer;

export const useCreateAdsContext = (): ICreateAdsContext => {
  const [selectedAds, setSelectedAds] = useState<IAdMedia[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedAdsError, setSelectedAdsError] = useState('');
  const [adSetQueue, setAdSetQueue] = useState([]);
  const [adsQueue, setAdsQueue] = useState<[]>([]);
  const [selectedFbApp, setSelectedFbApp] = useState(initialSelectedFbApp);
  const [campaignForm, setCampaignForm] = useState(initialCampaignForm);
  const [selectedTikTokApp,setSelectedTikTokApp] = useState(initialSelectedTikTokApp);
  const [adSetForm, setAdSetForm] = useState(JSON.parse(JSON.stringify(initialAdSetForm)));
  const [adsForm, setAdsForm] = useState(JSON.parse(JSON.stringify(initialAdsForm)));
  const [snapStoryForm, setSnapStoryForm] = useState(initialStoryForm);
  const [storyDiscoverTile, setStoryDiscoverTile] = useState(initialStoryDiscoverForm);
  const [storyFormSwitch, setStoryFormSwitch] = useState(initialStoryFormSwitch);
  const [adsAccountId, setAdsAccountId] = useState('');
  const [playbackStyle, setPlaybackStyle] = useState(PlayBack.AUTO_ADVANCING);
  const [adsetType, setAdsetType] = useState('');
  const [storyHeadline, setStoryHeadline] = useState('');

  let changeCounter = useRef(0);
  useEffect(() => {
    if (changeCounter.current < 4) {
      changeCounter.current += 1;
      return;
    }

    localStorage.setItem('last_campaign_creation_configs', JSON.stringify({
      campaignForm,
      adSetForm,
      adsForm,
      adSetQueue,
      adsQueue,
      selectedAds,
      timeStamp: Date.now()
    }))
  }, [
    campaignForm,
    adSetForm,
    adsForm,
    adSetQueue,
    adsQueue,
    selectedAds
  ]);

  const loadFormStatesFromCache = () => {
    const data = localStorage.getItem('last_campaign_creation_configs') || 'no_data';
    if (data !== 'no_data') {
      let configs = JSON.parse(data);
      setCampaignForm(configs.campaignForm);
      setAdSetForm(configs.adSetForm);
      setAdsForm(configs.adsForm);
      setAdSetQueue(configs.adSetQueue);
      setAdsQueue(configs.adsQueue);
    }
  }

  return {
    selectedAds, setSelectedAds,
    selectedAdsError, setSelectedAdsError,
    activeStep, setActiveStep,
    adSetQueue, setAdSetQueue,
    adsQueue, setAdsQueue,
    selectedFbApp, setSelectedFbApp,
    selectedTikTokApp,setSelectedTikTokApp,
    campaignForm, setCampaignForm,
    adSetForm, setAdSetForm,
    adsForm, setAdsForm,
    defaultAdSetForm: initialAdSetForm,
    defaultAdsForm: initialAdsForm,
    defaultStoryDiscoverForm  : initialStoryDiscoverForm,
    defaultStoryFormSwitch : initialStoryFormSwitch,
    snapStoryForm, setSnapStoryForm,
    storyDiscoverTile, setStoryDiscoverTile,
    storyFormSwitch, setStoryFormSwitch,
    adsAccountId, setAdsAccountId,
    playbackStyle, setPlaybackStyle,
    adsetType, setAdsetType,
    storyHeadline, setStoryHeadline,
    loadFormStatesFromCache
  };
};

export { CreateAdsContext, CreateAdsContextProvider, CreateAdsContextConsumer };
