import getEnv from './env';
import common from './common';
import analytics from './analytics';
import graph from './graph';
import aiAds from './aiAds';
import aiAdTemplates from './aiAdTemplates';
import sheets from './sheets';
import metricWidths from './metricWidths';
import metricAlignments from './metricAlignments';
import creativeOptions from './creativeOptions';

const assetUrl = 'https://media.useblitz.com/';

const constants = {
  s3Url: assetUrl,
  imagesUrl: assetUrl + 'assets/images/',
  videosUrl: assetUrl + 'assets/videos/',
  scriptsUrl: assetUrl + 'assets/scripts/',
  common,
  pricingPlans: [
    {
      label: 'Under $1,000/month',
      value: 'Under $1,000/month'
    },
    {
      label: 'Under $10,000/month',
      value: 'Under $10,000/month'
    },
    {
      label: 'Over $10,000+/month',
      value: 'Over $10,000+/month'
    }
  ],
  productTypes: [
    {
      label: 'Entertainment',
      value: 'Entertainment'
    },
    {
      label: 'Finance/Banking',
      value: 'Finance/Banking'
    },
    {
      label: 'Education',
      value: 'Education'
    },
    {
      label: 'Healthcare',
      value: 'Healthcare'
    },
    {
      label: 'Technology',
      value: 'Technology'
    },
    {
      label: 'E-Commerce',
      value: 'E-Commerce'
    },
    {
      label: 'Industrial',
      value: 'Industrial'
    },
    {
      label: 'Others',
      value: 'Others'
    }
  ],
  budgetMap: {
    1: 'Under $1,000/month',
    2: 'Under $10,000/month',
    3: 'Over $10,000+/month'
  },
  env: getEnv,
  graph,
  analytics,
  creativePlanGroups: {
    startup: 'start_up_plan',
    scaleup: 'scale_up_plan',
  },
  creativeDynamicPlanGroupSuffix: {
    startup: '_start_up_plan',
    scaleup: '_scale_up_plan',
  },
  creativeV1Ids: {
    getAmazingCreative: 'get-amazing-creative',
    qualityCreative: 'easy-quality-creative-generation',
    getGreatCreative: 'get-great-creative',
    getGreatFootage: 'get-great-footage',
    readyToRunAds: 'ready-to-run-ads',
    pricingSection: 'pricing-section-v1',
    footer: 'footer'
  },
  creativeV3Ids: {
    getAmazingCreative: 'get-amazing-creative',
    qualityCreative: 'easy-quality-creative-generation',
    getGreatCreative: 'get-great-creative',
    getGreatFootage: 'get-great-footage',
    readyToRunAds: 'ready-to-run-ads',
    pricingSection: 'pricing-section-v3',
    footer: 'footer'
  },
  sheets,
  metricsWidths: metricWidths,
  metricsAlignments: metricAlignments,
  creativeOptions,
  aiAds,
  aiAdTemplates
};

export default constants;
