import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { externalLink } from './Posts';

const PostMentalHealth: FC = () => {

  return (
    <div className="blog-post">
      <p>
        For this latest Ad of the Week, we are highlighting an ad run on TikTok for
        {externalLink('https://www.join-real.com/', 'Real')}, an app with therapist-designed mental health programs available on-demand. Real believes that mental health matters, and it is important to them that all marketing reflects their mission. This ad was done in partnership with Jeffrey Marsh (
        {externalLink('https://www.instagram.com/thejeffreymarsh/?hl=en#', '@thejeffreymarsh', false)}), author, actor, nonbinary activist, and LGBTQ keynote speaker.
      </p>
      <p>Since the Covid-19 pandemic there has been an unprecedented rise in the number of people globally in need of mental health services. Accompanying this rise, we've also seen an increase in the use of digital technologies to manage mental health. It's becoming more evident that no country has the human resource capacity to meet the current mental health needs; therefore, turning to digital solutions is becoming essential. The past few years have upended our lives: navigating mass uncertainty, blurred work-life boundaries, constant Covid variants, and more. Tackling your mental health struggles can sometimes be put on the back burner. Real is one of many online resources that allows you to take care of your mental health when and where you want.</p>
      <p>Advertising mental health services can be a tricky feat. You don't want to come across as glamorizing mental health struggles, generalizing mental illnesses, or sounding disingenuous about a serious topic. This Blitz ad highlights the benefits of joining Real's platform in a way that really resonated with users. We'll discuss more in depth how and why Jeffrey's video was so successful.</p>

      <h2>This Week's Highlight: Mental Health Matters with Jeffrey Marsh</h2>
      <div className="center mb-30">
        <iframe width="504" height="283" src="https://www.youtube.com/embed/OJzVqZeHo_E" title="Ad of the Week: Mental Health Matters" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      </div>
      <p>
        What sets this ad apart from other mental health ads is Jeffrey's authenticity. Jeffrey has been open about their mental health struggles, and in their book
        “{externalLink('https://www.goodreads.com/book/show/28110064-how-to-be-you', 'How to Be You: Stop Trying to Be Someone Else and Start Living Your Life', false)}” Jeffrey talks about their struggle with anxiety from seeking perfection. Although this ad is of course a paid sponsorship, Jeffrey truly is a mental health advocate, and Real's mission is in line with their work and activism.
      </p>
      <p>This ad was run as a regular ad on TikTok ads, and for the duration the ad was live, drove 80% of conversions for this brand. The TikTok ads platform quickly realized the impact and value of the content, and served it to a broad set of users who successfully signed up for a paid subscription with Real.</p>

      <h2>Why did this ad perform so well?</h2>
      <p>Jeffrey has an eye-catching aesthetic and presence paired with their strong and soothing personality. Without breaking eye contact, they give a sincere, confessional style talk. They start the video with a call-to-action to download Real, along with the disclaimer, “I don't talk about brands on my channel, but Real is different,” establishing the unique value proposition of the product and pairing their authenticity with that of the brand they are promoting. They go on to talk about how the app is a way to start your self-care and mental health journey. Jeffrey also highlights key elements of the app that make it user-friendly, especially for someone using mental health services for the first time: you don't have to talk to anyone, you don't have to show your face, and there are guided pathways you can do on your own private time. There are also subtitles for the ad, making it accessible to anyone watching with low or no audio. The video is only 43 seconds long, and there's no extraneous visuals, allowing the user to focus on what Jeffrey is saying. Overall it's a great blend because it's a casual, yet an authentic discussion of the product.</p>
      <p>
        Destigmatizing mental health struggles has been on a rise in recent years, and for good reason. Before the pandemic
        {externalLink('https://www.nimh.nih.gov/health/statistics/mental-illness', 'one in five')} adults in the United States experienced mental illness. Today those numbers are dramatically higher, with some studies showing that as many as
        {externalLink('https://www.globenewswire.com/en/news-release/2021/05/18/2231476/0/en/More-than-80-of-Americans-report-one-or-more-symptoms-of-depression-and-many-have-turned-to-substances-to-cope-with-negative-emotions-during-COVID-19-Pandemic-according-to-Field-Tr.html', '80%')} of Americans are struggling with anxiety, depression, grief or isolation. Having authentic ads regarding mental health services, like this ad for Real, is an avenue for people to seek out help and continue the fight to destigmatize mental health struggles.
      </p>
    </div>
  );
}

export default PostMentalHealth;
