import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { externalLink } from './Posts';

const PostABTesting: FC = () => {

  return (
    <div className="blog-post">
      <h3>"Creative is King"</h3>
      <p>If you are a part of the advertising industry in any shape or form, it is almost impossible to never have heard this expression. As much as simplistic it might be, it is true and holds even more importance in this post-IDFA world.</p>
      <p>
        <i>Creative is King</i>, and every single one of us is trying to find the best recipe to churn out
        {externalLink('https://www.useblitz.com/blogs/how-to-create-a-winning-ad', 'winning ads')} day in and day out. From ideation to implementation, it is a painstakingly slow process of testing and developing new creative without any guarantee of success
      </p>
      <p><i>Surely, there must be a way to avoid spending months developing and testing new assets?</i></p>
      <p><i>Surely, we must have something that ensures we have a better chance to see into the future and find a winning ad?</i></p>
      <p>Well, much to the relief of every marketer, there is, and it is the perfect amalgamation of art and math, of creativity and logicality.</p>
      <p><strong>Welcome to the ABC’s of A/B testing!</strong></p>

      <h3>What is an A/B test?</h3>
      <p>In simple words, it is a split test that helps us to compare two different variables to find out which one works better for the business goal.  We create two identical versions of an ad we want to test with a single difference between the two in an attempt to create a clean test free of bias and to know the impact of that one single variable on the performance of the ad.</p>
      <p>We can test out ad creatives with different color, audio, audience segments, or placements to find the ones best suited for our business. The question could simply be a matter of determining which color our prospective audience loves (is it a powdery purple or bright orange?), or which is the right audience for a particular type of messaging (is it the millennials or the Gen-Z?).</p>
      <p>All of these questions and more, previously thought to be possible only via the traditional method of trying and failing till we reach a conclusion, are now possible to answer with an A/B test. An A/B test helps us compare the different variations not just quickly but in a more bias-free manner based on cold hard numbers.</p>
      <p>It sounds like a magic trick, and no wonder that raises a question on its validity!</p>
      <p><strong>Well, let’s take a look behind the curtains then: let’s see how this trick actually works.</strong></p>

      <h3>How does an A/B test work?</h3>
      <p>At its core, the A/B test applies very simple logic. It splits and creates two almost identical variations barring the single variable we want to test out. After we select the variable we want to test, the ad platform divides the budgets equally and randomly divides exposure between the two versions of the selected variable.</p>
      <p>Let’s take a look at an example of this.</p>
      <p>Say we want to test out a creative with two different background music, one is a 60s pop song (<i>A Man without Love</i>) and the other is the latest sensation (<i>My Money Don’t Jiggle</i>). The ad creative will have the same elements, be it color or messaging or the CTA, except this audio. Now, we will create two separate variations with equal budgets, same set of placements and most importantly a similar set of audience with similar traits who will not overlap with each other, meaning no one person will see both ad variations. This ensures that the audience is evenly split and statistically comparable.</p>
      <p>The test then runs with the assigned budget for the stipulated time and at the end of it, we can measure the performance of each strategy on either a cost per impression, cost per conversion basis, or any other metric we see fit. We can examine the data from  spends to all the funnel events, helping us pick the reliable winner. To make this process even easier, the platform will also declare a winner based on the data and statistical probability.</p>

      <h3>Best Practices</h3>
      <p>The A/B test offers the best solution to test multiple variations of a single ad till we reach a point through iterations where we have the holy grail of advertising: a winning ad.</p>
      <p>However, for an A/B test to efficiently do this, there are a few best practices we need to keep in mind:</p>

      <h4>One Variable at a Time</h4>
      <p>We will have more conclusive results for the test if our campaigns/ad sets are otherwise identical except for the variable that we are testing. If we test multiple variables at a time, we will still find a winner, but we will never be able to pinpoint the precise reason that made that particular ad a winner.</p>
      <h4>A Measurable Hypothesis</h4>
      <p>
        Once we figure out what we want to test or what question we want to answer, creating a testable hypothesis enables us to improve future campaigns.
        <ul>
          <li>For example, we might start with a general question such as <i>"Do we get better results when we use a CTA in the ad?"</i></li>
          <li>This question can be further refined to something more specific, such as <i>"Do we get a lower cost per result when we use the CTA of ‘Play Now’ compared to ‘Install Now’ ?"</i></li>
          <li>From there, we can set a specific hypothesis, such as <i>"Our cost per result will be lower when we use ‘Play Now’ as the CTA."</i></li>
        </ul>
      </p>
      <p>This helps us interpret our results and empowers us to take specific action on future campaigns.</p>

      <h4>The Right Audience</h4>
      <p>Our audience should be large enough to support the test, and we shouldn't use this audience for any other Facebook campaign that we are running at the same time. Overlapping audiences may result in delivery problems and contaminate test results.</p>

      <h4>Ideal Time Frame</h4>
      <p>For the most reliable results, we generally recommend a minimum of 7-day tests.  A/B tests can only be run for a maximum of 30 days, but tests shorter than 7 days often produce inconclusive results.</p>
      <p>Our ideal testing time frame can also depend on the objective. For example, if we know our typical customer takes more than 7 days to convert after seeing an ad, we would want to run the test for a longer period of time (such as 15 days) to allow enough time for these expected typical conversions to occur.</p>

      <h4>Optimum Budget</h4>
      <p>The A/B test should have a budget that will produce enough results to confidently determine a winning strategy.</p>

      <h3>How to Create an A/B test?‍</h3>
      <p>Several platforms like TikTok, Facebook and Google have the option of leveraging A/B testing to find out the winning combination for an ad. However, Facebook Ads is the widely used platform for A/B testing owing to the speed and efficiency of the experiments and the feature availability across different campaigns types.</p>
      <p>We will be learning more about creation and evaluation of A/B tests through the lens of Facebook Ads platform in this post.</p>
      <p>There are several ways to create A/B tests on Facebook depending on the variable we are trying to test and where we start creating the A/B test:</p>

      <h4>From Ads Manager Toolbar</h4>
      <p>
        Generally speaking, we create an A/B test from the Ads Manager toolbar, which uses an existing ad campaign or ad set as a template for the test. To use this toolbar:
        <ol>
          <li>Go to the Ads Manager main table. This page lists all of the available ads, campaigns, and ad sets in your ad account</li>
          <li>Tick the box to the left of the campaign(s) or ad set(s) that we want to use for an A/B test</li>
          <li>From the toolbar above, click A/B Test</li>
          <li>Select an available variable and follow the on-screen instructions</li>
        </ol>
      </p>
      <p>Note: If we select Custom, we can create a test by duplicating the selected campaign or ad set and edit any variables in the new test campaign.</p>
      <p>Beyond the simple A/B test structured from the toolbar, there are a few other ways we can set up the test:</p>
      
      <h4>From the Experiments Tool</h4>
      <p>
        <ol>
          <li>In Experiments, we can create or duplicate ad campaigns to compare and determine a winning strategy</li>
          <li>The Experiments tool is useful when we want the flexibility of fine-tuning the ad campaigns before starting the test or when we want to use several existing ad campaigns for the test.</li>          
        </ol>
      </p>

      <h4>During the Creation of a New Campaign</h4>
      <p>
        <ol>
          <li>We can choose to start the A/B test when creating a new ad campaign</li>
          <li>This option is useful when we want to conveniently test a variable while creating a new ad campaign. </li>
        </ol>
      </p>

      <h4>By Duplicating an Existing Campaign/Ad Set/Ad</h4>
      <p>
        <ol>
          <li>We can duplicate and create an A/B test in Ads Manager if we want to quickly make a change to an existing ad and compare performance</li>
          <li>Duplicating an existing campaign lets us compare by changing one or more variables to determine a winning strategy.</li>
          <li>Duplicating an existing ad set or ad lets us choose a variable and it will then guide us through the kinds of changes we can make to compare and determine a winning strategy.</li>
        </ol>
      </p>
      <p>Note: All A/B tests use the same underlying technology to compare a different creative, audience or placement and determine a winner. All of your test results will be available in the Experiments section once the test has been finished.</p>

      <h3>Evaluating the Test Results</h3>
      <p>The winning variation (campaign/ad set/ad) is determined by comparing the cost per result of each campaign, where the result is the event that we selected during test creation such as cost per click, cost per purchase etc.</p>
      <p>Based on the activity observed during the test, Facebook simulates possible outcomes tens of thousands of times to determine how often the winning outcome would have won. This statistically calculates a winner with a certain confidence percentage, or chance of similar results if the test was repeated.</p>
      <p>For example, let's say that we run a creative test with 2 image creatives: one with animated characters and one with human characters. It is determined that the ad with human characters was the winner with the lowest cost per result and a 95% chance that we would get these results again. With these results, we adopt the winning strategy.</p>
      <p>If the results are less confident than a certain percentage (75%), the variables we tested are not likely to be a key factor in the performance of the ad, and we should repeat the test with new variables or we can test the campaign again with a longer schedule or higher budget. More time and budget typically produces more data, which helps us be more confident in your results.</p>

      <h3>What Next?</h3>
      <p>
        Once we have reviewed the results and understood which strategy performed best, we can:
        <ol>
          <li>Create a new ad from the winning ad set to be used across the campaigns for better results</li>
          <li>Create a new campaign based on what we learned to give it the best chance at optimizing results</li>
          <li>Run more tests to refine our strategy further</li>
          <li>For example, if we found out that the audience in the age group 18-30 has outperformed the audience in the age group of 30-45, we can carry out another test to see if we can narrow down the 18-30 age group further. We can run a test for the 18-30 ad group split between males and females, or we can run a test splitting this age group into 18-24 and 25-30 groups</li>
        </ol>
      </p>
      <p>Remember, A/B testing is not a single use silver bullet to instantly create a winning ad. Rather, it is a tool you can use to expedite creative learnings and campaign setup learnings to refine your strategy and find the best possible version of the ad setup that you can deliver to the audience. Repeated testing is the best way to extract the most you can, and continually developing and expanding your creative library of winners is the best strategy.</p>
      
    </div>
  );
}

export default PostABTesting;
