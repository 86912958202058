import React, { useContext } from 'react';
import { AppContext } from '../appContext';
import { Modal } from 'frontend-core';

function CustomPlanEnquirySuccess () {
  const appContext = useContext(AppContext);

  const footer = (
    <button
      className="btn"
      onClick={() => appContext.authModal.setShowCustomPlanEnquirySuccess(false)}
    >
      OK
    </button>
  );

  return(
    <Modal
      title="Enquiry Sent!"
      show={appContext.authModal.showCustomPlanEnquirySuccess}
      onClose={() => appContext.authModal.setShowCustomPlanEnquirySuccess(false)}
    >
      Thanks for contacting us regarding a custom plan. We will get back to you shorty.
    </Modal>
  );
}

export default CustomPlanEnquirySuccess;
