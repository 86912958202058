import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { externalLink } from './Posts';

const PostRiseOfTikTok: FC = () => {

  return (
    <div className="blog-post">
      <h3>The Social Network</h3>
      <p>When Six Degrees was born in May of 1997, few thought a website aggregating contacts had any future. Not even the hype of early 2000s sites like Friendster, Orkut and MySpace could indicate the scope and influence these social networking sites would have in the next few years.</p>
      <p>Even though quite a few existed before, it was Facebook that connected the world in a way no other site could do, truly creating a global social network. From there on, social sites proliferated; many new sites popped up, each bringing in something slightly new and different and most of them fading as quickly as they had started.</p>
      <p>
        Hike happened, topped the charts and soon churned out into {externalLink('https://qz.com/india/1959212/why-kavin-bharti-mittals-hike-messenger-shut-lost-to-whatsapp/#:~:text=leaving%20the%20app.-,On%20Jan.,Store%20and%20Apple%20App%20Store.', 'oblivion')}. The same fate was reserved for Vine, which despite garnering enough attention for a Twitter acquisition, was
        {externalLink('https://businesschief.com/technology-and-ai/rise-and-fall-vine-brief-timeline-1', 'dusted away')} in 2019. If you thought Instagram was a breath of fresh air, that's probably because you forgot about DailyBooth, the photo-first social platform, which crashed and burned faster than you could imagine. Despite gaining popularity among celebrities, it ended in just under 3 years when
        {externalLink('https://www.businessinsider.com/airbnb-acquires-dailybooth-to-build-up-team-2012-7?IR=T', 'Airbnb')} acquired the platform. Apple and Google have also tried and failed to create the next big thing in this field.
        {externalLink('https://www.idropnews.com/apple-history/looking-back-on-ping-apples-failed-social-media-platform/144049/', 'iTunes Ping')}, Google Buzz, and Hangouts are a few ventures that no one wants to talk about anymore.
      </p>
      <p>Try as they might to disrupt the social media behemoths, these smaller platforms could not compete. The few that showed true promise were quickly swallowed by the bigger whales.</p>
      <p>
        Snapchat, believed at one point to be destined to overtake Facebook, couldn't move past its stronghold on the teen demographic,
        {externalLink('https://www.vox.com/the-goods/22519100/snapchat-spotlight-forgotten-gen-z', 'failing to attract older users')}. Instagram, on the other hand, did manage to become the next big thing. Who can forget the craze it generated with over
        {externalLink('https://www.cbsnews.com/news/instagram-for-android-gets-1-million-downloads-in-first-day/#:~:text=(CBS%20News)%20Instagram%2C%20the,garnered%20over%201%20million%20downloads.', 'a million downloads')} in a single day when it was introduced on Google Play Store? But just when it looked possible for Instagram to truly compete with the big players, they were
        {externalLink('https://techcrunch.com/2012/04/09/facebook-to-acquire-instagram-for-1-billion/', 'snatched up by Facebook')}, bringing it into the fold of what is now known as Meta. The big players remained in control of the game.
      </p>
      <p>That was until:</p>

      <h3>TikTok, The New Kid in Town</h3>
      <p>
        2016.<br />
        It all started when Douyin was launched in China in {externalLink('https://www.historyofinformation.com/detail.php?id=5298', '2016')}. It was originally a short form video platform, with its primary focus on entertainment videos such as pranks, stunts, and dances. In a time when Chinese apps, despite their huge domestic success, failed to garner similar response in international markets (namely WeChat), there was an air of uncertainty around the international potential of this new video app as well.
      </p>
      <p>But Douyin's parent company, ByteDance, was not an amateur. They had proven before with apps like Toutiao that they had it in them to churn out successful apps inside and outside the mainlands of China. With the belief in their product being the next big thing, they launched TikTok, the international version of Douyin aimed at a global market. The numbers started to go up; the response was promising, but there was still something missing.</p>
      <p>
        Born in 2014, another product of Chinese developers, Musical.ly was a social media platform that allowed users to create lip syncing videos. With the integrations of millions and millions of audio tracks available to the users, it became a huge hit, reaching 90 million users in less than 2 years and crossing
        {externalLink('https://digital.hbs.edu/platform-digit/submission/musical-ly-breaking-through-the-social-media-clutter/', '200 million downloads')} by end of 2017. It created enough noise to grab the attention of ByteDance.
      </p>
      <p>
        2018 saw the {externalLink('https://www.bbc.com/news/business-41939296', 'merger')} that changed everything. ByteDance acquired Musical.ly and integrated it within TikTok, and the TikTok we know today was born. The growth this time around was massive, breaching the
        {externalLink('https://www.businessofapps.com/data/tik-tok-statistics/', '2 billion downloads')} mark in 2020. The platform had established itself as the next big thing.
      </p>
      <p>With an AI platform integrated within the app, TikTok was able to identify each and every user's likes and interests and feed them infinite short videos of the exact content they desired. A simple swipe up to the next video cut down the effort needed to jump to the next content to practically zero, creating a super addicting experience.</p>
      <p>
        TikTok is one of the fastest growing businesses and one of the more fascinating
        {externalLink('https://www.bbc.com/news/technology-53640724', 'stories')} of our times, falling just behind Facebook and Instagram in cumulative downloads  while being the
        {externalLink('https://www.businessofapps.com/data/most-popular-apps/', 'most downloaded app')} worldwide in 2021. Statistics don't lie, and they will tell you that right now, no one can deny TikTok's place at the big boy's table.
      </p>

      <h3>The New Era: TikTok Ads</h3>
      <p>Given how savvy ByteDance has been with the evolution of TikTok, it should have come as no surprise when they integrated advertising with the launch of TikTok Ads in 2019.</p>

      <h4>The Appeal</h4>
      <p>
        The appeal was there: businesses had seen trends going viral worldwide on TikTok like <i>#posechallenge</i>, <i>#filterswitch</i>, <i>#dontjudgemechallenge</i>, <i>#bottlecapchallenge</i>, and who did not get on the
        {externalLink('https://www.scmp.com/lifestyle/food-drink/article/3078561/story-behind-dalgona-coffee-coronavirus-social-media-craze', 'Dalgona coffee trend')} !
      </p>
      <div className="center mb-30">
        <iframe width="504" height="283" src="https://www.youtube.com/embed/VnD08NSJWPQ" title="DALGONA COFFEE TikTok Compilations | TikTok Challenge" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      </div>
      <p>
        TikTok was also responsible for propelling "{externalLink('https://www.buzzfeednews.com/article/laurenstrapagiel/tiktok-lil-nas-x-old-town-road', 'Old Town Road')}" to the top of the music charts in 2019.
        {externalLink('https://www.insider.com/charli-d-amelio-bio-how-old-tiktok-famous-renegade-super-bowl-2020#some-of-her-most-popular-tiktoks-are-her-dancing-to-the-song-lottery-renegade-more-commonly-known-as-just-the-renegade-3', 'Charli D\'Amelio')} became the Queen of TikTok, reigning supreme with over 145 million followers on TikTok. Celebrities rushed to join, and slowly the uncredited promoters of TikTok brought the app to every corner of the world. Clearly, there was a huge opportunity for brands to leverage the platform for their own growth.
      </p>

      <h4>Universal App</h4>
      <p>
        Despite the stereotype of the platform as “GenZ specialized”, TikTok has been reaching an increasingly broader audience. From 38% of its audience falling in the over 30 age group in 2021 to 53% in 2022 (source:
        {externalLink('https://www.businessofapps.com/data/tik-tok-statistics/', 'Business of Apps')}), TikTok's appeal to users of all ages increases every year.
      </p>
      <p>
        The platform's success, however, has not been solely due to its addictive design and the widespread creativity of its users. TikTok has risen at a moment when Facebook has struggled to hold the attention of its audience, and the
        {externalLink('https://www.forbes.com/sites/abrambrown/2022/02/04/facebook-meta-growth-zuckerberg-reels-tiktok-videos-creators-pay/?sh=6f1adf934008', 'spotlight has now shifted')}. Despite their aggressive cloning of compelling features from popular social media platforms, Facebook has yet to match the “zeitgeisty” feel of TikTok, proven by the decline in daily active users. And where there is audience, attention, and growth, there is the potential for brands to make money.
      </p>

      <h4>AI to the Rescue</h4>
      <p>Remember the AI that makes the #ForYouPage so compelling on TikTok? Well, the same technology that helped TikTok endear itself to every user with content primed for them was applied to ads as well. Finding the right audience for the right advertiser with the right pitch is a core problem in marketing, and TikTok was perfectly positioned to supply the answer. Its' advanced AI, backed by years of learning, was ready to compete with the big players in the paid media advertising world.</p>

      <h4>Just Video Content? No Problem!</h4>
      <p>While every other ad platform out there provides a myriad of creative formats, be it videos, gifs, images, or text copies, TikTok Ads decided to keep it simple. They stuck to the USP that brought them recognition and success: video.</p>
      <p>This decision was based on the belief that video ads would generate the high-value performance demanded by advertisers, turning the experience of watching an ad into an organic experience of the app itself. Rather than following the conventions laid down by the pioneers of paid social advertising, TikTok Ads doubled down on the feature that made their app unique in the first place. User experience was not the sacrifice they wanted to make, and so they found ways to make it work.</p>
      <p>TikTok Ads introduced placements like in-feed ads, top view ads, brand takeovers, branded hashtags and branded filters & effects. These ad types managed to feel organic while still catering to the diverse needs of advertisers, with in-feed ads offering short and quirky placements, while top view ads allowed a longer time to make their pitch. Brand hashtags and special effects take the integration of ads with the organic content to the next level, playing into the trend economy endemic to the platform.</p>

      <h4>Influencer Marketing</h4>
      <p>By far the biggest jackpots TikTok was able to hit was influencer marketing. Through special ads known as Spark Ads that appear to a user as posted from the influencer's account but can be configured as regular ads, the platform allowed advertisers to directly capitalize on the power of compelling content creators. Just as TikTok made stars out of everyday users, it helped monetize their content through influencer marketing. With the same people making ads as organic content on the platform, the distinction between the two vanished, leading to the rise of UGC (user generated content) in the advertising world. </p>
      <p>
        To further incentivize small businesses to try their ad platform, TikTok launched the 
        "{externalLink('https://boosted.lightricks.com/tiktoks-back-to-business-program-everything-small-business-owners-need-to-know/', 'Back to Business')}" program, providing $100 million in ad credits. TikTok is currently the top app for driving consumer spending,
        {externalLink('https://www.businessofapps.com/data/tik-tok-statistics/', 'generating 4.6 billion dollars in revenue')}, up by 142% from last year. The platform is well positioned to continue to grow its advertising business, and it has been aggressively rolling out new features to compete with Facebook Ads.
      </p>

      <h3>TikTok is just getting started!</h3>
      <p>At Blitz, we believe strongly in the power of TikTok ads. We were a launch partner with the ad platform in 2019, and we have been strong proponents of the platform with our client roster. 60% of our clients are activated on TikTok, and even with advertisers already seeing success on Facebook and Google ads, the platform provides a unique opportunity to scale while simultaneously testing creative.</p>
      <p>TikTok network is growing with every passing second, with {externalLink('https://www.oberlo.in/blog/tiktok-statistics', '9 out of 10 users accessing the app on a daily basis, averaging 52 minutes per day')}. This kind of ever growing, enriching and engaging audience available via TikTok Ads makes us confident that it is the place to be for all advertisers. The style of UGC content popularized on the platform has now migrated elsewhere, with advertisers on Facebook and YouTube borrowing the raw, authentic style of video popularized on TikTok.</p>

      <p>We've generated and run thousands of successful video ads on TikTok for brands like Gopuff, Pixelcut, and more:</p>
      <div className="center mb-30">
        <iframe width="504" height="283" src="https://www.youtube.com/embed/2H0cqRudjpw" title="TikTok Reel" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      </div>
      <p>If you're looking to get into TikTok ads, we are experts in generating compelling UGC content that will be successful both on this platform and others. {externalLink('https://www.useblitz.com/capabilities', 'Contact us')} to get set up on TikTok ads.</p>
    </div>
  );
}

export default PostRiseOfTikTok;
