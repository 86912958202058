import { fromJson, jsonConvert } from "../../Common/JsonUtil";
import { ImageInput } from "./ImageInput";
import { Input } from "./Input";
import { MultiInput } from "./MultiInput";
import { StringInput } from "./StringInput";

export function convertInput(inp:Input): Input {
    let elemJson: any = jsonConvert.serialize(inp);
    let type: String = elemJson["type"];
    switch (type) {
        case "STRING":
            return fromJson(elemJson, StringInput);
        case "IMAGE":
            return fromJson(elemJson, ImageInput);
        case "MULTI":
            return fromJson(elemJson, MultiInput);
        default:
            return inp;
    }
}