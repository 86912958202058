import TextElement, { ITextElement } from "./TextElement";
import { Template } from "../render/templates/Template";
import { fromJson } from "../Common/JsonUtil";
import { VideoPlatform } from "../render/templates/VideoPlatform";
import { Input } from "../render/inputs/Input";
import { templateJson } from "./templatejsons/template21";
import aiAdTemplates from "../../../constants/aiAdTemplates";


export interface IDrawCanvas {
}

class DrawCanvas implements IDrawCanvas {

  private context: any;
  private canvasId: string
  private canvas: HTMLCanvasElement | null = null;
  private textElement: ITextElement | null = null;

  constructor (canvasId: string) {
    this.canvasId = canvasId;
    this.getContext();
  }

  getContext() {
    const canvas: HTMLCanvasElement | null = document.getElementById(this.canvasId) as HTMLCanvasElement;
    this.canvas = canvas;
    if (canvas) {
      this.context = canvas.getContext('2d');
      this.initializeComponents();
      this.renderElements();
    }
  }

  initializeComponents () {
    this.textElement = new TextElement(this.context);
  }

  renderElements() {
    if (!this.canvas) {
      return;
    }
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // Render all the components here looping through JSON.
    // Need to write a switch statement based on the JSON and render them
    // Below is the example of a text component
    this.textElement?.render({
      x: 100,
      y: 100,
      text: 'TextElement Component from Canvas AI',
      color: '#FFFFFF',
      font: 'bold 40px Arial',
      background: '#c00000'
    });
  }

  async processTemplate(template:Template, platfrom:VideoPlatform) {
    let alltextVariables:Input[] = template.variable.filter(variable => (variable.type.toString() == "STRING"));
    let textVariables:Input[] = template.variable.filter(variable => (variable.type.toString() == "STRING") && (variable.name.startsWith("GRAPHIC_") || variable.name.startsWith("TEXT_") || variable.name.startsWith("PRODUCT_")));
    let imageVariables:Input[] = template.variable.filter(variable => (variable.type.toString() == "IMAGE") && (variable.name.startsWith("GRAPHIC_") || variable.name.startsWith("FOOTAGEIMAGE_") || variable.name.startsWith("PRODUCT_")) && !variable.name.endsWith("_PORTRAIT") && !variable.name.endsWith("_LANDSCAPE"));
    let brandImages:Input[] = template.variable.filter(variable => (variable.type.toString() == "IMAGE") && (variable.name.startsWith("WATER_MARK_IMAGE") || variable.name.startsWith("END_CAP_LOGO_IMAGE")));
    let brandImage:Input | null = brandImages.length > 0? brandImages[0]:null;
    // textVariables[0].value ='ACME ARE SHOES DESIGNED TO BE WORN EVERY DAY.'
    // brandImages[0].value = "https://media.useblitz.com/static/blitz/img/logo.png"
    console.log("BRAND IMAGES",brandImage);
    console.log("IMAGES",imageVariables);
    console.log("TEXTS",textVariables);
    await template.process(platfrom);
    let can = document.createElement("canvas");
    can.width = 1080;
    switch (platfrom) {
      case VideoPlatform.FACEBOOK:
        can.height = 1080;
        break;
      case VideoPlatform.INSTAGRAM:
        can.height = 1350;
        break;
      case VideoPlatform.SNAPCHAT:
        can.height = 1920;
        break;
      case VideoPlatform.INSTA_STORY:
        can.height = 1920;
        break;
      default:
        can.height = 1920;
        break;
    }
    document.body.append(can);
    await template.renderInCanvas(1, (platfrom), can);
  }

  async processOneByOne() {
    for (let i =0; i < aiAdTemplates.length; i++) {
      if (aiAdTemplates[i].id<19) continue;
      let template = fromJson(aiAdTemplates[i].json, Template);
      await this.processTemplate(template, VideoPlatform.FACEBOOK);
      // await this.processTemplate(template, VideoPlatform.INSTAGRAM);
      // await this.processTemplate(template, VideoPlatform.INSTA_STORY);
      // await this.processTemplate(template, VideoPlatform.SNAPCHAT);
    }
  }

  test() {
    try {
      let template: Template;
      template = fromJson(templateJson, Template);
      console.log('template.variable', template.variable);
      let alltextVariables:Input[] = template.variable.filter(variable => (variable.type.toString() == "STRING"));
      let textVariables:Input[] = template.variable.filter(variable => (variable.type.toString() == "STRING") && (variable.name.startsWith("GRAPHIC_") || variable.name.startsWith("TEXT_") || variable.name.startsWith("PRODUCT_")));
      let imageVariables:Input[] = template.variable.filter(variable => (variable.type.toString() == "IMAGE") && (variable.name.startsWith("GRAPHIC_") || variable.name.startsWith("FOOTAGEIMAGE_") || variable.name.startsWith("PRODUCT_")) && !variable.name.endsWith("_PORTRAIT") && !variable.name.endsWith("_LANDSCAPE"));
      let brandImages:Input[] = template.variable.filter(variable => (variable.type.toString() == "IMAGE") && (variable.name.startsWith("WATER_MARK_IMAGE") || variable.name.startsWith("END_CAP_LOGO_IMAGE")));
      let brandImage:Input | null = brandImages.length > 0? brandImages[0]:null;
      console.log("BRAND IMAGES",brandImage);
      console.log("IMAGES",imageVariables);
      console.log("TEXTS",textVariables);
      // textVariables[1].value = "- Sriharsha Reddy";
      // textVariables[0].value = "welcome welcome welcome welcome welcome welcome welcome welcome welcome welcome welcome welcome welcome welcome welcome welcome welcome ";
      // textVariables[1].value = "SRIHARSHA REDDY DEVARAM".toLowerCase();
      // imageVariables[1].value = "https://media.useblitz.com/static/blitz/img/logo.png";
    
      this.processOneByOne();
      
    } catch (e) {
      console.log((<Error>e));
    }
  }

}

export default DrawCanvas;
